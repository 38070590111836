import React from "react";
import { ProCard, ProForm } from "@ant-design/pro-components";
import {
  Row,
  Col,
  Typography,
  Divider,
  Button,
  Input,
  Select,
  Form,
} from "antd";
import lang from "../../../../lang";
import PurchaseRequestAddTable from "./PurchaseRequestAddTable";
import TinyMCEEditor from "../../../../common/base/Editor";


const { Title } = Typography;
const { Option } = Select;

const PurchaseRequestAdd: React.FC = () => {
  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <Title level={2}>{lang.get("purchase", ["Add Purchase Request"])}</Title>

      <ProCard>
        <ProForm layout="vertical" submitter={false}>
          {/* First Row */}
          <Row gutter={[16, 5]}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Purchase Request Code"])}
                  </span>
                }
                name="purchaseRequestCode"
                rules={[
                  {
                    required: true,
                    message: lang.get("purchase", ["Required"]),
                  },
                ]}
              >
                <Input
                  size="large"
                  value="#PR-00003-2025"
                  placeholder="#PR-00003-2025"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Tags"])}
                  </span>
                }
                name="tags"
              >
                <Select
                  mode="tags"
                  placeholder={lang.get("purchase", ["Add Tags"])}
                  size="large"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Currency"])}
                  </span>
                }
                name="currency"
                rules={[
                  {
                    required: true,
                    message: lang.get("purchase", ["Required"]),
                  },
                ]}
              >
                <Input value="LKR" placeholder="LKR" disabled size="large" />
              </Form.Item>
            </Col>
          </Row>

          {/* Second Row */}
          <Row gutter={[16, 5]}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Purchase Request Name"])}
                  </span>
                }
                name="purchaseRequestName"
                rules={[
                  {
                    required: true,
                    message: lang.get("purchase", ["Required"]),
                  },
                ]}
              >
                <Input
                  placeholder={lang.get("purchase", ["Enter Request Name"])}
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Project"])}
                  </span>
                }
                name="project"
              >
                <Select
                  placeholder={lang.get("purchase", ["Select Project"])}
                  size="large"
                >
                  <Option value="Project1">
                    {lang.get("purchase", ["Project 1"])}
                  </Option>
                  <Option value="Project2">
                    {lang.get("purchase", ["Project 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Sale Estimate"])}
                  </span>
                }
                name="saleEstimate"
              >
                <Select
                  placeholder={lang.get("purchase", ["Select Sale Estimate"])}
                  size="large"
                >
                  <Option value="Estimate1">
                    {lang.get("purchase", ["Estimate 1"])}
                  </Option>
                  <Option value="Estimate2">
                    {lang.get("purchase", ["Estimate 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Third Row */}
          <Row gutter={[16, 5]}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Type"])}
                  </span>
                }
                name="type"
              >
                <Select
                  placeholder={lang.get("purchase", ["Select Type"])}
                  size="large"
                >
                  <Option value="Type1">
                    {lang.get("purchase", ["Type 1"])}
                  </Option>
                  <Option value="Type2">
                    {lang.get("purchase", ["Type 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Sale Invoices"])}
                  </span>
                }
                name="saleInvoices"
              >
                <Select
                  placeholder={lang.get("purchase", ["Select Sale Invoices"])}
                  size="large"
                >
                  <Option value="Invoice1">
                    {lang.get("purchase", ["Invoice 1"])}
                  </Option>
                  <Option value="Invoice2">
                    {lang.get("purchase", ["Invoice 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Requester"])}
                  </span>
                }
                name="requester"
              >
                <Select
                  placeholder={lang.get("purchase", ["Select Requester"])}
                  size="large"
                >
                  <Option value="Madusanka">
                    {lang.get("purchase", ["Madusanka"])}
                  </Option>
                  <Option value="Thimira">
                    {lang.get("purchase", ["Kasun"])}
                  </Option>
                  <Option value="JohnDoe">
                    {lang.get("purchase", ["John Doe"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Fourth Row */}
          <Row gutter={[16, 5]}>
         
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Share to Vendors"])}
                  </span>
                }
                name="shareToVendors"
              >
                <Select
                  placeholder={lang.get("purchase", [
                    "Select Vendors to Share",
                  ])}
                  size="large"
                >
                  <Option value="Vendor1">
                    {lang.get("purchase", ["Vendor 1"])}
                  </Option>
                  <Option value="Vendor2">
                    {lang.get("purchase", ["Vendor 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 5]}>
            <Col xs={24}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Description"])}
                  </span>
                }
                name="Description"
              >
                <TinyMCEEditor />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <PurchaseRequestAddTable />

          <Divider />

          {/* Submit Section */}

          <Row justify="end" gutter={16}>
            <Col>
              {/* Close Button */}
              <Button
                onClick={() => console.log(lang.get("sales", ["Close"]))}
                style={{
                  backgroundColor: "white",
                  borderColor: "#d9d9d9",
                  color: "#000",
                  marginRight: 8,
                }}
              >
                {lang.get("sales", ["Close"])}
              </Button>

              {/* Save Button */}
              <Button
                type="primary"
                onClick={() => console.log(lang.get("sales", ["Save"]))}
                style={{
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  color: "#fff",
                  marginRight: 8,
                }}
              >
                {lang.get("sales", ["Save"])}
              </Button>
            </Col>
          </Row>
        </ProForm>
      </ProCard>
    </div>
  );
};

export default PurchaseRequestAdd;
