import { useEffect, useState } from "react";
import { Form, Input, Button, Table, Typography, Row, Spin } from "antd";
import lang from "../../../../../../lang";

interface Note {
  name: string;
  content: string;
}

const Notes = () => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();

  const onFinish = (values: { noteContent: string }) => {
    const newNote = {
      name: lang.get("notes", ["Note"]) + ` ${notes.length + 1}`,
      content: values.noteContent,
    };
    setNotes([...notes, newNote]);
    form.resetFields();
  };

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  const columns = [
    {
      title: lang.get("notes", ["Name"]),
      dataIndex: "name",
      key: "name",
    },
    {
      title: lang.get("notes", ["Content"]),
      dataIndex: "content",
      key: "content",
      render: (text: string) => (
        <Typography.Paragraph ellipsis={{ rows: 2, expandable: true }}>
          {text}
        </Typography.Paragraph>
      ),
    },
  ];

  return (
    <div style={{ padding: "16px", background: "#f5f7fa" }}>
      <Typography.Title level={4}>
        {lang.get("notes", ["Personal Notes"])}
      </Typography.Title>

      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="noteContent"
          rules={[
            {
              required: true,
              message: lang.get("notes", ["Note content is required"]),
            },
          ]}
        >
          <Input.TextArea
            rows={5}
            placeholder={lang.get("notes", ["Type your note here..."])}
            style={{ resize: "none" }}
          />
        </Form.Item>
        <Row justify="end">
          <Button type="primary" htmlType="submit">
            {lang.get("notes", ["Save Note"])}
          </Button>
        </Row>
      </Form>

      <Typography.Title level={5} style={{ marginTop: "24px" }}>
        {lang.get("notes", ["Saved Notes"])}
      </Typography.Title>
      {loading ? (
        <Spin style={{ display: "block", margin: "0 auto" }} />
      ) : (
        <Table
          dataSource={notes}
          columns={columns}
          rowKey="name"
          pagination={{ pageSize: 5 }}
          bordered
        />
      )}
    </div>
  );
};

export default Notes;
