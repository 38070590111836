// ProjectTable.tsx
import React, { useRef, useState } from "react";
import { Button, InputRef, Input, Space, DatePicker } from "antd";

import { SearchOutlined } from "@ant-design/icons";
import { members } from "../../../../common/data/Members";
import { ActionType, ProTable } from "@ant-design/pro-components";
import lang from "../../../../../../lang";
import Export from "../../../../../../common/components/Export";

const { RangePicker } = DatePicker;
const getMemberIdByName = (name: string): number | undefined => {
  const member = members.find((m) => m.name === name);
  return member ? member.id : undefined;
};

interface TimesheetTableProps {
  selectedMemberId: string | "all";
  projectMemberIds: string[];
}

const timesheetData = [
  {
    member: "John Doe",
    memberId: getMemberIdByName("John Doe"),
    task: "Design Landing Page",
    timesheetTags: "Design",
    startTime: "09:00 AM",
    endTime: "05:00 PM",
    note: "Worked on the hero section",
    timeH: 8,
    timeDecimal: 8.0,
    options: "",
    projectIds: [1, 2, 4, 10],
  },
  {
    member: "Jane Smith",
    memberId: getMemberIdByName("Jane Smith"),
    task: "Develop Authentication System",
    timesheetTags: "Development",
    startTime: "10:00 AM",
    endTime: "06:00 PM",
    note: "Implemented OAuth2",
    timeH: 7,
    timeDecimal: 7.0,
    options: "",
    projectIds: [1, 2, 4],
  },
  {
    member: "Michael Jenkins",
    memberId: getMemberIdByName("Michael Jenkins"),
    task: "Marketing Strategy Planning",
    timesheetTags: "Marketing",
    startTime: "10:00 AM",
    endTime: "06:00 PM",
    note: "Developed a strategic plan",
    timeH: 8,
    timeDecimal: 8.0,
    options: "",
    projectIds: [1, 4, 5, 10],
  },
  {
    member: "Jennifer Schramm",
    memberId: getMemberIdByName("Jennifer Schramm"),
    task: "UI Design",
    timesheetTags: "Design",
    startTime: "09:30 AM",
    endTime: "05:30 PM",
    note: "Designed new UI elements",
    timeH: 8,
    timeDecimal: 8.0,
    options: "",
    projectIds: [1, 5, 10],
  },
  {
    member: "Igor Borvibson",
    memberId: getMemberIdByName("Igor Borvibson"),
    task: "UI Testing",
    timesheetTags: "Testing",
    startTime: "09:00 AM",
    endTime: "06:00 PM",
    note: "Conducted end-to-end UI tests",
    timeH: 8,
    timeDecimal: 8.0,
    options: "",
    projectIds: [1, 4, 3],
  },
  {
    member: "Katerina Karenin",
    memberId: getMemberIdByName("Katerina Karenin"),
    task: "Campaign Planning",
    timesheetTags: "Marketing",
    startTime: "09:00 AM",
    endTime: "05:00 PM",
    note: "Developed campaign strategies",
    timeH: 8,
    timeDecimal: 8.0,
    options: "",
    projectIds: [1, 4],
  },
  {
    member: "Roy Anderson",
    memberId: getMemberIdByName("Roy Anderson"),
    task: "Database Optimization",
    timesheetTags: "Development",
    startTime: "10:00 AM",
    endTime: "06:00 PM",
    note: "Improved query performance",
    timeH: 8,
    timeDecimal: 8.0,
    options: "",
    projectIds: [3],
  },
  {
    member: "Tyrion Lannister",
    memberId: getMemberIdByName("Tyrion Lannister"),
    task: "Planning",
    timesheetTags: "Strategy",
    startTime: "08:00 AM",
    endTime: "04:00 PM",
    note: "Led strategic planning meeting",
    timeH: 8,
    timeDecimal: 8.0,
    options: "",
    projectIds: [6],
  },
  {
    member: "Milind Mikuja",
    memberId: getMemberIdByName("Milind Mikuja"),
    task: "Research Analysis",
    timesheetTags: "Research",
    startTime: "10:00 AM",
    endTime: "06:00 PM",
    note: "Conducted research on trends",
    timeH: 8,
    timeDecimal: 8.0,
    options: "",
    projectIds: [6, 9],
  },
  {
    member: "Stanly Drinkwater",
    memberId: getMemberIdByName("Stanly Drinkwater"),
    task: "Product Development",
    timesheetTags: "Development",
    startTime: "08:30 AM",
    endTime: "04:30 PM",
    note: "Worked on product prototype",
    timeH: 8,
    timeDecimal: 8.0,
    options: "",
    projectIds: [6, 7],
  },
  {
    member: "Josef Stravinsky",
    memberId: getMemberIdByName("Josef Stravinsky"),
    task: "Innovation Planning",
    timesheetTags: "Planning",
    startTime: "08:30 AM",
    endTime: "04:30 PM",
    note: "Strategized for space trash project",
    timeH: 7,
    timeDecimal: 7.0,
    options: "",
    projectIds: [8, 7],
  },
  {
    member: "Carry Anna",
    memberId: getMemberIdByName("Carry Anna"),
    task: "Research",
    timesheetTags: "Research",
    startTime: "09:00 AM",
    endTime: "05:00 PM",
    note: "Analyzed colony feasibility",
    timeH: 8,
    timeDecimal: 8.0,
    options: "",
    projectIds: [7],
  },
  {
    member: "Jean Renoir",
    memberId: getMemberIdByName("Jean Renoir"),
    task: "Operations Setup",
    timesheetTags: "Operations",
    startTime: "09:00 AM",
    endTime: "05:00 PM",
    note: "Coordinated logistics setup",
    timeH: 8,
    timeDecimal: 8.0,
    options: "",
    projectIds: [8],
  },
  {
    member: "Ricky Antony",
    memberId: getMemberIdByName("Ricky Antony"),
    task: "Drone Development",
    timesheetTags: "Development",
    startTime: "07:00 AM",
    endTime: "03:00 PM",
    note: "Developed drone technology",
    timeH: 8,
    timeDecimal: 8.0,
    options: "",
    projectIds: [9],
  },
  {
    member: "Emma Watson",
    memberId: getMemberIdByName("Emma Watson"),
    task: "Product R&D",
    timesheetTags: "Research",
    startTime: "08:00 AM",
    endTime: "04:00 PM",
    note: "Research and development activities",
    timeH: 8,
    timeDecimal: 8.0,
    options: "",
    projectIds: [5],
  },
  {
    member: "Alex Johnson",
    memberId: getMemberIdByName("Alex Johnson"),
    task: "Strategic Design",
    timesheetTags: "Design",
    startTime: "09:30 AM",
    endTime: "05:30 PM",
    note: "Contributed to strategic design",
    timeH: 8,
    timeDecimal: 8.0,
    options: "",
    projectIds: [10],
  },
];

const TimesheetTable: React.FC<TimesheetTableProps> = ({
  selectedMemberId,
  projectMemberIds,
}) => {
  const [pageSize, setPageSize] = useState(15);
  const searchInput = useRef<InputRef>(null);
  const filteredData = timesheetData.filter((entry) =>
    projectMemberIds.includes(entry.memberId?.toString() || "")
  );
  const actionRef = useRef<ActionType>();
  const [allSearch, setAllSearch] = useState<string>("");
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);

  const expobj = new Export(
    lang.get("Time", ["Timesheets"]) + ", " + new Date().toDateString()
  );

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([...searchAttributes, { key: field, value }]);
    }
  };

  const displayedData =
    selectedMemberId === "all"
      ? filteredData
      : filteredData.filter(
          (entry) => entry.memberId?.toString() === selectedMemberId
        );

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("Time", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={searchAttributes.find((item) => item.key === field)?.value}
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  actionRef.current?.reload();
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => actionRef.current?.reload()}
                style={{ width: 90 }}
              >
                {lang.get("Time", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("Time", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns = [
    {
      title: "Member",
      dataIndex: "member",
      key: "member",
      ...searchCols("member", lang.get("Time", ["Member"])),
      sorter: true,
    },
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
      ...searchCols("task", lang.get("Time", ["Task"])),
      sorter: true,
    },

    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      ...searchCols("startTime", lang.get("Time", ["Start Time"])),
      sorter: true,
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      ...searchCols("endTime", lang.get("Time", ["End Time"])),
      sorter: true,
    },

    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      ...searchCols("note", lang.get("Time", ["Note"])),
      sorter: true,
    },

    {
      title: "Time (H)",
      dataIndex: "timeH",
      key: "timeH",
      ...searchCols("timeH", lang.get("Time", ["Time (H)"])),
      sorter: true,
    },
    {
      title: "Time (Decimal)",
      dataIndex: "timeDecimal",
      key: "timeDecimal",
      ...searchCols("timeDecimal", lang.get("Time", ["Time (Decimal)"])),
      sorter: true,
    },
    {
      title: "Options",
      dataIndex: "options",
      key: "options",
      ...searchCols("options", lang.get("Time", ["Options"])),
      sorter: true,
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("Time", ["Timesheets"])}
      columns={columns}
      dataSource={displayedData}
      actionRef={actionRef}
      rowKey="id"
      pagination={{
        defaultCurrent: 1,
        pageSize,
        showSizeChanger: true,
        pageSizeOptions: [6, 10, 20],
        showTotal: (total, range) =>
          lang.get("Time", [
            `Showing ${range[0]}-${range[1]} of ${total} items`,
          ]),
        onShowSizeChange: (_, size) => setPageSize(size),
      }}
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <RangePicker />,
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("Time", ["Global Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef.current?.reload();
          }}
        />,
        <Button
          key="csv"
          onClick={() => {
            const csvData = displayedData.map((row) => ({
              [lang.get("Time", ["Member"])]: row.member,
              [lang.get("Time", ["Task"])]: row.task,
              [lang.get("Time", ["Start Time"])]: row.startTime,
              [lang.get("Time", ["End Time"])]: row.endTime,
              [lang.get("Time", ["Note"])]: row.note,
              [lang.get("Time", ["Time(H)"])]: row.timeH,
              [lang.get("Time", ["Time(Decimal)"])]: row.timeDecimal,
              [lang.get("Time", ["Options"])]: row.options,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("Time", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = displayedData.map((row) => ({
              [lang.get("Time", ["Member"])]: row.member,
              [lang.get("Time", ["Task"])]: row.task,
              [lang.get("Time", ["Start Time"])]: row.startTime,
              [lang.get("Time", ["End Time"])]: row.endTime,
              [lang.get("Time", ["Note"])]: row.note,
              [lang.get("Time", ["Time(H)"])]: row.timeH,
              [lang.get("Time", ["Time(Decimal)"])]: row.timeDecimal,
              [lang.get("Time", ["Options"])]: row.options,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("Time", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("Time", ["Member"]),
                  lang.get("Time", ["Task"]),
                  lang.get("Time", ["Start Time"]),
                  lang.get("Time", ["End Time"]),
                  lang.get("Time", ["Note"]),
                  lang.get("Time", ["Time(H)"]),
                  lang.get("Time", ["Time(Decimal)"]),
                  lang.get("Time", ["Options"]),
                ],
              ],
              body: displayedData.map((row) => [
                row.member,
                row.task,
                row.startTime,
                row.endTime,
                row.note,
                row.timeH,
                row.timeDecimal,
                row.options,
              ]),
            });
          }}
        >
          {lang.get("Time", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default TimesheetTable;
