//eslint-disable-next-line
declare const grecaptcha: any // Declare grecaptcha as any type since it's loaded dynamically

const siteKey = '6Ldu5mAqAAAAAJv8WAvypyTFBA4NaIlk2GM6eNwS' // Replace with your reCAPTCHA site key

// Function to execute reCAPTCHA
export default function executeRecaptcha(action: string): Promise<string> {
  return new Promise((resolve, reject) => {
    grecaptcha.ready(() => {
      grecaptcha
        .execute(siteKey, { action })
        .then((token: string) => {
          resolve(token)
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          reject(error)
        })
    })
  })
}
