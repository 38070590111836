import { useState, ChangeEvent } from "react";
import { Card, Steps, Row, Col, Input, Select, Form, Button } from "antd";
import ClientBillingAndShipping from "./CustomerBilling";
import ClientContactBody from "./CustomerContact";
import lang from "../../../lang";
const { Option } = Select;
const { Step } = Steps;
const { TextArea } = Input;

const CustomerAddBody = (props: {
  modelClose: () => void;
  setUpdateTable: () => void;
}) => {
  const [postData, setPostData] = useState({
    company_name: "",
    vat_number: "",
    website: "",
    phone: "",
    address: "",
    groups: [],
    currency: "lkr",
    default_language: "en",
    city: "",
    state: "",
    country: "",
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [clientBillingAddress, setClientBillingAddress] = useState<string[]>(
    []
  );
  const [clientShippingAddress, setClientShippingAddress] = useState<string[]>(
    []
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPostData({
      ...postData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSelectChange = (value: string, field: string) => {
    setPostData({
      ...postData,
      [field]: value,
    });
  };

  const steps = [
    {
      title: lang.get("customer", ["Company"]),
      content: (
        <Row gutter={[16, 4]}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("customer", "company name")}
                </span>
              }
              name="company_name"
            >
              <Input
                id="company_name"
                placeholder={lang.get("customer", ["Company Name"])}
                onChange={handleChange}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("customer", ["VAT Number"])}
                </span>
              }
              name="vat_number"
            >
              <Input
                id="vat_number"
                placeholder={lang.get("customer", ["VAT Number"])}
                onChange={handleChange}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("customer", ["Web site"])}
                </span>
              }
              name="website"
            >
              <Input
                id="website"
                placeholder={lang.get("customer", ["Website"])}
                onChange={handleChange}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("customer", ["Phone"])}
                </span>
              }
              name="phone"
            >
              <Input
                id="phone"
                placeholder={lang.get("customer", ["Phone"])}
                onChange={handleChange}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("customer", ["Groups"])}
                </span>
              }
              name="groups"
            >
              <Select
                mode="multiple"
                placeholder={lang.get("customer", ["Select groups"])}
                onChange={(value) => handleSelectChange(value, "groups")}
                size="large"
              >
                <Option value="group1">{lang.get("customer", ["Group 1"])}</Option>
                <Option value="group2">{lang.get("customer", ["Group 2"])}</Option>
                <Option value="group3">{lang.get("customer", ["Group 3"])}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("customer", ["Fax"])}
                </span>
              }
              name="fax"
            >
              <Input
                id="fax"
                placeholder={lang.get("customer", ["Fax"])}
                onChange={handleChange}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("customer", ["Currency"])}
                </span>
              }
              name="currency"
            >
              <Select
                onChange={(value) => handleSelectChange(value, "currency")}
                defaultValue="lkr"
                size="large"
              >
                <Option value="lkr">LKR</Option>
                <Option value="usd">USD</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("customer", ["Default Language"])}
                </span>
              }
              name="default_language"
            >
              <Select
                onChange={(value) =>
                  handleSelectChange(value, "default_language")
                }
                defaultValue="en"
                size="large"
              >
                <Option value="en">EN</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("customer", ["Address"])}
                </span>
              }
              name="address"
            >
              <TextArea
                id="address"
                placeholder={lang.get("customer", ["Address"])}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("customer", ["City"])}
                </span>
              }
              name="city"
            >
              <Input
                id="city"
                placeholder={lang.get("customer", ["City"])}
                onChange={handleChange}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("customer", ["State"])}
                </span>
              }
              name="state"
            >
              <Input
                id="state"
                placeholder={lang.get("customer", ["State"])}
                onChange={handleChange}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("customer", ["Zip Code"])}
                </span>
              }
              name="zip_code"
            >
              <Input
                id="zip_code"
                placeholder={lang.get("customer", ["Zip Code"])}
                onChange={handleChange}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("customer", ["Country"])}
                </span>
              }
              name="country"
            >
              <Select
                onChange={(value) => handleSelectChange(value, "country")}
                defaultValue={lang.get("customer", ["Sri Lanka"])}
                size="large"
              >
                <Option value="Sri Lanka">
                  {lang.get("customer", ["Sri Lanka"])}
                </Option>
                <Option value="India">
                  {lang.get("customer", ["India"])}
                </Option>
                <Option value="USA">
                  {lang.get("customer", ["USA"])}
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      title: lang.get("customer", ["Billing & Shipping"]),
      content: (
        <ClientBillingAndShipping
          setClientBillingAddress={() => setClientBillingAddress}
          setClientShippingAddress={() => setClientShippingAddress}
          clientBillingAddress={clientBillingAddress}
          clientShippingAddress={clientShippingAddress}
        />
      ),
    },

    {
      title: lang.get("customer", ["Contact Information"]),
      content: (
        <ClientContactBody
          companyData={postData}
          modelClose={props.modelClose}
          setUpdateTable={props.setUpdateTable}
        />
      ),
    },
  ];

  return (
    <Card>
      <Steps
        current={currentStep}
        onChange={(step) => setCurrentStep(step)}
        className="mb-4"
      >
        {steps.map((step, index) => (
          <Step key={index} title={step.title} />
        ))}
      </Steps>
      <Form layout="vertical" style={{ fontSize: "13px" }}>
        {steps[currentStep].content} {" "}
      </Form>
      <div className="steps-action mt-4">
        <Row justify="end" gutter={[8, 0]}>
          {currentStep > 0 && (
            <Col>
              <Button onClick={() => setCurrentStep(currentStep - 1)}>
                {lang.get("customer", ["Previous"])}
              </Button>
            </Col>
          )}
          {currentStep < steps.length - 1 && (
            <Col>
              <Button
                type="primary"
                onClick={() => setCurrentStep(currentStep + 1)}
              >
                {lang.get("customer", ["Next"])}
              </Button>
            </Col>
          )}
          {currentStep === steps.length - 1 && (
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  props.setUpdateTable();
                  props.modelClose();
                }}
              >
                {lang.get("customer", ["Save Client"])}
              </Button>
            </Col>
          )}
        </Row>
      </div>
    </Card>
  );
};

export default CustomerAddBody;
