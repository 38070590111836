import { useState } from "react";
import { Tabs } from "antd";
import {
  FileTextOutlined,
  CalculatorOutlined,
  FileOutlined,
  SolutionOutlined,
  WalletOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import { Customer } from "../../../types/types";
import ProposalTab from "../sales/proposal/Proposal";
import EstimateTab from "../sales/estimate/Estimate";
import InvoiceTab from "../sales/invoice/Invoice";
import CreditTab from "../sales/credit-notes/CreditNotes";
import DebitTab from "../sales/debit-notes/DebitNotes";
import lang from "../../../../../lang";
import PerformaInvoiceTab from "../sales/performa-invoice/PerformaInvoice";

const { TabPane } = Tabs;
interface TabLink {
  id: string;
  label: string;
  icon: JSX.Element;
  content?: JSX.Element;
}

const SaleContent = (props: {
  client: Customer;
  preload: boolean;
  setRefresh: (e: boolean) => void;
  refresh: boolean;
}) => {
  const [activeTab, setActiveTab] = useState("proposals");

  const tabLinks: TabLink[] = [
    {
      id: "proposals",
      label: lang.get("sale", ["Proposals"]),
      icon: <FileTextOutlined />,
      content: (
        <div>
          <ProposalTab />
        </div>
      ),
    },
    {
      id: "estimate",
      label: lang.get("sale", ["Estimate"]),
      icon: <CalculatorOutlined />,
      content: <EstimateTab />,
    },
    {
      id: "invoices",
      label: lang.get("sale", ["Invoices"]),
      icon: <FileOutlined />,
      content: <InvoiceTab />,
    },
    {
      id: "prformainvoices",
      label: lang.get("sale", ["Performa Invoices"]),
      icon: <SolutionOutlined />,
      content: <PerformaInvoiceTab />,
    },
    {
      id: "Credit Notes",
      label: lang.get("sale", ["Credit Notes"]),
      icon: <WalletOutlined />,
      content: <CreditTab />,
    },
    {
      id: "Debit Notes",
      label: lang.get("sale", ["Debit Notes"]),
      icon: <ReconciliationOutlined />,
      content: <DebitTab />,
    },
  ];

  return (
    <div style={{ background: "#fff", padding: "16px", borderRadius: "8px" }}>
      <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
        {tabLinks.map((tab) => (
          <TabPane
            tab={
              <span
                style={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                {tab.icon}
                {tab.label}
              </span>
            }
            key={tab.id}
          >
            {tab.content}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default SaleContent;
