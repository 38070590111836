import { useState, ChangeEvent } from "react";
import { Row, Col, Form, Input, Switch, Button, Upload, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import lang from "../../../lang";

const ClientContactBody = (props: {
  companyData: object;
  setUpdateTable: () => void;
  modelClose: () => void;
  selectedStep?: number;
}) => {
  const [postData, setPostData] = useState({
    profile_image: "",
    firstname: "",
    lastname: "",
    title: "",
    email: "",
    phonenumber: "",
    direction: "ltr",
    password: "",
    is_primary: true,
    donotsendwelcomeemail: false,
    send_set_password_email: false,
    permissions: "[1, 2, 3, 4, 5, 6]",
    contact_email_notifications: "[1, 2, 3, 4, 5, 6, 7]",
  });

  const [contactCapabilities, setContactCapabilities] = useState<string[]>([]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPostData({
      ...postData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSwitchChange = (checked: boolean, field: string) => {
    setPostData({
      ...postData,
      [field]: checked,
    });
  };

  const handleSelectChange = (value: string[]) => {
    setContactCapabilities(value);
    setPostData({
      ...postData,
      permissions: JSON.stringify(value),
    });
  };

  return (
    <Form layout="vertical">
      <Row gutter={[16, 3]}>
        <Col xs={24} lg={8}>
          <Form.Item
            label={<span style={{ fontSize: "14px" }}>{lang.get("contact", ["Profile Image"])} </span>}
          >
            <Upload
              accept="image/jpeg, image/png, image/jpg, image/gif, image/webp"
              beforeUpload={() => false}
              onChange={(info) => {
                setPostData({ ...postData, profile_image: info.file.name });
              }}
            >
              <Button icon={<UploadOutlined />}>
                <span style={{ fontSize: "14px" }}>{lang.get("contact", ["Click to upload"])} </span>
              </Button>
            </Upload>
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item label={<span style={{ fontSize: "14px" }}>{lang.get("contact", ["First Name"])} </span>}>
            <Input
              id="firstname"
              placeholder={lang.get("contact", ["First Name"])}
              onChange={handleChange}
              size="large"
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={8}>
          <Form.Item
            label={<span style={{ fontSize: "14px" }}>{lang.get("contact", ["Last Name"])} </span>}
          >
            <Input
              id="lastname"
              placeholder={lang.get("contact", ["Last Name"])}
              onChange={handleChange}
              size="large"
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item label={<span style={{ fontSize: "14px" }}>{lang.get("contact", ["Position"])} </span>}>
            <Input
              id="title"
              placeholder={lang.get("contact", ["Position"])}
              onChange={handleChange}
              size="large"
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item label={<span style={{ fontSize: "14px" }}>{lang.get("contact", ["Phone"])} </span>}>
            <Input
              id="phonenumber"
              placeholder={lang.get("contact", ["Phone"])}
              onChange={handleChange}
              size="large"
            />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label={<span style={{ fontSize: "14px" }}>{lang.get("contact", ["Email"])} </span>}>
            <Input
              id="email"
              placeholder={lang.get("contact", ["Email"])}
              onChange={handleChange}
              size="large"
            />
          </Form.Item>
        </Col>

        <Col xs={16} lg={16}>
          <Form.Item valuePropName="checked">
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <Switch
                id="donotsendwelcomeemail"
                onChange={(checked) =>
                  handleSwitchChange(checked, "donotsendwelcomeemail")
                }
                checked={postData.donotsendwelcomeemail}
              />
              <span style={{ fontSize: "14px" }}>
                {lang.get("contact", ["Do not send welcome email"])}
              </span>
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} lg={12}>
          <Form.Item label={<span style={{ fontSize: "14px" }}>{lang.get("contact", ["Password"])} </span>}>
            <Input.Password
              id="password"
              placeholder={lang.get("contact", ["Password"])}
              onChange={handleChange}
              size="large"
            />
          </Form.Item>
        </Col>

        <Col xs={8} lg={16}>
          <Form.Item valuePropName="checked">
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <Switch
                id="send_set_password_email"
                onChange={(checked) =>
                  handleSwitchChange(checked, "send_set_password_email")
                }
                checked={postData.send_set_password_email}
              />
              <span style={{ fontSize: "14px" }}>
                {lang.get("contact", ["Send SET password email"])}
              </span>
            </div>
          </Form.Item>
        </Col>

        <Col xs={8} lg={8}>
          <Form.Item valuePropName="checked">
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <Switch
                id="is_primary"
                onChange={(checked) =>
                  handleSwitchChange(checked, "is_primary")
                }
                checked={postData.is_primary}
              />
              <span style={{ fontSize: "14px" }}>
                {lang.get("contact", ["Primary Contact"])}
              </span>
            </div>
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item
            label={<span style={{ fontSize: "14px" }}>{lang.get("contact", ["Permissions"])} </span>}
          >
            <Select
              mode="multiple"
              placeholder={lang.get("contact", ["Select Permissions"])}
              onChange={handleSelectChange}
              value={contactCapabilities}
              size="large"
            >
              options={contactCapabilities}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item
            label={
              <span style={{ fontSize: "14px" }}>{lang.get("contact", ["Email Notifications"])} </span>
            }
          >
            <Select
              mode="multiple"
              placeholder={lang.get("contact", ["Select Notifications"])}
              onChange={(value) =>
                setPostData({
                  ...postData,
                  contact_email_notifications: JSON.stringify(value),
                })
              }
              size="large"
            >
              options={contactCapabilities}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ClientContactBody;
