import React, { useState } from "react";
import { Breadcrumb, Typography, Button, Row, Col } from "antd";
import { PlusOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import PerformaTable from "./PeroformaTable";
import InvoiceStatistics from "../../../project/project-list/components/tabs/sales/invoices/InvoiceStatistics";
import lang from "../../../../lang";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const PerformaInvoices: React.FC = () => {
  const [showStatistics, setShowStatistics] = useState(true);
  const navigate = useNavigate();
  const handleToggleStatistics = () => {
    setShowStatistics(!showStatistics);
  };

  const handleCreateInvoiceClick = () => {
    navigate("/performa-invoices-add");
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* Breadcrumb */}
      <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
        <Breadcrumb.Item>{lang.get("breadcrumbs", ["Home"])}</Breadcrumb.Item>
        <Breadcrumb.Item>{lang.get("breadcrumbs", ["Sales"])}</Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Invoices"])}
        </Breadcrumb.Item>
      </Breadcrumb>

      {/* Page Title */}
      <Title level={2} style={{ marginBottom: "16px" }}>
        {lang.get("sales", ["Invoices"])} (10)
      </Title>

      {/* Statistics Section */}
      {showStatistics && (
        <div style={{ marginBottom: "16px" }}>
          <InvoiceStatistics />
        </div>
      )}

      {/* Action Buttons Row */}
      <Row
        gutter={[16, 16]}
        style={{ marginBottom: "16px" }}
        align="middle"
        justify="space-between"
      >
        {/* Left Section: Create Invoice Button */}
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleCreateInvoiceClick}
          >
            {lang.get("sales", ["Create Invoice"])}
          </Button>
        </Col>

        {/* Right Section: Show/Hide Statistics Button */}
        <Col>
          <Button
            type="primary"
            onClick={handleToggleStatistics}
            icon={showStatistics ? <UpOutlined /> : <DownOutlined />}
          >
            {showStatistics
              ? lang.get("sales", ["Hide Statistics"])
              : lang.get("sales", ["Show Statistics"])}
          </Button>
        </Col>
      </Row>

      {/* Invoice Table */}
      <PerformaTable />
    </div>
  );
};

export default PerformaInvoices;
