import { Button, Space, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ExpenseStatitstic from "./ExpensesStatistic";
import ExpensesTable from "./ExpenseTable";
import lang from "../../../../../../../lang";

const Expenses = () => {
  return (
    <div>
      <Row
        gutter={[16, 16]}
        style={{ marginBottom: "16px", marginTop: "16px" }}
        align="middle"
        justify="space-between"
      >
        {/* Left Section: Buttons */}
        <Col xs={24} sm={24} md={12} lg={12}>
          <Space size="middle" wrap>
            <Button type="primary" icon={<PlusOutlined />}>
              {lang.get("expenses", ["Record Expenses "])}
            </Button>
          </Space>
        </Col>
      </Row>
      <div style={{ marginBottom: "16px" }}>
        <ExpenseStatitstic />
      </div>

      <ExpensesTable />
    </div>
  );
};

export default Expenses;
