import React, { useState, useRef, useEffect } from "react";
import type {
  ProColumns,
  EditableFormInstance,
  ActionType,
} from "@ant-design/pro-components";
import { EditableProTable, ProCard } from "@ant-design/pro-components";
import { Input, Select, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import lang from "../../../lang";

const { Option } = Select;

export type DeliveryVoucherTableDataType = {
  id: React.Key;
  itemName?: string;
  description?: string;
  quantity?: number;
  freeQuantity?: number;
  tax?: string;
  discount?: string;
  discountType?: string;
  warranty?: string;
  total?: number;
};

export const itemList = [
  {
    id: 1,
    itemName: "Item1",
    description: "Item 1 Description",
    quantity: 0,
    freeQuantity: 0,
    tax: "",
    discount: "",
    warranty: "",
    total: 0,
  },
  {
    id: 2,
    itemName: "Item 2",
    description: "Item 2 Description",
    quantity: 0,
    freeQuantity: 0,
    tax: "",
    discount: "",
    warranty: "",
    total: 0,
  },
  {
    id: 3,
    itemName: "Item 3",
    description: "Item 3 Description",
    quantity: 0,
    freeQuantity: 0,
    tax: "",
    discount: "",
    warranty: "",
    total: 0,
  },
];

const defaultValues = {
  itemName: "",
  description: "",
  quantity: 0,
  freeQuantity: 0,
  tax: lang.get("receivingVoucher", ["No Tax"]),
  discount: lang.get("receivingVoucher", ["No Discount"]),
  warranty: lang.get("receivingVoucher", ["No Warranty"]),
  total: 0,
};

const DeliveryVoucherAddTable: React.FC = () => {
  const [tableData, setTableData] = useState<DeliveryVoucherTableDataType[]>([
    { id: 1, ...defaultValues },
  ]);
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([1]);
  const actionRef = useRef<ActionType>();
  const editableFormRef = useRef<EditableFormInstance>();

  const handleItemSelect = (
    record: DeliveryVoucherTableDataType,
    value: string
  ) => {
    const selectedItem = itemList.find((item) => item.itemName === value);
    if (selectedItem && record) {
      const updatedRow = {
        ...record,
        itemName: selectedItem.itemName,
        description: selectedItem.description,
        discount: selectedItem.discount,
        tax: selectedItem.tax,
        warranty: selectedItem.warranty,
      };

      setTableData((prev) => {
        const updatedData = prev.map((row) =>
          row.id === record.id ? updatedRow : row
        );
        const isEmptyRowPresent = updatedData.some(
          (row) => !row.itemName && !row.discount
        );
        if (!isEmptyRowPresent) {
          const newRow = { id: Date.now(), ...defaultValues };
          setEditableRowKeys((keys) => [...keys, newRow.id]);
          return [...updatedData, newRow];
        }
        return updatedData;
      });
    }
  };

  const deleteLastRow = () => {
    setTableData((prev) => {
      if (prev.length > 1) {
        const newData = prev.slice(0, -1);
        setEditableRowKeys((keys) =>
          keys.filter((key) => key !== prev[prev.length - 1].id)
        );
        return newData;
      } else {
        message.warning(
          lang.get("receivingVoucher", [
            "Cannot delete the last remaining row.",
          ])
        );
        return prev;
      }
    });
  };

  const calculateRowTotal = (row: DeliveryVoucherTableDataType) => {
    const quantity = row.quantity || 0;
    const taxRate = parseFloat(row.tax || "0") / 100 || 0;
    const discountRate = parseFloat(row.discount || "0") / 100 || 0;

    const baseAmount = quantity * 100;
    const taxAmount = baseAmount * taxRate;
    const discountAmount = baseAmount * discountRate;

    return baseAmount + taxAmount - discountAmount;
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        deleteLastRow();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const columns: ProColumns<DeliveryVoucherTableDataType>[] = [
    {
      title: lang.get("receivingVoucher", ["Item Name"]),
      dataIndex: "itemName",
      align: "center",
      renderFormItem: (_, { record }) => (
        <Select
          showSearch
          placeholder={lang.get("sales", ["Search and select an item"])}
          value={record?.itemName}
          onChange={(value) =>
            handleItemSelect(record as DeliveryVoucherTableDataType, value)
          }
          style={{ width: "100%" }}
        >
          {itemList.map((item) => (
            <Option key={item.id} value={item.itemName}>
              {item.itemName}
            </Option>
          ))}
        </Select>
      ),
    },

    {
      title: lang.get("receivingVoucher", ["Description"]),
      dataIndex: "description",
      align: "center",
      renderFormItem: (_, { record }) => (
        <Input
          value={record?.description}
          onChange={(e) => {
            const value = e.target.value;
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, description: value } : row
              )
            );
          }}
        />
      ),
    },

    {
      title: lang.get("receivingVoucher", ["Quantity"]),
      dataIndex: "quantity",
      align: "center",
      valueType: "digit",
      renderFormItem: (_, { record }) => (
        <Input
          type="number"
          value={record?.quantity}
          onChange={(e) => {
            const value = Number(e.target.value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id
                  ? { ...row, quantity: value, total: calculateRowTotal(row) }
                  : row
              )
            );
          }}
        />
      ),
    },
    {
      title: lang.get("receivingVoucher", ["Free Quantity"]),
      dataIndex: "freeQuantity",
      align: "center",
      renderFormItem: (_, { record }) => (
        <Input
          type="number"
          value={record?.freeQuantity}
          onChange={(e) => {
            const value = Number(e.target.value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, freeQuantity: value } : row
              )
            );
          }}
        />
      ),
    },
    {
      title: lang.get("receivingVoucher", ["Tax"]),
      dataIndex: "tax",
      align: "center",
      renderFormItem: (_, { record }) => (
        <Select
          value={record?.tax}
          onChange={(value) => {
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id
                  ? { ...row, tax: value, total: calculateRowTotal(row) }
                  : row
              )
            );
          }}
          placeholder={lang.get("receivingVoucher", ["Select Tax"])}
        >
          <Option value="No Tax">
            {lang.get("receivingVoucher", ["No Tax"])}
          </Option>
          <Option value="5">{lang.get("receivingVoucher", ["5%"])}</Option>
          <Option value="10">{lang.get("receivingVoucher", ["10%"])}</Option>
        </Select>
      ),
    },
    {
      title: lang.get("receivingVoucher", ["Discount"]),
      dataIndex: "discount",
      align: "center",
      renderFormItem: (_, { record }) => (
        <Input.Group compact>
          <Input
            value={record?.discount}
            onChange={(e) => {
              const value = e.target.value;
              setTableData((prev) =>
                prev.map((row) =>
                  row.id === record?.id
                    ? { ...row, discount: value, total: calculateRowTotal(row) }
                    : row
                )
              );
            }}
            placeholder={lang.get("receivingVoucher", ["Enter Discount"])}
            style={{ width: "70%" }}
          />
          <Select
            value={record?.discountType || "%"}
            onChange={(value) => {
              setTableData((prev) =>
                prev.map((row) =>
                  row.id === record?.id
                    ? {
                        ...row,
                        discountType: value,
                        total: calculateRowTotal(row),
                      }
                    : row
                )
              );
            }}
            style={{ width: "30%" }}
            dropdownStyle={{ width: "5%" }}
          >
            <Option value="%">{lang.get("receivingVoucher", ["%"])}</Option>
            <Option value="Fixed">
              {lang.get("receivingVoucher", ["Fixed"])}
            </Option>
          </Select>
        </Input.Group>
      ),
    },
    {
      title: lang.get("receivingVoucher", ["Warranty"]),
      dataIndex: "warranty",
      align: "center",
      renderFormItem: (_, { record }) => (
        <Select
          value={record?.warranty}
          onChange={(value) => {
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, warranty: value } : row
              )
            );
          }}
          placeholder={lang.get("receivingVoucher", ["Select Warranty"])}
        >
          <Option value="No Warranty">
            {lang.get("receivingVoucher", ["No Warranty"])}
          </Option>
          <Option value="6 Months">
            {lang.get("receivingVoucher", ["6 Months"])}
          </Option>
          <Option value="1 Year">
            {lang.get("receivingVoucher", ["1 Year"])}
          </Option>
        </Select>
      ),
    },
    {
      title: lang.get("receivingVoucher", ["Total"]),
      dataIndex: "total",
      align: "center",
      renderText: (_, record) => record.total || "0.00",
      editable: false,
    },
    {
      title: lang.get("receivingVoucher", ["Actions"]),
      align: "center",
      valueType: "option",
    },
  ];

  const calculateTotals = () => {
    const totals = {
      quantity: 0,
      freeQuantity: 0,
      amount: 0,
      discount: 0,
      taxAmount: 0,
      totalAmount: 0,
    };

    tableData.forEach((row) => {
      const itemAmount = row.total || 0;
      const discountAmount = parseFloat(row.discount as string) || 0;
      const taxAmount = parseFloat(row.tax as string) || 0;

      totals.quantity += row.quantity || 0;
      totals.freeQuantity += row.freeQuantity || 0;
      totals.amount += itemAmount;
      totals.discount += discountAmount;
      totals.taxAmount += taxAmount;
      totals.totalAmount += itemAmount - discountAmount + taxAmount;
    });

    return totals;
  };

  return (
    <ProCard
      style={{
        border: "1px solid #d9d9d9",
        borderRadius: "8px",
        backgroundColor: "#f9f9fb",
        position: "relative",
      }}
    >
      <EditableProTable<DeliveryVoucherTableDataType>
        rowKey="id"
        value={tableData}
        onChange={(value) =>
          setTableData([...value] as DeliveryVoucherTableDataType[])
        }
        columns={columns}
        editable={{
          type: "multiple",
          editableKeys,
          onChange: setEditableRowKeys,
          actionRender: (row, _, defaultDoms) => [
            <button
              key="delete"
              type="button"
              style={{
                color: tableData.length === 1 ? "gray" : "red",
                border: "none",
                background: "none",
                cursor: tableData.length === 1 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (tableData.length > 1) {
                  setTableData((prev) =>
                    prev.filter((item) => item.id !== row.id)
                  );
                  setEditableRowKeys((prev) =>
                    prev.filter((key) => key !== row.id)
                  );
                } else {
                  message.warning(
                    lang.get("receivingVoucher", [
                      "Cannot delete the last remaining row.",
                    ])
                  );
                }
              }}
              disabled={tableData.length === 1}
            >
              <DeleteOutlined />
            </button>,
          ],
        }}
        recordCreatorProps={false}
        actionRef={actionRef}
        editableFormRef={editableFormRef}
        search={false}
        scroll={{ x: 800 }}
        toolBarRender={() => []}
        summary={() => {
          const totals = calculateTotals();
          return (
            <tr>
              <td style={{ textAlign: "center" }} colSpan={2}></td>{" "}
              <td style={{ textAlign: "center" }}>
                {lang.get("receivingVoucher", ["Total Qty : "])}

                {totals.quantity}
              </td>
              <td style={{ textAlign: "center" }}></td>{" "}
              <td style={{ textAlign: "center" }}>
                {lang.get("receivingVoucher", ["Total Tax : "])}

                {totals.taxAmount.toFixed(2)}
              </td>
              <td style={{ textAlign: "center" }}>
                {lang.get("receivingVoucher", ["Total Discount : "])}

                {totals.discount.toFixed(2)}
              </td>
              <td style={{ textAlign: "center" }} colSpan={2}></td>{" "}
            </tr>
          );
        }}
      />
    </ProCard>
  );
};

export default DeliveryVoucherAddTable;
