import executeRecaptcha from "./grecaptcha";

export default class apiRequest {
  headers = { "Content-Type": "application/json" };
  method = "POST";
  isJSONStringify = true;
  reCaptcha = false;
  apiBaseUrl = "https://demo.globalcloudmedia.com/";

  constructor(baseUrl, auth = false, reCaptcha = false) {
    if (!this.isValidUrl(baseUrl)) {
      this.baseUrl = this.apiBaseUrl + baseUrl;
    } else {
      this.baseUrl = baseUrl;
    }

    this.reCaptcha = reCaptcha;

    if (auth) {
      this.setHeaders([
        {
          key: "Authorization",
          value: "Bearer " + localStorage.getItem("token"),
        },
      ]);
    }
  }

  isValidUrl(url) {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    return regex.test(url);
  }

  setHeaders(headerArr) {
    headerArr.forEach((headers) => {
      this.headers[headers.key] = headers.value;
    });
  }

  setMethod(method) {
    this.method = method;
  }

  setJSONStringify(status) {
    this.isJSONStringify = status;
  }

  async send(request_data = {}, successCallback, endPoint) {
    if (this.reCaptcha) {
      const getGCaptchaToken = await executeRecaptcha("submit_form");

      this.headers["x-captcha-token"] = getGCaptchaToken;
    }

    return fetch(endPoint !== undefined ? endPoint : this.baseUrl, {
      method: this.method,
      headers: this.headers,
      body: this.isJSONStringify ? JSON.stringify(request_data) : request_data,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status && data.status === "denied") {
          localStorage.removeItem("token");
          localStorage.setItem("sess_expired", 1);
          localStorage.setItem(
            "sess_expired_user",
            localStorage.getItem("username")
          );
        }
        if (successCallback === undefined) {
          return data;
        }

        return successCallback(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async sendWithAppUser(request_data = {}, successCallback, endPoint) {
    var custom_headers = {
      "Content-Type": "application/json",
      "X-App-User": localStorage.getItem("token"),
    };
    if (this.reCaptcha) {
      const getGCaptchaToken = await executeRecaptcha("submit_form");

      custom_headers["x-captcha-token"] = getGCaptchaToken;
    }

    return fetch(endPoint !== undefined ? endPoint : this.baseUrl, {
      method: this.method,
      headers: custom_headers,
      body: this.isJSONStringify ? JSON.stringify(request_data) : request_data,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status && data.status === "denied") {
          localStorage.removeItem("token");
        }

        if (successCallback === undefined) {
          return data;
        }
        successCallback(data);
      })
      .catch((error) => {
        console.log(error !== undefined ? error : "");
      });
  }
}
