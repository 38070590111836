import { SearchOutlined } from "@ant-design/icons";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import { Button, Input, Space, InputRef, Tag, DatePicker } from "antd";
import { useRef, useState } from "react";
import Export from "../../../../../../common/components/Export";
import lang from "../../../../../../lang";

const { RangePicker } = DatePicker;

interface CreditNote {
  id: string;
  creditNoteDate: string;
  status: string;
  project: string;
  reference: string;
  amount: string;
  remainingAmount: string;
}

const CreditNotes: CreditNote[] = [
  {
    id: "CN-000001",
    creditNoteDate: "2023-11-28",
    status: "Closed",
    project: "",
    reference: "INV-000015",
    amount: "$15,000.00",
    remainingAmount: "$0.00",
  },
];

const CreditTable = () => {
  const actionRef = useRef<ActionType>();
  const [pageSize, setPageSize] = useState<number>(10);
  const searchInput = useRef<InputRef>(null);
  const [searchAttributes, setSearchAttributes] = useState<{
    key: string;
    value: string;
  }[]>([]);
  const [allSearch, setAllSearch] = useState<string>("");

  const expobj = new Export("credit-notes, " + new Date().toDateString());

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("credit", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("credit", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("credit", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<CreditNote>[] = [
    {
      title: lang.get("credit", ["Credit Note #"]),
      dataIndex: "id",
      sorter: true,
      ellipsis: true,
      ...searchCols("id", lang.get("credit", ["Credit Note #"])),
    },
    {
      title: lang.get("credit", ["Credit Note Date"]),
      dataIndex: "creditNoteDate",
      sorter: true,
      ...searchCols("creditNoteDate", lang.get("credit", ["Credit Note Date"])),
    },
    {
      title: lang.get("credit", ["Status"]),
      dataIndex: "status",
      sorter: true,
      filters: [
        { text: lang.get("credit", ["Closed"]), value: "Closed" },
        { text: lang.get("credit", ["Open"]), value: "Open" },
      ],
      render: (_, record) =>
        record.status === "Closed" ? (
          <Tag color="green">{lang.get("credit", ["Closed"])}</Tag>
        ) : (
          <Tag color="blue">{lang.get("credit", ["Open"])}</Tag>
        ),
    },
    {
      title: lang.get("credit", ["Project"]),
      dataIndex: "project",
      sorter: true,
      ...searchCols("project", lang.get("credit", ["Project"])),
    },
    {
      title: lang.get("credit", ["Reference #"]),
      dataIndex: "reference",
      sorter: true,
      ...searchCols("reference", lang.get("credit", ["Reference #"])),
    },
    {
      title: lang.get("credit", ["Amount"]),
      dataIndex: "amount",
      sorter: true,
      ...searchCols("amount", lang.get("credit", ["Amount"])),
    },
    {
      title: lang.get("credit", ["Remaining Amount"]),
      dataIndex: "remainingAmount",
      sorter: true,
      ...searchCols("remainingAmount", lang.get("credit", ["Remaining Amount"])),
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("credit", ["Credit Notes"])}
      columns={columns}
      actionRef={actionRef}
      dataSource={CreditNotes}
      rowKey="id"
      cardBordered
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        showTotal: (total, range) =>
          lang.get("credit", [
            `Showing ${range[0]}-${range[1]} of ${total} items`,
          ]),
        onShowSizeChange: (current, size) => {
          setPageSize(size);
        },
      }}
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <RangePicker key="date" />,
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("credit", ["Global Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef?.current?.reload();
          }}
        />,
        <Button
          key="csv"
          onClick={() => {
            const csvData = CreditNotes.map((row) => ({
              [lang.get("credit", ["Credit Note #"])]: row.id,
              [lang.get("credit", ["Credit Note Date"])]: row.creditNoteDate,
              [lang.get("credit", ["Status"])]: row.status,
              [lang.get("credit", ["Project"])]: row.project,
              [lang.get("credit", ["Reference #"])]: row.reference,
              [lang.get("credit", ["Amount"])]: row.amount,
              [lang.get("credit", ["Remaining Amount"])]: row.remainingAmount,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("credit", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = CreditNotes.map((row) => ({
              [lang.get("credit", ["Credit Note #"])]: row.id,
              [lang.get("credit", ["Credit Note Date"])]: row.creditNoteDate,
              [lang.get("credit", ["Status"])]: row.status,
              [lang.get("credit", ["Project"])]: row.project,
              [lang.get("credit", ["Reference #"])]: row.reference,
              [lang.get("credit", ["Amount"])]: row.amount,
              [lang.get("credit", ["Remaining Amount"])]: row.remainingAmount,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("credit", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("credit", ["Credit Note #"]),
                  lang.get("credit", ["Credit Note Date"]),
                  lang.get("credit", ["Status"]),
                  lang.get("credit", ["Project"]),
                  lang.get("credit", ["Reference #"]),
                  lang.get("credit", ["Amount"]),
                  lang.get("credit", ["Remaining Amount"]),
                ],
              ],
              body: CreditNotes.map((row) => [
                row.id,
                row.creditNoteDate,
                row.status,
                row.project,
                row.reference,
                row.amount,
                row.remainingAmount,
              ]),
            });
          }}
        >
          {lang.get("credit", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default CreditTable;
