import { useState } from "react";
import { Card, Button, Space, DatePicker, Popover, Upload } from "antd";
import {
  BellOutlined,
  CalendarOutlined,
  PaperClipOutlined,
  InboxOutlined,
} from "@ant-design/icons";

const Reminder = () => {
  const [showUploader, setShowUploader] = useState(false);
  const [showDatePopover, setShowDatePopover] = useState(false);
  const [showNotifyPopover, setShowNotifyPopover] = useState(false);

  const toggleUploader = () => setShowUploader(!showUploader);
  const toggleDatePopover = () => setShowDatePopover(!showDatePopover);
  const toggleNotifyPopover = () => setShowNotifyPopover(!showNotifyPopover);

  const handleDateChange = (date: any) => {
    setShowDatePopover(false);
  };

  const quickDateOptions = [
    { label: "Today", date: new Date() },
    { label: "Tomorrow", date: new Date(Date.now() + 86400000) },
    {
      label: "This weekend",
      date: new Date(Date.now() + (6 - new Date().getDay()) * 86400000),
    },
    { label: "Next week", date: new Date(Date.now() + 7 * 86400000) },
    { label: "2 weeks", date: new Date(Date.now() + 14 * 86400000) },
    { label: "4 weeks", date: new Date(Date.now() + 28 * 86400000) },
  ];

  const notificationOptions = [
    "On due date",
    "10 minutes before",
    "1 hour before",
    "Custom...",
    "Don't notify",
  ];

  return (
    <Card style={{ padding: "16px", borderRadius: "8px" }}>
      <h5 style={{ marginBottom: "24px", color: "#888" }}>
        Reminder name or type "/" for commands
      </h5>

      {/* Buttons Section */}
      <Space size="middle" style={{ marginBottom: "24px" }}>
        {/* Date Button */}
        <Popover
          content={
            <div style={{ width: "300px" }}>
              <Space
                direction="vertical"
                size="middle"
                style={{ width: "100%" }}
              >
                {quickDateOptions.map((option, index) => (
                  <Button
                    key={index}
                    type="text"
                    block
                    onClick={() => handleDateChange(option.date)}
                  >
                    {option.label}
                  </Button>
                ))}
                <DatePicker
                  onChange={handleDateChange}
                  style={{ width: "100%" }}
                  placeholder="Select date"
                />
              </Space>
            </div>
          }
          trigger="click"
          open={showDatePopover}
          onOpenChange={toggleDatePopover}
        >
          <Button icon={<CalendarOutlined />}>Today</Button>
        </Popover>

        {/* Notify Button */}
        <Popover
          content={
            <Space direction="vertical">
              {notificationOptions.map((option, index) => (
                <Button
                  key={index}
                  type="text"
                  block
                  onClick={() => {
                    console.log(`Selected notification: ${option}`);
                    setShowNotifyPopover(false);
                  }}
                >
                  {option}
                </Button>
              ))}
            </Space>
          }
          trigger="click"
          open={showNotifyPopover}
          onOpenChange={toggleNotifyPopover}
        >
          <Button icon={<BellOutlined />}>Notify me</Button>
        </Popover>
      </Space>

      {/* File Uploader Section */}
      {showUploader && (
        <div style={{ marginBottom: "24px" }}>
          <h5>Attachments</h5>
          <Upload.Dragger
            multiple
            beforeUpload={(file) => {
              console.log("Uploaded file:", file);
              return false; // Prevent automatic upload
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p>
              Drag and drop files to attach or{" "}
              <span style={{ color: "#1890ff" }}>browse</span>
            </p>
          </Upload.Dragger>
        </div>
      )}

      {/* Footer Buttons */}
      <Space style={{ width: "100%", justifyContent: "flex-end" }}>
        <Button
          icon={<PaperClipOutlined />}
          type="text"
          onClick={toggleUploader}
        />
        <Button type="primary">Create Reminder</Button>
      </Space>
    </Card>
  );
};

export default Reminder;
