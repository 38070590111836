import React, { useState } from "react";
import { Row, Col, Form, Select, Checkbox } from "antd";

const { Option } = Select;

const options = [
  { value: "Tab1", label: "Tasks" },
  { value: "Tab2", label: "Timesheets" },
  { value: "Tab3", label: "Milestones" },
  { value: "Tab4", label: "Files" },
  { value: "Tab5", label: "Discussions" },
  { value: "Tab6", label: "Gantt" },
  { value: "Tab7", label: "Tickets" },
  { value: "Tab8", label: "Contracts" },
  { value: "Tab9", label: "Proposals" },
  { value: "Tab10", label: "Estimates" },
  { value: "Tab11", label: "Invoices" },
  { value: "Tab12", label: "Subscriptions" },
  { value: "Tab13", label: "Expenses" },
  { value: "Tab14", label: "Credit Notes" },
  { value: "Tab15", label: "Purchase Request" },
  { value: "Tab16", label: "Purchase Order" },
  { value: "Tab17", label: "Purchase Contract" },
  { value: "Tab18", label: "Notes" },
  { value: "Tab19", label: "Activity" },
];

const ProjectSettingBody: React.FC = () => {
  const [postData, setPostData] = useState({
    notification: "",
    tabs: [],
  });

  const handleSelectChange = (value: string | string[], field: string) => {
    setPostData({
      ...postData,
      [field]: value,
    });
  };

  return (
    <Form layout="vertical">
      <Row gutter={[16, 8]}>
        {/* Notification Select */}
        <Col xs={24}>
          <Form.Item label="Send contacts notifications" name="notification">
            <Select
              placeholder="Select notification option"
              onChange={(value) => handleSelectChange(value, "notification")}
              value={postData.notification}
               size="large"
            >
              <Option value="all contacts">
                To all contacts with notifications for projects enabled
              </Option>
              <Option value="Specific">Specific contacts</Option>
              <Option value="Do not send">Do not send notifications</Option>
            </Select>
          </Form.Item>
        </Col>

        {/* Tabs Multi-Select */}
        <Col xs={24}>
          <Form.Item label="Select Tags" name="tabs">
            <Select
              mode="multiple"
              placeholder="Select tabs"
              onChange={(value) => handleSelectChange(value, "tabs")}
              options={options}
              size="large"
            />
          </Form.Item>
        </Col>

        {/* Checkboxes */}
        {[
          { id: "view-tasks", label: "Allow customer to view tasks" },
          { id: "create-tasks", label: "Allow customer to create tasks" },
          {
            id: "comment-tasks",
            label: "Allow customer to comment on project tasks",
          },
          {
            id: "view-task-comments",
            label: "Allow customer to view task comments",
          },
          {
            id: "view-task-attachments",
            label: "Allow customer to view task attachments",
          },
          {
            id: "checkedCheckbox",
            label: "Calculate the progress through tasks",
          },
          {
            id: "view-checklist-items",
            label: "Allow customer to view task checklist items",
          },
          {
            id: "upload-task-attachments",
            label: "Allow customer to upload attachments on tasks",
          },
          {
            id: "view-logged-time",
            label: "Allow customer to view task total logged time",
          },
          {
            id: "view-finance-overview",
            label: "Allow customer to view finance overview",
          },
          { id: "upload-files", label: "Allow customer to upload files" },
          {
            id: "open-discussions",
            label: "Allow customer to open discussions",
          },
          { id: "view-gantt", label: "Allow customer to view Gantt" },
          { id: "view-timesheets", label: "Allow customer to view timesheets" },
          {
            id: "view-activity-log",
            label: "Allow customer to view activity log",
          },
          {
            id: "view-team-members",
            label: "Allow customer to view team members",
          },
          {
            id: "hide-project-tasks",
            label: "Hide project tasks on main tasks table (admin area)",
          },
        ].map((item) => (
          <Col xs={24} key={item.id}>
            <Checkbox id={item.id}>{item.label}</Checkbox>
          </Col>
        ))}
      </Row>
    </Form>
  );
};

export default ProjectSettingBody;
