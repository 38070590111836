import { language } from "../common/types/types";

class lng {
  private lng: language = {};

  async getCore(lng_type: string, force_cache: boolean = false) {
    const core_languge_cache = localStorage.getItem(
      "core_languge_cache_" + lng_type
    );
    if (!core_languge_cache || force_cache) {
      try {
        const lang_file = await import("./core" + `/${lng_type}.tsx`);

        this.lng = lang_file.lng;
        localStorage.setItem(
          "core_languge_cache_" + lng_type,
          JSON.stringify(this.lng)
        );
      } catch (error) {
        console.error("Failed to load array:", error);
      }
    } else {
      this.lng = JSON.parse(core_languge_cache);
    }
  }

  private toTitleCase(str: string) {
    return str.replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  }

  private getKey(key: string | string[], returnType: string): string {
    // eslint-disable-next-line
    var returnKey: string = "";
    if (Array.isArray(key)) {
      returnKey = key.map((k) => this.getKey(k, returnType)).join(" ");
    } else {
      returnKey = this.lng[key] ? this.lng[key] : key;
    }

    if (returnType == "L") {
      return returnKey.toLowerCase().trim();
    } else if (returnType == "U") {
      return returnKey.toUpperCase().trim();
    } else if (returnType == "S") {
      return this.toTitleCase(returnKey).trim();
    } else {
      return returnKey.trim();
    }
  }

  private hasKey(key: string | string[]): boolean {
    if (Array.isArray(key)) {
      return key.every((k) => this.hasKey(k));
    } else {
      return this.lng[key] ? true : false;
    }
  }

  get(
    module: string,
    key: string | string[],
    returnType: string = "S"
  ): string {
    const user_lng_ls = localStorage.getItem("user_lng");
    const user_lng = user_lng_ls ? user_lng_ls : "en";
    const module_languge_cache = localStorage.getItem(
      module + "_languge_cache_" + user_lng
    );

    this.getCore(user_lng);

    if (module_languge_cache) {
      this.lng = JSON.parse(module_languge_cache);

      if (!this.hasKey(key)) {
        this.getCore(user_lng, true);
        this.set(module, user_lng);
      }
    } else {
      this.set(module, user_lng);
    }

    return this.getKey(key, returnType);
  }

  async set(module: string, lng_type: string) {
    try {
      const lang_file = await import(
        "../modules" + `/${module}` + `/lang/${lng_type}.tsx`
      );

      this.lng = Object.assign(lang_file.lng, this.lng);
      console.log(this.lng);
      localStorage.setItem(
        module + "_languge_cache_" + lng_type,
        JSON.stringify(this.lng)
      );
    } catch {}
  }
}

export default new lng();
