import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Typography,
  Card,
  Statistic,
  Grid,
} from "antd";
import {
  AppstoreOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import TasksTable from "./TaskTable";
import lang from "../../../../../../lang";

const { Text } = Typography;
const { useBreakpoint } = Grid;

const Tasks: React.FC = () => {
  const [view, setView] = useState<"table" | "kanban">("table");
  const screens = useBreakpoint();

  const handleViewChange = (viewType: "table" | "kanban") => {
    setView(viewType);
  };

  const metrics = [
    {
      label: lang.get("tasks", ["Sessions"]),
      value: "16,500",
      percentage: "15%",
      isIncrease: true,
      comparisonText: lang.get("tasks", ["Increase vs Last Month"]),
    },
    {
      label: lang.get("tasks", ["New Users"]),
      value: "2,160",
      percentage: "10%",
      isIncrease: true,
      comparisonText: lang.get("tasks", ["Increase vs Last Month"]),
    },
    {
      label: lang.get("tasks", ["Bounce Rate"]),
      value: "56.4%",
      percentage: "25%",
      isIncrease: false,
      comparisonText: lang.get("tasks", ["Decrease vs Last Month"]),
    },
    {
      label: lang.get("tasks", ["Avg Engagement Time"]),
      value: "3m 02s",
      percentage: "15%",
      isIncrease: false,
      comparisonText: lang.get("tasks", ["Decrease vs Last Month"]),
    },
  ];

  return (
    <div style={{ marginTop: "20px" }}>
      {view === "table" ? (
        <>
          {/* Header Actions */}
          <Row
            gutter={[16, 16]}
            justify="space-between"
            align="middle"
            style={{ marginBottom: "16px" }}
          >
            <Col>
              <Button
                type="default"
                shape="default"
                icon={<AppstoreOutlined />}
                onClick={() => handleViewChange("kanban")}
              >
                {lang.get("tasks", ["View Kanban"])}
              </Button>
            </Col>
          </Row>

          {/* Metrics Section */}
          <Row gutter={[16, 16]}>
            {metrics.map((metric, idx) => (
              <Col xs={24} sm={12} lg={6} key={idx}>
                <Card>
                  <Statistic
                    title={metric.label}
                    value={metric.value}
                    valueStyle={{ fontSize: "20px", fontWeight: "bold" }}
                    prefix={
                      <Text
                        style={{
                          color: metric.isIncrease ? "#52c41a" : "#ff4d4f",
                        }}
                      >
                        {metric.isIncrease ? "▲" : "▼"} {metric.percentage}
                      </Text>
                    }
                    suffix={
                      screens.sm && (
                        <Text type="secondary">{metric.comparisonText}</Text>
                      )
                    }
                  />
                </Card>
              </Col>
            ))}
          </Row>
          <div style={{ marginTop: "20px" }}>
            <TasksTable />
          </div>
        </>
      ) : (
        <>
          {/* Kanban View */}
          <Button
            type="default"
            icon={<ArrowLeftOutlined />}
            onClick={() => handleViewChange("table")}
            style={{ marginBottom: "16px" }}
          >
            {lang.get("tasks", ["Back"])}
          </Button>
          {/* Placeholder for Kanban View */}
          <div style={{ textAlign: "center", padding: "40px" }}>
            <Text type="secondary">{lang.get("tasks", ["Kanban View Placeholder"])}</Text>
          </div>
        </>
      )}
    </div>
  );
};

export default Tasks;
