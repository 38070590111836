import React, { useState, useRef } from "react";
import { Input, Button, Space, DatePicker, Tag, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import lang from "../../../lang";
import Export from "../../../common/components/Export";

const { RangePicker } = DatePicker;
const { Option } = Select;

interface InternalDeliveryNote {
  note: string;
  deliverer: string;
  addedFrom: string;
  dateCreated: string;
  totalAmount: number;
  status: string;
}

const InternalDeliveryTable: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchText, setSearchText] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [selectedDates, setSelectedDates] = useState<[string, string] | null>(
    null
  );
  const actionRef = useRef<ActionType>();
  const expobj = new Export(
    lang.get("internalDeliveryNote", ["Internal Delivery Note"]) +
      ", " +
      new Date().toDateString()
  );

  const handleDateChange = (dates: any, dateStrings: [string, string]) => {
    setSelectedDates(dateStrings);
  };

  const handleStatusChange = (value: string | null) => {
    setStatusFilter(value);
  };


  const columns: ProColumns<InternalDeliveryNote>[] = [
    {
      title: lang.get("internalDeliveryNote", ["Internal Delivery Note"]),
      dataIndex: "note",
      key: "note",
      sorter: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: lang.get("internalDeliveryNote", ["Date Created"]),
      dataIndex: "dateCreated",
      key: "dateCreated",
      sorter: true,
    },
    {
      title: lang.get("internalDeliveryNote", ["Deliverer"]),
      dataIndex: "deliverer",
      key: "deliverer",
      sorter: true,
    },
    {
      title: lang.get("internalDeliveryNote", ["Added From"]),
      dataIndex: "addedFrom",
      key: "addedFrom",
      sorter: true,
    },
   
    {
      title: lang.get("internalDeliveryNote", ["Total Amount"]),
      dataIndex: "totalAmount",
      key: "totalAmount",
      sorter: true,
      align: "right",
    },
    {
      title: lang.get("internalDeliveryNote", ["Status"]),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (status) => (
        <Tag
          color={status === "Approved" ? "green" : "blue"}
        >
          {status}
        </Tag>
      ),
    },
  ];

  const data: InternalDeliveryNote[] = [
    {
      note: "IDN-001",
      deliverer: "John Doe",
      addedFrom: "Warehouse 1",
      dateCreated: "2024-12-20",
      totalAmount: 2500.0,
      status: "Approved",
    },
    {
      note: "IDN-002",
      deliverer: "Jane Smith",
      addedFrom: "Warehouse 2",
      dateCreated: "2024-12-18",
      totalAmount: 4000.0,
      status: "Pending",
    },
    // Add more rows here as needed
  ];

  return (
    <ProTable
      columns={columns}
      actionRef={actionRef}
      dataSource={data}
      rowKey="note"
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        onShowSizeChange: (_, size) => setPageSize(size),
      }}
      search={false}
      scroll={{ x: "max-content" }}
      toolBarRender={() => [
        <Space size="middle" wrap key="toolbar">
          <RangePicker
            key="datePicker"
            format="YYYY-MM-DD"
            onChange={handleDateChange}
            placeholder={[
              lang.get("internalDeliveryNote", ["Start Date"]),
              lang.get("internalDeliveryNote", ["End Date"]),
            ]}
          />
          <Input
            key="searchInput"
            placeholder={lang.get("internalDeliveryNote", ["Search"])}
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ width: 250 }}
          />
           <Select
            key="statusFilter"
            placeholder={lang.get("internalDeliveryNote", ["Filter by Status"])}
            onChange={handleStatusChange}
            allowClear
            style={{ width: 200 }}
          >
            <Option value="Approved">
              {lang.get("internalDeliveryNote", ["Approved"])}
            </Option>
            <Option value="Pending">
              {lang.get("internalDeliveryNote", ["Pending"])}
            </Option>
          </Select>
        </Space>,
        <Button
          key="csv"
          onClick={() => {
            const csvData = data.map((row) => ({
              [lang.get("internalDeliveryNote", ["Internal Delivery Note"])]: row.note,
              [lang.get("internalDeliveryNote", ["Deliverer"])]: row.deliverer,
              [lang.get("internalDeliveryNote", ["Added From"])]: row.addedFrom,
              [lang.get("internalDeliveryNote", ["Date Created"])]: row.dateCreated,
              [lang.get("internalDeliveryNote", ["Total Amount"])]: row.totalAmount,
              [lang.get("internalDeliveryNote", ["Status"])]: row.status,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("internalDeliveryNote", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = data.map((row) => ({
              [lang.get("internalDeliveryNote", ["Internal Delivery Note"])]: row.note,
              [lang.get("internalDeliveryNote", ["Deliverer"])]: row.deliverer,
              [lang.get("internalDeliveryNote", ["Added From"])]: row.addedFrom,
              [lang.get("internalDeliveryNote", ["Date Created"])]: row.dateCreated,
              [lang.get("internalDeliveryNote", ["Total Amount"])]: row.totalAmount,
              [lang.get("internalDeliveryNote", ["Status"])]: row.status,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("internalDeliveryNote", ["Export Excel"])}
        </Button>,
      ]}
    />
  );
};

export default InternalDeliveryTable;
