import React, { useState } from "react";
import { Row, Col, Form, Tabs, Input, Typography, Select } from "antd";
import {
  UserOutlined,
  EnvironmentOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import { Customer } from "../../../types/types";
import ContactTab from "./ContactTab";
import Map from "./Map";

const { TabPane } = Tabs;
const { Text } = Typography;
const { TextArea } = Input;

interface TabLink {
  id: string;
  label: string;
  icon: JSX.Element;
  content?: JSX.Element;
}

const ProfileContent = (props: {
  client: Customer;
  preload: boolean;
  setRefresh: (e: boolean) => void;
  refresh: boolean;
}) => {
  const [activeTab, setActiveTab] = useState("profile");
  const [preload] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [client, setClient] = useState<Customer>({
    id: "",
    name: "",
    vat_number: "",
    phone: "",
    currency: "",
    address: "",
    last_login: "",
    default_language: "",
    last_order: "",
    website: "",
    zip_code: "",
    city: "",
    state: "",
    country: "",
  });

  const handleSelectChange = (value: string, field: string) => {
    setClient({
      ...client,
      [field]: value,
    });
  };

  const tabLinks: TabLink[] = [
    {
      id: "profile",
      label: "Profile",
      icon: <UserOutlined />,
      content: (
        <div style={{ padding: "16px" }}>
          <Form layout="vertical">
            <Row gutter={[16, 16]} justify="space-between">
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label={<Text strong>Company Name</Text>}>
                  <Input
                    placeholder="Company Name"
                    defaultValue={props.client.name}
                    onBlur={(e) => updateChanges(e, props.client.name)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label={<Text strong>VAT Number</Text>}>
                  <Input
                    placeholder="VAT Number"
                    defaultValue={props.client.vat_number}
                    onBlur={(e) => updateChanges(e, props.client.vat_number)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label={<Text strong>Website</Text>}>
                  <Input
                    placeholder="Website"
                    defaultValue={props.client.website}
                    onBlur={(e) => updateChanges(e, props.client.website)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]} justify="space-between">
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label={<Text strong>Address</Text>}>
                  <TextArea
                    placeholder="Address"
                    defaultValue={props.client.address}
                    onBlur={(e) => updateChanges(e, props.client.address)}
                    rows={2}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} lg={8}>
                <Form.Item label={<Text strong>Phone</Text>}>
                  <Input
                    placeholder="Phone"
                    defaultValue={props.client.phone}
                    onBlur={(e) => updateChanges(e, props.client.phone)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label={<Text strong>City</Text>}>
                  <Input
                    placeholder="City"
                    defaultValue={props.client.city}
                    onBlur={(e) => updateChanges(e, props.client.city)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]} justify="space-between">
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label={<Text strong>State</Text>}>
                  <Input
                    placeholder="State"
                    defaultValue={props.client.state}
                    onBlur={(e) => updateChanges(e, props.client.state)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label={<Text strong>Zip Code</Text>}>
                  <Input
                    placeholder="Zip Code"
                    defaultValue={props.client.zip_code}
                    onBlur={(e) => updateChanges(e, props.client.zip_code)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={8}>
                <Form.Item label={<Text strong>Country</Text>} name="country">
                  <Select
                    onChange={(value) => handleSelectChange(value, "country")}
                    showSearch
                    placeholder="Select a country"
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                      option?.label
                        ?.toLowerCase()
                        .includes(input.toLowerCase()) ?? false
                    }
                    options={[
                      { value: "Sri Lanka", label: "Sri Lanka" },
                      { value: "India", label: "India" },
                      { value: "USA", label: "USA" },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      ),
    },
    {
      id: "contacts",
      label: "Contacts",
      icon: <ContactsOutlined />,
      content: (
        <div style={{ padding: "16px" }}>
          <ContactTab
            client={client}
            preload={preload}
            setRefresh={setRefresh}
            refresh={refresh}
            currentKey={activeTab}
          />
        </div>
      ),
    },
    {
      id: "map",
      label: "Map",
      icon: <EnvironmentOutlined />,
      content: (
        <div style={{ padding: "16px" }}>
         <Map /> 
        </div>
      ),
    },
  ];

  const updateChanges = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    prevValue: string = ""
  ) => {
    const fieldId = e.target.id;
    const value = e.target.value;
    if (prevValue === value) return;

    console.log(`Updating ${fieldId} to ${value}`);
    props.setRefresh(!props.refresh);
  };

  return (
    <div style={{ background: "#fff", padding: "16px", borderRadius: "8px" }}>
      <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
        {tabLinks.map((tab) => (
          <TabPane
            tab={
              <span
                style={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                {tab.icon}
                {tab.label}
              </span>
            }
            key={tab.id}
          >
            {tab.content}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default ProfileContent;
