import React from "react";
import { Breadcrumb, Typography } from "antd";
import PaymentsTable from "./PaymentTable";
import lang from "../../../../lang";

const { Title } = Typography;

const Payments: React.FC = () => {
  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* Breadcrumb */}
      <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
        <Breadcrumb.Item>{lang.get("breadcrumbs", ["Home"])}</Breadcrumb.Item>
        <Breadcrumb.Item>{lang.get("breadcrumbs", ["Sales"])}</Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Payments"])}
        </Breadcrumb.Item>
      </Breadcrumb>

      <Title level={2} style={{ marginBottom: "16px" }}>
        {lang.get("sales", ["Payments"])} (20)
      </Title>

      <PaymentsTable />
    </div>
  );
};

export default Payments;
