import apiRequest from "./apiRequest";
class validation {
  static validateEmail(email: string) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      ) &&
      email !== ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  static validatePassword(password: string) {
    if (password !== "") {
      return true;
    } else {
      return false;
    }
  }

  static async ReInitializeSession() {
    const API = new apiRequest("authorize_token", true, true);

    const status = await API.send(
      {},
      (data: { status: string; token: string }) => {
        if (data.status === "success") {
          return true;
        } else {
          return false;
        }
      }
    );

    return status;
  }
}

export default validation;
