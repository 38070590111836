import React, { useState } from "react";
import { Row, Col, Form, Button, Input } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import lang from "../../../lang";

interface ClientBillingShipping {
  address: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
}

const ClientBillingAndShipping = (props: {
  setClientBillingAddress: () => void;
  setClientShippingAddress: () => void;
  clientBillingAddress: string[];
  clientShippingAddress: string[];
}) => {
  const [billingAddress, setBillingAddress] = useState<ClientBillingShipping[]>(
    []
  );
  const [shippingAddress, setShippingAddress] = useState<
    ClientBillingShipping[]
  >([]);

  const removeShippingAddress = (index: number) => {
    const updatedAddresses = shippingAddress.filter((_, i) => i !== index);
    setShippingAddress(updatedAddresses);
  };

  const removeBillingAddress = (index: number) => {
    const updatedAddresses = billingAddress.filter((_, i) => i !== index);
    setBillingAddress(updatedAddresses);
  };

  return (
    <Row gutter={[16, 16]}>
      {/* Billing Address */}
      <Col xs={24} lg={12}>
        <Row align="middle" gutter={[16, 8]}>
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setBillingAddress([
                  ...billingAddress,
                  {
                    address: "",
                    city: "",
                    state: "",
                    zip_code: "",
                    country: "",
                  },
                ]);
              }}
            >
              {lang.get("address", ["Add"])}
            </Button>
          </Col>
          <Col>
            <h5 className="text-body-highlight" style={{ fontSize: "14px" }}>
              {lang.get("address", ["Billing Address"])}
            </h5>
          </Col>
        </Row>
        {billingAddress.map((item, index) => (
          <Row
            key={"b" + index}
            gutter={[16, 4]}
            style={{
              borderBottom: "1px dotted #000",
              paddingBottom: "10px",
              marginBlockStart: "10px",
            }}
          >
            <Col span={24}>
              <Form.Item>
                <Input
                  placeholder={lang.get("address", ["Address"])}
                  size="large"
                  style={{ fontSize: "14px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Input
                  placeholder={lang.get("address", ["City"])}
                  size="large"
                  style={{ fontSize: "14px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Input
                  placeholder={lang.get("address", ["State"])}
                  size="large"
                  style={{ fontSize: "14px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Input
                  placeholder={lang.get("address", ["Zip Code"])}
                  size="large"
                  style={{ fontSize: "14px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Input
                  placeholder={lang.get("address", ["Country"])}
                  size="large"
                  style={{ fontSize: "14px" }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => removeBillingAddress(index)}
              >
                {lang.get("address", ["Remove"])}
              </Button>
            </Col>
          </Row>
        ))}
      </Col>

      {/* Shipping Address */}
      <Col xs={24} lg={12}>
        <Row align="middle" gutter={[16, 8]}>
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() =>
                setShippingAddress([
                  ...shippingAddress,
                  {
                    address: "",
                    city: "",
                    state: "",
                    zip_code: "",
                    country: "",
                  },
                ])
              }
            >
              {lang.get("address", ["Add"])}
            </Button>
          </Col>
          <Col>
            <h5 className="text-body-highlight" style={{ fontSize: "14px" }}>
              {lang.get("address", ["Shipping Address"])}
            </h5>
          </Col>
        </Row>
        {shippingAddress.map((item, index) => (
          <Row
            key={"s" + index}
            gutter={[16, 4]}
            style={{
              borderBottom: "1px dotted #000",
              paddingBottom: "10px",
              marginBlockStart: "10px",
            }}
          >
            <Col span={24}>
              <Form.Item>
                <Input
                  placeholder={lang.get("address", ["Address"])}
                  size="large"
                  style={{ fontSize: "14px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Input
                  placeholder={lang.get("address", ["City"])}
                  size="large"
                  style={{ fontSize: "14px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Input
                  placeholder={lang.get("address", ["State"])}
                  size="large"
                  style={{ fontSize: "14px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Input
                  placeholder={lang.get("address", ["Zip Code"])}
                  size="large"
                  style={{ fontSize: "14px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Input
                  placeholder={lang.get("address", ["Country"])}
                  size="large"
                  style={{ fontSize: "14px" }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => removeShippingAddress(index)}
              >
                {lang.get("address", ["Remove"])}
              </Button>
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
  );
};

export default ClientBillingAndShipping;
