import React from "react";
import { Button, Space, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ProCard } from "@ant-design/pro-components";
import CreditTable from "./CreditTable";
import lang from "../../../../../../lang";
const CreditTab: React.FC = () => {
  return (
    <div style={{ borderRadius: "8px" }}>
      <Row
        gutter={[16, 16]}
        style={{ marginBottom: "16px", marginTop: "16px" }}
        align="middle"
        justify="space-between"
      >
        <ProCard
          ghost
          style={{
            background: "#FFFBE6",
            border: "1px solid #FFD591",
            borderRadius: "8px",
            padding: "8px 10px",
            marginBottom: "16px",
          }}
        >
          <span style={{ fontWeight: 500 }}>
            {" "}
            {lang.get("credit", "$0.00 credits available.")}{" "}
          </span>
        </ProCard>
        {/* Left Section: Buttons */}
        <Col xs={24} sm={24} md={12} lg={12}>
          <Space size="middle" wrap>
            <Button type="primary" icon={<PlusOutlined />}>
              {lang.get("credit", "New Credit Note")}
            </Button>
          </Space>
        </Col>
      </Row>

      <CreditTable />
    </div>
  );
};

export default CreditTab;
