import React, { useState, useRef, useEffect } from "react";
import type {
  ProColumns,
  EditableFormInstance,
  ActionType,
} from "@ant-design/pro-components";
import { EditableProTable, ProCard } from "@ant-design/pro-components";
import { Input, Select, message, DatePicker } from "antd";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import lang from "../../../lang";

const { Option } = Select;

export type ReceivingVoucherTableDataType = {
  id: React.Key;
  itemName?: string;
  quantity?: number;
  freeQuantity?: number;
  purchasePrice?: number;
  tax?: string;
  discount?: number;
  sellingPrice?: number;
  batchNumber?: string;
  warranty?: string;
  mfgDate?: string;
  expDate?: string;
};

export const itemList = [
  {
    id: 1,
    itemName: lang.get("sales", ["Item 1"]),
    rate: 0,
    discount: 0,
    tax: "",
    warranty: "",
    sellingPrice: 0,
    batchNumber: "",
    mfgDate: "",
    expDate: "",
    purchasePrice: 0,
  },
  {
    id: 2,
    itemName: lang.get("sales", ["Item 2"]),
    rate: 0,
    discount: 0,
    tax: "",
    warranty: "",
    sellingPrice: 0,
    batchNumber: "",
    mfgDate: "",
    expDate: "",
    purchasePrice: 0,
  },
  {
    id: 3,
    itemName: lang.get("sales", ["Item 3"]),
    rate: 0,
    discount: 0,
    tax: "",
    warranty: "",
    sellingPrice: 0,
    batchNumber: "",
    mfgDate: "",
    expDate: "",
    purchasePrice: 0,
  },
];

const defaultValues = {
  quantity: 0,
  freeQuantity: 0,
  purchasePrice: 0.0,
  tax: lang.get("receivingVoucher", ["No Tax"]),
  discount: 0,
  sellingPrice: 0.0,
  batchNumber: lang.get("receivingVoucher", ["N/A"]),
  warranty: lang.get("receivingVoucher", ["No Warranty"]),
  mfgDate: "",
  expDate: "",
};

const ReceivingVoucherAddTable: React.FC = () => {
  const [tableData, setTableData] = useState<ReceivingVoucherTableDataType[]>([
    { id: 1, ...defaultValues },
  ]);
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([1]);
  const actionRef = useRef<ActionType>();
  const editableFormRef = useRef<EditableFormInstance>();

  const handleItemSelect = (
    record: ReceivingVoucherTableDataType,
    value: string
  ) => {
    const selectedItem = itemList.find((item) => item.itemName === value);
    if (selectedItem && record) {
      const updatedRow = {
        ...record,
        itemName: selectedItem.itemName,
        purchasePrice: selectedItem.rate,
        discount: selectedItem.discount,
        tax: selectedItem.tax,
        warranty: selectedItem.warranty,
      };

      setTableData((prev) => {
        const updatedData = prev.map((row) =>
          row.id === record.id ? updatedRow : row
        );
        const isEmptyRowPresent = updatedData.some(
          (row) => !row.itemName && !row.discount
        );
        if (!isEmptyRowPresent) {
          const newRow = { id: Date.now(), ...defaultValues };
          setEditableRowKeys((keys) => [...keys, newRow.id]);
          return [...updatedData, newRow];
        }
        return updatedData;
      });
    }
  };

  const deleteLastRow = () => {
    setTableData((prev) => {
      if (prev.length > 1) {
        const newData = prev.slice(0, -1);
        setEditableRowKeys((keys) =>
          keys.filter((key) => key !== prev[prev.length - 1].id)
        );
        return newData;
      } else {
        message.warning(
          lang.get("receivingVoucher", [
            "Cannot delete the last remaining row.",
          ])
        );
        return prev;
      }
    });
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        deleteLastRow();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const columns: ProColumns<ReceivingVoucherTableDataType>[] = [
    {
      title: lang.get("receivingVoucher", ["Item Name"]),
      dataIndex: "itemName",
      align: "center",
      renderFormItem: (_, { record }) => (
        <Select
          showSearch
          placeholder={lang.get("sales", ["Search and select an item"])}
          value={record?.itemName}
          onChange={(value) =>
            handleItemSelect(record as ReceivingVoucherTableDataType, value)
          }
          style={{ width: "100%" }}
        >
          {itemList.map((item) => (
            <Option key={item.id} value={item.itemName}>
              {item.itemName}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: lang.get("receivingVoucher", ["Quantity"]),
      dataIndex: "quantity",
      align: "center",
      valueType: "digit",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          type="number"
          value={record?.quantity}
          onChange={(e) => {
            const value = Number(e.target.value);
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, quantity: value } : row
              )
            );
          }}
        />
      ),
    },
    {
      title: lang.get("receivingVoucher", ["Free Quantity"]),
      dataIndex: "freeQuantity",
      align: "center",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          type="number"
          value={record?.freeQuantity}
          onChange={(e) => {
            const value = Number(e.target.value);
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, freeQuantity: value } : row
              )
            );
          }}
        />
      ),
    },
    {
      title: lang.get("receivingVoucher", ["Purchase Price"]),
      dataIndex: "purchasePrice",
      align: "center",
      valueType: "money",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          type="number"
          value={record?.purchasePrice}
          onChange={(e) => {
            const value = Number(e.target.value);
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, purchasePrice: value } : row
              )
            );
          }}
        />
      ),
    },
    {
      title: lang.get("receivingVoucher", ["Tax"]),
      dataIndex: "tax",
      align: "center",
      renderFormItem: (_, { record, onChange }) => (
        <Select
          value={record?.tax}
          onChange={(value) => {
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, tax: value } : row
              )
            );
          }}
          placeholder={lang.get("receivingVoucher", ["Select Tax"])}
        >
          <Option value="No Tax">
            {lang.get("receivingVoucher", ["No Tax"])}
          </Option>
          <Option value="5%">{lang.get("receivingVoucher", ["5%"])}</Option>
          <Option value="10%">{lang.get("receivingVoucher", ["10%"])}</Option>
        </Select>
      ),
    },
    {
      title: lang.get("receivingVoucher", ["Discount"]),
      dataIndex: "discount",
      align: "center",
      renderFormItem: (_, { record, onChange }) => (
        <Select
          value={record?.discount}
          onChange={(value) => {
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, discount: value } : row
              )
            );
          }}
          placeholder={lang.get("receivingVoucher", ["Select Discount"])}
        >
          <Option value="No Discount">
            {lang.get("receivingVoucher", ["No Discount"])}
          </Option>
          <Option value="5%">{lang.get("receivingVoucher", ["5%"])}</Option>
          <Option value="10%">{lang.get("receivingVoucher", ["10%"])}</Option>
        </Select>
      ),
    },
    {
      title: lang.get("receivingVoucher", ["Selling Price"]),
      dataIndex: "sellingPrice",
      align: "center",
      valueType: "money",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          type="number"
          value={record?.sellingPrice}
          onChange={(e) => {
            const value = Number(e.target.value);
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, sellingPrice: value } : row
              )
            );
          }}
        />
      ),
    },
    {
      title: lang.get("receivingVoucher", ["Batch Number"]),
      dataIndex: "batchNumber",
      align: "center",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          value={record?.batchNumber}
          onChange={(e) => {
            const value = e.target.value;
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, batchNumber: value } : row
              )
            );
          }}
        />
      ),
    },
    {
      title: lang.get("receivingVoucher", ["Warranty"]),
      dataIndex: "warranty",
      align: "center",
      renderFormItem: (_, { record, onChange }) => (
        <Select
          value={record?.warranty}
          onChange={(value) => {
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, warranty: value } : row
              )
            );
          }}
          placeholder={lang.get("receivingVoucher", ["Select Warranty"])}
        >
          <Option value="No Warranty">
            {lang.get("receivingVoucher", ["No Warranty"])}
          </Option>
          <Option value="6 Months">
            {lang.get("receivingVoucher", ["6 Months"])}
          </Option>
          <Option value="1 Year">
            {lang.get("receivingVoucher", ["1 Year"])}
          </Option>
          <Option value="2 Years">
            {lang.get("receivingVoucher", ["2 Years"])}
          </Option>
        </Select>
      ),
    },
    {
      title: lang.get("receivingVoucher", ["MFG Date"]),
      dataIndex: "mfgDate",
      align: "center",
      renderFormItem: (_, { record, onChange }) => (
        <DatePicker
          style={{ width: "100%" }}
          value={record?.mfgDate ? moment(record.mfgDate) : null}
          onChange={(date) => {
            const value = date ? date.format("YYYY-MM-DD") : "";
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, mfgDate: value } : row
              )
            );
          }}
        />
      ),
    },
    {
      title: lang.get("receivingVoucher", ["EXP Date"]),
      dataIndex: "expDate",
      align: "center",
      renderFormItem: (_, { record, onChange }) => (
        <DatePicker
          style={{ width: "100%" }}
          value={record?.expDate ? moment(record.expDate) : null}
          onChange={(date) => {
            const value = date ? date.format("YYYY-MM-DD") : "";
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, expDate: value } : row
              )
            );
          }}
        />
      ),
    },
    {
      title: lang.get("receivingVoucher", ["Actions"]),
      align: "center",
      valueType: "option",
    },
  ];

  const calculateTotals = () => {
    const totals = {
      quantity: 0,
      freeQuantity: 0,
      amount: 0,
      discount: 0,
      taxAmount: 0,
      totalAmount: 0,
    };

    tableData.forEach((row) => {
      const itemAmount = (row.sellingPrice || 0) * (row.quantity || 0);
      const discountAmount = (itemAmount * (row.discount || 0)) / 100;
      const taxAmount =
        ((itemAmount - discountAmount) * (parseFloat(row.tax || "0") || 0)) /
        100;

      totals.quantity += row.quantity || 0;
      totals.freeQuantity += row.freeQuantity || 0;
      totals.amount += itemAmount;
      totals.discount += discountAmount;
      totals.taxAmount += taxAmount;
      totals.totalAmount += itemAmount - discountAmount + taxAmount;
    });

    return totals;
  };

  const totals = calculateTotals();

  return (
    <ProCard
      style={{
        border: "1px solid #d9d9d9",
        borderRadius: "8px",
        backgroundColor: "#f9f9fb",
        position: "relative",
      }}
    >
      <EditableProTable<ReceivingVoucherTableDataType>
        rowKey="id"
        value={tableData}
        onChange={(value) =>
          setTableData([...value] as ReceivingVoucherTableDataType[])
        }
        columns={columns}
        editable={{
          type: "multiple",
          editableKeys,
          onChange: setEditableRowKeys,
          actionRender: (row, _, defaultDoms) => [
            <button
              key="delete"
              type="button"
              style={{
                color: tableData.length === 1 ? "gray" : "red",
                border: "none",
                background: "none",
                cursor: tableData.length === 1 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (tableData.length > 1) {
                  setTableData((prev) =>
                    prev.filter((item) => item.id !== row.id)
                  );
                  setEditableRowKeys((prev) =>
                    prev.filter((key) => key !== row.id)
                  );
                } else {
                  message.warning(
                    lang.get("receivingVoucher", [
                      "Cannot delete the last remaining row.",
                    ])
                  );
                }
              }}
              disabled={tableData.length === 1}
            >
              <DeleteOutlined />
            </button>,
          ],
        }}
        recordCreatorProps={false}
        actionRef={actionRef}
        editableFormRef={editableFormRef}
        search={false}
        scroll={{ x: 800 }}
        toolBarRender={() => []}
        summary={() => {
          const totals = calculateTotals();
          return (
            <tr>
              <th style={{ textAlign: "left" }}></th>
              <td style={{ textAlign: "left" }}>
                {lang.get("receivingVoucher", ["Total Qty : "])}{" "}
                {totals.quantity}
              </td>
              <td style={{ textAlign: "left" }}>
                {" "}
                {lang.get("receivingVoucher", ["Total Free Qty : "])}{" "}
                {totals.freeQuantity}
              </td>
              <td style={{ textAlign: "left" }}>
                {lang.get("receivingVoucher", ["Total Price : "])}{" "}
                {totals.amount.toFixed(2)}
              </td>
              <td style={{ textAlign: "left" }}>
                {lang.get("receivingVoucher", ["Total Tax : "])}{" "}
                {totals.discount.toFixed(2)}
              </td>
              <td style={{ textAlign: "left" }}>
                {lang.get("receivingVoucher", ["Total Discount : "])}{" "}
                {totals.taxAmount.toFixed(2)}
              </td>
              <td style={{ textAlign: "left" }}>
                {lang.get("receivingVoucher", ["Total Amount : "])}{" "}
                {totals.totalAmount.toFixed(2)}
              </td>
            </tr>
          );
        }}
      />
      
    </ProCard>
  );
};

export default ReceivingVoucherAddTable;
