import React, { useState, useRef } from "react";
import { Input, Button, Space, DatePicker, Tag, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import lang from "../../../lang";

const { RangePicker } = DatePicker;

interface StockAdjustment {
  type: string;
  timeAdjusted: string;
  dateCreator: string;
  status: string;
  reason: string;
  creator: string;
}

const StockTable: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedDates, setSelectedDates] = useState<[string, string] | null>(
    null
  );
  const actionRef = useRef<ActionType>();

  const handleDateChange = (dates: any, dateStrings: [string, string]) => {
    setSelectedDates(dateStrings);
  };

  const columns: ProColumns<StockAdjustment>[] = [
    {
      title: lang.get("stockAdjustments", ["Type"]),
      dataIndex: "type",
      key: "type",
      sorter: true,
    },
    {
      title: lang.get("stockAdjustments", ["Time (lost or adjusted)"]),
      dataIndex: "timeAdjusted",
      key: "timeAdjusted",
      sorter: true,
    },
    {
      title: lang.get("stockAdjustments", ["Date Creator"]),
      dataIndex: "dateCreator",
      key: "dateCreator",
      sorter: true,
    },
    {
      title: lang.get("stockAdjustments", ["Status"]),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (status) => (
        <Tag color={status === "Approved" ? "green" : "blue"}>{status}</Tag>
      ),
    },
    {
      title: lang.get("stockAdjustments", ["Reason"]),
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: lang.get("stockAdjustments", ["Creator"]),
      dataIndex: "creator",
      key: "creator",
    },
    {
      title: lang.get("stockAdjustments", ["Options"]),
      key: "options",
      render: () => (
        <Button type="link">{lang.get("stockAdjustments", ["View"])}</Button>
      ),
    },
  ];

  const data: StockAdjustment[] = [
    {
      type: "Adjustment",
      timeAdjusted: "2024-12-25",
      dateCreator: "2024-12-20",
      status: "Approved",
      reason: "Stock Correction",
      creator: "Admin",
    },
    {
      type: "Loss",
      timeAdjusted: "2024-12-22",
      dateCreator: "2024-12-19",
      status: "Pending",
      reason: "Damaged Goods",
      creator: "Manager",
    },
  ];

  return (
    <ProTable
      columns={columns}
      actionRef={actionRef}
      dataSource={data}
      rowKey="type"
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        onShowSizeChange: (_, size) => setPageSize(size),
      }}
      search={false}
      scroll={{ x: "max-content" }}
      toolBarRender={() => [
        <Space size="middle" wrap key="toolbar">
          {/* Date Range Picker */}
          <RangePicker
            key="datePicker"
            format="YYYY-MM-DD"
            onChange={handleDateChange}
            placeholder={[
              lang.get("stockAdjustments", ["From Date"]),
              lang.get("stockAdjustments", ["To Date"]),
            ]}
          />

          {/* Type Selector */}
          <Select
            key="typeSelector"
            placeholder={lang.get("stockAdjustments", ["Type"])}
            style={{ width: 200 }}
            options={[
              {
                value: "Adjustment",
                label: lang.get("stockAdjustments", ["Adjustment"]),
              },
              { value: "Loss", label: lang.get("stockAdjustments", ["Loss"]) },
            ]}
          />

          {/* Status Selector */}
          <Select
            key="statusSelector"
            placeholder={lang.get("stockAdjustments", ["Status"])}
            mode="multiple"
            allowClear
            style={{ width: 300 }}
            options={[
              {
                value: "approved",
                label: lang.get("stockAdjustments", ["Approved"]),
              },
              {
                value: "notApproved",
                label: lang.get("stockAdjustments", ["Not yet approved"]),
              },
              {
                value: "pending",
                label: lang.get("stockAdjustments", ["Pending"]),
              },
            ]}
          />

          {/* Search Input */}
          <Input
            key="searchInput"
            placeholder={lang.get("stockAdjustments", ["Search"])}
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ width: 250 }}
          />
        </Space>,
      ]}
    />
  );
};

export default StockTable;
