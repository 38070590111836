import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Breadcrumb, Card, notification } from "antd";
import lang from "../../../lang";
import { items, Item } from "./common/data/ItemsData";
// import ItemSummary from "./components/SummarySection";
import ItemTabs from "./item-components/ItemTabs";

const ItemDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [item, setItem] = useState<Item | undefined>();
  const [currentItemId, setCurrentItemId] = useState<number | undefined>();

  const summaryRef = useRef<HTMLDivElement>(null);
  const tabsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (id) {
      const itemId = parseInt(id, 10);
      const foundItem: Item | undefined = items.find(
        (item: Item) => parseInt(item.id, 10) === itemId
      );
      if (foundItem) {
        setCurrentItemId(itemId);
        setItem(foundItem);
      } else {
        notification.error({
          message: lang.get("notifications", ["Error"]),
          description: lang.get("notifications", ["Item not found!"]),
        });
      }
    }
  }, [id]);



  if (!currentItemId || !item) {
    return (
      <Card style={{ marginTop: 50, textAlign: "center" }}>
        <p>{lang.get("items", ["Item not found"])}</p>
      </Card>
    );
  }

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <div ref={summaryRef} id="items-summary">
        <Breadcrumb
          style={{ marginBottom: "20px" }}
          items={[
            { title: lang.get("breadcrumbs", ["Home"]), href: "/" },
            { title: lang.get("breadcrumbs", ["Items"]), href: "/items" },
            { title: lang.get("items", [item.itemName]) },
          ]}
        />
      </div>
      <div ref={tabsRef} id="item-tabs" style={{ marginTop: "20px" }}>
        <ItemTabs itemId={currentItemId} />
      </div>
    </div>
  );
};

export default ItemDetails;
