import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card } from "antd";
import { ApexOptions } from "apexcharts";

const WarehouseLevels: React.FC = () => {
  const data = {
    values: [27, 25, 18, 15, 10, 5], 
    categories: ["A", "B", "C", "D", "E", "F"], 
  };


  if (!data.values || !data.categories || data.values.length !== data.categories.length) {
    console.error("Data mismatch or undefined properties in the input.");
    return <div>Error: Invalid data</div>;
  }

  const options: ApexOptions = {
    chart: {
      type: "pie",
      toolbar: {
        show: false,
      },
    },
    labels: data.categories,
    legend: {
      position: "top",
      horizontalAlign: "center",
      fontSize: "14px",
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
    },
    colors: ["#f18e56", "#d787ff", "#7f6bff", "#68c738", "#2389ff", "#0dcccc"],
    dataLabels: {
      enabled: true,
      formatter: function (val: number, opts: any) {
        return `${opts.w.globals.labels[opts.seriesIndex]}: ${val.toFixed(1)}%`;
      },
      style: {
        fontSize: "12px",
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const series = data.values; 

  return (
    <Card
      title="Warehouse Levels"
      bordered={false}
      style={{ width: "100%", height: "600px" }}
    >
     
        <ReactApexChart
          options={options}
          series={series}
          type="pie"
          height={450}
        />
    
    </Card>
  );
};

export default WarehouseLevels;
