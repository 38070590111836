import { useState } from "react";
import { Card } from "antd";
import { Editor } from "@tinymce/tinymce-react";

const Whiteboard = () => {
  const [content, setContent] = useState("");

  const handleEditorChange = (content: string) => {
    setContent(content);
  };

  return (
    <Card style={{ borderRadius: "8px" }}>
      <Card.Meta
        title={<h5 style={{ marginBottom: 0 }}>Whiteboard</h5>}
        style={{ marginBottom: "16px", backgroundColor: "#f5f5f5", padding: "16px" }}
      />
      <div style={{ padding: "16px" }}>
        <Editor
          value={content}
          init={{
            height: 400,
            menubar: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "undo redo | formatselect | bold italic backcolor | " +
              "alignleft aligncenter alignright alignjustify | " +
              "bullist numlist outdent indent | removeformat | help",
          }}
          onEditorChange={handleEditorChange}
        />
      </div>
    </Card>
  );
};

export default Whiteboard;
