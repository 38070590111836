import React from "react";
import { ProForm, ProCard } from "@ant-design/pro-components";
import {
  Button,
  Row,
  Col,
  Divider,
  Typography,
  Input,
  Form,
  DatePicker,
  Select,
} from "antd";

import lang from "../../../../lang";
// import TransferAddTable from "./TransferAddTable";
import TinyMCEEditor from "../../../../common/base/Editor";
import { SendOutlined } from "@ant-design/icons";
import StockAddTable from "./StockAddTable";

const { Title } = Typography;
const { Option } = Select;

const StockAdjustmentAdd: React.FC = () => {
  const handleSave = () => {
    console.log("Stock data saved.");
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <Title level={2}>{lang.get("stock", ["Add Stock Adjustment Note"])} </Title>
      <ProCard>
        <ProForm layout="vertical" submitter={false}>
          <Row gutter={[16, 5]}>
            {/* Stock Adjustment Number */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("stock", ["Stock Adjustment Number"])}
                  </span>
                }
                name="stockAdjustmentNumber"
              >
                <Input
                  placeholder={lang.get("stock", ["Enter Stock Adjustment Number"])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Date */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("stock", ["Date"])}
                  </span>
                }
                name="date"
              >
                <DatePicker style={{ width: "100%" }} size="large" />
              </Form.Item>
            </Col>

            {/* Account */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("stock", ["Account"])}
                  </span>
                }
                name="account"
              >
                <Select
                  placeholder={lang.get("stock", ["Select Account"])}
                  size="large"
                >
                  <Option value="Account1">{lang.get("stock", ["Account 1"])}</Option>
                  <Option value="Account2">{lang.get("stock", ["Account 2"])}</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Reference Number */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("stock", ["Reference Number"])}
                  </span>
                }
                name="referenceNumber"
              >
                <Input
                  placeholder={lang.get("stock", ["Enter Reference Number"])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Warehouse */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("stock", ["Warehouse"])}
                  </span>
                }
                name="warehouse"
              >
                <Select
                  placeholder={lang.get("stock", ["Select Warehouse"])}
                  size="large"
                >
                  <Option value="Warehouse1">{lang.get("stock", ["Warehouse 1"])}</Option>
                  <Option value="Warehouse2">{lang.get("stock", ["Warehouse 2"])}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Divider />

        <StockAddTable  />

          <Divider />

          {/* Notes Section */}
          <Row gutter={[16, 5]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Client Note"])}
                  </span>
                }
                name="note"
              >
                <TinyMCEEditor />
              </Form.Item>
            </Col>
          </Row>

          {/* Submit Section */}

          <Row justify="end" gutter={16}>
            <Button
              color="primary"
              variant="outlined"
              icon={<SendOutlined />}
              style={{ marginBottom: "30px", marginTop: "30px" }}
              onClick={() =>
                console.log(
                  lang.get("sales", ["Send The Stock Adjustment For Approval"])
                )
              }
            >
              {lang.get("sales", ["Send The Stock Adjustment For Approval"])}
            </Button>
          </Row>
          <Row justify="end" gutter={16}>
            <Col>
              <Button
                onClick={() => console.log(lang.get("stock", ["Close"]))}
                style={{
                  backgroundColor: "white",
                  borderColor: "#d9d9d9",
                  color: "#000",
                  marginRight: 8,
                }}
              >
                {lang.get("stock", ["Close"])}
              </Button>
              <Button
                type="primary"
                onClick={handleSave}
                style={{
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  color: "#fff",
                  marginRight: 8,
                }}
              >
                {lang.get("stock", ["Save"])}
              </Button>
              <Button
                type="primary"
                onClick={() =>
                  console.log(lang.get("stock", ["Save & Send Request"]))
                }
                style={{
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  color: "#fff",
                }}
              >
                {lang.get("stock", ["Save & Send Request"])}
              </Button>
            </Col>
          </Row>
        </ProForm>
      </ProCard>
    </div>
  );
};

export default StockAdjustmentAdd;
