import React from "react";
import { Breadcrumb, Typography, Button, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import EstimateTable from "./EstimateTable";
import EstimateStatistic from "../../../project/project-list/components/tabs/sales/estimates/EstimateStatistics";
import lang from "../../../../lang";
import { useNavigate } from "react-router-dom";
const { Title } = Typography;

const Estimates: React.FC = () => {
  const navigate = useNavigate();

  const handleCreateEstimateClick = () => {
    navigate("/estimate-add");
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* Breadcrumb */}
      <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
        <Breadcrumb.Item>{lang.get("breadcrumbs", ["Home"])}</Breadcrumb.Item>
        <Breadcrumb.Item>{lang.get("breadcrumbs", ["Sales"])}</Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Estimates"])}
        </Breadcrumb.Item>
      </Breadcrumb>

      <Title level={2} style={{ marginBottom: "16px" }}>
        {lang.get("sales", ["Estimates"])} (10)
      </Title>

      <div style={{ marginBottom: "16px" }}>
        <EstimateStatistic />
      </div>

      <Row
        gutter={[16, 16]}
        style={{ marginBottom: "16px" }}
        align="middle"
        justify="space-between"
      >
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleCreateEstimateClick}
          >
            {lang.get("sales", ["Create Estimate"])}
          </Button>
        </Col>
      </Row>

      {/* Statistics Section */}

      {/* Estimate Table */}
      <EstimateTable />
    </div>
  );
};

export default Estimates;
