import { useEffect } from "react";
import ProposalsTable from "./ProposalsTable";
const Proposals = () => {
  useEffect(() => {}, []);
  return (
    <div>
      <ProposalsTable />
    </div>
  );
};

export default Proposals;
