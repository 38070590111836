import { Breadcrumb, Typography, Button, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import lang from "../../../lang";
import PackageTable from "./PackageTable";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const PackageList: React.FC = () => {
  const navigate = useNavigate();

  const handleShipmentAddClick = () => {
      navigate("/shipment-add-new");
  }

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* Breadcrumb */}
      <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
        <Breadcrumb.Item>{lang.get("breadcrumbs", ["Home"])}</Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Inventory"])}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Shipments List"])}
        </Breadcrumb.Item>
      </Breadcrumb>

      {/* Page Title */}
      <Title level={2} style={{ marginBottom: "16px" }}>
        {lang.get("inventory", ["Shipments"])} (7)
      </Title>

      {/* Filter and Actions */}
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col xs={24} sm={12} md={12} lg={12} style={{ textAlign: "left" }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => handleShipmentAddClick()}
          >
            {lang.get("inventory", ["Add Shipment"])}
          </Button>
        </Col>
      </Row>

      {/* Table */}
      <PackageTable />

    
    </div>
  );
};

export default PackageList;
