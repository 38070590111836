import React, { useState, useRef, useEffect } from "react";
import type {
  ProColumns,
  EditableFormInstance,
  ActionType,
} from "@ant-design/pro-components";
import { EditableProTable, ProCard } from "@ant-design/pro-components";
import { Select, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import lang from "../../../../lang";
const { Option } = Select;

export type TransferDataType = {
  id: React.Key;
  itemName?: string;
  availableQuantity?: number;
  transferQuantity?: number;
  unitPrice?: number;
  amount?: number;
};

export const itemList = [
  {
    id: 1,
    itemName: "Item 1",
    availableQuantity: 0,
    transferQuantity: 0,
    unitPrice: 100,
    amount: 0,
  },
  {
    id: 2,
    itemName: "Item 2",
    availableQuantity: 0,
    transferQuantity: 0,
    unitPrice: 200,
    amount: 0,
  },
  {
    id: 3,
    itemName: "Item 3",
    availableQuantity: 0,
    transferQuantity: 0,
    unitPrice: 300,
    amount: 0,
  },
];

const defaultValues = {
  itemName: "",
  availableQuantity: 0,
  transferQuantity: 0,
  unitPrice: 0,
  amount: 0,
};
const StockAddTable: React.FC = () => {
  const [tableData, setTableData] = useState<TransferDataType[]>([
    { id: 1, ...defaultValues },
  ]);
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([1]);
  const actionRef = useRef<ActionType>();
  const editableFormRef = useRef<EditableFormInstance>();

  const handleItemSelect = (record: TransferDataType, value: string) => {
    const selectedItem = itemList.find((item) => item.itemName === value);
    if (selectedItem && record) {
      const updatedRow = {
        ...record,
        itemName: selectedItem.itemName,
        unitPrice: selectedItem.unitPrice,
        availableQuantity: selectedItem.availableQuantity,
        transferQuantity: selectedItem.transferQuantity,
        amount: selectedItem.unitPrice * selectedItem.transferQuantity,
      };

      setTableData((prev) => {
        const updatedData = prev.map((row) =>
          row.id === record.id ? updatedRow : row
        );
        const isEmptyRowPresent = updatedData.some(
          (row) => !row.itemName && !row.unitPrice
        );
        if (!isEmptyRowPresent) {
          const newRow = { id: Date.now(), ...defaultValues };
          setEditableRowKeys((keys) => [...keys, newRow.id]);
          return [...updatedData, newRow];
        }
        return updatedData;
      });
    }
  };

  const deleteLastRow = () => {
    setTableData((prev) => {
      if (prev.length > 1) {
        const newData = prev.slice(0, -1);
        setEditableRowKeys((keys) =>
          keys.filter((key) => key !== prev[prev.length - 1].id)
        );
        return newData;
      } else {
        message.warning(
          lang.get("purchase", ["Cannot delete the last remaining row."])
        );
        return prev;
      }
    });
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        deleteLastRow();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const columns: ProColumns<TransferDataType>[] = [
    {
      title: lang.get("purchase", ["Item Name"]),
      dataIndex: "itemName",
      align: "center",
      renderFormItem: (_, { record }) => (
        <Select
          showSearch
          placeholder={lang.get("purchase", ["Search and select an item"])}
          value={record?.itemName}
          onChange={(value) =>
            handleItemSelect(record as TransferDataType, value)
          }
          style={{ width: "100%" }}
        >
          {itemList.map((item) => (
            <Option key={item.id} value={item.itemName}>
              {item.itemName}
            </Option>
          ))}
        </Select>
      ),
    },

    {
      title: lang.get("purchase", [" Availabel Quantity"]),
      dataIndex: "availableQuantity",
      align: "center",
      valueType: "digit",
      renderText: (_, record) => record.availableQuantity || "0",
    },
    {
      title: lang.get("purchase", [" Transfer Quantity"]),
      dataIndex: "quantity",
      align: "center",
      valueType: "digit",
      renderText: (_, record) => record.transferQuantity || "0",
    },
    {
      title: lang.get("purchase", ["Unit Price"]),
      dataIndex: "purchasePrice",
      align: "center",
      renderText: (_, record) => record.unitPrice || "0.00",
    },

    {
      title: lang.get("purchase", ["Amount"]),
      dataIndex: "total",
      align: "center",
      renderText: (_, record) => record.amount || "0.00",
      editable: false,
    },
    {
      title: lang.get("purchase", ["Actions"]),
      align: "center",
      valueType: "option",
    },
  ];

  return (
    <ProCard
      style={{
        border: "1px solid #d9d9d9",
        borderRadius: "8px",
        backgroundColor: "#f9f9fb",
        position: "relative",
      }}
    >
      <EditableProTable<TransferDataType>
        rowKey="id"
        value={tableData}
        onChange={(value) => setTableData([...value] as TransferDataType[])}
        columns={columns}
        editable={{
          type: "multiple",
          editableKeys,
          onChange: setEditableRowKeys,
          actionRender: (row, _, defaultDoms) => [
            <button
              key="delete"
              type="button"
              style={{
                color: tableData.length === 1 ? "gray" : "red",
                border: "none",
                background: "none",
                cursor: tableData.length === 1 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (tableData.length > 1) {
                  setTableData((prev) =>
                    prev.filter((item) => item.id !== row.id)
                  );
                  setEditableRowKeys((prev) =>
                    prev.filter((key) => key !== row.id)
                  );
                } else {
                  message.warning(
                    lang.get("receivingVoucher", [
                      "Cannot delete the last remaining row.",
                    ])
                  );
                }
              }}
              disabled={tableData.length === 1}
            >
              <DeleteOutlined />
            </button>,
          ],
        }}
        recordCreatorProps={false}
        actionRef={actionRef}
        editableFormRef={editableFormRef}
        search={false}
        scroll={{ x: 800 }}
        toolBarRender={() => []}
      />
    </ProCard>
  );
};

export default StockAddTable;
