import React, { useState } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Switch,
  Button,
  Select,
  Divider,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import lang from "../../../../../../lang";
import { DB_ClientContact } from "../../../../types/types";

const ContactModel = ({
  contact,
  setContactModelOpen,
}: {
  contact?: DB_ClientContact;
  client: string;
  refreshComponent: boolean;
  setRefreshComponent: (e: boolean) => void;
  setContactModelOpen: (e: boolean) => void;
}) => {
  const [title, setTitle] = useState(contact?.title);
  const [firstName, setFirstName] = useState(contact?.first_name);
  const [lastName, setLastName] = useState(contact?.last_name);
  const [email] = useState(contact?.email);
  const [position, setPosition] = useState(contact?.position);
  const [phone, setPhone] = useState(contact?.phonenumber);
  const [password, setPassword] = useState("");
  const [primary, setPrimary] = useState(contact ? contact.is_primary : 1);
  const [doNotSendWelcomeEmail, setDoNotSendWelcomeEmail] = useState(
    contact ? contact.do_not_send_welcome_email : 0
  );
  const [sendSetPassword, setSendSetPassword] = useState(
    contact ? contact.send_set_password : 0
  );

  return (
    <Form layout="vertical">
      {/* General Information */}
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item label={lang.get("contact", ["Title"])}>
            <Input
              placeholder={lang.get("contact", ["Enter Title"])}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label={lang.get("contact", ["Position"])}>
            <Input
              placeholder={lang.get("contact", ["Enter Position"])}
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item label={lang.get("contact", ["First Name"])}>
            <Input
              placeholder={lang.get("contact", ["Enter First Name"])}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label={lang.get("contact", ["Last Name"])}>
            <Input
              placeholder={lang.get("contact", ["Enter Last Name"])}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* Contact Information */}
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item label={lang.get("contact", ["Email"])}>
            <Input
              placeholder={lang.get("contact", ["Enter Email"])}
              value={email}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label={lang.get("contact", ["Phone"])}>
            <Input
              placeholder={lang.get("contact", ["Enter Phone"])}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item label={lang.get("contact", ["Profile Image"])}>
            <Upload
              accept="image/jpeg, image/png, image/jpg, image/gif, image/webp"
              beforeUpload={() => false}
            >
              <Button icon={<UploadOutlined />}>
                <span style={{ fontSize: "14px" }}>
                  {lang.get("contact", ["Click to upload"])}
                </span>
              </Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label={lang.get("contact", ["Password"])}>
            <Input.Password
              placeholder={lang.get("contact", ["Enter Password"])}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* Switches Row */}
      <Row gutter={[16, 16]}>
        <Col xs={24} md={8}>
          <Form.Item label={lang.get("contact", ["Primary Contact"])}>
            <Switch
              checked={!!primary}
              onChange={(checked) => setPrimary(checked ? 1 : 0)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item label={lang.get("contact", ["Do Not Send Welcome Email"])}>
            <Switch
              checked={!!doNotSendWelcomeEmail}
              onChange={(checked) => setDoNotSendWelcomeEmail(checked ? 1 : 0)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item label={lang.get("contact", ["Send Set Password Email"])}>
            <Switch
              checked={!!sendSetPassword}
              onChange={(checked) => setSendSetPassword(checked ? 1 : 0)}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* Permissions */}
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item label={lang.get("contact", ["Permissions"])}>
            <Select
              mode="multiple"
              placeholder={lang.get("contact", ["Select permissions"])}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item label={lang.get("contact", ["Email Notifications"])}>
            <Select
              mode="multiple"
              placeholder={lang.get("contact", ["Select email notifications"])}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* Actions */}
      <Divider />
      <Row justify="end" gutter={[16, 16]}>
        <Col>
          <Button onClick={() => setContactModelOpen(false)}>
            {lang.get("contact", ["Cancel"])}
          </Button>
        </Col>
        <Col>
          <Button type="primary">{lang.get("contact", ["Save"])}</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactModel;
