import React from "react";
import { Button, Badge, Space } from "antd";
import { BellOutlined, AppstoreOutlined } from "@ant-design/icons";
import ThemeToggler from "../common/base/ThemeToggleButton";
import ProfileDropdownMenu from "./ProfileDropdownMenu";
import StartTimerComponent from "../modules/project/project-list/components/tabs/timesheet/Timer";

interface NavbarActionsProps {
  theme: "light" | "dark";
  toggleTheme: () => void;
}

const NavbarActions: React.FC<NavbarActionsProps> = ({
  theme,
  toggleTheme,
}) => {
  return (
    <Space size="middle" className="navbar-actions">

      <StartTimerComponent />
      {/* Theme Toggle Button */}
      <ThemeToggler />

      <Badge>
        <Button shape="circle" icon={<BellOutlined />} />
      </Badge>

      {/* Nine Dot Menu */}
      <Button shape="circle" icon={<AppstoreOutlined />} />

      {/* User Avatar */}
      <ProfileDropdownMenu />
    </Space>
  );
};

export default NavbarActions;
