import React, { useState, useRef } from "react";
import {  Space, Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import lang from "../../../lang";

interface Warehouse {
  warehouseNumber: string;
  warehouseName: string;
  warehouseAddress: string;
  assignToStaff: string;
}

const WarehouseTable: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchText, setSearchText] = useState<string>("");
  const actionRef = useRef<ActionType>();
  const [assignedStaff, setAssignedStaff] = useState<string[]>([]);
  const columns: ProColumns<Warehouse>[] = [
    {
      title: lang.get("warehouse", ["Warehouse Number"]),
      dataIndex: "warehouseNumber",
      key: "warehouseNumber",
      sorter: true,
      render: (text) => text,
    },
    {
      title: lang.get("warehouse", ["Warehouse Name"]),
      dataIndex: "warehouseName",
      key: "warehouseName",
      sorter: true,
    },
    {
      title: lang.get("warehouse", ["Warehouse Address"]),
      dataIndex: "warehouseAddress",
      key: "warehouseAddress",
      sorter: true
    },
    {
      title: lang.get("warehouse", ["Assign to Staff"]),
      dataIndex: "assignToStaff",
      key: "assignToStaff",
      sorter: true
    },
  ];

  const data: Warehouse[] = [
    {
      warehouseNumber: "001",
      warehouseName: "Main",
      warehouseAddress: "Main Street, City",
      assignToStaff: "John Doe",
    },
    {
      warehouseNumber: "002",
      warehouseName: "Secondary",
      warehouseAddress: "Secondary Street, City",
      assignToStaff: "Jane Smith",
    },
  ];

  return (
    <ProTable
      columns={columns}
      actionRef={actionRef}
      dataSource={data}
      rowKey="warehouseNumber"
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        onShowSizeChange: (_, size) => setPageSize(size),
      }}
      search={false}
      toolBarRender={() => [
        <Space size="middle" key="toolbar">
          <Select
            mode="multiple"
            allowClear
            placeholder={lang.get("warehouse", ["Assigned to Staff"])}
            style={{ width: 300 }}
            value={assignedStaff}
            onChange={(value) => setAssignedStaff(value)}
            options={[
              { value: "John Doe", label: "John Doe" },
              { value: "Jane Smith", label: "Jane Smith" },
              { value: "Mike Ross", label: "Mike Ross" },
            ]}
          />
          <Input
            placeholder={lang.get("warehouse", ["Search"])}
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ width: 250 }}
          />
          
        </Space>,
      ]}
    />
  );
};

export default WarehouseTable;
