import React, { useState, useRef, useEffect } from "react";
import type {
  ProColumns,
  EditableFormInstance,
  ActionType,
} from "@ant-design/pro-components";
import { EditableProTable, ProCard } from "@ant-design/pro-components";
import { Input, Select, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import lang from "../../../../lang";
const { Option } = Select;

export type OrderReturnTableDataType = {
  id: React.Key;
  itemName?: string;
  quantity?: number;
  purchasePrice?: number;
  tax?: string;
  subtotal?: number;
  discount?: string;
  discountType?: string;
  discountMoney?: number;
  total?: number;
};

export const itemList = [
  {
    id: 1,
    itemName: "Item 1",
    quantity: 0,
    purchasePrice: 100,
    tax: "0",
    subtotal: 0,
    discount: "0",
    discountMoney: 0,
    totalPayment: 0,
  },
  {
    id: 2,
    itemName: "Item 2",
    quantity: 0,
    purchasePrice: 200,
    tax: "0",
    subtotal: 0,
    discount: "0",
    discountMoney: 0,
    totalPayment: 0,
  },
  {
    id: 3,
    itemName: "Item 3",
    quantity: 0,
    purchasePrice: 300,
    tax: "0",
    subtotal: 0,
    discount: "0",
    discountMoney: 0,
    totalPayment: 0,
  },
];

const defaultValues = {
  itemName: "",
  description: "",
  quantity: 0,
  purchasePrice: 0,
  tax: "No Tax",
  discount: "0",
  subtotal: 0,
  discountMoney: 0,
  totalPayment: 0,
};

const DeliveryVoucherAddTable: React.FC = () => {
  const [tableData, setTableData] = useState<OrderReturnTableDataType[]>([
    { id: 1, ...defaultValues },
  ]);
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([1]);
  const actionRef = useRef<ActionType>();
  const editableFormRef = useRef<EditableFormInstance>();

  const handleItemSelect = (
    record: OrderReturnTableDataType,
    value: string
  ) => {
    const selectedItem = itemList.find((item) => item.itemName === value);
    if (selectedItem && record) {
      const updatedRow = {
        ...record,
        itemName: selectedItem.itemName,
        purchasePrice: selectedItem.purchasePrice,
        discount: selectedItem.discount,
        tax: selectedItem.tax,
      };

      setTableData((prev) => {
        const updatedData = prev.map((row) =>
          row.id === record.id ? updatedRow : row
        );
        const isEmptyRowPresent = updatedData.some(
          (row) => !row.itemName && !row.discount
        );
        if (!isEmptyRowPresent) {
          const newRow = { id: Date.now(), ...defaultValues };
          setEditableRowKeys((keys) => [...keys, newRow.id]);
          return [...updatedData, newRow];
        }
        return updatedData;
      });
    }
  };

  const deleteLastRow = () => {
    setTableData((prev) => {
      if (prev.length > 1) {
        const newData = prev.slice(0, -1);
        setEditableRowKeys((keys) =>
          keys.filter((key) => key !== prev[prev.length - 1].id)
        );
        return newData;
      } else {
        message.warning(
          lang.get("purchase", ["Cannot delete the last remaining row."])
        );
        return prev;
      }
    });
  };

  const calculateRowTotal = (row: OrderReturnTableDataType) => {
    const quantity = row.quantity || 0;
    const taxRate = parseFloat(row.tax || "0") / 100 || 0;
    const discountRate = parseFloat(row.discount || "0") / 100 || 0;

    const baseAmount = quantity * 100;
    const taxAmount = baseAmount * taxRate;
    const discountAmount = baseAmount * discountRate;

    return baseAmount + taxAmount - discountAmount;
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        deleteLastRow();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const columns: ProColumns<OrderReturnTableDataType>[] = [
    {
      title: lang.get("purchase", ["Item Name"]),
      dataIndex: "itemName",
      align: "center",
      renderFormItem: (_, { record }) => (
        <Select
          showSearch
          placeholder={lang.get("purchase", ["Search and select an item"])}
          value={record?.itemName}
          onChange={(value) =>
            handleItemSelect(record as OrderReturnTableDataType, value)
          }
          style={{ width: "100%" }}
        >
          {itemList.map((item) => (
            <Option key={item.id} value={item.itemName}>
              {item.itemName}
            </Option>
          ))}
        </Select>
      ),
    },

    {
      title: lang.get("purchase", ["Quantity"]),
      dataIndex: "quantity",
      align: "center",
      valueType: "digit",
      renderFormItem: (_, { record }) => (
        <Input
          type="number"
          value={record?.quantity}
          onChange={(e) => {
            const value = Number(e.target.value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id
                  ? { ...row, quantity: value, total: calculateRowTotal(row) }
                  : row
              )
            );
          }}
        />
      ),
    },
    {
      title: lang.get("purchase", ["Purchase Price"]),
      dataIndex: "purchasePrice",
      align: "center",
      renderText: (_, record) => record.purchasePrice || "0.00",
    },

    {
      title: lang.get("purchase", ["Tax"]),
      dataIndex: "tax",
      align: "center",
      renderFormItem: (_, { record }) => (
        <Select
          value={record?.tax}
          onChange={(value) => {
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id
                  ? { ...row, tax: value, total: calculateRowTotal(row) }
                  : row
              )
            );
          }}
          placeholder={lang.get("purchase", ["Select Tax"])}
        >
          <Option value="No Tax">{lang.get("purchase", ["No Tax"])}</Option>
          <Option value="5">{lang.get("purchase", ["5%"])}</Option>
          <Option value="10">{lang.get("purchase", ["10%"])}</Option>
        </Select>
      ),
    },

    {
      title: lang.get("purchase", ["Discount"]),
      dataIndex: "discount",
      align: "center",
      renderFormItem: (_, { record }) => (
        <Input.Group compact>
          <Input
            value={record?.discount}
            onChange={(e) => {
              const value = e.target.value;
              setTableData((prev) =>
                prev.map((row) =>
                  row.id === record?.id
                    ? { ...row, discount: value, total: calculateRowTotal(row) }
                    : row
                )
              );
            }}
            placeholder={lang.get("purchase", ["Enter Discount"])}
            style={{ width: "70%" }}
          />
          <Select
            value={record?.discountType || "%"}
            onChange={(value) => {
              setTableData((prev) =>
                prev.map((row) =>
                  row.id === record?.id
                    ? {
                        ...row,
                        discountType: value,
                        total: calculateRowTotal(row),
                      }
                    : row
                )
              );
            }}
            style={{ width: "30%" }}
            dropdownStyle={{ width: "5%" }}
          >
            <Option value="%">{lang.get("purchase", ["%"])}</Option>
            <Option value="Fixed">{lang.get("purchase", ["Fixed"])}</Option>
          </Select>
        </Input.Group>
      ),
    },
    {
      title: lang.get("purchase", ["Discount (money)"]),
      dataIndex: "discountMoney",
      align: "center",
      editable: false,
    },
    {
      title: lang.get("purchase", ["Total"]),
      dataIndex: "total",
      align: "center",
      renderText: (_, record) => record.total || "0.00",
      editable: false,
    },
    {
      title: lang.get("purchase", ["Actions"]),
      align: "center",
      valueType: "option",
    },
  ];

  return (
    <ProCard
      style={{
        border: "1px solid #d9d9d9",
        borderRadius: "8px",
        backgroundColor: "#f9f9fb",
        position: "relative",
      }}
    >
      <EditableProTable<OrderReturnTableDataType>
        rowKey="id"
        value={tableData}
        onChange={(value) =>
          setTableData([...value] as OrderReturnTableDataType[])
        }
        columns={columns}
        editable={{
          type: "multiple",
          editableKeys,
          onChange: setEditableRowKeys,
          actionRender: (row, _, defaultDoms) => [
            <button
              key="delete"
              type="button"
              style={{
                color: tableData.length === 1 ? "gray" : "red",
                border: "none",
                background: "none",
                cursor: tableData.length === 1 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (tableData.length > 1) {
                  setTableData((prev) =>
                    prev.filter((item) => item.id !== row.id)
                  );
                  setEditableRowKeys((prev) =>
                    prev.filter((key) => key !== row.id)
                  );
                } else {
                  message.warning(
                    lang.get("receivingVoucher", [
                      "Cannot delete the last remaining row.",
                    ])
                  );
                }
              }}
              disabled={tableData.length === 1}
            >
              <DeleteOutlined />
            </button>,
          ],
        }}
        recordCreatorProps={false}
        actionRef={actionRef}
        editableFormRef={editableFormRef}
        search={false}
        scroll={{ x: 800 }}
        toolBarRender={() => []}
      />
    </ProCard>
  );
};

export default DeliveryVoucherAddTable;
