import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Select,
  Button,
  DatePicker,
  Input,
  Typography,
  Card,
  Statistic,
  Space,
  Grid,
  Modal,
  Form,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import TimesheetTable from "./TimeTable";
import { projects, Project } from "../../../../common/data/ProjectsData";
import StartTimerComponent from "./Timer";

const { Text } = Typography;
const { useBreakpoint } = Grid;

interface TimesheetProps {
  projectId: number;
}

const Timesheet: React.FC<TimesheetProps> = ({ projectId }) => {
  const screens = useBreakpoint();
  const [projectMembers, setProjectMembers] = useState<Project["members"]>([]);
  const [selectedMemberId, setSelectedMemberId] = useState<string | "all">(
    "all"
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  const metrics = [
    {
      label: "Sessions",
      value: "16,500",
      percentage: "15%",
      isIncrease: true,
      comparisonText: "Increase vs Last Month",
    },
    {
      label: "New Users",
      value: "2,160",
      percentage: "10%",
      isIncrease: true,
      comparisonText: "Increase vs Last Month",
    },
    {
      label: "Bounce Rate",
      value: "56.4%",
      percentage: "25%",
      isIncrease: false,
      comparisonText: "Increase vs Last Month",
    },
    {
      label: "Avg Engagement Time",
      value: "3m 02s",
      percentage: "15%",
      isIncrease: false,
      comparisonText: "Decrease vs Last Month",
    },
  ];

  useEffect(() => {
    // Get the members of the selected project
    const selectedProject = projects.find((proj) => proj.id === projectId);
    if (selectedProject) {
      setProjectMembers(selectedProject.members || []);
    }
  }, [projectId]);

  const projectMemberIds = projectMembers.map((member) => member.id.toString());

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      {/* Header Actions */}
      <Row justify="start" style={{ marginBottom: "16px" }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          style={{ marginRight: "8px" }}
          onClick={showModal}
        >
          Timesheet
        </Button>
      </Row>

      {/* Modal for Timesheet */}
      <Modal
        title="Add Timesheet"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        centered
        footer={[
          <Button key="cancel" onClick={handleModalCancel}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleModalOk}>
            Save
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Tags" name="tags">
            <Input placeholder="Enter tags" />
          </Form.Item>
          <Form.Item label="Start Time" name="startTime">
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item label="End Time" name="endTime">
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item label="Task" name="task">
            <Select placeholder="Select a task">
              <Select.Option value="task1">Task 1</Select.Option>
              <Select.Option value="task2">Task 2</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Member" name="member">
            <Select placeholder="Select a member">
              {projectMembers.map((member) => (
                <Select.Option key={member.id} value={member.id.toString()}>
                  {member.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Note" name="note">
            <Input.TextArea placeholder="Add a note" rows={4} />
          </Form.Item>
        </Form>
      </Modal>

      {/* Metrics Section */}
      <Row gutter={[16, 16]}>
        {metrics.map((metric, idx) => (
          <Col xs={24} sm={12} lg={6} key={idx}>
            <Card>
              <Statistic
                title={metric.label}
                value={metric.value}
                valueStyle={{ fontSize: "20px", fontWeight: "bold" }}
                prefix={
                  <Text
                    style={{
                      color: metric.isIncrease ? "#52c41a" : "#ff4d4f",
                    }}
                  >
                    {metric.isIncrease ? "▲" : "▼"} {metric.percentage}
                  </Text>
                }
                suffix={
                  screens.sm && (
                    <Text type="secondary">{metric.comparisonText}</Text>
                  )
                }
              />
            </Card>
          </Col>
        ))}
      </Row>

      {/* Filters & Actions */}
      <Row
        gutter={[16, 16]}
        align="middle"
        justify="space-between"
        style={{ marginTop: "16px", marginBottom: "16px" }}
      >
        <Col>
          <Space>
            {/* Items Per Page */}
            <Select
              style={{ width: "120px" }}
              placeholder="Items/Page"
              defaultValue="25"
              options={[
                { label: "25", value: "25" },
                { label: "50", value: "50" },
                { label: "100", value: "100" },
              ]}
            />
            {/* Filter by Member */}
            <Select
              style={{ width: "150px" }}
              placeholder="All Members"
              defaultValue="all"
              options={[
                { label: "All Members", value: "all" },
                ...projectMembers.map((member) => ({
                  label: member.name,
                  value: member.id.toString(),
                })),
              ]}
              onChange={(value) => setSelectedMemberId(value)}
            />
            <StartTimerComponent />
          </Space>
        </Col>
      </Row>

      {/* Timesheet Table */}
      <TimesheetTable
        selectedMemberId={selectedMemberId}
        projectMemberIds={projectMemberIds}
      />
    </div>
  );
};

export default Timesheet;
