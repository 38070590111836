import { SearchOutlined } from "@ant-design/icons";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import { Button, Input, Space, InputRef, Tag, Select, DatePicker } from "antd";
import { ReactNode, useRef, useState } from "react";
import Export from "../../../../../../common/components/Export";
import lang from "../../../../../../lang";

interface Invoice {
  id: string;
  amount: string;
  totalTax: string;
  date: string;
  project: string;
  tags: string[];
  dueDate: string;
  status: string;
}

const Invoices: Invoice[] = [
  {
    id: "INV-DRAFT",
    amount: "$10.00",
    totalTax: "$0.00",
    date: "2024-05-08",
    project: "",
    tags: [],
    dueDate: "2024-06-07",
    status: "Draft",
  },
  {
    id: "INV-000022",
    amount: "$100.00",
    totalTax: "$0.00",
    date: "2024-11-01",
    project: "",
    tags: [],
    dueDate: "2024-12-01",
    status: "Unpaid",
  },
  {
    id: "INV-000020",
    amount: "$0.00",
    totalTax: "$0.00",
    date: "2024-06-03",
    project: "",
    tags: [],
    dueDate: "2024-07-03",
    status: "Paid",
  },
  {
    id: "INV-000019",
    amount: "$6,500.00",
    totalTax: "$0.00",
    date: "2024-03-16",
    project: "",
    tags: [],
    dueDate: "2024-04-15",
    status: "Paid",
  },
  {
    id: "INV-000018",
    amount: "$8,500.00",
    totalTax: "$0.00",
    date: "2024-03-12",
    project: "",
    tags: [],
    dueDate: "2024-04-11",
    status: "Unpaid",
  },
];

const { Option } = Select;
const { RangePicker } = DatePicker;

const InvoiceTable = () => {
  const actionRef = useRef<ActionType>();
  const [pageSize, setPageSize] = useState<number>(10);
  const searchInput = useRef<InputRef>(null);
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const [allSearch, setAllSearch] = useState<string>("");

  const expobj = new Export("invoices, " + new Date().toDateString());

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("invoice", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("invoice", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("invoice", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<Invoice>[] = [
    {
      title: lang.get("invoice", ["Invoice #"]),
      dataIndex: "id",
      sorter: true,
      ellipsis: true,
      ...searchCols("id", lang.get("invoice", ["Invoice #"])),
    },
    {
      title: lang.get("invoice", ["Amount"]),
      dataIndex: "amount",
      sorter: true,
      ...searchCols("amount", lang.get("invoice", ["Amount"])),
    },
    {
      title: lang.get("invoice", ["Total Tax"]),
      dataIndex: "totalTax",
      sorter: true,
      ...searchCols("totalTax", lang.get("invoice", ["Total Tax"])),
    },
    {
      title: lang.get("invoice", ["Date"]),
      dataIndex: "date",
      sorter: true,
      ...searchCols("date", lang.get("invoice", ["Date"])),
    },
    {
      title: lang.get("invoice", ["Project"]),
      dataIndex: "project",
      sorter: true,
      ...searchCols("project", lang.get("invoice", ["Project"])),
    },
    {
      title: lang.get("invoice", ["Tags"]),
      dataIndex: "tags",
      render: (tags: ReactNode) => {
        const tagArray = tags as string[] | undefined;
        return tagArray && tagArray.length > 0 ? (
          tagArray.map((tag) => (
            <Tag color="blue" key={tag}>
              {tag}
            </Tag>
          ))
        ) : (
          <span style={{ color: "#ccc" }}>-</span>
        );
      },
    },
    {
      title: lang.get("invoice", ["Due Date"]),
      dataIndex: "dueDate",
      sorter: true,
      ...searchCols("dueDate", lang.get("invoice", ["Due Date"])),
    },
    {
      title: lang.get("invoice", ["Status"]),
      dataIndex: "status",
      sorter: true,
      filters: [
        { text: lang.get("invoice", ["Paid"]), value: "Paid" },
        { text: lang.get("invoice", ["Unpaid"]), value: "Unpaid" },
        { text: lang.get("invoice", ["Draft"]), value: "Draft" },
      ],
      render: (_, record) =>
        record.status === "Paid" ? (
          <Tag color="green">{lang.get("invoice", ["Paid"])}</Tag>
        ) : record.status === "Unpaid" ? (
          <Tag color="red">{lang.get("invoice", ["Unpaid"])}</Tag>
        ) : (
          <Tag color="default">{lang.get("invoice", ["Draft"])}</Tag>
        ),
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("invoice", ["Invoices"])}
      columns={columns}
      actionRef={actionRef}
      dataSource={Invoices}
      rowKey="id"
      cardBordered
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        showTotal: (total, range) =>
          lang.get("invoice", [`Showing ${range[0]}-${range[1]} of ${total} items`]),
        onShowSizeChange: (current, size) => {
          setPageSize(size);
        },
      }}
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <RangePicker key="date" />,
        <Select
          placeholder={lang.get("invoice", ["Select Currency"])}
          style={{ width: 200 }}
        >
          <Option value="USD">USD - {lang.get("invoice", ["US Dollar"])}</Option>
          <Option value="EUR">EUR - {lang.get("invoice", ["Euro"])}</Option>
          <Option value="GBP">GBP - {lang.get("invoice", ["British Pound"])}</Option>
          <Option value="LKR">LKR - {lang.get("invoice", ["Sri Lankan Rupee"])}</Option>
        </Select>,
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("invoice", ["Global Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef?.current?.reload();
          }}
        />,
        <Button
          key="csv"
          onClick={() => {
            const csvData = Invoices.map((row) => ({
              [lang.get("invoice", ["Invoice #"])]: row.id,
              [lang.get("invoice", ["Amount"])]: row.amount,
              [lang.get("invoice", ["Total Tax"])]: row.totalTax,
              [lang.get("invoice", ["Project"])]: row.project,
              [lang.get("invoice", ["Tags"])]: row.tags.join(", "),
              [lang.get("invoice", ["Date"])]: row.date,
              [lang.get("invoice", ["Due Date"])]: row.dueDate,
              [lang.get("invoice", ["Status"])]: row.status,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("invoice", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = Invoices.map((row) => ({
              [lang.get("invoice", ["Invoice #"])]: row.id,
              [lang.get("invoice", ["Amount"])]: row.amount,
              [lang.get("invoice", ["Total Tax"])]: row.totalTax,
              [lang.get("invoice", ["Project"])]: row.project,
              [lang.get("invoice", ["Tags"])]: row.tags.join(", "),
              [lang.get("invoice", ["Date"])]: row.date,
              [lang.get("invoice", ["Due Date"])]: row.dueDate,
              [lang.get("invoice", ["Status"])]: row.status,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("invoice", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("invoice", ["Invoice #"]),
                  lang.get("invoice", ["Amount"]),
                  lang.get("invoice", ["Total Tax"]),
                  lang.get("invoice", ["Date"]),
                  lang.get("invoice", ["Project"]),
                  lang.get("invoice", ["Tags"]),
                  lang.get("invoice", ["Due Date"]),
                  lang.get("invoice", ["Status"]),
                ],
              ],
              body: Invoices.map((row) => [
                row.id,
                row.amount,
                row.totalTax,
                row.date,
                row.project,
                row.tags.join(", "),
                row.dueDate,
                row.status,
              ]),
            });
          }}
        >
          {lang.get("invoice", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default InvoiceTable;
