import { useState, useEffect, useRef } from "react";
import { Menu, Button } from "antd";
import {
  DollarOutlined,
  FileOutlined,
  BarChartOutlined,
  ProjectOutlined,
  FileSearchOutlined,
  UserOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import lang from "../../../../../../lang";
import Proposals from "./proposal/Proposals"
import Estimates from "./estimates/Estimates";
import Invoice from "./invoices/Invoice";
import Subscription from "./subscription/Subscription";
import Expenses from "./expenses/Expenses";
import CreditTab from "./credit-notes/Credit";

const SalesTab = () => {
  const [activeKey, setActiveKey] = useState("proposals");
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1920);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollTabs = (direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === "right" ? 150 : -150;
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const salesTabs = [
    {
      id: "proposals",
      label: lang.get("salesSections", ["Proposals"]),
      icon: <UserOutlined />,
    },
    {
      id: "estimates",
      label: lang.get("salesSections", ["estimates"]),
      icon: <BarChartOutlined />,
    },
    {
      id: "invoices",
      label: lang.get("salesSections", ["invoices"]),
      icon: <DollarOutlined />,
    },
    {
      id: "subscriptions",
      label: lang.get("salesSections", ["subscriptions"]),
      icon: <FileSearchOutlined />,
    },
    {
      id: "expenses",
      label: lang.get("salesSections", ["expenses"]),
      icon: <ProjectOutlined />,
    },
    {
      id: "creditNotes",
      label: lang.get("salesSections", ["Credit Notes"]),
      icon: <FileOutlined />,
    },
  ];

  const tabContent: Record<string, JSX.Element> = {
    proposals: <Proposals />,
    estimates: <Estimates />,
    invoices: <Invoice/>,
    subscriptions: <Subscription />,
    expenses: <Expenses/>,
    creditNotes: <CreditTab/>
  };

  return (
    <>
      <div
        style={{
          background: "#fff",
          borderRadius: 5,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {isSmallScreen && (
            <Button
              icon={<LeftOutlined />}
              type="text"
              onClick={() => scrollTabs("left")}
            />
          )}
          <div
            ref={scrollContainerRef}
            style={{
              display: "flex",
              overflowX: "auto",
              scrollbarWidth: "none",
              gap: "10px",
              width: "100%",
              whiteSpace: "nowrap",
            }}
          >
            <Menu
              onClick={({ key }) => setActiveKey(key)}
              selectedKeys={[activeKey]}
              mode="horizontal"
              style={{
                display: "flex",
                justifyContent: "start",
                columnGap: "20px",
                fontSize: "14px",
              }}
              items={salesTabs.map((tab) => ({
                key: tab.id,
                label: tab.label,
                icon: tab.icon,
              }))}
            />
          </div>
          {isSmallScreen && (
            <Button
              icon={<RightOutlined />}
              type="text"
              onClick={() => scrollTabs("right")}
            />
          )}
        </div>
      </div>

      <div style={{ marginTop: "16px" }}>{tabContent[activeKey]}</div>
    </>
  );
};

export default SalesTab;
