import React, { useState, useRef } from "react";
import { Button, Input, InputRef, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ProTable, ProColumns, ActionType } from "@ant-design/pro-components";
import lang from "../../../../../../lang";
import Export from "../../../../../../common/components/Export";

interface Milestone {
  id: string;
  name: string;
  startDate: string;
  dueDate: string;
  description: string;
}

const milestoneData: Milestone[] = [
  {
    id: "1",
    name: "Project Kickoff",
    startDate: "2024-01-01",
    dueDate: "2024-01-10",
    description: "Initial project kickoff meeting with stakeholders",
  },
];

const MilestonesTable: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(6);
  const searchInput = useRef<InputRef>(null);
  const actionRef = useRef<ActionType>();
  const [allSearch, setAllSearch] = useState<string>("");
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);

  const expobj = new Export(
    lang.get("milestones", ["Milestones"]) + ", " + new Date().toDateString()
  );

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([...searchAttributes, { key: field, value }]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("milestones", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={searchAttributes.find((item) => item.key === field)?.value}
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  actionRef.current?.reload();
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => actionRef.current?.reload()}
                style={{ width: 90 }}
              >
                {lang.get("milestones", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("milestones", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<Milestone>[] = [
    {
      title: lang.get("milestones", ["Name"]),
      dataIndex: "name",
      key: "name",
      sorter: true,
      ...searchCols("name", lang.get("milestones", ["Name"])),
    },
    {
      title: lang.get("milestones", ["Start Date"]),
      dataIndex: "startDate",
      key: "startDate",
      sorter: true,
      ...searchCols("startDate", lang.get("milestones", ["Start Date"])),
    },
    {
      title: lang.get("milestones", ["Due Date"]),
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: true,
      ...searchCols("dueDate", lang.get("milestones", ["Due Date"])),
    },
    {
      title: lang.get("milestones", ["Description"]),
      dataIndex: "description",
      key: "description",
      sorter: true,
      ...searchCols("description", lang.get("milestones", ["Description"])),
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("milestones", ["Milestones"])}
      columns={columns}
      dataSource={milestoneData}
      actionRef={actionRef}
      rowKey="id"
      pagination={{
        defaultCurrent: 1,
        pageSize,
        showSizeChanger: true,
        pageSizeOptions: [6, 10, 20],
        showTotal: (total, range) =>
          lang.get("milestones", [
            `Showing ${range[0]}-${range[1]} of ${total} items`,
          ]),
        onShowSizeChange: (_, size) => setPageSize(size),
      }}
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("milestones", ["Global Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef.current?.reload();
          }}
        />,
        <Button
          key="csv"
          onClick={() => {
            const csvData = milestoneData.map((row) => ({
              [lang.get("milestones", ["Name"])]: row.name,
              [lang.get("milestones", ["Start Date"])]: row.startDate,
              [lang.get("milestones", ["Due Date"])]: row.dueDate,
              [lang.get("milestones", ["Description"])]: row.description,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("milestones", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = milestoneData.map((row) => ({
              [lang.get("milestones", ["Name"])]: row.name,
              [lang.get("milestones", ["Start Date"])]: row.startDate,
              [lang.get("milestones", ["Due Date"])]: row.dueDate,
              [lang.get("milestones", ["Description"])]: row.description,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("milestones", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("milestones", ["Name"]),
                  lang.get("milestones", ["Start Date"]),
                  lang.get("milestones", ["Due Date"]),
                  lang.get("milestones", ["Description"]),
                ],
              ],
              body: milestoneData.map((row) => [
                row.name,
                row.startDate,
                row.dueDate,
                row.description,
              ]),
            });
          }}
        >
          {lang.get("milestones", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default MilestonesTable;
