import React from "react";
import { ProForm, ProCard } from "@ant-design/pro-components";
import {
  Button,
  Row,
  Col,
  Divider,
  Typography,
  Input,
  Form,
  DatePicker,
  Select,
} from "antd";

import lang from "../../../../lang";
import TransferAddTable from "./TransferAddTable";
import TinyMCEEditor from "../../../../common/base/Editor";
import { SendOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { Option } = Select;

const StockAdd: React.FC = () => {
  const handleSave = () => {
    console.log("Stock data saved.");
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <Title level={2}>{lang.get("stock", ["Add Stock Transfer Note"])} </Title>
      <ProCard>
        <ProForm layout="vertical" submitter={false}>
          <Row gutter={[16, 5]}>
            {/* Transfer Note Number */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("stock", ["Transfer Note Number"])}
                  </span>
                }
                name="transferNoteNumber"
              >
                <Input
                  placeholder={lang.get("stock", [
                    "Enter Transfer Note Number",
                  ])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Date */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("stock", ["Date"])}
                  </span>
                }
                name="date"
              >
                <DatePicker style={{ width: "100%" }} size="large" />
              </Form.Item>
            </Col>

            {/* Accounting Date */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("stock", ["Accounting Date"])}
                  </span>
                }
                name="accountingDate"
              >
                <DatePicker style={{ width: "100%" }} size="large" />
              </Form.Item>
            </Col>

            {/* Voucher Date */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("stock", ["Voucher Date"])}
                  </span>
                }
                name="voucherDate"
              >
                <DatePicker style={{ width: "100%" }} size="large" />
              </Form.Item>
            </Col>

            {/* Deliverer */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("stock", ["Deliverer"])}
                  </span>
                }
                name="deliverer"
              >
                <Select
                  placeholder={lang.get("stock", ["Select Deliverer"])}
                  size="large"
                >
                  <Option value="Deliverer1">
                    {lang.get("stock", ["Deliverer 1"])}
                  </Option>
                  <Option value="Deliverer2">
                    {lang.get("stock", ["Deliverer 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Location From */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("stock", ["Location From"])}
                  </span>
                }
                name="locationFrom"
              >
                <Select
                  placeholder={lang.get("stock", ["Select Location From"])}
                  size="large"
                >
                  <Option value="Location1">
                    {lang.get("stock", ["Location 1"])}
                  </Option>
                  <Option value="Location2">
                    {lang.get("stock", ["Location 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Location To */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("stock", ["Location To"])}
                  </span>
                }
                name="locationTo"
              >
                <Select
                  placeholder={lang.get("stock", ["Select Location To"])}
                  size="large"
                >
                  <Option value="Location1">
                    {lang.get("stock", ["Location 1"])}
                  </Option>
                  <Option value="Location2">
                    {lang.get("stock", ["Location 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <TransferAddTable />

          <Divider />

          {/*Notes Section */}
          <Row gutter={[16, 5]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("inventory", ["Client Note"])}
                  </span>
                }
                name="Note"
              >
                <TinyMCEEditor />
              </Form.Item>
            </Col>
          </Row>

          {/* Submit Section */}

          <Row justify="end" gutter={16}>
            <Button
              color="primary"
              variant="outlined"
              icon={<SendOutlined />}
              style={{ marginBottom: "30px", marginTop: "30px" }}
              onClick={() =>
                console.log(
                  lang.get("sales", ["Send The Stock Transfer For Approval"])
                )
              }
            >
              {lang.get("sales", ["Send The Stock Transfer For Approval"])}
            </Button>
          </Row>
          <Row justify="end" gutter={16}>
            <Col>
              <Button
                onClick={() => console.log(lang.get("stock", ["Close"]))}
                style={{
                  backgroundColor: "white",
                  borderColor: "#d9d9d9",
                  color: "#000",
                  marginRight: 8,
                }}
              >
                {lang.get("stock", ["Close"])}
              </Button>
              <Button
                type="primary"
                onClick={handleSave}
                style={{
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  color: "#fff",
                  marginRight: 8,
                }}
              >
                {lang.get("stock", ["Save"])}
              </Button>
              <Button
                type="primary"
                onClick={() =>
                  console.log(lang.get("stock", ["Save & Send Request"]))
                }
                style={{
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  color: "#fff",
                }}
              >
                {lang.get("stock", ["Save & Send Request"])}
              </Button>
            </Col>
          </Row>
        </ProForm>
      </ProCard>
    </div>
  );
};

export default StockAdd;
