import {
  Button,
  Dropdown,
  Switch,
  Row,
  Col,
  Upload,
  Menu,
  Space,
} from "antd";
import {
  UploadOutlined,
  DownloadOutlined,
  DownOutlined,
} from "@ant-design/icons";
import FilesTable from "./FilesTable";
import lang from "../../../../../../lang";
const Files = () => {
  const menus = [
    {
      label: lang.get("files", ["Manipulation"]),
      items: [
        {
          label: lang.get("files", ["Move"]),
          key: "move",
          onClick: () => console.log(lang.get("files", ["Move"])),
        },
        {
          label: lang.get("files", ["Duplicate"]),
          key: "duplicate",
          onClick: () => console.log(lang.get("files", ["Duplicate"])),
        },
      ],
    },
  ];

  const uploadProps = {
    onChange: (info: any) => console.log(info),
  };

  return (
    <div
      style={{ padding: "16px", background: "#f5f7fa", borderRadius: "8px" }}
    >
      {/* Upload Section */}
      <Row
        align="middle"
        justify="space-between"
        style={{ marginBottom: "16px" }}
      >
        <Col>
          <Upload {...uploadProps}>
            <Button type="primary" icon={<UploadOutlined />}>
              {lang.get("files", ["Upload File"])}
            </Button>
          </Upload>
        </Col>
        <Col>
          <Space>
            <Switch
              defaultChecked
              onChange={(checked) =>
                console.log(
                  lang.get("files", [
                    checked
                      ? "Visible to Customer Enabled"
                      : "Visible to Customer Disabled",
                  ])
                )
              }
            />
            <span>{lang.get("files", ["Visible to Customer"])}</span>
          </Space>
        </Col>
      </Row>

      {/* Controls Section */}
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "16px" }}
      >
        <Col>
          <Space>
            {menus.map((menu) => (
              <Dropdown
                key={menu.label}
                overlay={
                  <Menu>
                    {menu.items.map((item) => (
                      <Menu.Item key={item.key} onClick={item.onClick}>
                        {item.label}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <Button type="default">
                  {menu.label} <DownOutlined />
                </Button>
              </Dropdown>
            ))}
            <Button type="dashed" icon={<DownloadOutlined />}>
              {lang.get("files", ["Download All"])}
            </Button>
          </Space>
        </Col>
      </Row>

      <FilesTable />
    </div>
  );
};

export default Files;
