import {
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FullscreenOutlined,
  MailOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import {
  Button,
  Input,
  Space,
  InputRef,
  Tag,
  Select,
  DatePicker,
  Row,
  Card,
  Col,
  Descriptions,
  Menu,
  Table,
} from "antd";
import { ReactNode, useRef, useState } from "react";
import Export from "../../../../common/components/Export";
import lang from "../../../../lang";

interface Invoice {
  id: string;
  amount: string;
  totalTax: string;
  date: string;
  customer: string;
  project: string;
  tags: string[];
  dueDate: string;
  status: string;
}

const Invoices: Invoice[] = [
  {
    id: "INV-DRAFT",
    amount: "$10.00",
    totalTax: "$0.00",
    date: "2024-05-08",
    project: "",
    tags: [],
    dueDate: "2024-06-07",
    status: "Draft",
    customer: "",
  },
  {
    id: "INV-000022",
    amount: "$100.00",
    totalTax: "$0.00",
    date: "2024-11-01",
    project: "",
    tags: [],
    dueDate: "2024-12-01",
    status: "Unpaid",
    customer: "",
  },
  {
    id: "INV-000020",
    amount: "$0.00",
    totalTax: "$0.00",
    date: "2024-06-03",
    project: "",
    tags: [],
    dueDate: "2024-07-03",
    status: "Paid",
    customer: "",
  },
  {
    id: "INV-000019",
    amount: "$6,500.00",
    totalTax: "$0.00",
    date: "2024-03-16",
    project: "",
    tags: [],
    dueDate: "2024-04-15",
    status: "Paid",
    customer: "",
  },
  {
    id: "INV-000018",
    amount: "$8,500.00",
    totalTax: "$0.00",
    date: "2024-03-12",
    project: "",
    tags: [],
    dueDate: "2024-04-11",
    status: "Unpaid",
    customer: "",
  },
];

const { Option } = Select;
const { RangePicker } = DatePicker;

const PerformaTable = () => {
  const actionRef = useRef<ActionType>();
  const [pageSize, setPageSize] = useState<number>(10);
  const searchInput = useRef<InputRef>(null);
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const [allSearch, setAllSearch] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>("invoice");
  const expobj = new Export("invoices, " + new Date().toDateString());
  const [ExpandedRecord, setExpandedRecord] = useState<Invoice | null>(null);

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  function handleTabChange(key: string): void {
    setActiveTab(key);
  }

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("sales", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("sales", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("sales", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<Invoice>[] = [
    {
      title: lang.get("sales", ["Invoice #"]),
      dataIndex: "id",
      sorter: true,
      ellipsis: true,
      ...searchCols("id", lang.get("sales", ["Invoice #"])),
    },
    {
      title: lang.get("sales", ["Amount"]),
      dataIndex: "amount",
      sorter: true,
      ...searchCols("amount", lang.get("sales", ["Amount"])),
    },
    {
      title: lang.get("sales", ["Total Tax"]),
      dataIndex: "totalTax",
      sorter: true,
      ...searchCols("totalTax", lang.get("sales", ["Total Tax"])),
    },
    {
      title: lang.get("sales", ["Date"]),
      dataIndex: "date",
      sorter: true,
      ...searchCols("date", lang.get("sales", ["Date"])),
    },
    {
      title: lang.get("sales", ["Customer"]),
      dataIndex: "customer",
      sorter: true,
      ...searchCols("customer", lang.get("sales", ["Customer"])),
    },
    {
      title: lang.get("sales", ["Project"]),
      dataIndex: "project",
      sorter: true,
      ...searchCols("project", lang.get("sales", ["Project"])),
    },
    {
      title: lang.get("sales", ["Tags"]),
      dataIndex: "tags",
      render: (tags: ReactNode) => {
        const tagArray = tags as string[] | undefined;
        return tagArray && tagArray.length > 0 ? (
          tagArray.map((tag) => (
            <Tag color="blue" key={tag}>
              {tag}
            </Tag>
          ))
        ) : (
          <span style={{ color: "#ccc" }}>-</span>
        );
      },
    },
    {
      title: lang.get("sales", ["Due Date"]),
      dataIndex: "dueDate",
      sorter: true,
      ...searchCols("dueDate", lang.get("sales", ["Due Date"])),
    },
    {
      title: lang.get("sales", ["Status"]),
      dataIndex: "status",
      sorter: true,
      filters: [
        { text: lang.get("sales", ["Paid"]), value: "Paid" },
        { text: lang.get("sales", ["Unpaid"]), value: "Unpaid" },
        { text: lang.get("sales", ["Draft"]), value: "Draft" },
      ],
      render: (_, record) =>
        record.status === "Paid" ? (
          <Tag color="green">{lang.get("sales", ["Paid"])}</Tag>
        ) : record.status === "Unpaid" ? (
          <Tag color="red">{lang.get("sales", ["Unpaid"])}</Tag>
        ) : (
          <Tag color="default">{lang.get("sales", ["Draft"])}</Tag>
        ),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} md={ExpandedRecord ? 14 : 24}>
        <ProTable
          headerTitle={lang.get("sales", ["Invoices"])}
          columns={columns}
          actionRef={actionRef}
          dataSource={Invoices}
          rowKey="id"
          onRow={(record) => ({
            onClick: () => {
              setExpandedRecord(record);
            },
          })}
          cardBordered
          pagination={{
            defaultCurrent: 1,
            pageSize: pageSize,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 50],
            showTotal: (total, range) =>
              lang.get("sales", [
                `Showing ${range[0]}-${range[1]} of ${total} items`,
              ]),
            onShowSizeChange: (current, size) => {
              setPageSize(size);
            },
          }}
          style={{ cursor: "pointer" }}
          search={false}
          scroll={{ x: 800 }}
          toolBarRender={() => [
            <RangePicker key="date" />,
            <Select
              placeholder={lang.get("sales", ["Select Currency"])}
              style={{ width: 200 }}
            >
              <Option value="USD">
                USD - {lang.get("sales", ["US Dollar"])}
              </Option>
              <Option value="EUR">EUR - {lang.get("sales", ["Euro"])}</Option>
              <Option value="GBP">
                GBP - {lang.get("sales", ["British Pound"])}
              </Option>
              <Option value="LKR">
                LKR - {lang.get("sales", ["Sri Lankan Rupee"])}
              </Option>
            </Select>,
            <Input
              style={{ width: "auto" }}
              key="all_search"
              allowClear
              placeholder={lang.get("sales", ["Global Search"])}
              value={allSearch}
              onChange={(e) => {
                setAllSearch(e.target.value);
                setSearchAttributes([]);
                actionRef?.current?.reload();
              }}
            />,
            <Button
              key="csv"
              onClick={() => {
                const csvData = Invoices.map((row) => ({
                  [lang.get("sales", ["Invoice #"])]: row.id,
                  [lang.get("sales", ["Amount"])]: row.amount,
                  [lang.get("sales", ["Total Tax"])]: row.totalTax,
                  [lang.get("sales", ["Project"])]: row.project,
                  [lang.get("sales", ["Tags"])]: row.tags.join(", "),
                  [lang.get("sales", ["Date"])]: row.date,
                  [lang.get("sales", ["Due Date"])]: row.dueDate,
                  [lang.get("sales", ["Status"])]: row.status,
                  [lang.get("sales", ["Customer"])]: row.customer,
                }));
                expobj.exportToCSV(csvData);
              }}
            >
              {lang.get("sales", ["Export CSV"])}
            </Button>,
            <Button
              key="excel"
              onClick={() => {
                const excelData = Invoices.map((row) => ({
                  [lang.get("sales", ["Invoice #"])]: row.id,
                  [lang.get("sales", ["Amount"])]: row.amount,
                  [lang.get("sales", ["Total Tax"])]: row.totalTax,
                  [lang.get("sales", ["Project"])]: row.project,
                  [lang.get("sales", ["Customer"])]: row.customer,
                  [lang.get("sales", ["Tags"])]: row.tags.join(", "),
                  [lang.get("sales", ["Date"])]: row.date,
                  [lang.get("sales", ["Due Date"])]: row.dueDate,
                  [lang.get("sales", ["Status"])]: row.status,
                }));
                expobj.exportToExcel(excelData);
              }}
            >
              {lang.get("sales", ["Export Excel"])}
            </Button>,
            <Button
              key="pdf"
              onClick={() => {
                expobj.exportToPDF({
                  head: [
                    [
                      lang.get("sales", ["Invoice #"]),
                      lang.get("sales", ["Amount"]),
                      lang.get("sales", ["Total Tax"]),
                      lang.get("sales", ["Date"]),
                      lang.get("sales", ["Project"]),
                      lang.get("sales", ["Tags"]),
                      lang.get("sales", ["Due Date"]),
                      lang.get("sales", ["Status"]),
                    ],
                  ],
                  body: Invoices.map((row) => [
                    row.id,
                    row.amount,
                    row.totalTax,
                    row.date,
                    row.project,
                    row.tags.join(", "),
                    row.dueDate,
                    row.status,
                  ]),
                });
              }}
            >
              {lang.get("sales", ["Export PDF"])}
            </Button>,
          ]}
        />
      </Col>

      {ExpandedRecord && (
        <Col xs={24} md={10}>
          {/* Expanded Section */}
          <Card
            title={
              <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
              >
          <div
            style={{ display: "flex", gap: "12px", alignItems: "center" }}
          >
            <Button type="text" icon={<MailOutlined />} />
            <Button type="text" icon={<EyeOutlined />} />
            <Button type="text" icon={<FullscreenOutlined />} />
          </div>
          {/* Action Buttons */}
          <div style={{ display: "flex", gap: "8px" }}>
            <Button type="text" size="small" icon={<EditOutlined />} />
            <Button type="text" size="small" icon={<DeleteOutlined />} />
            <Button type="primary" size="small">
              <span style={{ fontSize: "10px" }}>
                {ExpandedRecord.status.toUpperCase()}
              </span>
            </Button>
          </div>
              </div>
            }
            bordered
            style={{
              border: "1px solid #1677ff",
              overflow: "auto",
              height: "100%",
            }}
            extra={
              <span
          style={{
            cursor: "pointer",
            color: "#e9554a",
            marginLeft: "10px",
          }}
          onClick={() => setExpandedRecord(null)}
              >
          <CloseCircleOutlined />
              </span>
            }
          >
            {/* Tabs */}
            <Menu
              mode="horizontal"
              defaultSelectedKeys={["invoice"]}
              selectedKeys={[activeTab]}
              onClick={({ key }) => handleTabChange(key)}
              style={{
          marginBottom: "20px",
          borderBottom: "1px solid #e3e3e3",
              }}
              items={[
          { key: "invoice", label: lang.get("sales", ["Invoice"]) },
          { key: "paymentHistory", label: lang.get("sales", ["Payment History"]) },
          { key: "activityLog", label: lang.get("sales", ["Activity Log"]) },
          { key: "notes", label: lang.get("sales", ["Notes"]) },
              ]}
            />

            {/* Tab Contents */}
            {activeTab === "invoice" && (
          <div>
          {/* Invoice Details */}
          <div
            style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "16px",
            }}
          >
            <div style={{ textAlign: "left", flexBasis: "40%" }}>
            <p
              style={{
              fontWeight: "bold",
              color: "#1677ff",
              marginBottom: "8px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              }}
            >
              {ExpandedRecord.id}
              {ExpandedRecord.status === "Paid" ? (
              <Tag color="green">{lang.get("sales", ["Paid"])}</Tag>
              ) : ExpandedRecord.status === "Unpaid" ? (
              <Tag color="red">{lang.get("sales", ["Unpaid"])}</Tag>
              ) : (
              <Tag color="default">{lang.get("sales", ["Draft"])}</Tag>
              )}
            </p>
            <p style={{ fontWeight: "bold", marginBottom: "4px" }}>
              {lang.get("sales", ["Invoice for ABC Pvt Ltd"])}
            </p>
            <p style={{ marginBottom: "4px" }}>
              {lang.get("sales", ["1234 Main Street"])}
            </p>
            <p style={{ marginBottom: "4px" }}>
              {lang.get("sales", ["Cityville, State"])}
            </p>
            <p style={{ marginBottom: "8px" }}>
              {lang.get("sales", ["Country"])}
            </p>
            </div>

            <div style={{ textAlign: "right", flexBasis: "40%" }}>
            <p>
              <strong>{lang.get("sales", ["Bill To:"])}</strong> XYZ Corp
            </p>
            <p>{lang.get("sales", ["4567 Secondary St"])}</p>
            <p>{lang.get("sales", ["Townsville, State"])}</p>
            <Descriptions
              bordered
              column={1}
              size="small"
              style={{ textAlign: "center", flexBasis: "40%" }}
            >
              <Descriptions.Item label={lang.get("sales", ["Invoice Date"])}>
              {ExpandedRecord?.date || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label={lang.get("sales", ["Due Date"])}>
              {ExpandedRecord?.dueDate || "N/A"}
              </Descriptions.Item>
            </Descriptions>
            </div>
          </div>

          <Table
            dataSource={[
              {
                description: "Consulting Services",
                qty: 1,
                rate: 5000,
                tax: "10%",
                amount: 5500,
              },
              {
                description: "Software License",
                qty: 1,
                rate: 3000,
                tax: "10%",
                amount: 3300,
              },
            ]}
            columns={[
              {
                title: lang.get("sales", ["Description"]),
                dataIndex: "description",
                key: "description",
                width: "40%",
              },
              {
                title: lang.get("sales", ["Qty"]),
                dataIndex: "qty",
                key: "qty",
                align: "center",
              },
              {
                title: lang.get("sales", ["Rate"]),
                dataIndex: "rate",
                key: "rate",
                align: "right",
              },
              {
                title: lang.get("sales", ["Tax"]),
                dataIndex: "tax",
                key: "tax",
                align: "center",
              },
              {
                title: lang.get("sales", ["Amount"]),
                dataIndex: "amount",
                key: "amount",
                align: "right",
              },
            ]}
            pagination={false}
            size="small"
            style={{ marginTop: 20 }}
            rowKey={(record, index) => `${record.description}-${index}`}
          />
              </div>
            )}
            {activeTab === "paymentHistory" && (
              <div>{lang.get("sales", ["Payment History Content"])}</div>
            )}
            {activeTab === "activityLog" && <div>{lang.get("sales", ["Activity Log Content"])}</div>}
            {activeTab === "notes" && <div>{lang.get("sales", ["Notes Content"])}</div>}
          </Card>
        </Col>
      )}
    </Row>
  );
};

export default PerformaTable;
