import React from "react";
import { Breadcrumb, Typography, Button, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CreditTable from "./CreditTable";
import lang from "../../../../lang";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const CreditNotes: React.FC = () => {
  const navigate = useNavigate();
  const handleCreateCreditNoteClick = () => {
    navigate("/credit-add");
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* Breadcrumb */}
      <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
        <Breadcrumb.Item>{lang.get("breadcrumbs", ["Home"])}</Breadcrumb.Item>
        <Breadcrumb.Item>{lang.get("breadcrumbs", ["Sales"])}</Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Credit Notes"])}
        </Breadcrumb.Item>
      </Breadcrumb>

      {/* Page Title */}
      <Title level={2} style={{ marginBottom: "16px" }}>
        {lang.get("sales", ["Credit Notes"])} (20)
      </Title>

      {/* Action Buttons Row */}
      <Row
        gutter={[16, 16]}
        style={{ marginBottom: "16px" }}
        align="middle"
        justify="space-between"
      >
        {/* Left Section: Create Credit Note Button */}
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleCreateCreditNoteClick}
          >
            {lang.get("sales", ["Create Credit Note"])}
          </Button>
        </Col>
      </Row>

      {/* Credit Notes Table */}
      <CreditTable />
    </div>
  );
};

export default CreditNotes;
