import React, { useEffect } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ConfigProvider, theme } from "antd";

import LoginPage from "./auth/SignInForm";

import CustomerList from "./modules/customer/customer-list/CustomerList";
import Profile from "./modules/customer/customer-profile/CustomerProfile";
import ProjectList from "./modules/project/project-list/ProjectList";
import ProjectAddBody from "./modules/project/project-add/ProjectAddBody";
import ProjectDetails from "./modules/project/project-list/ProjectDetails";

import AppLayout from "./layout/AppLayout";
import "bootstrap/dist/css/bootstrap.min.css";

import { notification } from "antd";

import PWAInit from "./PWAInit";

import Dashboard from "./common/dashboard/dashboard";
import en_US from "antd/lib/locale/en_US";
import Estimates from "./modules/sales/components/estimate/Estimate";
import Proposals from "./modules/sales/components/proposals/Proposals";
import Invoices from "./modules/sales/components/invoice/Invoice";
import PerformaInvoices from "./modules/sales/components/performa-invoice/PerformaInvoice";
import CreditNotes from "./modules/sales/components/credit-notes/CreditNotes";
import DebitNotes from "./modules/sales/components/debit-notes/DebitNotes";
import Payments from "./modules/sales/components/payments/Payment";
import AddInvoice from "./modules/sales/components/invoice/invoice-add/Addinvoice";
import AddProposal from "./modules/sales/components/proposals/proposal-add/AddProposal";
import AddEstimate from "./modules/sales/components/estimate/estimate-add/AddEstimate";
import AddCredit from "./modules/sales/components/credit-notes/credit-add/AddCredit";
import AddDebit from "./modules/sales/components/debit-notes/debit-add/AddDebit";
import AddPerformaInvoice from "./modules/sales/components/performa-invoice/performa-invoice-add/AddPerforma";
import ItemList from "./modules/inventory/item/ItemList";
import ItemDetails from "./modules/inventory/item/ItemDetails";
import ReceivingVoucher from "./modules/inventory/inventory-receiving/ReceivingVoucher";
import InventoryDeliver from "./modules/inventory/inventory-delivering/VoucherDeliver";
import PackageList from "./modules/inventory/packaging-list/PackageList";
import InternalDeliveryNote from "./modules/inventory/internal-delivery/DeliveryNote";
import ReturnOrders from "./modules/inventory/return-orders/ReturnOrders";
import StockAdjustment from "./modules/inventory/stock/Stock";
import Warehouse from "./modules/inventory/warehouse/Warehouse";
import ScrollToTop from "./common/base/ScrollToTop";
import ReceivingVoucherAddBody from "./modules/inventory/inventory-receiving/ReceivingVoucherAddBody";
import DeliveryVoucherAddBody from "./modules/inventory/inventory-delivering/DelveryVoucherAdd";
import ShipmentAdd from "./modules/inventory/packaging-list/ShipmentAdd";
import Vendors from "./modules/purchase/vendors/Vendors";
import PurchaseRequest from "./modules/purchase/purchase-request/PurchaseRequest";
import PurchaseOrder from "./modules/purchase/purchase-order/PurchaseOrder";
import OrderReturns from "./modules/purchase/order-returns/OrderReturns";
import OrderReturnAdd from "./modules/purchase/order-returns/add-order-return/OrderReturnAdd";
import PurchaseOrderAdd from "./modules/purchase/purchase-order/add-purchase-order/PurchaseOrderAdd";
import PurchaseRequestAdd from "./modules/purchase/purchase-request/add-purchase-request/PurchaseRequestAdd";
import VendorAddBody from "./modules/purchase/vendors/vendor-add/VendorAddBody";
import TransferAdd from "./modules/inventory/internal-delivery/transfer-add/TransferAdd";
import StockAdd from "./modules/inventory/internal-delivery/transfer-add/TransferAdd";
import StockAdjustmentAdd from "./modules/inventory/stock/stock-add/StockAdd";

const App: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    setInterval(() => {
      if (
        localStorage.getItem("toast") &&
        localStorage.getItem("toast") != null
      ) {
        const toast = JSON.parse(localStorage.getItem("toast") as string);

        api.info({
          type: toast?.type,
          message: toast?.message,
          description: toast?.description,
          placement: toast?.placement,
          duration: toast?.duration,
        });

        localStorage.removeItem("toast");
      }
    }, 100);
  }, [localStorage.getItem("toast")]);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.compactAlgorithm,
      }}
      locale={en_US}
    >
      <PWAInit />
      {contextHolder}
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route
            path="/dashboard"
            element={
              <AppLayout>
                <Dashboard />
              </AppLayout>
            }
          />
          <Route
            path="/customer-list"
            element={
              <AppLayout>
                <CustomerList />
              </AppLayout>
            }
          />
          <Route
            path="/customer-profile"
            element={
              <AppLayout>
                <Profile />
              </AppLayout>
            }
          />
          <Route
            path="/project-list"
            element={
              <AppLayout>
                <ProjectList />
              </AppLayout>
            }
          />
          <Route
            path="/project-add"
            element={
              <AppLayout>
                <ProjectAddBody />
              </AppLayout>
            }
          />
          <Route
            path="/project-details/:id"
            element={
              <AppLayout>
                <ProjectDetails />
              </AppLayout>
            }
          />
          <Route
            path="/estimates"
            element={
              <AppLayout>
                <Estimates />
              </AppLayout>
            }
          />
          <Route
            path="/estimate-add"
            element={
              <AppLayout>
                <AddEstimate />
              </AppLayout>
            }
          />
          <Route
            path="/proposals"
            element={
              <AppLayout>
                <Proposals />
              </AppLayout>
            }
          />
          <Route
            path="/proposal-add"
            element={
              <AppLayout>
                <AddProposal />
              </AppLayout>
            }
          />
          <Route
            path="/invoices"
            element={
              <AppLayout>
                <Invoices />
              </AppLayout>
            }
          />
          <Route
            path="/invoice-add"
            element={
              <AppLayout>
                <AddInvoice />
              </AppLayout>
            }
          />
          <Route
            path="/performa-invoices"
            element={
              <AppLayout>
                <PerformaInvoices />
              </AppLayout>
            }
          />
          <Route
            path="/performa-invoices-add"
            element={
              <AppLayout>
                <AddPerformaInvoice />
              </AppLayout>
            }
          />
          <Route
            path="/credit-notes"
            element={
              <AppLayout>
                <CreditNotes />
              </AppLayout>
            }
          />
          <Route
            path="/credit-add"
            element={
              <AppLayout>
                <AddCredit />
              </AppLayout>
            }
          />
          <Route
            path="/debit-notes"
            element={
              <AppLayout>
                <DebitNotes />
              </AppLayout>
            }
          />
          <Route
            path="/debit-add"
            element={
              <AppLayout>
                <AddDebit />
              </AppLayout>
            }
          />
          <Route
            path="/payments"
            element={
              <AppLayout>
                <Payments />
              </AppLayout>
            }
          />
          <Route
            path="/payments"
            element={
              <AppLayout>
                <Payments />
              </AppLayout>
            }
          />
          <Route
            path="/items"
            element={
              <AppLayout>
                <ItemList />
              </AppLayout>
            }
          />
          <Route
            path="/item-details/:id"
            element={
              <AppLayout>
                <ItemDetails />
              </AppLayout>
            }
          />
          <Route
            path="/inventory-receiving-voucher"
            element={
              <AppLayout>
                <ReceivingVoucher />
              </AppLayout>
            }
          />
          <Route
            path="/inventory-receiving-voucher-add"
            element={
              <AppLayout>
                <ReceivingVoucherAddBody />
              </AppLayout>
            }
          />
          <Route
            path="/inventory-delivery-voucher-add"
            element={
              <AppLayout>
                <DeliveryVoucherAddBody />
              </AppLayout>
            }
          />
          <Route
            path="/inventory-delivery-voucher"
            element={
              <AppLayout>
                <InventoryDeliver />
              </AppLayout>
            }
          />
          <Route
            path="/inventory-delivery-voucher"
            element={
              <AppLayout>
                <InventoryDeliver />
              </AppLayout>
            }
          />
          <Route
            path="/packing-list"
            element={
              <AppLayout>
                <PackageList />
              </AppLayout>
            }
          />
          <Route
            path="/shipment-add-new"
            element={
              <AppLayout>
                <ShipmentAdd />
              </AppLayout>
            }
          />
          <Route
            path="/internal-delivery-note"
            element={
              <AppLayout>
                <InternalDeliveryNote />
              </AppLayout>
            }
          />

          <Route
            path="/stock-transfer-add"
            element={
              <AppLayout>
                <TransferAdd />
              </AppLayout>
            }
          />
          <Route
            path="/return-orders"
            element={
              <AppLayout>
                <ReturnOrders />
              </AppLayout>
            }
          />
          <Route
            path="/stock-adjustment"
            element={
              <AppLayout>
                <StockAdjustment />
              </AppLayout>
            }
          />

          <Route
            path="/stock-adjustment-add"
            element={
              <AppLayout>
                <StockAdjustmentAdd />
              </AppLayout>
            }
          />

          <Route
            path="/warehouse-management"
            element={
              <AppLayout>
                <Warehouse />
              </AppLayout>
            }
          />
          {/* Purchase modules */}
          <Route
            path="purchase/vendor"
            element={
              <AppLayout>
                <Vendors />
              </AppLayout>
            }
          />
          <Route
            path="purchase/purchase-request"
            element={
              <AppLayout>
                <PurchaseRequest />
              </AppLayout>
            }
          />
          <Route
            path="purchase/purchase-order"
            element={
              <AppLayout>
                <PurchaseOrder />
              </AppLayout>
            }
          />
          <Route
            path="purchase/order-returns"
            element={
              <AppLayout>
                <OrderReturns />
              </AppLayout>
            }
          />
          <Route
            path="purchase/order-returns/order-add"
            element={
              <AppLayout>
                <OrderReturnAdd />
              </AppLayout>
            }
          />
          <Route
            path="purchase/purchase-order/order-add"
            element={
              <AppLayout>
                <PurchaseOrderAdd />
              </AppLayout>
            }
          />

          <Route
            path="purchase/purchase-request/request-add"
            element={
              <AppLayout>
                <PurchaseRequestAdd />
              </AppLayout>
            }
          />

          <Route
            path="purchase/vendor/vendor-add"
            element={
              <AppLayout>
                <VendorAddBody />
              </AppLayout>
            }
          />
        </Routes>
      </Router>
    </ConfigProvider>
  );
};

export default App;
