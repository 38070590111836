import React, { useState, ChangeEvent } from "react";
import {
  Row,
  Col,
  Card,
  Tabs,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Typography,
} from "antd";
import { InfoCircleOutlined, SettingOutlined } from "@ant-design/icons";

import { Editor } from "@tinymce/tinymce-react";
import ProjectSettingBody from "./ProjectSettingBody";
import { ProFormUploadDragger } from "@ant-design/pro-components";
import lang from "../../../lang";

const { TabPane } = Tabs;
const { Title } = Typography;

const ProjectAddBody: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("projectInfo");
  const [postData, setPostData] = useState({
    Project_name: "",
    customer: "",
    BillingType: "Fixed",
    status: "In Progress",
    totalRate: "",
    EstimatedHours: "",
    Members: [],
    startDate: null,
    Deadline: null,
    tags: [],
    description: "",
    priority: "",
    ProjectManager: [],
    Department: [],
    ProjectNotifyDone: [],
    attachments: null,
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPostData({
      ...postData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSelectChange = (value: string | string[], field: string) => {
    setPostData({
      ...postData,
      [field]: value,
    });
  };

  const handleSave = () => {
    console.log("Project Data Saved:", postData);
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* <PageBreadcrumb items={defaultBreadcrumbItems} /> */}
      <Title level={2} className="mb-4">
        Create a Project
      </Title>
      <Card>
        <Tabs
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key)}
          tabBarStyle={{ marginBottom: 24, backgroundColor: "#f5f7fa" }}
        >
          {/* Project Info Tab */}
          <TabPane
            tab={
              <div style={{ paddingLeft: "10px" }}>
                <InfoCircleOutlined /> Project Info
              </div>
            }
            key="projectInfo"
          >
            <Form layout="vertical">
              <Row gutter={[16, 4]}>
                {/* Project Name */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Project Name" name="Project_name">
                    <Input
                      id="Project_name"
                      placeholder="Enter Project Name"
                      onChange={handleInputChange}
                      size="large"
                    />
                  </Form.Item>
                </Col>

                {/* Customer */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Customer" name="customer">
                    <Select
                      placeholder="Select a Customer"
                      onChange={(value) =>
                        handleSelectChange(value, "customer")
                      }
                      size="large"
                    >
                      <Select.Option value="1">Customer 1</Select.Option>
                      <Select.Option value="2">Customer 2</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                {/* Billing Type */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Billing Type" name="BillingType">
                    <Select
                      placeholder="Select Billing Type"
                      value={postData.BillingType}
                      onChange={(value) =>
                        handleSelectChange(value, "BillingType")
                      }
                      size="large"
                    >
                      <Select.Option value="Fixed">Fixed Rate</Select.Option>
                      <Select.Option value="Hourly">Hourly Rate</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                {/* Status */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Status" name="status">
                    <Select
                      placeholder="Select Status"
                      value={postData.status}
                      onChange={(value) => handleSelectChange(value, "status")}
                      size="large"
                    >
                      <Select.Option value="In Progress">
                        In Progress
                      </Select.Option>
                      <Select.Option value="Completed">Completed</Select.Option>
                      <Select.Option value="On Hold">On Hold</Select.Option>
                      <Select.Option value="Cancelled">Cancelled</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                {/* Priority */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Priority" name="priority">
                    <Select
                      placeholder="Select Priority"
                      onChange={(value) =>
                        handleSelectChange(value, "priority")
                      }
                      size="large"
                    >
                      <Select.Option value="Low">Low</Select.Option>
                      <Select.Option value="Medium">Medium</Select.Option>
                      <Select.Option value="High">High</Select.Option>
                      <Select.Option value="Urgent">Urgent</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                {/* Project Manager */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Project Manager" name="ProjectManager">
                    <Select
                      mode="multiple"
                      placeholder="Select Project Managers"
                      onChange={(value) =>
                        handleSelectChange(value, "ProjectManager")
                      }
                      size="large"
                    >
                      <Select.Option value="John Doe">John Doe</Select.Option>
                      <Select.Option value="Jane Smith">
                        Jane Smith
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                {/* Department */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Department" name="Department">
                    <Select
                      mode="multiple"
                      placeholder="Select Department"
                      onChange={(value) =>
                        handleSelectChange(value, "Department")
                      }
                      size="large"
                    >
                      <Select.Option value="IT">IT</Select.Option>
                      <Select.Option value="HR">HR</Select.Option>
                      <Select.Option value="Finance">Finance</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                {/* Total Rate */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Total Rate" name="totalRate">
                    <Input
                      placeholder="Enter Total Rate"
                      id="totalRate"
                      onChange={handleInputChange}
                      size="large"
                    />
                  </Form.Item>
                </Col>

                {/* Estimated Hours */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Estimated Hours" name="EstimatedHours">
                    <Input
                      placeholder="Enter Estimated Hours"
                      id="EstimatedHours"
                      onChange={handleInputChange}
                      size="large"
                    />
                  </Form.Item>
                </Col>

                {/* Start Date */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Start Date" name="startDate">
                    <DatePicker
                      onChange={(date) =>
                        handleSelectChange(date?.toString() || "", "startDate")
                      }
                      style={{ width: "100%" }}
                      size="large"
                    />
                  </Form.Item>
                </Col>

                {/* Deadline */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Deadline" name="Deadline">
                    <DatePicker
                      onChange={(date) =>
                        handleSelectChange(date?.toString() || "", "Deadline")
                      }
                      style={{ width: "100%" }}
                      size="large"
                    />
                  </Form.Item>
                </Col>

                {/* Members */}
                <Col xs={24} sm={8}>
                  <Form.Item label="Team Members" name="Members">
                    <Select
                      mode="multiple"
                      placeholder="Select Members"
                      onChange={(value) => handleSelectChange(value, "Members")}
                      size="large"
                    >
                      <Select.Option value="John Doe">John Doe</Select.Option>
                      <Select.Option value="Jane Smith">
                        Jane Smith
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                {/* Tags */}
                <Col xs={24}>
                  <Form.Item label="Tags" name="tags">
                    <Select
                      mode="multiple"
                      placeholder="Add Tags"
                      onChange={(value) => handleSelectChange(value, "tags")}
                      size="large"
                    >
                      <Select.Option value="Tag1">Tag1</Select.Option>
                      <Select.Option value="Tag2">Tag2</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                {/* Description */}
                <Col xs={24}>
                  <Form.Item label="Description" name="description">
                    <Editor />
                  </Form.Item>
                </Col>

                {/* Attachments */}
                <Col xs={24}>
                  <ProFormUploadDragger
                    onChange={(info) => {}}
                    title={lang.get("project", [
                      "Click or drag files into this area to upload",
                    ])}
                    description={lang.get("project", [
                      "Support single or batch upload",
                    ])}
                    accept="image/*"
                    name="drag-pic"
                  />
                </Col>
              </Row>
            </Form>
          </TabPane>

          {/* Project Settings Tab */}
          <TabPane
            tab={
              <>
                <SettingOutlined /> Project Settings
              </>
            }
            key="projectSettings"
          >
            <ProjectSettingBody />
          </TabPane>
        </Tabs>

        <Row justify="end" gutter={16} className="mt-4">
          <Col>
            <Button type="primary" onClick={handleSave}>
              Create
            </Button>
          </Col>
          <Col>
            <Button type="default" onClick={handleSave}>
              Create & Save New
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ProjectAddBody;
