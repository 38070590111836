import React from "react";
import { Button, Space, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import EstimateTable from "./EstimateTable";
import EstimateStatistic from "./EstimateStatistic";
import lang from "../../../../../../lang";

const EstimateTab: React.FC = () => {
  return (
    <div style={{ borderRadius: "8px" }}>
      <Row
        gutter={[16, 16]}
        style={{ marginBottom: "16px", marginTop: "16px" }}
        align="middle"
        justify="space-between"
      >
        {/* Left Section: Buttons */}
        <Col xs={24} sm={24} md={12} lg={12}>
          <Space size="middle" wrap>
            <Button type="primary" icon={<PlusOutlined />}>
              {lang.get("estimate", ["Create New Estimate "])}
            </Button>
          </Space>
        </Col>
      </Row>

      <div style={{ marginBottom: "16px" }}>
        <EstimateStatistic />
      </div>

      <EstimateTable />
    </div>
  );
};

export default EstimateTab;
