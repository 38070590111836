import {
    Breadcrumb,
    Typography,
    Button,
    Row,
    Col,
    Modal,
 
  } from "antd";
  import { PlusOutlined } from "@ant-design/icons";
  import lang from "../../../lang";
  import { useState } from "react";
import ReturnOrdersTable from "./ReturnOrdersTable";
//   import ReturnOrdersTable from "./ReturnOrdersTable";
  
  const { Title } = Typography;
  
  const ReturnOrders: React.FC = () => {
    const [modalOpen, setModalOpen] = useState(false);
  
    return (
      <div
        style={{
          padding: "20px",
          backgroundColor: "#f5f7fa",
          minHeight: "100vh",
        }}
      >
        {/* Breadcrumb */}
        <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
          <Breadcrumb.Item>{lang.get("breadcrumbs", ["Home"])}</Breadcrumb.Item>
          <Breadcrumb.Item>
            {lang.get("breadcrumbs", ["Inventory"])}
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {lang.get("breadcrumbs", ["Return Orders"])}
          </Breadcrumb.Item>
        </Breadcrumb>
  
        {/* Page Title */}
        <Title level={2} style={{ marginBottom: "16px" }}>
          {lang.get("returnOrders", ["Return Orders"])} (7)
        </Title>
  
        {/* Filter and Actions */}
        <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
          <Col xs={24} sm={12} md={12} lg={12} style={{ textAlign: "left" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setModalOpen(true)}
            >
              {lang.get("returnOrders", ["Add Return Order"])}
            </Button>
          </Col>
        </Row>
  
        {/* Table */}
        <ReturnOrdersTable />
  
        {/* Add Return Order Modal */}
        <Modal
          title={lang.get("returnOrders", ["Add Return Order"])}
          open={modalOpen}
          footer={null}
          onCancel={() => setModalOpen(false)}
          width={1250}
        >
          {/* Add Return Order Modal Content */}
          {/* <ReturnOrderAddBody
            modalClose={() => setModalOpen(false)}
            setUpdateTable={() => console.log("Update table")}
          /> */}
        </Modal>
      </div>
    );
  };
  
  export default ReturnOrders;
  