import { StatisticCard } from "@ant-design/pro-components";
import RcResizeObserver from "rc-resize-observer";
import type { StatisticProps } from "antd";
import CountUp from "react-countup";
import { useEffect, useState } from "react";
import apiRequest from "../../../../common/utils/apiRequest";
import { DBStatistic } from "../../types/types";
import lang from "../../../../lang";

const { Statistic, Divider } = StatisticCard;
const formatter: StatisticProps["formatter"] = (value) => {
  return <CountUp end={value as number} separator="," />;
};
const CustomerListStatitstic = () => {
  const [responsive, setResponsive] = useState(false);
  const [loader, setLoader] = useState(true);
  const [statisticData, setStatisticData] = useState<DBStatistic>();
  const current_month = new Date().getMonth();

  useEffect(() => {
    const API_REQUEST = new apiRequest("client/statistics", true);
    API_REQUEST.send({}, (data: { status: string; data: DBStatistic }) => {
      if (data && data.status === "success") {
        setStatisticData(data.data);
      }
      setLoader(false);
    });
  }, []);

  return (
    <RcResizeObserver
      key="resize-observer"
      onResize={(offset) => {
        setResponsive(offset.width < 596);
      }}
    >
      <StatisticCard.Group
        direction={responsive ? "row" : "row"}
        style={{
          paddingTop: "16px",
          paddingBottom: "16px",
          height: responsive ? "100px" : "auto",
          overflowX: "auto",
          overflowY: "hidden",
        }}
      >
        <StatisticCard
          style={{ width: "100%" }}
          loading={loader}
          statistic={{
            formatter,
            title: lang.get("customer", ["Total Customers"]),
            value: statisticData?.total_customers,
            description: (
              <Statistic
                title={lang.get("customer", ["Growth In This Month"])}
                formatter={formatter}
                value={
                  (((statisticData?.month_wise_total_customers[current_month] ||
                    0) -
                    (statisticData?.month_wise_total_customers[
                      current_month - 1
                    ] || 0)) /
                    (statisticData?.month_wise_total_customers[current_month] ||
                      1)) *
                  100
                }
                suffix="%"
              />
            ),
          }}
          chart={
            <img
              src="https://gw.alipayobjects.com/zos/alicdn/6YR18tCxJ/huanlv.svg"
              alt="Inactive Customers"
              width="100%"
            />
          }
          chartPlacement="left"
        />
        <Divider type={responsive ? "horizontal" : "vertical"} />
        <StatisticCard
          loading={loader}
          statistic={{
            formatter,
            title: lang.get("customer", ["Active Customers"]),
            value: statisticData?.active_customers,
            description: (
              <Statistic
                title={lang.get("customer", ["Growth In This Month"])}
                formatter={formatter}
                value={
                  (((statisticData?.month_wise_total_active_customers[
                    current_month
                  ] || 0) -
                    (statisticData?.month_wise_total_active_customers[
                      current_month - 1
                    ] || 0)) /
                    (statisticData?.month_wise_total_customers[current_month] ||
                      1)) *
                  100
                }
                suffix="%"
              />
            ),
            suffix: <>/ {statisticData?.total_customers || 0}</>,
          }}
          chart={
            <img
              src="https://gw.alipayobjects.com/zos/alicdn/ShNDpDTik/huan.svg"
              alt="Active Customers"
              width="100%"
            />
          }
          chartPlacement="left"
        />
        <StatisticCard
          loading={loader}
          statistic={{
            formatter,
            title: lang.get("customer", ["Inactive Customers"]),
            value: statisticData?.inactive_customers,
            description: (
              <Statistic
                title={lang.get("customer", ["Growth In This Month"])}
                formatter={formatter}
                value={
                  (((statisticData?.month_wise_total_inactive_customers[
                    current_month
                  ] || 0) -
                    (statisticData?.month_wise_total_inactive_customers[
                      current_month - 1
                    ] || 0)) /
                    (statisticData?.month_wise_total_customers[current_month] ||
                      1)) *
                  100
                }
                suffix="%"
              />
            ),
            suffix: <>/ {statisticData?.total_customers || 0}</>,
          }}
          chart={
            <img
              src="https://gw.alipayobjects.com/zos/alicdn/6YR18tCxJ/huanlv.svg"
              alt="Inactive Customers"
              width="100%"
            />
          }
          chartPlacement="left"
        />
        <Divider type={responsive ? "horizontal" : "vertical"} />
        <StatisticCard
          loading={loader}
          statistic={{
            formatter,
            title: lang.get("customer", ["Total Contacts"]),
            value: statisticData?.total_contacts,
            description: (
              <Statistic
                title={lang.get("customer", ["Growth In This Month"])}
                formatter={formatter}
                value={
                  (((statisticData?.month_wise_total_contacts[current_month] ||
                    0) -
                    (statisticData?.month_wise_total_contacts[
                      current_month - 1
                    ] || 0)) /
                    (statisticData?.month_wise_total_contacts[current_month] ||
                      1)) *
                  100
                }
                suffix="%"
              />
            ),
          }}
          chart={
            <img
              src="https://gw.alipayobjects.com/zos/alicdn/6YR18tCxJ/huanlv.svg"
              alt="Inactive Customers"
              width="100%"
            />
          }
          chartPlacement="left"
        />
      </StatisticCard.Group>
    </RcResizeObserver>
  );
};

export default CustomerListStatitstic;
