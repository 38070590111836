import { SearchOutlined } from "@ant-design/icons";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import { Button, Input, Space, InputRef, DatePicker } from "antd";
import { useRef, useState } from "react";
import Export from "../../../../common/components/Export";
import lang from "../../../../lang";

interface Payment {
  paymentId: string;
  invoiceId: string;
  paymentMode: string;
  transactionId: string;
  customer: string;
  amount: string;
  date: string;
}

const Payments: Payment[] = [
  {
    paymentId: "PAY-00001",
    invoiceId: "INV-00010",
    paymentMode: "Credit Card",
    transactionId: "TXN-12345",
    customer: "John Doe",
    amount: "$500.00",
    date: "2024-12-01",
  },
  {
    paymentId: "PAY-00002",
    invoiceId: "INV-00011",
    paymentMode: "Bank Transfer",
    transactionId: "TXN-67890",
    customer: "Jane Smith",
    amount: "$1,000.00",
    date: "2024-12-02",
  },
];

const { RangePicker } = DatePicker;

const PaymentsTable = () => {
  const actionRef = useRef<ActionType>();
  const [pageSize, setPageSize] = useState<number>(10);
  const searchInput = useRef<InputRef>(null);
  const [allSearch, setAllSearch] = useState<string>("");

  const expobj = new Export("payments, " + new Date().toDateString());

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("payment", [`Search ${label}`])}
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  if (actionRef.current) actionRef.current.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("payment", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("payment", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<Payment>[] = [
    {
      title: lang.get("payment", ["Payment #"]),
      dataIndex: "paymentId",
      sorter: true,
      ...searchCols("paymentId", lang.get("payment", ["Payment #"])),
    },
    {
      title: lang.get("payment", ["Invoice #"]),
      dataIndex: "invoiceId",
      sorter: true,
      ...searchCols("invoiceId", lang.get("payment", ["Invoice #"])),
    },
    {
      title: lang.get("payment", ["Payment Mode"]),
      dataIndex: "paymentMode",
      sorter: true,
      ...searchCols("paymentMode", lang.get("payment", ["Payment Mode"])),
    },
    {
      title: lang.get("payment", ["Transaction ID"]),
      dataIndex: "transactionId",
      sorter: true,
      ...searchCols("transactionId", lang.get("payment", ["Transaction ID"])),
    },
    {
      title: lang.get("payment", ["Customer"]),
      dataIndex: "customer",
      sorter: true,
      ...searchCols("customer", lang.get("payment", ["Customer"])),
    },
    {
      title: lang.get("payment", ["Amount"]),
      dataIndex: "amount",
      sorter: true,
      ...searchCols("amount", lang.get("payment", ["Amount"])),
    },
    {
      title: lang.get("payment", ["Date"]),
      dataIndex: "date",
      sorter: true,
      ...searchCols("date", lang.get("payment", ["Date"])),
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("payment", ["Payments"])}
      columns={columns}
      actionRef={actionRef}
      dataSource={Payments}
      rowKey="paymentId"
      cardBordered
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        showTotal: (total, range) =>
          lang.get("payment", [
            `Showing ${range[0]}-${range[1]} of ${total} items`,
          ]),
        onShowSizeChange: (current, size) => {
          setPageSize(size);
        },
      }}
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <RangePicker key="date" />,
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("payment", ["Global Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            if (actionRef.current) actionRef.current.reload();
          }}
        />,
        <Button
          key="csv"
          onClick={() => {
            const csvData = Payments.map((row) => ({
              [lang.get("payment", ["Payment #"])]: row.paymentId,
              [lang.get("payment", ["Invoice #"])]: row.invoiceId,
              [lang.get("payment", ["Payment Mode"])]: row.paymentMode,
              [lang.get("payment", ["Transaction ID"])]: row.transactionId,
              [lang.get("payment", ["Customer"])]: row.customer,
              [lang.get("payment", ["Amount"])]: row.amount,
              [lang.get("payment", ["Date"])]: row.date,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("payment", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = Payments.map((row) => ({
              [lang.get("payment", ["Payment #"])]: row.paymentId,
              [lang.get("payment", ["Invoice #"])]: row.invoiceId,
              [lang.get("payment", ["Payment Mode"])]: row.paymentMode,
              [lang.get("payment", ["Transaction ID"])]: row.transactionId,
              [lang.get("payment", ["Customer"])]: row.customer,
              [lang.get("payment", ["Amount"])]: row.amount,
              [lang.get("payment", ["Date"])]: row.date,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("payment", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("payment", ["Payment #"]),
                  lang.get("payment", ["Invoice #"]),
                  lang.get("payment", ["Payment Mode"]),
                  lang.get("payment", ["Transaction ID"]),
                  lang.get("payment", ["Customer"]),
                  lang.get("payment", ["Amount"]),
                  lang.get("payment", ["Date"]),
                ],
              ],
              body: Payments.map((row) => [
                row.paymentId,
                row.invoiceId,
                row.paymentMode,
                row.transactionId,
                row.customer,
                row.amount,
                row.date,
              ]),
            });
          }}
        >
          {lang.get("payment", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default PaymentsTable;
