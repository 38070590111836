// MemberAvatarGroup.tsx
import React from "react";
import { Avatar, Tooltip } from "antd";

interface Member {
  id: number;
  name: string;
  avatar?: string;
}

interface MemberAvatarGroupProps {
  members: Member[];
}

const MemberAvatarGroup: React.FC<MemberAvatarGroupProps> = ({ members }) => {
  return (
    <Avatar.Group>
      {members.map((member) => (
        <Tooltip title={member.name} key={member.id}>
          <Avatar src={member.avatar} alt={member.name}>
            {!member.avatar && member.name[0]}
          </Avatar>
        </Tooltip>
      ))}
    </Avatar.Group>
  );
};

export default MemberAvatarGroup;
