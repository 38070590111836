import React, { useState } from "react";
import {
  ProForm,
  ProFormText,
  ProFormSelect,
  ProFormDependency,
  ProCard,
} from "@ant-design/pro-components";
import {
  Button,
  Modal,
  Row,
  Col,
  Divider,
  Typography,
  Space,
  Input,
  Select,
  Form,
  DatePicker,
  Tabs,
} from "antd";
import AddEstimateTable from "./AddEstimateTable";
import select from "antd/es/select";
import lang from "../../../../../lang";
import TinyMCEEditor from "../../../../../common/base/Editor";
import { SendOutlined } from "@ant-design/icons";

const { Title } = Typography;
const Option = select;
const { TabPane } = Tabs;

const AddEstimate: React.FC = () => {
  const [isBillToModalVisible, setBillToModalVisible] = useState(false);
  const [isShipToModalVisible, setShipToModalVisible] = useState(false);
  const [discountType, setDiscountType] = useState<string>("%");
  const [discount, setDiscount] = useState<number>(0);
  const [adjustment] = useState<number>(0);
  const [subTotal] = useState<number>(0);
  const [tax, setTax] = useState<string[]>([]);
  const [shipping, setShipping] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<string>("estimate");

  const calculateTotal = () => {
    const discountAmount =
      discountType === "%" ? (subTotal * discount) / 100 : discount;
    return subTotal - discountAmount + adjustment;
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <Title level={2}>{lang.get("sales", ["Create New Estimate"])}</Title>
      <ProCard>
        <ProForm layout="vertical" submitter={false}>
          <Row gutter={[16, 5]}>
            {/* Estimate Number */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Estimate Number"])}
                  </span>
                }
                name="estimateNumber"
                rules={[
                  {
                    required: true,
                    message: lang.get("sales", [
                      "Estimate Number is required!",
                    ]),
                  },
                ]}
              >
                <Input addonBefore="EST-" placeholder="000009" size="large" />
              </Form.Item>
            </Col>

            {/* Estimate Date */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Estimate Date"])}
                  </span>
                }
                name="estimateDate"
                rules={[
                  {
                    required: true,
                    message: lang.get("sales", ["Please select a date!"]),
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} size="large" />
              </Form.Item>
            </Col>

            {/* Due Date */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Due Date"])}
                  </span>
                }
                name="dueDate"
                rules={[
                  {
                    required: true,
                    message: lang.get("sales", ["Please select a due date!"]),
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} size="large" />
              </Form.Item>
            </Col>

            {/* Customer */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Customer"])}
                  </span>
                }
                name="customer"
                rules={[
                  {
                    required: true,
                    message: lang.get("sales", ["Please select a customer!"]),
                  },
                ]}
              >
                <Select
                  placeholder={lang.get("sales", ["Select and begin typing"])}
                  size="large"
                >
                  <Select.Option value="Customer 1">
                    {lang.get("sales", ["Customer 1"])}
                  </Select.Option>
                  <Select.Option value="Customer 2">
                    {lang.get("sales", ["Customer 2"])}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Billing Address */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Billing Address"])}
                  </span>
                }
                name="billingAddress"
                rules={[
                  {
                    required: true,
                    message: lang.get("sales", [
                      "Billing Address is required!",
                    ]),
                  },
                ]}
              >
                <Input.TextArea
                  placeholder={lang.get("sales", ["Enter Billing Address"])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Shipping Address */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Shipping Address"])}
                  </span>
                }
                name="shippingAddress"
              >
                <Input.TextArea
                  placeholder={lang.get("sales", ["Enter Shipping Address"])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Currency */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Currency"])}
                  </span>
                }
                name="currency"
                rules={[
                  {
                    required: true,
                    message: lang.get("sales", ["Currency is required!"]),
                  },
                ]}
              >
                <Select
                  placeholder={lang.get("sales", ["Select Currency"])}
                  size="large"
                >
                  <Select.Option value="LKR">
                    {lang.get("sales", ["LKR LKR"])}
                  </Select.Option>
                  <Select.Option value="USD">
                    {lang.get("sales", ["USD"])}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Exchange Rate */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Exchange Rate"])}
                  </span>
                }
                name="exchangeRate"
              >
                <Input
                  placeholder={lang.get("sales", ["Enter Exchange Rate"])}
                  size="large"
                  type="number"
                />
              </Form.Item>
            </Col>

            {/* Reference */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Reference"])}
                  </span>
                }
                name="reference"
              >
                <Input
                  placeholder={lang.get("sales", ["Enter Reference"])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Allowed Payment Modes */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Allowed Payment Modes"])}
                  </span>
                }
                name="paymentModes"
              >
                <Select
                  placeholder={lang.get("sales", ["Select Payment Modes"])}
                  size="large"
                  mode="multiple"
                >
                  <Select.Option value="Credit Card">
                    {lang.get("sales", ["Credit Card"])}
                  </Select.Option>
                  <Select.Option value="Bank Transfer">
                    {lang.get("sales", ["Bank Transfer"])}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Warehouse */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Warehouse"])}
                  </span>
                }
                name="warehouse"
              >
                <Select
                  placeholder={lang.get("sales", ["Select Warehouse"])}
                  size="large"
                >
                  <Select.Option value="Warehouse 1">
                    {lang.get("sales", ["Warehouse 1"])}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Sales Rep */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Sales Rep"])}
                  </span>
                }
                name="salesRep"
              >
                <Select
                  placeholder={lang.get("sales", ["Select Sales Rep"])}
                  size="large"
                >
                  <Select.Option value="Rep 1">
                    {lang.get("sales", ["Rep 1"])}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Assigned */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Assigned"])}
                  </span>
                }
                name="assigned"
              >
                <Select
                  placeholder={lang.get("sales", ["Select Assigned"])}
                  size="large"
                >
                  <Select.Option value="User 1">
                    {lang.get("sales", ["User 1"])}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Order By */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Order by"])}
                  </span>
                }
                name="orderBy"
              >
                <Select
                  placeholder={lang.get("sales", ["Select Order By"])}
                  size="large"
                >
                  <Select.Option value="Employee 1">
                    {lang.get("sales", ["Employee 1"])}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Checked By */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Checked By"])}
                  </span>
                }
                name="checkedBy"
              >
                <Select
                  placeholder={lang.get("sales", ["Select Checked By"])}
                  size="large"
                >
                  <Select.Option value="Employee 2">
                    {lang.get("sales", ["Employee 2"])}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Shipping Carrier */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Shipping Carrier"])}
                  </span>
                }
                name="shippingCarrier"
              >
                <Select
                  placeholder={lang.get("sales", ["Select Shipping Carrier"])}
                  size="large"
                >
                  <Select.Option value="Carrier 1">
                    {lang.get("sales", ["Carrier 1"])}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Admin Note */}
            <Col span={24}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Admin Note"])}
                  </span>
                }
                name="adminNote"
              >
                <TinyMCEEditor />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
            <TabPane tab={lang.get("sales", ["Estimate"])} key="estimate">
              <AddEstimateTable />
            </TabPane>
            <TabPane tab={lang.get("sales", ["Optional"])} key="optional">
              <AddEstimateTable />
            </TabPane>
          </Tabs>

          <Divider />

          {/* Summary Section */}
          <Row justify="end" gutter={16} style={{ borderRadius: "8px" }}>
            <ProFormDependency name={["total", "adjustment"]}>
              {() => (
                <Space
                  direction="vertical"
                  style={{
                    width: "30%",
                    padding: "16px",
                  }}
                >
                  {/* Shipping Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    {/* Shipping Input */}
                    <Col style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "12px",
                          marginRight: "8px",
                        }}
                      >
                        {lang.get("sales", ["Shipping ($) :"])}
                      </div>
                      <Input
                        type="number"
                        value={shipping}
                        onChange={(e) => setShipping(Number(e.target.value))}
                        size="small"
                        style={{ width: "100px", textAlign: "center" }}
                      />
                    </Col>

                    {/* Discount Input */}
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "16px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            fontWeight: 400,
                            fontSize: "12px",
                            marginRight: "8px",
                          }}
                        >
                          {lang.get("sales", ["Discount ($) :"])}
                        </div>
                        <Input
                          type="number"
                          value={discount}
                          onChange={(e) => setDiscount(Number(e.target.value))}
                          size="small"
                          style={{ width: "100px", textAlign: "left" }}
                          addonAfter={
                            <Select
                              value={discountType}
                              onChange={(value) => setDiscountType(value)}
                              style={{ width: "50px" }}
                              dropdownStyle={{
                                width: "150px",
                                textAlign: "center",
                              }}
                            >
                              <Option value="Fixed">
                                {lang.get("sales", ["Fixed"])}
                              </Option>
                              <Option value="%">
                                {lang.get("sales", ["%"])}
                              </Option>
                            </Select>
                          }
                        />
                      </div>
                    </Col>
                  </Row>

                  <Divider style={{ margin: "8px 0" }} />

                  {/*Taxes Input */}

                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {lang.get("sales", ["Taxes"])}
                    </Col>
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      <Select
                        mode="multiple"
                        value={tax}
                        onChange={(values) => setTax(values)}
                        style={{ width: "150px" }}
                        placeholder={lang.get("sales", ["Select Taxes"])}
                      >
                        <Option value="5">
                          {lang.get("sales", ["VAT (5%)"])}
                        </Option>
                        <Option value="10">
                          {lang.get("sales", ["Service Tax (10%)"])}
                        </Option>
                        <Option value="15">
                          {lang.get("sales", ["CGT (15%)"])}
                        </Option>
                      </Select>
                    </Col>
                  </Row>

                  {/* Shipping Total Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {lang.get("sales", ["Shipping"])}
                    </Col>
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {shipping ? `$${shipping.toFixed(2)}` : "-"}
                    </Col>
                  </Row>

                  {/* Adjustment Total Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {lang.get("sales", ["Adjustment"])}
                    </Col>
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {adjustment ? `$${adjustment.toFixed(2)}` : "-"}
                    </Col>
                  </Row>

                  {/* Discount Total Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {lang.get("sales", ["Discount"])}
                    </Col>
                    <Col
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: "#ff4d4f",
                      }}
                    >
                      -$
                      {discountType === "%"
                        ? ((subTotal * discount) / 100).toFixed(2)
                        : discount.toFixed(2)}
                    </Col>
                  </Row>

                  {/* Taxes Total Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {lang.get("sales", ["Taxes"])}
                    </Col>
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {tax.length > 0
                        ? `$${tax
                            .reduce((acc, curr) => acc + parseFloat(curr), 0)
                            .toFixed(2)}`
                        : "-"}
                    </Col>
                  </Row>

                  <Divider style={{ margin: "8px 0" }} />

                  {/* Total Row */}
                  <Row justify="space-between" align="middle">
                    <Col style={{ fontWeight: 500, fontSize: "12px" }}>
                      {lang.get("sales", ["Total"])}
                    </Col>
                    <Col style={{ fontWeight: 500, fontSize: "12px" }}>
                      ${calculateTotal().toFixed(2)}
                    </Col>
                  </Row>
                </Space>
              )}
            </ProFormDependency>
          </Row>

          <Divider />

          <Row gutter={[16, 5]}>
            {/* Select Client Note */}
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Select Client Note"])}
                  </span>
                }
                name="selectClientNote"
              >
                <Select
                  placeholder={lang.get("sales", ["Select a Client Note"])}
                  size="large"
                >
                  <Select.Option value="Note1">
                    {lang.get("sales", ["Client Note 1"])}
                  </Select.Option>
                  <Select.Option value="Note2">
                    {lang.get("sales", ["Client Note 2"])}
                  </Select.Option>
                  <Select.Option value="Note3">
                    {lang.get("sales", ["Client Note 3"])}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}></Col>
            {/* TinyMCE Editor for Client Note */}
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Client Note"])}
                  </span>
                }
                name="Note"
              >
                <TinyMCEEditor />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 5]}>
            {/* Select Terms & Conditions */}
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Select Terms & Conditions"])}
                  </span>
                }
                name="selectTerms"
              >
                <Select
                  placeholder={lang.get("sales", ["Select Terms & Conditions"])}
                  size="large"
                >
                  <Select.Option value="Terms1">
                    {lang.get("sales", ["Terms & Conditions 1"])}
                  </Select.Option>
                  <Select.Option value="Terms2">
                    {lang.get("sales", ["Terms & Conditions 2"])}
                  </Select.Option>
                  <Select.Option value="Terms3">
                    {lang.get("sales", ["Terms & Conditions 3"])}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}></Col>
            {/* TinyMCE Editor for Terms & Conditions */}
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Terms & Conditions"])}
                  </span>
                }
                name="Terms"
              >
                <TinyMCEEditor />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row justify="end" gutter={16}>
            <Button
              color="primary"
              variant="outlined"
              icon={<SendOutlined />}
              style={{ marginBottom: "30px", marginTop: "30px" }}
              onClick={() =>
                console.log(
                  lang.get("sales", ["Send the created estimate  For Approval"])
                )
              }
            >
              {lang.get("sales", ["Send the created estimate  For approval"])}
            </Button>
          </Row>
          <Row justify="end" gutter={16}>
            <Col>
              {/* Close Button */}
              <Button
                onClick={() => console.log(lang.get("sales", ["Close"]))}
                style={{
                  backgroundColor: "white",
                  borderColor: "#d9d9d9",
                  color: "#000",
                  marginRight: 8,
                }}
              >
                {lang.get("sales", ["Close"])}
              </Button>

              {/* Save Button */}
              <Button
                type="primary"
                onClick={() =>
                  console.log(lang.get("sales", ["Save as Draft"]))
                }
                style={{
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  color: "#fff",
                  marginRight: 8,
                }}
              >
                {lang.get("sales", ["Save as Draft"])}
              </Button>

              {/* Save & Send Request Button */}
              <Button
                type="primary"
                onClick={() =>
                  console.log(lang.get("sales", ["Save & Send To Customer"]))
                }
                style={{
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  color: "#fff",
                }}
              >
                {lang.get("sales", ["Save & Send To Customer"])}
              </Button>
            </Col>
          </Row>
        </ProForm>
      </ProCard>

      {/* Modals */}
      <Modal
        title={lang.get("sales", ["Edit Bill To"])}
        open={isBillToModalVisible}
        onCancel={() => setBillToModalVisible(false)}
        footer={null}
      >
        <ProForm layout="vertical" submitter={false}>
          <ProFormText
            name="street"
            label={lang.get("sales", ["Street"])}
            placeholder={lang.get("sales", ["Enter street"])}
          />
          <ProFormText
            name="city"
            label={lang.get("sales", ["City"])}
            placeholder={lang.get("sales", ["Enter city"])}
          />
          <ProFormText
            name="state"
            label={lang.get("sales", ["State"])}
            placeholder={lang.get("sales", ["Enter state"])}
          />
          <ProFormText
            name="zipCode"
            label={lang.get("sales", ["Zip Code"])}
            placeholder={lang.get("sales", ["Enter zip code"])}
          />
          <ProFormSelect
            name="country"
            label={lang.get("sales", ["Country"])}
            placeholder={lang.get("sales", ["Select country"])}
            options={[
              { value: "USA", label: lang.get("sales", ["USA"]) },
              { value: "UK", label: lang.get("sales", ["UK"]) },
            ]}
          />
        </ProForm>
      </Modal>

      <Modal
        title={lang.get("sales", ["Edit Ship To"])}
        open={isShipToModalVisible}
        onCancel={() => setShipToModalVisible(false)}
        footer={null}
      >
        <ProForm layout="vertical" submitter={false}>
          <ProFormText
            name="street"
            label={lang.get("sales", ["Street"])}
            placeholder={lang.get("sales", ["Enter street"])}
          />
          <ProFormText
            name="city"
            label={lang.get("sales", ["City"])}
            placeholder={lang.get("sales", ["Enter city"])}
          />
          <ProFormText
            name="state"
            label={lang.get("sales", ["State"])}
            placeholder={lang.get("sales", ["Enter state"])}
          />
          <ProFormText
            name="zipCode"
            label={lang.get("sales", ["Zip Code"])}
            placeholder={lang.get("sales", ["Enter zip code"])}
          />
          <ProFormSelect
            name="country"
            label={lang.get("sales", ["Country"])}
            placeholder={lang.get("sales", ["Select country"])}
            options={[
              { value: "USA", label: lang.get("sales", ["USA"]) },
              { value: "UK", label: lang.get("sales", ["UK"]) },
            ]}
          />
        </ProForm>
      </Modal>
    </div>
  );
};

export default AddEstimate;
