import React, { useState } from "react";
import {
  ProForm,
  ProFormText,
  ProFormSelect,
  ProFormDependency,
  ProCard,
  ProFormCheckbox,
} from "@ant-design/pro-components";
import {
  Button,
  Modal,
  Row,
  Col,
  Divider,
  Typography,
  Space,
  Input,
  Select,
  Form,
  DatePicker,
} from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import AddCreditTable from "./AddCreditTable";
import { Editor } from "@tinymce/tinymce-react";
import lang from "../../../../../lang";

const { Title } = Typography;
const Option = Select;
const AddCredit: React.FC = () => {
  const [isBillToModalVisible, setBillToModalVisible] = useState(false);
  const [isShipToModalVisible, setShipToModalVisible] = useState(false);
  const [discountType, setDiscountType] = useState<string>("%");
  const [discount, setDiscount] = useState<number>(0);
  const [adjustment, setAdjustment] = useState<number>(0);
  const [subTotal] = useState<number>(0);
  const [tax, setTax] = useState<string[]>([]);

  const calculateTotal = () => {
    const discountAmount =
      discountType === "%" ? (subTotal * discount) / 100 : discount;
    return subTotal - discountAmount + adjustment;
  };

  const handleTaxChange = (values: string[]) => {
    console.log("Selected Tax Options: ", values);
    setTax(values);
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <Title level={2}>{lang.get("sales", ["New Credit Note"])}</Title>
      <ProCard>
        <ProForm layout="vertical" submitter={false}>
          <Row gutter={[16, 5]}>
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Customer"])}
                  </span>
                }
                name="customer"
                rules={[
                  {
                    required: true,
                    message: lang.get("validation", ["Customer is required!"]),
                  },
                ]}
              >
                <Select
                  placeholder={lang.get("sales", ["Select and begin typing"])}
                  size="large"
                >
                  <Select.Option value="Customer 1">
                    {lang.get("sales", ["Customer 1"])}
                  </Select.Option>
                  <Select.Option value="Customer 2">
                    {lang.get("sales", ["Customer 2"])}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Currency"])}
                  </span>
                }
                name="currency"
                rules={[
                  {
                    required: true,
                    message: lang.get("validation", ["Currency is required!"]),
                  },
                ]}
              >
                <Select
                  placeholder={lang.get("sales", ["Select Currency"])}
                  size="large"
                >
                  <Select.Option value="LKR">
                    {lang.get("currency", ["LKR LKR"])}
                  </Select.Option>
                  <Select.Option value="USD">
                    {lang.get("currency", ["USD"])}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Bill To"])}
                  </span>
                }
                name="billTo"
              >
                <Input
                  placeholder="--"
                  size="large"
                  suffix={
                    <EditOutlined onClick={() => setBillToModalVisible(true)} />
                  }
                  readOnly
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Ship To"])}
                  </span>
                }
                name="shipTo"
              >
                <Input
                  placeholder="--"
                  size="large"
                  suffix={
                    <EditOutlined onClick={() => setShipToModalVisible(true)} />
                  }
                  readOnly
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Credit Note Date"])}
                  </span>
                }
                name="creditNoteDate"
                rules={[
                  {
                    required: true,
                    message: lang.get("validation", ["Please select a date!"]),
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} size="large" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Credit Note #"])}
                  </span>
                }
                name="creditNoteNumber"
                rules={[
                  {
                    required: true,
                    message: lang.get("validation", [
                      "Credit Note Number is required!",
                    ]),
                  },
                ]}
              >
                <Input addonBefore="CN-" placeholder="000002" size="large" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Reference #"])}
                  </span>
                }
                name="reference"
              >
                <Input
                  placeholder={lang.get("sales", ["Enter Reference Number"])}
                  size="large"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Discount Type"])}
                  </span>
                }
                name="discountType"
              >
                <Select
                  placeholder={lang.get("sales", ["Select Discount Type"])}
                  size="large"
                >
                  <Select.Option value="No discount">
                    {lang.get("sales", ["No discount"])}
                  </Select.Option>
                  <Select.Option value="BeforeTax">
                    {lang.get("sales", ["Before Tax"])}
                  </Select.Option>
                  <Select.Option value="AfterTax">
                    {lang.get("sales", ["After Tax"])}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Admin Note"])}
                  </span>
                }
                name="adminNote"
              >
                <Input.TextArea
                  placeholder={lang.get("sales", ["Enter admin notes"])}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <AddCreditTable />
          <Divider />

          <Row justify="end" gutter={16}>
            <ProFormDependency name={["total", "adjustment"]}>
              {() => (
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                    backgroundColor: "#f9f9fb",
                    padding: "16px",
                    borderRadius: "8px",
                  }}
                >
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      <span>{lang.get("sales", ["Subtotal"])}</span>
                    </Col>
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      ${subTotal.toFixed(2)}
                    </Col>
                  </Row>
                  <Divider style={{ margin: "8px 0" }} />

                  <Row justify="space-between" style={{ marginBottom: "4px" }}>
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      <span>{lang.get("sales", ["Discount"])}</span>
                      <Input
                        value={discount}
                        onChange={(e) => setDiscount(Number(e.target.value))}
                        type="number"
                        size="large"
                        style={{ width: "150px", marginLeft: "10px" }}
                        addonAfter={
                          <Select
                            value={discountType}
                            onChange={(value) => setDiscountType(value)}
                            style={{ width: "70px" }}
                            dropdownStyle={{ textAlign: "center" }}
                          >
                            <Option value="%">%</Option>
                            <Option value="Fixed">
                              <span>{lang.get("sales", ["Fixed"])}</span>
                            </Option>
                          </Select>
                        }
                      />
                    </Col>

                    <Col
                      style={{
                        fontWeight: 600,
                        fontSize: "14px",
                        color: "#ff4d4f",
                      }}
                    >
                      -$
                      {discountType === "%"
                        ? ((subTotal * discount) / 100).toFixed(2)
                        : discount.toFixed(2)}
                    </Col>
                  </Row>
                  <Divider style={{ margin: "8px 0" }} />
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      <span>{lang.get("sales", ["Tax"])}</span>

                      <Select
                        mode="multiple"
                        value={tax}
                        onChange={handleTaxChange}
                        size="large"
                        style={{ width: "150px", marginLeft: "10px" }}
                        placeholder="Select Tax"
                      >
                        <Option value="VAT">
                          {lang.get("sales", ["VAT (5%)"])}
                        </Option>
                        <Option value="CGT">
                          {lang.get("sales", ["CGT (10%)"])}
                        </Option>
                        <Option value="Service Tax">
                          {lang.get("sales", ["Service Tax (8%)"])}
                        </Option>
                      </Select>
                    </Col>
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      ${adjustment.toFixed(2)}
                    </Col>
                  </Row>
                  <Divider style={{ margin: "8px 0" }} />
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      <span>{lang.get("sales", ["Adjustment"])}</span>
                      <Input
                        value={adjustment}
                        onChange={(e) => setAdjustment(Number(e.target.value))}
                        type="number"
                        size="large"
                        style={{ width: "100px", marginLeft: "10px" }}
                      />
                    </Col>
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      ${adjustment.toFixed(2)}
                    </Col>
                  </Row>
                  <Divider style={{ margin: "8px 0" }} />

                  <Row justify="space-between" align="middle">
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      Total :
                    </Col>
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      ${calculateTotal().toFixed(2)}
                    </Col>
                  </Row>
                </Space>
              )}
            </ProFormDependency>
          </Row>

          <Divider />
          <Row gutter={[16, 5]}>
            <Col span={24}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Client Note"])}
                  </span>
                }
                name="clientNote"
              >
                <Editor
                  apiKey="your-tinymce-api-key"
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: ["lists link code"],
                    toolbar:
                      "undo redo | formatselect | bold italic underline | bullist numlist | link code",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Terms & Conditions */}
          <Row gutter={[16, 5]}>
            <Col span={24}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Terms & Conditions"])}
                  </span>
                }
                name="termsConditions"
              >
                <Editor
                  apiKey="your-tinymce-api-key"
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: ["lists link code"],
                    toolbar:
                      "undo redo | formatselect | bold italic underline | bullist numlist | link code",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />

          <Row justify="end" gutter={16}>
            <ProFormCheckbox name="sendForApproval">
              {lang.get("sales", ["Send the created invoice for approval"])}
            </ProFormCheckbox>
          </Row>
          <Row justify="end" gutter={16}>
            <Col>
              <Button onClick={() => console.log("Saved as Draft")}>
                {lang.get("sales", ["Save as Draft"])}
              </Button>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => console.log("Saved Final Invoice")}
                style={{ marginLeft: 8 }}
              >
                {lang.get("sales", ["Save"])}
              </Button>
            </Col>
          </Row>
        </ProForm>
      </ProCard>

      {/* Modals */}
      <Modal
        title={lang.get("sales", ["Edit Bill To"])}
        open={isBillToModalVisible}
        onCancel={() => setBillToModalVisible(false)}
        footer={null}
      >
        <ProForm layout="vertical" submitter={false}>
          <ProFormText
            name="street"
            label={lang.get("sales", ["Street"])}
            placeholder={lang.get("sales", ["Enter street"])}
          />
          <ProFormText
            name="city"
            label={lang.get("sales", ["City"])}
            placeholder={lang.get("sales", ["Enter city"])}
          />
          <ProFormText
            name="state"
            label={lang.get("sales", ["State"])}
            placeholder={lang.get("sales", ["Enter state"])}
          />
          <ProFormText
            name="zipCode"
            label={lang.get("sales", ["Zip Code"])}
            placeholder={lang.get("sales", ["Enter zip code"])}
          />
          <ProFormSelect
            name="country"
            label={lang.get("sales", ["Country"])}
            placeholder={lang.get("sales", ["Select country"])}
            options={[
              { value: "USA", label: lang.get("sales", ["USA"]) },
              { value: "UK", label: lang.get("sales", ["UK"]) },
            ]}
          />
        </ProForm>
      </Modal>

      <Modal
        title={lang.get("sales", ["Edit Ship To"])}
        open={isShipToModalVisible}
        onCancel={() => setShipToModalVisible(false)}
        footer={null}
      >
        <ProForm layout="vertical" submitter={false}>
          <ProFormText
            name="street"
            label={lang.get("sales", ["Street"])}
            placeholder={lang.get("sales", ["Enter street"])}
          />
          <ProFormText
            name="city"
            label={lang.get("sales", ["City"])}
            placeholder={lang.get("sales", ["Enter city"])}
          />
          <ProFormText
            name="state"
            label={lang.get("sales", ["State"])}
            placeholder={lang.get("sales", ["Enter state"])}
          />
          <ProFormText
            name="zipCode"
            label={lang.get("sales", ["Zip Code"])}
            placeholder={lang.get("sales", ["Enter zip code"])}
          />
          <ProFormSelect
            name="country"
            label={lang.get("sales", ["Country"])}
            placeholder={lang.get("sales", ["Select country"])}
            options={[
              { value: "USA", label: lang.get("sales", ["USA"]) },
              { value: "UK", label: lang.get("sales", ["UK"]) },
            ]}
          />
        </ProForm>
      </Modal>
    </div>
  );
};

export default AddCredit;
