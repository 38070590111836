import { useEffect } from "react";
import { Button, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DiscussionTable from "./DiscussionTable";
import lang from "../../../../../../lang";

const Discussion = () => {
  useEffect(() => {}, []);

  return (
    <div style={{ padding: "16px", background: "#f5f7fa" }}>
      {/* Header Section */}
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "16px" }}
      >
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            style={{ marginRight: "8px" }}
          >
            {lang.get("discussion", ["Create Discussion"])}
          </Button>
        </Col>
      </Row>

      {/* Discussion Table */}
      <DiscussionTable />
    </div>
  );
};

export default Discussion;
