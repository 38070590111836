import React, { useState, useEffect } from "react";
import { Modal } from "antd";

interface BeforeInstallPromptEvent extends Event {
  prompt: () => void;
  userChoice: Promise<{ outcome: "accepted" | "dismissed" }>;
}
interface Navigator {
  standalone?: boolean;
}

const isPWAInstalled = (): boolean => {
  const isStandalone = (window.navigator as Navigator).standalone === true;

  const isDisplayModeStandalone = window.matchMedia(
    "(display-mode: standalone)"
  ).matches;

  return (
    isStandalone ||
    isDisplayModeStandalone ||
    localStorage.getItem("isPWAInstalled") === "1" ||
    localStorage.getItem("isPWACancelInstall") === "1"
  );
};

const isMobileDevice = () => {
  return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|webOS/i.test(
    navigator.userAgent
  );
};

const isiOS = (): boolean => {
  return (
    ["iPad", "iPhone", "iPod"].includes(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

const usePWAInstall = () => {
  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);
  const [showInstallButton, setShowInstallButton] = useState<boolean>(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: Event) => {
      event.preventDefault();
      setDeferredPrompt(event as BeforeInstallPromptEvent);

      if (!isPWAInstalled()) {
        if (isiOS()) {
          alert(
            'To install this app, tap the share button and select "Add to Home Screen."'
          );
        } else {
          if (isMobileDevice()) {
            setShowInstallButton(true);
          }
        }
      }
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  return {
    deferredPrompt,
    showInstallButton,
    setShowInstallButton,
    setDeferredPrompt,
  };
};

const PWAInit: React.FC = () => {
  const {
    deferredPrompt,
    showInstallButton,
    setShowInstallButton,
    setDeferredPrompt,
  } = usePWAInstall();

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          localStorage.removeItem("isPWACancelInstall");
          localStorage.setItem("isPWAInstalled", "1");
        } else {
          localStorage.removeItem("isPWAInstalled");
          localStorage.setItem("isPWACancelInstall", "1");
        }
        setDeferredPrompt(null);
        setShowInstallButton(false);
      });
    }
  };

  const handleOk = () => {
    handleInstallClick();
  };

  return (
    <div>
      {showInstallButton && (
        <Modal
          title="Cross Platform Experience"
          centered
          open={showInstallButton}
          okText="Install"
          okType="primary"
          onOk={handleOk}
          onClose={() => {}}
          onCancel={() => {
            setShowInstallButton(false);
            localStorage.setItem("isPWACancelInstall", "1");
            localStorage.removeItem("isPWAInstalled");
          }}
        >
          Install this app for quick access and a better experience!
        </Modal>
      )}
    </div>
  );
};

export default PWAInit;
