import React, { useState } from "react";
import {
  ProCard,
  ProForm,
  ProFormDependency,
} from "@ant-design/pro-components";
import {
  Row,
  Col,
  Typography,
  Divider,
  Button,
  Input,
  Select,
  DatePicker,
  Form,
  Space,
} from "antd";
import lang from "../../../../lang";
import PurchaseOrderAddTable from "./PurchaseOrderAddTable";
import TinyMCEEditor from "../../../../common/base/Editor";

const { Title } = Typography;
const { Option } = Select;

const PurchaseOrderAdd: React.FC = () => {
  const [discountType, setDiscountType] = useState<string>("%");
  const [discount, setDiscount] = useState<number>(0);
  const [adjustment] = useState<number>(0);
  const [subTotal] = useState<number>(0);
  const [tax, setTax] = useState<string[]>([]);
  const [shipping, setShipping] = useState<number>(0);

  const calculateTotal = () => {
    const discountAmount =
      discountType === "%" ? (subTotal * discount) / 100 : discount;
    return subTotal - discountAmount + adjustment;
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <Title level={2}>{lang.get("purchase", ["Add Purchase Order"])}</Title>

      <ProCard>
        <ProForm layout="vertical" submitter={false}>
          {/* First Row */}
          <Row gutter={[16, 5]}>
            {/* Purchase Order Description */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Purchase Order Description"])}
                  </span>
                }
                name="purchaseOrderDescription"
                rules={[
                  {
                    required: true,
                    message: lang.get("purchase", ["Required"]),
                  },
                ]}
              >
                <Input
                  placeholder={lang.get("purchase", ["Enter Description"])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Purchase Order Number */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Purchase Order Number"])}
                  </span>
                }
                name="purchaseOrderNumber"
              >
                <Input
                  size="large"
                  value="#PO-00005-Jan-2025"
                  placeholder="#PO-00005-Jan-2025"
                  disabled
                />
              </Form.Item>
            </Col>

            {/* Currency */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Currency"])}
                  </span>
                }
                name="currency"
              >
                <Input value="LKR" placeholder="LKR" disabled size="large" />
              </Form.Item>
            </Col>

            {/* Vendors */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Vendors"])}
                  </span>
                }
                name="vendors"
                rules={[
                  {
                    required: true,
                    message: lang.get("purchase", ["Required"]),
                  },
                ]}
              >
                <Select
                  placeholder={lang.get("purchase", ["Select Vendor"])}
                  size="large"
                >
                  <Option value="Vendor1">
                    {lang.get("purchase", ["Vendor 1"])}
                  </Option>
                  <Option value="Vendor2">
                    {lang.get("purchase", ["Vendor 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Purchase Request */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Purchase Request"])}
                  </span>
                }
                name="purchaseRequest"
              >
                <Select
                  placeholder={lang.get("purchase", ["Select Request"])}
                  size="large"
                >
                  <Option value="Request1">
                    {lang.get("purchase", ["Request 1"])}
                  </Option>
                  <Option value="Request2">
                    {lang.get("purchase", ["Request 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Order Date */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Order Date"])}
                  </span>
                }
                name="orderDate"
              >
                <DatePicker style={{ width: "100%" }} size="large" />
              </Form.Item>
            </Col>

            {/* Department */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Department"])}
                  </span>
                }
                name="department"
              >
                <Select
                  placeholder={lang.get("purchase", ["Select Department"])}
                  size="large"
                >
                  <Option value="Dept1">
                    {lang.get("purchase", ["Department 1"])}
                  </Option>
                  <Option value="Dept2">
                    {lang.get("purchase", ["Department 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Project */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Project"])}
                  </span>
                }
                name="project"
              >
                <Select
                  placeholder={lang.get("purchase", ["Select Project"])}
                  size="large"
                >
                  <Option value="Project1">
                    {lang.get("purchase", ["Project 1"])}
                  </Option>
                  <Option value="Project2">
                    {lang.get("purchase", ["Project 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Type */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Type"])}
                  </span>
                }
                name="type"
              >
                <Select
                  placeholder={lang.get("purchase", ["Select Type"])}
                  size="large"
                >
                  <Option value="Type1">
                    {lang.get("purchase", ["Type 1"])}
                  </Option>
                  <Option value="Type2">
                    {lang.get("purchase", ["Type 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Number of Days Owed */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Number of Days Owed"])}
                  </span>
                }
                name="daysOwed"
              >
                <Input
                  placeholder={lang.get("purchase", [
                    "Enter Number of Days Owed",
                  ])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Delivery Date */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Delivery Date"])}
                  </span>
                }
                name="deliveryDate"
              >
                <DatePicker style={{ width: "100%" }} size="large" />
              </Form.Item>
            </Col>

            {/* Customers */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Customers"])}
                  </span>
                }
                name="customers"
              >
                <Select
                  placeholder={lang.get("purchase", ["Select Customer"])}
                  size="large"
                >
                  <Option value="Customer1">
                    {lang.get("purchase", ["Customer 1"])}
                  </Option>
                  <Option value="Customer2">
                    {lang.get("purchase", ["Customer 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Person in Charge"])}
                  </span>
                }
                name="personInCharge"
              >
                <Select
                  placeholder={lang.get("purchase", [
                    "Select Person in Charge",
                  ])}
                  size="large"
                >
                  <Option value="Person1">
                    {lang.get("purchase", ["Person 1"])}
                  </Option>
                  <Option value="Person2">
                    {lang.get("purchase", ["Person 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Tags"])}
                  </span>
                }
                name="tags"
              >
                <Select
                  mode="multiple"
                  placeholder={lang.get("purchase", ["Select Tags"])}
                  size="large"
                >
                  <Option value="Tag1">
                    {lang.get("purchase", ["Tag 1"])}
                  </Option>
                  <Option value="Tag2">
                    {lang.get("purchase", ["Tag 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("purchase", ["Sale Invoices"])}
                  </span>
                }
                name="saleInvoices"
              >
                <Select
                  placeholder={lang.get("purchase", ["Select Sale Invoices"])}
                  size="large"
                >
                  <Option value="Invoice1">
                    {lang.get("purchase", ["Invoice 1"])}
                  </Option>
                  <Option value="Invoice2">
                    {lang.get("purchase", ["Invoice 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <PurchaseOrderAddTable />
          <Divider />

          {/* Summary Section */}
          <Row justify="end" gutter={16} style={{ borderRadius: "8px" }}>
            <ProFormDependency name={["total", "adjustment"]}>
              {() => (
                <Space
                  direction="vertical"
                  style={{
                    width: "30%",
                    padding: "16px",
                  }}
                >
                  {/* Shipping Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    {/* Shipping Input */}
                    <Col style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "12px",
                          marginRight: "8px",
                        }}
                      >
                        {lang.get("sales", ["Shipping ($) :"])}
                      </div>
                      <Input
                        type="number"
                        value={shipping}
                        onChange={(e) => setShipping(Number(e.target.value))}
                        size="small"
                        style={{ width: "100px", textAlign: "center" }}
                      />
                    </Col>

                    {/* Discount Input */}
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "16px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            fontWeight: 400,
                            fontSize: "12px",
                            marginRight: "8px",
                          }}
                        >
                          {lang.get("sales", ["Discount ($) :"])}
                        </div>
                        <Input
                          type="number"
                          value={discount}
                          onChange={(e) => setDiscount(Number(e.target.value))}
                          size="small"
                          style={{ width: "100px", textAlign: "left" }}
                          addonAfter={
                            <Select
                              value={discountType}
                              onChange={(value) => setDiscountType(value)}
                              style={{ width: "50px" }}
                              dropdownStyle={{
                                width: "150px",
                                textAlign: "center",
                              }}
                            >
                              <Option value="Fixed">
                                {lang.get("sales", ["Fixed"])}
                              </Option>
                              <Option value="%">
                                {lang.get("sales", ["%"])}
                              </Option>
                            </Select>
                          }
                        />
                      </div>
                    </Col>
                  </Row>

                  <Divider style={{ margin: "8px 0" }} />

                  {/*Taxes Input */}

                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {lang.get("sales", ["Taxes"])}
                    </Col>
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      <Select
                        mode="multiple"
                        value={tax}
                        onChange={(values) => setTax(values)}
                        style={{ width: "150px" }}
                        placeholder={lang.get("sales", ["Select Taxes"])}
                      >
                        <Option value="5">
                          {lang.get("sales", ["VAT (5%)"])}
                        </Option>
                        <Option value="10">
                          {lang.get("sales", ["Service Tax (10%)"])}
                        </Option>
                        <Option value="15">
                          {lang.get("sales", ["CGT (15%)"])}
                        </Option>
                      </Select>
                    </Col>
                  </Row>

                  {/* Shipping Total Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {lang.get("sales", ["Shipping"])}
                    </Col>
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {shipping ? `$${shipping.toFixed(2)}` : "-"}
                    </Col>
                  </Row>

                  {/* Adjustment Total Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {lang.get("sales", ["Adjustment"])}
                    </Col>
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {adjustment ? `$${adjustment.toFixed(2)}` : "-"}
                    </Col>
                  </Row>

                  {/* Discount Total Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {lang.get("sales", ["Discount"])}
                    </Col>
                    <Col
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: "#ff4d4f",
                      }}
                    >
                      -$
                      {discountType === "%"
                        ? ((subTotal * discount) / 100).toFixed(2)
                        : discount.toFixed(2)}
                    </Col>
                  </Row>

                  {/* Taxes Total Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {lang.get("sales", ["Taxes"])}
                    </Col>
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {tax.length > 0
                        ? `$${tax
                            .reduce((acc, curr) => acc + parseFloat(curr), 0)
                            .toFixed(2)}`
                        : "-"}
                    </Col>
                  </Row>

                  <Divider style={{ margin: "8px 0" }} />

                  {/* Total Row */}
                  <Row justify="space-between" align="middle">
                    <Col style={{ fontWeight: 500, fontSize: "12px" }}>
                      {lang.get("sales", ["Total"])}
                    </Col>
                    <Col style={{ fontWeight: 500, fontSize: "12px" }}>
                      ${calculateTotal().toFixed(2)}
                    </Col>
                  </Row>
                </Space>
              )}
            </ProFormDependency>
          </Row>

          <Row gutter={[16, 5]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Client Note"])}
                  </span>
                }
                name="Note"
              >
                <TinyMCEEditor />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          {/* Submit Section */}

          <Row justify="end" gutter={16}>
            <Col>
              {/* Close Button */}
              <Button
                onClick={() => console.log(lang.get("sales", ["Close"]))}
                style={{
                  backgroundColor: "white",
                  borderColor: "#d9d9d9",
                  color: "#000",
                  marginRight: 8,
                }}
              >
                {lang.get("sales", ["Close"])}
              </Button>

              {/* Save Button */}
              <Button
                type="primary"
                onClick={() => console.log(lang.get("sales", ["Save"]))}
                style={{
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  color: "#fff",
                  marginRight: 8,
                }}
              >
                {lang.get("sales", ["Save"])}
              </Button>
            </Col>
          </Row>
        </ProForm>
      </ProCard>
    </div>
  );
};

export default PurchaseOrderAdd;
