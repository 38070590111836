import { SearchOutlined } from "@ant-design/icons";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import { Button, Input, Space, InputRef, Tag, DatePicker } from "antd";
import { useRef, useState } from "react";
import Export from "../../../../../../../common/components/Export";
import lang from "../../../../../../../lang";

const { RangePicker } = DatePicker;

interface Request {
  purchaseRequestCode: string;
  purchaseRequestName: string;
  requester: string;
  department: string;
  requestDate: string;
  status: string;
  poNumber: string;
}

const Requests: Request[] = [
  {
    purchaseRequestCode: "REQ001",
    purchaseRequestName: "Office Supplies",
    requester: "John Doe",
    department: "Administration",
    requestDate: "2023-10-01",
    status: "Pending",
    poNumber: "PO1234",
  },
  {
    purchaseRequestCode: "REQ002",
    purchaseRequestName: "IT Equipment",
    requester: "Jane Smith",
    department: "IT",
    requestDate: "2023-10-05",
    status: "Approved",
    poNumber: "PO5678",
  },
];

const RequestTable = () => {
  const actionRef = useRef<ActionType>();
  const [pageSize, setPageSize] = useState<number>(10);
  const searchInput = useRef<InputRef>(null);
  const [searchAttributes, setSearchAttributes] = useState<{
    key: string;
    value: string;
  }[]>([]);
  const [allSearch, setAllSearch] = useState<string>("");

  const expobj = new Export("requests, " + new Date().toDateString());

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("requests", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("requests", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("requests", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<Request>[] = [
    {
      title: lang.get("requests", ["Purchase Request Code"]),
      dataIndex: "purchaseRequestCode",
      sorter: true,
      ...searchCols("purchaseRequestCode", lang.get("requests", ["Purchase Request Code"]))
    },
    {
      title: lang.get("requests", ["Purchase Request Name"]),
      dataIndex: "purchaseRequestName",
      sorter: true,
      ...searchCols("purchaseRequestName", lang.get("requests", ["Purchase Request Name"]))
    },
    {
      title: lang.get("requests", ["Requester"]),
      dataIndex: "requester",
      sorter: true,
      ...searchCols("requester", lang.get("requests", ["Requester"]))
    },
    {
      title: lang.get("requests", ["Department"]),
      dataIndex: "department",
      sorter: true,
      ...searchCols("department", lang.get("requests", ["Department"]))
    },
    {
      title: lang.get("requests", ["Request Date"]),
      dataIndex: "requestDate",
      sorter: true,
      ...searchCols("requestDate", lang.get("requests", ["Request Date"]))
    },
    {
      title: lang.get("requests", ["Status"]),
      dataIndex: "status",
      sorter: true,
      render: (_, record) => (
        <Tag color={record.status === "Approved" ? "green" : "orange"}>
          {lang.get("requests", [record.status])}
        </Tag>
      ),
    },
    {
      title: lang.get("requests", ["PO No."]),
      dataIndex: "poNumber",
      sorter: true,
      ...searchCols("poNumber", lang.get("requests", ["PO No."]))
    },
    {
      title: lang.get("requests", ["Options"]),
      key: "options",
      render: () => (
        <Space>
          <Button type="link">{lang.get("requests", ["View"])} </Button>
        </Space>
      ),
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("requests", ["Purchase Requests"])}
      columns={columns}
      actionRef={actionRef}
      dataSource={Requests}
      rowKey="purchaseRequestCode"
      cardBordered
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        showTotal: (total, range) =>
          lang.get("requests", [
            `Showing ${range[0]}-${range[1]} of ${total} items`,
          ]),
        onShowSizeChange: (current, size) => {
          setPageSize(size);
        },
      }}
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <RangePicker key="date" />,
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("requests", ["Global Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef?.current?.reload();
          }}
        />, // Add export buttons here
        <Button
          key="csv"
          onClick={() => {
            const csvData = Requests.map((row) => ({
              [lang.get("requests", ["Purchase Request Code"])]: row.purchaseRequestCode,
              [lang.get("requests", ["Purchase Request Name"])]: row.purchaseRequestName,
              [lang.get("requests", ["Requester"])]: row.requester,
              [lang.get("requests", ["Department"])]: row.department,
              [lang.get("requests", ["Request Date"])]: row.requestDate,
              [lang.get("requests", ["Status"])]: row.status,
              [lang.get("requests", ["PO No."])]: row.poNumber,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("requests", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = Requests.map((row) => ({
              [lang.get("requests", ["Purchase Request Code"])]: row.purchaseRequestCode,
              [lang.get("requests", ["Purchase Request Name"])]: row.purchaseRequestName,
              [lang.get("requests", ["Requester"])]: row.requester,
              [lang.get("requests", ["Department"])]: row.department,
              [lang.get("requests", ["Request Date"])]: row.requestDate,
              [lang.get("requests", ["Status"])]: row.status,
              [lang.get("requests", ["PO No."])]: row.poNumber,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("requests", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("requests", ["Purchase Request Code"]),
                  lang.get("requests", ["Purchase Request Name"]),
                  lang.get("requests", ["Requester"]),
                  lang.get("requests", ["Department"]),
                  lang.get("requests", ["Request Date"]),
                  lang.get("requests", ["Status"]),
                  lang.get("requests", ["PO No."]),
                ],
              ],
              body: Requests.map((row) => [
                row.purchaseRequestCode,
                row.purchaseRequestName,
                row.requester,
                row.department,
                row.requestDate,
                row.status,
                row.poNumber,
              ]),
            });
          }}
        >
          {lang.get("requests", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default RequestTable;
