import React, { useState, useRef } from "react";
import { Input, Button, Space, DatePicker, Tag, InputRef } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import lang from "../../../lang";
import Export from "../../../common/components/Export";

const { RangePicker } = DatePicker;

interface ReceivingVoucher {
  number: string;
  date: string;
  supplier: string;
  purchaseOrder: string;
  voucherDate: string;
  warehouse: string;
  totalTax: number;
  totalAmount: number;
  status: string;
  createdUser: string;
}

const ReceivingVoucherTable: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(10);
  const searchInput = useRef<InputRef>(null);
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const actionRef = useRef<ActionType>();
  const [allSearch, setAllSearch] = useState<string>("");
  const expobj = new Export(
    lang.get("inventory", ["Receiving Voucher"]) +
      ", " +
      new Date().toDateString()
  );

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("inventory", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("inventory", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("inventory", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<ReceivingVoucher>[] = [
    {
      title: lang.get("inventory", ["Inventory Receiving Number"]),
      dataIndex: "number",
      key: "code",
      sorter: true,
      render: (text) => <strong>{text}</strong>,

      ...searchCols("inventory", "Inventory Receiving Number"),
    },
    {
      title: lang.get("inventory", ["Date"]),
      dataIndex: "date",
      key: "date",
      sorter: true,
      ...searchCols("inventory", "Date"),
    },
    {
      title: lang.get("inventory", ["Supplier Name"]),
      dataIndex: "supplier",
      key: "supplier",
      sorter: true,
      ...searchCols("inventory", "Supplier Name"),
    },
    {
      title: lang.get("inventory", ["Purchase Order"]),
      dataIndex: "purchaseOrder",
      key: "purchaseOrder",
      render: (text) => text,
      ...searchCols("inventory", "Purchase Order"),
    },
    {
      title: lang.get("inventory", ["Voucher Date"]),
      dataIndex: "voucherDate",
      key: "voucherDate",
      sorter: true,
      width: 150,
      ...searchCols("inventory", "Voucher Date"),
    },
    {
      title: lang.get("inventory", ["Warehouse Name"]),
      dataIndex: "warehouse",
      key: "warehouse",
      sorter: true,
      ...searchCols("inventory", "Warehouse Name"),
    },
    {
      title: lang.get("inventory", ["Total Tax Amount"]),
      dataIndex: "totalTax",
      key: "totalTax",
      sorter: true,
      align: "center",
      ...searchCols("inventory", "Total Tax Amount"),
    },
    {
      title: lang.get("inventory", ["Total Amount"]),
      dataIndex: "totalAmount",
      key: "totalAmount",
      sorter: true,
      align: "center",
      ...searchCols("inventory", "Total Amount"),
    },
    {
      title: lang.get("inventory", ["Status"]),
      dataIndex: "status",
      key: "status",
      sorter: true,
      ...searchCols("inventory", "Status"),
      render: (status) => (
        <Tag
          color={
            status === "Approved"
              ? "green"
              : status === "Not yet approved"
              ? "blue"
              : "red"
          }
        >
          {status}
        </Tag>
      ),
    },
    {
      title: lang.get("inventory", ["Created User"]),
      dataIndex: "createdUser",
      key: "createdUser",
      align: "left",
      render: (text) => text,
      sorter: true,
      ...searchCols("inventory", "Created User"),
    },
  ];

  const data: ReceivingVoucher[] = [
    {
      number: "NK13",
      date: "2023-07-10",
      supplier: "Micro zone",
      purchaseOrder: "#PO-00004-j7",
      voucherDate: "2023-07-10",
      warehouse: "Main Warehouse",
      totalTax: 0.0,
      totalAmount: 850000.0,
      status: "Not Approved",
      createdUser: "ABC",
    },
    {
      number: "NK12",
      date: "2023-06-21",
      supplier: "Micro zone",
      purchaseOrder: "#PO-00004-j7",
      voucherDate: "2023-06-21",
      warehouse: "Main Warehouse",
      totalTax: 16900.0,
      totalAmount: 81900.0,
      status: "Approved",
      createdUser: "ABC",
    },
  ];

  return (
    <ProTable
      columns={columns}
      actionRef={actionRef}
      dataSource={data}
      rowKey="code"
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        onShowSizeChange: (_, size) => setPageSize(size),
      }}
      search={false}
      scroll={{ x: "max-content" }}
      toolBarRender={() => [
        <Space size="middle" wrap key="toolbar">
          <RangePicker key="date" />
          <Input
            style={{ width: "auto" }}
            key="all_search"
            allowClear
            placeholder={lang.get("inventory", ["Global Search"])}
            value={allSearch}
            onChange={(e) => {
              setAllSearch(e.target.value);
              setSearchAttributes([]);
              actionRef?.current?.reload();
            }}
          />
          ,
        </Space>,
        <Button
          key="csv"
          onClick={() => {
            const csvData = data.map((row) => ({
              [lang.get("inventory", ["Inventory Receiving Number"])]: row.number,
              [lang.get("inventory", ["Date"])]: row.date,
              [lang.get("inventory", ["Supplier Name"])]: row.supplier,
              [lang.get("inventory", ["Purchase Order"])]: row.purchaseOrder,
              [lang.get("inventory", ["Voucher Date"])]: row.voucherDate,
              [lang.get("inventory", ["Warehouse Name"])]: row.warehouse,
              [lang.get("inventory", ["Total Tax Amount"])]: row.totalTax,
              [lang.get("inventory", ["Total Amount"])]: row.totalAmount,
              [lang.get("inventory", ["Status"])]: row.status,
              [lang.get("inventory", ["Created User"])]: row.createdUser,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("receivingVoucher", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = data.map((row) => ({
              [lang.get("inventory", ["Inventory Receiving Number"])]: row.number,
              [lang.get("inventory", ["Date"])]: row.date,
              [lang.get("inventory", ["Supplier Name"])]: row.supplier,
              [lang.get("inventory", ["Purchase Order"])]: row.purchaseOrder,
              [lang.get("inventory", ["Voucher Date"])]: row.voucherDate,
              [lang.get("inventory", ["Warehouse Name"])]: row.warehouse,
              [lang.get("inventory", ["Total Tax Amount"])]: row.totalTax,
              [lang.get("inventory", ["Total Amount"])]: row.totalAmount,
              [lang.get("inventory", ["Status"])]: row.status,
              [lang.get("inventory", ["Created User"])]: row.createdUser,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("inventory", ["Export Excel"])}
        </Button>,
      ]}
    />
  );
};

export default ReceivingVoucherTable;
