import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, Col, DatePicker, Row } from "antd";
import { ApexOptions } from "apexcharts";
import { Dayjs } from "dayjs";

const SalesChart: React.FC = () => {
  const [filteredData, setFilteredData] = useState({
    sales: [120, 150, 200, 180, 220, 170, 190, 210, 230, 250, 270, 300],
    returns: [30, 20, 50, 40, 30, 20, 10, 40, 50, 60, 70, 80],
    categories: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  });
  const handleYearChange = (date: Dayjs | null) => {
    if (date) {
      const selectedYear = date.format("YYYY");
      console.log("Selected Year:", selectedYear);
    } else {
      setFilteredData({
        sales: [120, 150, 200, 180, 220, 170, 190, 210, 230, 250, 270, 300],
        returns: [30, 20, 50, 40, 30, 20, 10, 40, 50, 60, 70, 80],
        categories: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
      });
    }
  };

  const options: ApexOptions = {
    chart: {
      type: "bar",
      stacked: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: filteredData.categories,
      title: {
        text: "Months",
        style: {
          fontWeight: "bold",
        },
      },
    },
    yaxis: {
      title: {
        text: "Quantity",
        style: {
          fontWeight: "bold",
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: "80%",
      },
    },
    colors: ["#1677ff", "#f5222d"],
  };

  const series = [
    {
      name: "Sales",
      data: filteredData.sales,
    },
    {
      name: "Return",
      data: filteredData.returns,
    },
  ];

  return (
    <Card
      title="Sales and Returns"
      bordered={false}
      style={{ width: "100%", height: "600px", overflowX: "scroll" }}
    >
      <Row align="middle" justify="start" style={{ marginBottom: "20px" }}>
        <Col>
          <DatePicker
            onChange={handleYearChange}
            picker="year"
            style={{ width: 150 }}
            placeholder="Select Year"
            size="small"
          />
        </Col>
      </Row>
      <div style={{ minWidth: "800px" }}>
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={450}
        />
      </div>
    </Card>
  );
};

export default SalesChart;
