import React from "react";
import { Button, Space, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ContractTable from "./ContractTable";
import lang from "../../../../../../../lang";

const ContractTab: React.FC = () => {
  return (
    <div style={{ borderRadius: "8px" }}>
      <Row
        gutter={[16, 16]}
        style={{ marginBottom: "16px", marginTop: "16px" }}
        align="middle"
        justify="space-between"
      >
        <Col xs={24} sm={24} md={12} lg={12}>
          <Space size="middle" wrap>
            <Button type="primary" icon={<PlusOutlined />}>
              {lang.get("credit", "New Contract")}
            </Button>
          </Space>
        </Col>
      </Row>

      <ContractTable />
    </div>
  );
};

export default ContractTab;
