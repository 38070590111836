import { StatisticCard } from "@ant-design/pro-components";
import RcResizeObserver from "rc-resize-observer";
import type { StatisticProps } from "antd";
import CountUp from "react-countup";
import { useState } from "react";

const formatter: StatisticProps["formatter"] = (value) => {
  return (
    <CountUp end={value as number} separator="," prefix="$" decimals={2} />
  );
};

const ExpenseStatitstic = () => {
  const [responsive, setResponsive] = useState(false);

  return (
    <RcResizeObserver
      key="resize-observer"
      onResize={(offset) => {
        setResponsive(offset.width < 596);
      }}
 
      
    >
      <StatisticCard.Group
        direction={responsive ? "column" : "row"}
        style={{
          paddingTop: "16px",
          paddingBottom: "16px",
          overflowX: responsive ? "auto" : "hidden",
          overflowY: "hidden",
          boxShadow: "4px  4px 4px rgba(0, 0, 0, 0.1)",
      
        }}
      >
        <StatisticCard
          statistic={{
            formatter,
            title: "Total",
            value: 0.0,
            valueStyle: { color: "#faad14" },
          }}
          chart={
            <img
              src="https://gw.alipayobjects.com/zos/alicdn/ShNDpDTik/huan.svg"
              alt="Total"
              width="80%"
            />
          }
          chartPlacement="left"
        />
        <StatisticCard
          statistic={{
            formatter,
            title: "Billable",
            value: 0.0,
            valueStyle: { color: "#52c41a" },
          }}
          chart={
            <img
              src="https://gw.alipayobjects.com/zos/alicdn/6YR18tCxJ/huanlv.svg"
              alt="Billable"
              width="80%"
            />
          }
          chartPlacement="left"
        />
        <StatisticCard
          statistic={{
            formatter,
            title: "Non Billable",
            value: 0.0,
            valueStyle: { color: "#faad14" },
          }}
          chart={
            <img
              src="https://gw.alipayobjects.com/zos/alicdn/ShNDpDTik/huan.svg"
              alt="Not Invoiced"
              width="80%"
            />
          }
          chartPlacement="left"
        />
        <StatisticCard
          statistic={{
            formatter,
            title: "Not Invoiced",
            value: 0.0,
            valueStyle: { color: "#ff4d4f" },
          }}
          chart={
            <img
              src="https://gw.alipayobjects.com/zos/alicdn/6YR18tCxJ/huanlv.svg"
              alt="Not Invoiced"
              width="80%"
            />
          }
          chartPlacement="left"
        />
        <StatisticCard
          statistic={{
            formatter,
            title: "Billed",
            value: 0.0,
            valueStyle: { color: "#52c41a" },
          }}
          chart={
            <img
              src="https://gw.alipayobjects.com/zos/alicdn/ShNDpDTik/huan.svg"
              alt="Not Invoiced"
              width="80%"
            />
          }
          chartPlacement="left"
        />
      </StatisticCard.Group>
    </RcResizeObserver>
  );
};

export default ExpenseStatitstic;
