import React, { useState, useRef } from "react";
import { Tag, Input, Button, Space, Select, DatePicker } from "antd";
import {
  SearchOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import lang from "../../../lang";
import Export from "../../../common/components/Export";

const { Option } = Select;
const { RangePicker } = DatePicker;

const PurchaseOrderTable = () => {
  const [pageSize, setPageSize] = useState(15);
  const [selectedFilters, setSelectedFilters] = useState({
    vendors: null,
    type: null,
    project: null,
    department: null,
    deliveryStatus: null,
    approvalStatus: null,
    dateRange: null,
  });
  const actionRef = useRef<ActionType>();
  const expobj = new Export(
    lang.get("purchaseOrders", ["Purchase Orders"]) +
      ", " +
      new Date().toDateString()
  );

  const handleFilterChange = (key: string, value: any) => {
    setSelectedFilters({ ...selectedFilters, [key]: value });
    actionRef.current?.reload();
  };

  const columns: ProColumns<any>[] = [
    {
      title: lang.get("purchaseOrders", ["Purchase Order"]),
      dataIndex: "purchaseOrder",
      key: "purchaseOrder",
      sorter: true,
      render: (text) => <a>{text}</a>,
      width: "250px"
    },
    {
      title: lang.get("purchaseOrders", ["Vendors"]),
      dataIndex: "vendors",
      key: "vendors",
      sorter: true,
      width: "150px"
    },
    {
      title: lang.get("purchaseOrders", ["Order Date"]),
      dataIndex: "orderDate",
      key: "orderDate",
      sorter: true,
      width: "150px"
    },
    {
      title: lang.get("purchaseOrders", ["Type"]),
      dataIndex: "type",
      key: "type",
      sorter: true,
      width: "150px"
    },
    {
      title: lang.get("purchaseOrders", ["Project"]),
      dataIndex: "project",
      key: "project",
      sorter: true,
      width: "150px"
    },
    {
      title: lang.get("purchaseOrders", ["Department"]),
      dataIndex: "department",
      key: "department",
      sorter: true,
      width: "150px"
    },
    {
      title: lang.get("purchaseOrders", ["PO Description"]),
      dataIndex: "poDescription",
      key: "poDescription",
      sorter: true,
      width: "150px"
    },
    {
      title: lang.get("purchaseOrders", ["PO Value"]),
      dataIndex: "poValue",
      key: "poValue",
      sorter: true,
      width: "150px"
    },
    {
      title: lang.get("purchaseOrders", ["Tax Value"]),
      dataIndex: "taxValue",
      key: "taxValue",
      sorter: true,
      width: "150px"
    },
    {
      title: lang.get("purchaseOrders", ["PO Value (Incl. Tax)"]),
      dataIndex: "poValueInclTax",
      key: "poValueInclTax",
      sorter: true,
      width: "150px"
    },
    {
      title: lang.get("purchaseOrders", ["Approval Status"]),
      dataIndex: "approvalStatus",
      key: "approvalStatus",
      render: (status) => (
        <Tag color={status === "Approved" ? "green" : "red"}>{status}</Tag>
      ),
      width: "150px"
    },
    {
      title: lang.get("purchaseOrders", ["Delivery Status"]),
      dataIndex: "deliveryStatus",
      key: "deliveryStatus",
      render: (status) => (
        <Tag color={status === "Delivered" ? "green" : "red"}>{status}</Tag>
      ),
      width: "150px"
    },
    {
      title: lang.get("purchaseOrders", ["Delivery Date"]),
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      width: "150px"
    },
    {
      title: lang.get("purchaseOrders", ["Payment Status"]),
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (value) => <Tag color="blue">{`${value}%`}</Tag>,
      width: "150px"
    },
    {
      title: lang.get("purchaseOrders", ["Convert to Expense"]),
      dataIndex: "convertToExpense",
      key: "convertToExpense",
      width: "150px",
      render: () => (
        <Button
          type="primary"
          style={{ backgroundColor: "#d4a017", borderColor: "#d4a017" }}
        >
          {lang.get("purchaseOrders", ["Convert"])}
        </Button>
      ),
    },
  ];

  const data = [
    {
      id: "1",
      purchaseOrder: "#PO-00004-May-2023-1234",
      vendors: "Micro Zone",
      orderDate: "2023-05-17",
      type: "Purchase",
      project: "Project A",
      department: "IT",
      poDescription: "J7 new stock",
      poValue: "LKR 850,000.00",
      taxValue: "LKR 0.00",
      poValueInclTax: "LKR 850,000.00",
      approvalStatus: "Approved",
      deliveryStatus: "Undelivered",
      deliveryDate: null,
      paymentStatus: 94,
    },
  ];

  return (
    <ProTable
      columns={columns}
      actionRef={actionRef}
      dataSource={data}
      rowKey="id"
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        onShowSizeChange: (_, size) => setPageSize(size),
      }}
      search={false}
      scroll={{ x: "max-content" }}
      toolBarRender={() => [
        <Space size="middle" wrap>
          <RangePicker
            onChange={(dates, dateStrings) =>
              handleFilterChange("dateRange", dateStrings)
            }
            style={{ width: 150 }}
          />
          <Select
            placeholder={lang.get("purchaseOrders", ["Approval Status"])}
            style={{ width: 150 }}
            allowClear
            onChange={(value) => handleFilterChange("approvalStatus", value)}
          >
            <Option value="Approved">Approved</Option>
            <Option value="Rejected">Rejected</Option>
          </Select>
          <Select
            placeholder={lang.get("purchaseOrders", ["Vendors"])}
            style={{ width: 150 }}
            allowClear
            onChange={(value) => handleFilterChange("vendors", value)}
          >
            <Option value="Micro Zone">Micro Zone</Option>
            <Option value="Vendor B">Vendor B</Option>
          </Select>
          <Select
            placeholder={lang.get("purchaseOrders", ["Type"])}
            style={{ width: 100 }}
            allowClear
            onChange={(value) => handleFilterChange("type", value)}
          >
            <Option value="Purchase">Purchase</Option>
            <Option value="Lease">Lease</Option>
          </Select>
          <Select
            placeholder={lang.get("purchaseOrders", ["Project"])}
            style={{ width: 100 }}
            allowClear
            onChange={(value) => handleFilterChange("project", value)}
          >
            <Option value="Project A">Project A</Option>
            <Option value="Project B">Project B</Option>
          </Select>
          <Select
            placeholder={lang.get("purchaseOrders", ["Department"])}
            style={{ width: 100 }}
            allowClear
            onChange={(value) => handleFilterChange("department", value)}
          >
            <Option value="IT">IT</Option>
            <Option value="Finance">Finance</Option>
          </Select>
          <Select
            placeholder={lang.get("purchaseOrders", ["Delivery Status"])}
            style={{ width: 150 }}
            allowClear
            onChange={(value) => handleFilterChange("deliveryStatus", value)}
          >
            <Option value="Delivered">Delivered</Option>
            <Option value="Undelivered">Undelivered</Option>
          </Select>
        </Space>,
        <Button
          key="csv"
          icon={<FileTextOutlined />}
          onClick={() => {
            const csvData = data.map((row) => ({
              [lang.get("purchaseOrders", ["Purchase Order"])]:
                row.purchaseOrder,
              [lang.get("purchaseOrders", ["Vendors"])]: row.vendors,
              [lang.get("purchaseOrders", ["Order Date"])]: row.orderDate,
              [lang.get("purchaseOrders", ["Type"])]: row.type,
              [lang.get("purchaseOrders", ["Project"])]: row.project,
              [lang.get("purchaseOrders", ["Department"])]: row.department,
              [lang.get("purchaseOrders", ["PO Description"])]:
                row.poDescription,
              [lang.get("purchaseOrders", ["PO Value"])]: row.poValue,
              [lang.get("purchaseOrders", ["Tax Value"])]: row.taxValue,
              //   [lang.get("purchaseOrders", ["PO Value (Incl. Tax)")]: row.poValueInclTax,
              [lang.get("purchaseOrders", ["Approval Status"])]:
                row.approvalStatus,
              [lang.get("purchaseOrders", ["Delivery Status"])]:
                row.deliveryStatus,
              [lang.get("purchaseOrders", ["Delivery Date"])]: row.deliveryDate,
              [lang.get("purchaseOrders", [
                "Payment Status",
              ])]: `${row.paymentStatus}%`,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("purchaseOrders", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          icon={<FileExcelOutlined />}
          onClick={() => {
            const excelData = data.map((row) => ({
              [lang.get("purchaseOrders", ["Purchase Order"])]:
                row.purchaseOrder,
              [lang.get("purchaseOrders", ["Vendors"])]: row.vendors,
              [lang.get("purchaseOrders", ["Order Date"])]: row.orderDate,
              [lang.get("purchaseOrders", ["Type"])]: row.type,
              [lang.get("purchaseOrders", ["Project"])]: row.project,
              [lang.get("purchaseOrders", ["Department"])]: row.department,
              [lang.get("purchaseOrders", ["PO Description"])]:
                row.poDescription,
              [lang.get("purchaseOrders", ["PO Value"])]: row.poValue,
              [lang.get("purchaseOrders", ["Tax Value"])]: row.taxValue,
              //   [lang.get("purchaseOrders", ["PO Value (Incl. Tax)")]: row.poValueInclTax,
              [lang.get("purchaseOrders", ["Approval Status"])]:
                row.approvalStatus,
              [lang.get("purchaseOrders", ["Delivery Status"])]:
                row.deliveryStatus,
              [lang.get("purchaseOrders", ["Delivery Date"])]: row.deliveryDate,
              [lang.get("purchaseOrders", [
                "Payment Status",
              ])]: `${row.paymentStatus}%`,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("purchaseOrders", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          icon={<FilePdfOutlined />}
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("purchaseOrders", ["Purchase Order"]),
                  lang.get("purchaseOrders", ["Vendors"]),
                  lang.get("purchaseOrders", ["Order Date"]),
                  lang.get("purchaseOrders", ["Type"]),
                  lang.get("purchaseOrders", ["Project"]),
                  lang.get("purchaseOrders", ["Department"]),
                  lang.get("purchaseOrders", ["PO Description"]),
                  lang.get("purchaseOrders", ["PO Value"]),
                  lang.get("purchaseOrders", ["Tax Value"]),
                  lang.get("purchaseOrders", ["PO Value (Incl. Tax)"]),
                  lang.get("purchaseOrders", ["Approval Status"]),
                  lang.get("purchaseOrders", ["Delivery Status"]),
                  lang.get("purchaseOrders", ["Delivery Date"]),
                  lang.get("purchaseOrders", ["Payment Status"]),
                ],
              ],
              body: data.map((row) => [
                row.purchaseOrder,
                row.vendors,
                row.orderDate,
                row.type,
                row.project,
                row.department,
                row.poDescription,
                row.poValue,
                row.taxValue,
                row.poValueInclTax,
                row.approvalStatus,
                row.deliveryStatus,
                row.deliveryDate,
                `${row.paymentStatus}%`,
              ]),
            });
          }}
        >
          {lang.get("purchaseOrders", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default PurchaseOrderTable;
