import React, { useState, useEffect, useRef } from "react";
import { Menu, Button, Row, Typography } from "antd";
import {
  AppstoreOutlined,
  FileOutlined,
  LeftOutlined,
  RightOutlined,
  BarcodeOutlined,
  HistoryOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import lang from "../../../../lang";
import { items, Item } from "../common/data/ItemsData";
import OverviewComponent from "./item-tabs/Overview";
import StockInventoryTable from "./item-tabs/StockInventory";
import TransactionHistoryTable from "./item-tabs/TransactionsHistory";
import SerialNumberTrackingTable from "./item-tabs/SerialNumberTrackingTable";
import ActivityLogs from "./item-tabs/ActivityLogs";

const { Text } = Typography;

interface ItemTabsProps {
  itemId: number;
}

const ItemTabs: React.FC<ItemTabsProps> = ({ itemId }) => {
  const [activeTab, setActiveTab] = useState<string>("overview");
  const [item, setItem] = useState<Item | undefined>();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const foundItem = items.find((item: Item) => item.id === itemId.toString());
    setItem(foundItem);
  }, [itemId]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1920);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollTabs = (direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === "right" ? 150 : -150;
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const tabs = [
    {
      id: "overview",
      label: lang.get("tabs", ["Overview"]),
      icon: <PieChartOutlined  />,
    },
    {
      id: "stock-inventory",
      label: lang.get("tabs", ["Stock Inventory"]),
      icon: <AppstoreOutlined />,
    },
    {
      id: "transaction-history",
      label: lang.get("tabs", ["Transaction History"]),
      icon: <FileOutlined />,
    },
    {
      id: "serial-number-tracking",
      label: lang.get("tabs", ["Serial Number Tracking"]),
      icon: <BarcodeOutlined />,
    },
    {
      id: "activity-log",
      label: lang.get("tabs", ["Activity Log"]),
      icon: <HistoryOutlined />,
    },
  ];

  const tabContent: Record<string, JSX.Element> = {
    overview: <OverviewComponent />,
    "stock-inventory": <StockInventoryTable />,
   "transaction-history": <TransactionHistoryTable />,
    "serial-number-tracking": <SerialNumberTrackingTable />,
  "activity-log": <ActivityLogs />,
   
  };

  if (!item) {
    return <Text>{lang.get("tabs", ["Item not found"])}</Text>;
  }

  return (
    <>
      <Row
        style={{
          background: "#fff",
          borderRadius: "5px",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          {isSmallScreen && (
            <Button
              icon={<LeftOutlined />}
              type="text"
              onClick={() => scrollTabs("left")}
              style={{ marginRight: "10px" }}
            />
          )}
          <div
            ref={scrollContainerRef}
            style={{
              display: "flex",
              overflowX: "auto",
              scrollbarWidth: "none",
              gap: "10px",
              width: "100%",
              whiteSpace: "nowrap",
            }}
          >
            <Menu
              onClick={({ key }) => setActiveTab(key)}
              selectedKeys={[activeTab]}
              mode="horizontal"
              style={{
                display: "flex",
                justifyContent: "start",
                columnGap: "10px",
                fontSize: "14px",
              }}
              items={tabs.map((tab) => ({
                key: tab.id,
                label: (
                  <span>
                    {tab.icon} {tab.label}
                  </span>
                ),
              }))}
            />
          </div>
          {isSmallScreen && (
            <Button
              icon={<RightOutlined />}
              type="text"
              onClick={() => scrollTabs("right")}
              style={{ marginLeft: "10px" }}
            />
          )}
        </div>
      </Row>
      <div style={{ background: "#f5f7fa", borderRadius: "5px" }}>
        {tabContent[activeTab]}
      </div>
    </>
  );
};

export default ItemTabs;
