import React from "react";
import { Button, Tooltip } from "antd";
import { SunOutlined, MoonOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { useTheme } from "../context/ThemeContext";

interface ThemeTogglerProps {
  slim?: boolean;
  className?: string;
  isRTL?: boolean;
}

const ThemeToggler: React.FC<ThemeTogglerProps> = ({
  slim,
  className,
  isRTL,
}) => {
  const { theme, toggleTheme } = useTheme();

  return (
    <Tooltip
      title={
        slim
          ? "Switch theme"
          : theme === "dark"
          ? "Switch to light theme"
          : "Switch to dark theme"
      }
      placement={slim ? "bottom" : isRTL ? "right" : "left"}
    >
      <Button type="text" onClick={toggleTheme}>
        <div className="theme-control-toggle-label">
          {theme === "dark" ? (
            <SunOutlined
              className={classNames({
                "me-1 d-none d-sm-block": slim,
              })}
              style={{ fontSize: slim ? 10 : 16 }}
            />
          ) : (
            <MoonOutlined
              className={classNames({
                "me-1 d-none d-sm-block": slim,
              })}
              style={{ fontSize: slim ? 10 : 16 }}
            />
          )}
          {slim && (
            <span className="fs-9 fw-bold">
              {theme === "dark" ? "Dark" : "Light"}
            </span>
          )}
        </div>
      </Button>
    </Tooltip>
  );
};

export default ThemeToggler;
