import React, { useState } from "react";
import {
  ProForm,
  ProFormText,
  ProFormSelect,
  ProFormDependency,
  ProCard,
} from "@ant-design/pro-components";
import {
  Button,
  Modal,
  Row,
  Col,
  Divider,
  Typography,
  Space,
  Input,
  Select,
  Form,
  DatePicker,
} from "antd";
import { EditOutlined, SendOutlined } from "@ant-design/icons";
import ReceivingVoucherAddTable from "./ReceivingVoucherAddTable";
import select from "antd/es/select";
import TinyMCEEditor from "../../../common/base/Editor";
import lang from "../../../lang";

const { Title } = Typography;
const Option = select;

const ReceivingVoucherAddBody: React.FC = () => {
  const [isBillToModalVisible, setBillToModalVisible] = useState(false);
  const [isShipToModalVisible, setShipToModalVisible] = useState(false);
  const [discountType, setDiscountType] = useState<string>("%");
  const [discount, setDiscount] = useState<number>(0);
  const [adjustment] = useState<number>(0);
  const [subTotal] = useState<number>(0);
  const [tax, setTax] = useState<string[]>([]);
  const [shipping, setShipping] = useState<number>(0);

  const calculateTotal = () => {
    const discountAmount =
      discountType === "%" ? (subTotal * discount) / 100 : discount;
    return subTotal - discountAmount + adjustment;
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <Title level={2}>
        {lang.get("sales", ["Inventory receiving voucher"])}
      </Title>
      <ProCard>
        <ProForm layout="vertical" submitter={false}>
          <Row gutter={[16, 5]}>
            {/* Customer */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Inventory Receiving Number"])}
                  </span>
                }
                name="number"
              >
                <Input placeholder="000009" size="large" />
              </Form.Item>
            </Col>

            {/* Tags */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Purchase Order"])}
                  </span>
                }
                name="tags"
              >
                <Select
                  placeholder={lang.get("sales", ["Select Order"])}
                  size="large"
                >
                  <select.Option value="Order 1">
                    {lang.get("sales", ["Order 1"])}
                  </select.Option>
                  <select.Option value="Order 2">
                    {lang.get("sales", ["Order 2"])}
                  </select.Option>
                  <select.Option value="Order 3">
                    {lang.get("sales", ["Order 3"])}
                  </select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Supplier Name"])}
                  </span>
                }
                name="supplierName"
              >
                <Input
                  placeholder={lang.get("receivingVoucher", [
                    "Enter Supplier Name",
                  ])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Date */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Date"])}
                  </span>
                }
                name="date"
              >
                <DatePicker style={{ width: "100%" }} size="large" />
              </Form.Item>
            </Col>

            {/* Accounting Date */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Accounting Date"])}
                  </span>
                }
                name="accountingDate"
              >
                <DatePicker style={{ width: "100%" }} size="large" />
              </Form.Item>
            </Col>

            {/* Voucher Date */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Voucher Date"])}
                  </span>
                }
                name="voucherDate"
              >
                <DatePicker style={{ width: "100%" }} size="large" />
              </Form.Item>
            </Col>

            {/* Address */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Address"])}
                  </span>
                }
                name="address"
              >
                <Input
                  placeholder={lang.get("receivingVoucher", ["Enter Address"])}
                  size="large"
                  suffix={<EditOutlined />}
                />
              </Form.Item>
            </Col>

            {/* Delivery Location */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Delivery Location"])}
                  </span>
                }
                name="deliveryLocation"
              >
                <Input
                  placeholder={lang.get("receivingVoucher", [
                    "Enter Delivery Location",
                  ])}
                  size="large"
                  suffix={<EditOutlined />}
                />
              </Form.Item>
            </Col>

            {/* Ship No */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Ship No"])}
                  </span>
                }
                name="shipNo"
              >
                <Input
                  placeholder={lang.get("receivingVoucher", ["Enter Ship No"])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Order By */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Order By"])}
                  </span>
                }
                name="orderBy"
              >
                <Input
                  placeholder={lang.get("receivingVoucher", ["Enter Order By"])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Request By */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Request By"])}
                  </span>
                }
                name="requestBy"
              >
                <Input
                  placeholder={lang.get("receivingVoucher", [
                    "Enter Request By",
                  ])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Checked By */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Checked By"])}
                  </span>
                }
                name="checkedBy"
              >
                <Input
                  placeholder={lang.get("receivingVoucher", [
                    "Enter Checked By",
                  ])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Rep Name */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Rep Name"])}
                  </span>
                }
                name="repName"
              >
                <Input
                  placeholder={lang.get("receivingVoucher", ["Enter Rep Name"])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Shipping Service */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Shipping Service"])}
                  </span>
                }
                name="shippingService"
              >
                <Input
                  placeholder={lang.get("receivingVoucher", [
                    "Enter Shipping Service",
                  ])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Reference Number */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Reference Number"])}
                  </span>
                }
                name="referenceNumber"
              >
                <Input
                  placeholder={lang.get("receivingVoucher", [
                    "Enter Reference Number",
                  ])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Dispatched Number */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Dispatched Number"])}
                  </span>
                }
                name="dispatchedNumber"
              >
                <Input
                  placeholder={lang.get("receivingVoucher", [
                    "Enter Dispatched Number",
                  ])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Project */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Project"])}
                  </span>
                }
                name="project"
              >
                <Select
                  placeholder={lang.get("receivingVoucher", ["Select Project"])}
                  size="large"
                >
                  <Select.Option value="Project1">
                    {lang.get("receivingVoucher", ["Project 1"])}
                  </Select.Option>
                  <Select.Option value="Project2">
                    {lang.get("receivingVoucher", ["Project 2"])}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Department */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Department"])}
                  </span>
                }
                name="department"
              >
                <Select
                  placeholder={lang.get("receivingVoucher", [
                    "Select Department",
                  ])}
                  size="large"
                >
                  <Select.Option value="Dept1">
                    {lang.get("receivingVoucher", ["Department 1"])}
                  </Select.Option>
                  <Select.Option value="Dept2">
                    {lang.get("receivingVoucher", ["Department 2"])}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Type */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("receivingVoucher", ["Type"])}
                  </span>
                }
                name="type"
              >
                <Select
                  placeholder={lang.get("receivingVoucher", ["Select Type"])}
                  size="large"
                >
                  <Select.Option value="Type1">
                    {lang.get("receivingVoucher", ["Type 1"])}
                  </Select.Option>
                  <Select.Option value="Type2">
                    {lang.get("receivingVoucher", ["Type 2"])}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <ReceivingVoucherAddTable />
          <Divider />

          {/* Summary Section */}
          <Row justify="end" gutter={16} style={{ borderRadius: "8px" }}>
            <ProFormDependency name={["total", "adjustment"]}>
              {() => (
                <Space
                  direction="vertical"
                  style={{
                    width: "30%",
                    padding: "16px",
                  }}
                >
                  {/* Shipping Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    {/* Shipping Input */}
                    <Col style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "12px",
                          marginRight: "8px",
                        }}
                      >
                        {lang.get("sales", ["Shipping ($) :"])}
                      </div>
                      <Input
                        type="number"
                        value={shipping}
                        onChange={(e) => setShipping(Number(e.target.value))}
                        size="small"
                        style={{ width: "100px", textAlign: "center" }}
                      />
                    </Col>

                    {/* Discount Input */}
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "16px",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            fontWeight: 400,
                            fontSize: "12px",
                            marginRight: "8px",
                          }}
                        >
                          {lang.get("sales", ["Discount ($) :"])}
                        </div>
                        <Input
                          type="number"
                          value={discount}
                          onChange={(e) => setDiscount(Number(e.target.value))}
                          size="small"
                          style={{ width: "120px", textAlign: "left" }}
                          addonAfter={
                            <Select
                              value={discountType}
                              onChange={(value) => setDiscountType(value)}
                              style={{ width: "50px" }}
                              dropdownStyle={{
                                width: "150px",
                                textAlign: "center",
                              }}
                            >
                              <Option value="Fixed">
                                {lang.get("sales", ["Fixed"])}
                              </Option>
                              <Option value="%">
                                {lang.get("sales", ["%"])}
                              </Option>
                            </Select>
                          }
                        />
                      </div>
                    </Col>
                  </Row>

                  <Divider style={{ margin: "8px 0" }} />

                  {/*Taxes Input */}

                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {lang.get("sales", ["Taxes"])}
                    </Col>
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      <Select
                        mode="multiple"
                        value={tax}
                        onChange={(values) => setTax(values)}
                        style={{ width: "150px" }}
                        placeholder={lang.get("sales", ["Select Taxes"])}
                      >
                        <Option value="5">
                          {lang.get("sales", ["VAT (5%)"])}
                        </Option>
                        <Option value="10">
                          {lang.get("sales", ["Service Tax (10%)"])}
                        </Option>
                        <Option value="15">
                          {lang.get("sales", ["CGT (15%)"])}
                        </Option>
                      </Select>
                    </Col>
                  </Row>

                  {/* Subtotal Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {lang.get("sales", ["Subtotal"])}
                    </Col>
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      ${subTotal.toFixed(2)}
                    </Col>
                  </Row>

                  {/* Shipping Total Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {lang.get("sales", ["Shipping"])}
                    </Col>
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {shipping ? `$${shipping.toFixed(2)}` : "-"}
                    </Col>
                  </Row>

                  {/* Discount Total Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {lang.get("sales", ["Discount"])}
                    </Col>
                    <Col
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: "#ff4d4f",
                      }}
                    >
                      -$
                      {discountType === "%"
                        ? ((subTotal * discount) / 100).toFixed(2)
                        : discount.toFixed(2)}
                    </Col>
                  </Row>

                  {/* Taxes Total Row */}
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {lang.get("sales", ["Taxes"])}
                    </Col>
                    <Col style={{ fontWeight: 400, fontSize: "12px" }}>
                      {tax.length > 0
                        ? `$${tax
                            .reduce((acc, curr) => acc + parseFloat(curr), 0)
                            .toFixed(2)}`
                        : "-"}
                    </Col>
                  </Row>

                  <Divider style={{ margin: "8px 0" }} />

                  {/* Total Row */}
                  <Row justify="space-between" align="middle">
                    <Col style={{ fontWeight: 500, fontSize: "12px" }}>
                      {lang.get("sales", ["Total"])}
                    </Col>
                    <Col style={{ fontWeight: 500, fontSize: "12px" }}>
                      ${calculateTotal().toFixed(2)}
                    </Col>
                  </Row>
                </Space>
              )}
            </ProFormDependency>
          </Row>

          <Divider />

          {/* Notes Section */}
          <Row gutter={[16, 16]}>
            {/* Ex. Rate Column */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Ex. Rate"])}
                  </span>
                }
                name="ExRate"
              >
                <Input
                  type="number"
                  placeholder={lang.get("sales", ["Enter Exchange Rate"])}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            {/* Account Column */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Account"])}
                  </span>
                }
                name="Account"
              >
                <Select
                  placeholder={lang.get("sales", ["Select Account"])}
                  style={{ width: "100%" }}
                >
                  <Option value="Accounts Payable">
                    {lang.get("sales", ["Accounts Payable"])}
                  </Option>
                  <Option value="Accounts Receivable">
                    {lang.get("sales", ["Accounts Receivable"])}
                  </Option>
                  <Option value="Cash Account">
                    {lang.get("sales", ["Cash Account"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Notes Section */}
          <Row gutter={[16, 5]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Client Note"])}
                  </span>
                }
                name="Note"
              >
                <TinyMCEEditor />
              </Form.Item>
            </Col>
          </Row>


          <Divider />

          {/* Submit Section */}
          <Row justify="end" gutter={16}>
            <Button
              color="primary"
              variant="outlined"
              icon={<SendOutlined />}
              style={{ marginBottom: "30px", marginTop: "30px" }}
              onClick={() =>
                console.log(
                  lang.get("sales", ["Send the created invoice  For Approval"])
                )
              }
            >
              {lang.get("sales", ["Send the created invoice  For approval"])}
            </Button>
          </Row>
          <Row justify="end" gutter={16}>
            <Col>
              {/* Close Button */}
              <Button
                onClick={() => console.log(lang.get("sales", ["Close"]))}
                style={{
                  backgroundColor: "white",
                  borderColor: "#d9d9d9",
                  color: "#000",
                  marginRight: 8,
                }}
              >
                {lang.get("sales", ["Close"])}
              </Button>

              {/* Save Button */}
              <Button
                type="primary"
                onClick={() => console.log(lang.get("sales", ["Save"]))}
                style={{
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  color: "#fff",
                  marginRight: 8,
                }}
              >
                {lang.get("sales", ["Save"])}
              </Button>

              {/* Save & Send Request Button */}
              <Button
                type="primary"
                onClick={() =>
                  console.log(lang.get("sales", ["Save & Send Request"]))
                }
                style={{
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  color: "#fff",
                }}
              >
                {lang.get("sales", ["Save & Send Request"])}
              </Button>
            </Col>
          </Row>
        </ProForm>
      </ProCard>

      {/* Modals */}
      <Modal
        title={lang.get("sales", ["Edit Bill To"])}
        open={isBillToModalVisible}
        onCancel={() => setBillToModalVisible(false)}
        footer={null}
      >
        <ProForm layout="vertical" submitter={false}>
          <ProFormText
            name="street"
            label={lang.get("sales", ["Street"])}
            placeholder={lang.get("sales", ["Enter street"])}
          />
          <ProFormText
            name="city"
            label={lang.get("sales", ["City"])}
            placeholder={lang.get("sales", ["Enter city"])}
          />
          <ProFormText
            name="state"
            label={lang.get("sales", ["State"])}
            placeholder={lang.get("sales", ["Enter state"])}
          />
          <ProFormText
            name="zipCode"
            label={lang.get("sales", ["Zip Code"])}
            placeholder={lang.get("sales", ["Enter zip code"])}
          />
          <ProFormSelect
            name="country"
            label={lang.get("sales", ["Country"])}
            placeholder={lang.get("sales", ["Select country"])}
            options={[
              { value: "USA", label: lang.get("sales", ["USA"]) },
              { value: "UK", label: lang.get("sales", ["UK"]) },
            ]}
          />
        </ProForm>
      </Modal>

      <Modal
        title={lang.get("sales", ["Edit Ship To"])}
        open={isShipToModalVisible}
        onCancel={() => setShipToModalVisible(false)}
        footer={null}
      >
        <ProForm layout="vertical" submitter={false}>
          <ProFormText
            name="street"
            label={lang.get("sales", ["Street"])}
            placeholder={lang.get("sales", ["Enter street"])}
          />
          <ProFormText
            name="city"
            label={lang.get("sales", ["City"])}
            placeholder={lang.get("sales", ["Enter city"])}
          />
          <ProFormText
            name="state"
            label={lang.get("sales", ["State"])}
            placeholder={lang.get("sales", ["Enter state"])}
          />
          <ProFormText
            name="zipCode"
            label={lang.get("sales", ["Zip Code"])}
            placeholder={lang.get("sales", ["Enter zip code"])}
          />
          <ProFormSelect
            name="country"
            label={lang.get("sales", ["Country"])}
            placeholder={lang.get("sales", ["Select country"])}
            options={[
              { value: "USA", label: lang.get("sales", ["USA"]) },
              { value: "UK", label: lang.get("sales", ["UK"]) },
            ]}
          />
        </ProForm>
      </Modal>
    </div>
  );
};

export default ReceivingVoucherAddBody;
