import React, { useEffect } from "react";
import { Button, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ContractTable from "./ContractTable";
import lang from "../../../../../../lang";
const Contract: React.FC = () => {
  useEffect(() => {}, []);

  return (
    <div style={{ padding: "16px", background: "#f5f7fa" }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "16px" }}
      >
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            style={{ marginRight: "8px" }}
          >
            {lang.get("contract", ["Create Contract "])}
          </Button>
        </Col>
      </Row>
      <ContractTable />
    </div>
  );
};

export default Contract;
