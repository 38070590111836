import { StatisticCard } from "@ant-design/pro-components";
import RcResizeObserver from "rc-resize-observer";
import { useState } from "react";

const ProjectStatistic = () => {
  const [responsive, setResponsive] = useState(false);

  return (
    <RcResizeObserver
      key="resize-observer"
      onResize={(offset) => {
        setResponsive(offset.width < 596);
      }}
    >
      <StatisticCard.Group
        direction={responsive ? "column" : "row"}
        style={{
          paddingTop: "16px",
          paddingBottom: "16px",
          overflowX: responsive ? "auto" : "hidden",
          overflowY: "hidden",
          boxShadow: "4px  4px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <StatisticCard
          statistic={{
            title: "Not Started",
            value: 0.0,
            valueStyle: { color: "#faad14" },
          }}
          chart={
            <img
              src="https://gw.alipayobjects.com/zos/alicdn/ShNDpDTik/huan.svg"
              alt="Total"
              width="80%"
            />
          }
          chartPlacement="left"
        />
        <StatisticCard
          statistic={{
            title: "In Progress",
            value: 0.0,
            valueStyle: { color: "#52c41a" },
          }}
          chart={
            <img
              src="https://gw.alipayobjects.com/zos/alicdn/6YR18tCxJ/huanlv.svg"
              alt="Billable"
              width="80%"
            />
          }
          chartPlacement="left"
        />
        <StatisticCard
          statistic={{
            title: "On Hold",
            value: 0.0,
            valueStyle: { color: "#faad14" },
          }}
          chart={
            <img
              src="https://gw.alipayobjects.com/zos/alicdn/ShNDpDTik/huan.svg"
              alt="Not Invoiced"
              width="80%"
            />
          }
          chartPlacement="left"
        />
        <StatisticCard
          statistic={{
            title: "Cancelled",
            value: 0.0,
            valueStyle: { color: "#ff4d4f" },
          }}
          chart={
            <img
              src="https://gw.alipayobjects.com/zos/alicdn/6YR18tCxJ/huanlv.svg"
              alt="Not Invoiced"
              width="80%"
            />
          }
          chartPlacement="left"
        />
        <StatisticCard
          statistic={{
            title: "Finished",
            value: 0.0,
            valueStyle: { color: "#52c41a" },
          }}
          chart={
            <img
              src="https://gw.alipayobjects.com/zos/alicdn/ShNDpDTik/huan.svg"
              alt="Not Invoiced"
              width="80%"
            />
          }
          chartPlacement="left"
        />
      </StatisticCard.Group>
    </RcResizeObserver>
  );
};

export default ProjectStatistic;
