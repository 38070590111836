// ProjectTable.tsx
import React, { useState, useRef } from "react";
import {
  Tag,
  InputRef,
  Input,
  Button,
  Space,
  DatePicker,
  Dropdown,
} from "antd";

import {
  CheckCircleOutlined,
  PauseCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  AlertOutlined,
  SearchOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import lang from "../../../../../../lang";
import Export from "../../../../../../common/components/Export";

const { RangePicker } = DatePicker;

const statusStyles: Record<string, { color: string; icon: React.ReactNode }> = {
  Finished: { color: "green", icon: <CheckCircleOutlined /> },
  "On Hold": { color: "orange", icon: <PauseCircleOutlined /> },
  "In Progress": { color: "blue", icon: <SyncOutlined spin /> },
  Cancelled: { color: "red", icon: <CloseCircleOutlined /> },
};

const priorityStyles: Record<string, { color: string; icon: React.ReactNode }> =
  {
    High: { color: "red", icon: <AlertOutlined /> },
    Medium: { color: "blue", icon: <SyncOutlined /> },
    Low: { color: "gray", icon: <PauseCircleOutlined /> },
    Urgent: { color: "orange", icon: <AlertOutlined /> },
  };

const filterMenus = [
  {
    label: "Status",
    key: "status",
    children: [
      { label: "Not Started", key: "not_started" },
      { label: "In Progress", key: "in_progress" },
      { label: "On Hold", key: "on_hold" },
      { label: "Cancelled", key: "cancelled" },
      { label: "Finished", key: "finished" },
    ],
  },
  {
    label: "Tags",
    key: "tags",
    children: [
      { label: "Marketing", key: "marketing" },
      { label: "Design", key: "design" },
      { label: "Development", key: "development" },
    ],
  },
  {
    label: "Priority",
    key: "priority",
    children: [
      { label: "High", key: "high" },
      { label: "Medium", key: "medium" },
      { label: "Low", key: "low" },
      { label: "Urgent", key: "urgent" },
    ],
  },
];

export const tasks = [
  {
    id: 1,
    name: "Making the Butterflies shoot each other dead",
    start: "Dec 12, 2018",
    deadline: "Dec 12, 2026",
    status: {
      label: "Finished",
      icon: "check",
    },
    assigned: "John Doe",
    priority: {
      label: "High",
      icon: "alert-octagon",
    },
  },
  {
    id: 2,
    name: "Developing AI to understand human sarcasm",
    start: "Jan 10, 2020",
    deadline: "Dec 30, 2024",
    status: {
      label: "In Progress",
      icon: "refresh-cw",
    },
    assigned: "Jane Smith",
    priority: {
      label: "Medium",
      icon: "alert-circle",
    },
  },
  {
    id: 3,
    name: "Training penguins to do backflips",
    start: "Aug 15, 2021",
    deadline: "Aug 15, 2023",
    status: {
      label: "Not Started",
      icon: "clock",
    },
    assigned: "Alice Johnson",
    priority: {
      label: "Low",
      icon: "arrow-down",
    },
  },
  {
    id: 4,
    name: "Building a cloud server on Mars",
    start: "Oct 05, 2022",
    deadline: "Oct 05, 2030",
    status: {
      label: "On Hold",
      icon: "pause-circle",
    },
    assigned: "Chris Brown",
    priority: {
      label: "High",
      icon: "alert-octagon",
    },
  },
  {
    id: 5,
    name: "Creating a sustainable spaceship for cats",
    start: "Mar 01, 2023",
    deadline: "Mar 01, 2028",
    status: {
      label: "Cancelled",
      icon: "x-circle",
    },
    assigned: "Ella Green",
    priority: {
      label: "Urgent",
      icon: "zap",
    },
  },
];

const TasksTable: React.FC = () => {
  const [pageSize, setPageSize] = useState(15);
  const searchInput = useRef<InputRef>(null);
  const actionRef = useRef<ActionType>();
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const [allSearch, setAllSearch] = useState<string>("");

  const expobj = new Export(
    lang.get("Task", ["Tasks"]) + ", " + new Date().toDateString()
  );

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  interface Task {
    id: number;
    name: string;
    start: string;
    deadline: string;
    status: {
      label: string;
      icon: string;
    };
    assigned: string;
    priority: {
      label: string;
      icon: string;
    };
  }

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("Task", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("Task", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("projects", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<Task>[] = [
    {
      title: "Task Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ...searchCols("name", lang.get("Task", ["Task Name"])),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record: Task) => {
        const { color, icon } = statusStyles[record.status.label] || {
          color: "gray",
          icon: null,
        };
        return (
          <Tag color={color}>
            {icon} {lang.get("Task", [record.status.label])}
          </Tag>
        );
      },
      sorter: true,
      ...searchCols("status", lang.get("Task", ["Status"])),
    },

    {
      title: "Start Date",
      dataIndex: "start",
      key: "start",
      ...searchCols("start", lang.get("Task", ["Start Date"])),
      sorter: true,
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      key: "deadline",
      ...searchCols("deadline", lang.get("Task", ["Deadline"])),
      sorter: true,
    },

    {
      title: "Assigned To",
      dataIndex: "assigned",
      key: "assigned",
      sorter: true,
      ...searchCols("assigned", lang.get("Task", ["Assigned To"])),
    },

    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      render: (_, record: Task) => {
        const { color, icon } = priorityStyles[record.priority.label] || {
          color: "gray",
          icon: null,
        };
        return (
          <Tag color={color}>
            {icon} {lang.get("projects", [record.priority.label])}
          </Tag>
        );
      },
      sorter: true,
      ...searchCols("priority", lang.get("Task", ["Priority"])),
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("projects", ["Tasks"])}
      columns={columns}
      actionRef={actionRef}
      dataSource={tasks}
      rowKey="id"
      cardBordered
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        showTotal: (total, range) =>
          lang.get("projects", [
            `Showing ${range[0]}-${range[1]} of ${total} items`,
          ]),
        onShowSizeChange: (current, size) => {
          setPageSize(size);
        },
      }}
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <Dropdown menu={{ items: filterMenus }}>
          <Button icon={<FilterOutlined />}>Filter</Button>
        </Dropdown>,

        <RangePicker />,
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("Task", ["Global Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef?.current?.reload();
          }}
        />,
        <Button
          key="csv"
          onClick={() => {
            const csvData = tasks.map((row) => ({
              [lang.get("Task", ["Task Name"])]: row.name,
              [lang.get("Task", ["Status"])]: row.status.label,
              [lang.get("Task", ["Start Date"])]: row.start,
              [lang.get("Task", ["Deadline"])]: row.deadline,
              [lang.get("Task", ["Assigned To"])]: row.assigned,
              [lang.get("Task", ["Priority"])]: row.priority.label,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("Task", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = tasks.map((row) => ({
              [lang.get("Task", ["Task Name"])]: row.name,
              [lang.get("Task", ["Status"])]: row.status.label,
              [lang.get("Task", ["Start Date"])]: row.start,
              [lang.get("Task", ["Deadline"])]: row.deadline,
              [lang.get("Task", ["Assigned To"])]: row.assigned,
              [lang.get("Task", ["Priority"])]: row.priority.label,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("Task", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("Task", ["Task Name"]),
                  lang.get("Task", ["Status"]),
                  lang.get("Task", ["Start Date"]),
                  lang.get("Task", ["Deadline"]),
                  lang.get("Task", ["Assigned To"]),
                  lang.get("Task", ["Priority"]),
                ],
              ],
              body: tasks.map((row) => [
                row.name,
                row.start,
                row.deadline,
                row.assigned,
                row.status.label,
                row.priority.label,
              ]),
            });
          }}
        >
          {lang.get("Task", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default TasksTable;
