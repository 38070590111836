import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FullscreenOutlined,
  MailOutlined,
  SearchOutlined,
  SendOutlined,
} from "@ant-design/icons";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import {
  Button,
  Input,
  Space,
  InputRef,
  Tag,
  DatePicker,
  Select,
  Card,
  Descriptions,
  Table,
  Row,
  Col,
  Menu,
} from "antd";
import { ReactNode, useRef, useState } from "react";
import Export from "../../../../common/components/Export";
import lang from "../../../../lang";

const { Option } = Select;
const { RangePicker } = DatePicker;

interface Estimate {
  id: string;
  amount: string;
  totalTax: string;
  discount: string;
  customer: string;
  project: string;
  tags: string[];
  date: string;
  expiryDate: string;
  reference: string;
  status: string;
  items: {
    name: string;
    qty: number;
    rate: number;
    tax: string;
    amount: number;
  }[];
}

const Estimates: Estimate[] = [
  {
    id: "EST-000008",
    amount: "$6,500.00",
    totalTax: "$0.00",
    discount: "0%",
    customer: "",
    project: "",
    tags: ["Urgent"],
    date: "2024-06-20",
    expiryDate: "2024-06-27",
    reference: "",
    status: "Sent",
    items: [{ name: "Item 1", qty: 1, rate: 6500, tax: "0%", amount: 6500 }],
  },
  {
    id: "EST-000007",
    amount: "$0.00",
    totalTax: "$0.00",
    discount: "5%",
    customer: "",
    project: "",
    tags: ["Follow-up"],
    date: "2024-05-25",
    expiryDate: "2024-06-01",
    reference: "",
    status: "Accepted",
    items: [{ name: "Item 2", qty: 2, rate: 3000, tax: "10%", amount: 6000 }],
  },
  {
    id: "EST-000006",
    amount: "$10.00",
    totalTax: "$0.00",
    discount: "10%",
    customer: "",
    project: "",
    tags: ["Review"],
    date: "2024-03-26",
    expiryDate: "2024-04-02",
    reference: "",
    status: "Accepted",
    items: [{ name: "Item 3", qty: 1, rate: 10, tax: "0%", amount: 10 }],
  },
];

const EstimateTable = () => {
  const actionRef = useRef<ActionType>();
  const [pageSize, setPageSize] = useState<number>(10);
  const searchInput = useRef<InputRef>(null);
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const [allSearch, setAllSearch] = useState<string>("");

  const expobj = new Export("estimates, " + new Date().toDateString());
  const [activeTab, setActiveTab] = useState<string>("estimate");

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("sales", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("sales", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("sales", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const [ExpandedRecord, setExpandedRecord] = useState<Estimate | null>(null);

  const columns: ProColumns<Estimate>[] = [
    {
      title: lang.get("estimate", ["Estimate #"]),
      dataIndex: "id",
      sorter: true,
      ellipsis: true,
      ...searchCols("id", lang.get("estimate", ["Estimate #"])),
      responsive: ["xs", "sm", "md", "lg"],
    },
    {
      title: lang.get("estimate", ["Date"]),
      dataIndex: "date",
      sorter: true,
      ...searchCols("date", lang.get("estimate", ["Date"])),
    },
    {
      title: lang.get("estimate", ["Customer"]),
      dataIndex: "customer",
      sorter: true,
      ...searchCols("customer", lang.get("estimate", ["Customer"])),
      responsive: ["lg"],
    },
    {
      title: lang.get("estimate", ["Project"]),
      dataIndex: "project",
      sorter: true,
      ...searchCols("project", lang.get("estimate", ["Project"])),
      responsive: ["lg"],
    },
    {
      title: lang.get("estimate", ["Total Discount"]),
      dataIndex: "discount",
      sorter: true,
      ...searchCols("project", lang.get("estimate", ["Discount"])),
      responsive: ["lg"],
    },
    {
      title: lang.get("estimate", ["Total Tax"]),
      dataIndex: "totalTax",
      sorter: true,
      ...searchCols("totalTax", lang.get("estimate", ["Total Tax"])),
      responsive: ["md", "lg"],
    },
    {
      title: lang.get("estimate", ["Amount"]),
      dataIndex: "amount",
      sorter: true,
      ...searchCols("amount", lang.get("estimate", ["Amount"])),
      responsive: ["sm", "md", "lg"],
    },
    {
      title: lang.get("estimate", ["Tags"]),
      dataIndex: "tags",
      render: (tags: ReactNode) => {
        const tagArray = tags as string[] | undefined;
        return tagArray && tagArray.length > 0 ? (
          tagArray.map((tag) => (
            <Tag color="blue" key={tag}>
              {tag}
            </Tag>
          ))
        ) : (
          <span style={{ color: "#ccc" }}>-</span>
        );
      },
    },
    {
      title: lang.get("estimate", ["Expiry Date"]),
      dataIndex: "expiryDate",
      sorter: true,
      ...searchCols("expiryDate", lang.get("estimate", ["Expiry Date"])),
    },
    {
      title: lang.get("estimate", ["Reference #"]),
      dataIndex: "reference",
      sorter: true,
      ...searchCols("reference", lang.get("estimate", ["Reference #"])),
    },
    {
      title: lang.get("estimate", ["Status"]),
      dataIndex: "status",
      sorter: true,
      filters: [
        { text: lang.get("estimate", ["Sent"]), value: "Sent" },
        { text: lang.get("estimate", ["Accepted"]), value: "Accepted" },
        { text: lang.get("estimate", ["Draft"]), value: "Draft" },
      ],
      render: (_, record) =>
        record.status === "Sent" ? (
          <Tag color="blue">{lang.get("estimate", ["Sent"])}</Tag>
        ) : record.status === "Accepted" ? (
          <Tag color="green">{lang.get("estimate", ["Accepted"])}</Tag>
        ) : (
          <Tag color="orange">{lang.get("estimate", ["Draft"])}</Tag>
        ),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} md={ExpandedRecord ? 11 : 24}>
        <ProTable
          headerTitle={lang.get("sales", ["Estimates"])}
          columns={columns}
          actionRef={actionRef}
          dataSource={Estimates}
          rowKey="id"
          onRow={(record) => {
            return {
              onClick: () => {
                setExpandedRecord(record);
              },
            };
          }}
          style={{ cursor: "pointer" }}
          cardBordered
          pagination={{
            defaultCurrent: 1,
            pageSize: pageSize,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 50],
            showTotal: (total, range) =>
              lang.get("sales", [
                `Showing ${range[0]}-${range[1]} of ${total} items`,
              ]),
            onShowSizeChange: (current, size) => {
              setPageSize(size);
            },
          }}
          search={false}
          scroll={{ x: 800 }}
          toolBarRender={() => [
            <RangePicker key="date" />,
            <Select
              placeholder={lang.get("sales", ["Select Currency"])}
              style={{ width: 200 }}
            >
              <Option value="USD">
                USD - {lang.get("sales", ["US Dollar"])}
              </Option>
              <Option value="EUR">EUR - {lang.get("sales", ["Euro"])}</Option>
              <Option value="GBP">
                GBP - {lang.get("sales", ["British Pound"])}
              </Option>
              <Option value="LKR">
                LKR - {lang.get("sales", ["Sri Lankan Rupee"])}
              </Option>
            </Select>,
            <Input
              style={{ width: "auto" }}
              key="all_search"
              allowClear
              placeholder={lang.get("sales", ["Global Search"])}
              value={allSearch}
              onChange={(e) => {
                setAllSearch(e.target.value);
                setSearchAttributes([]);
                actionRef?.current?.reload();
              }}
            />,
            <Button
              key="csv"
              onClick={() => {
                const csvData = Estimates.map((row) => ({
                  [lang.get("sales", ["Estimate #"])]: row.id,
                  [lang.get("sales", ["Amount"])]: row.amount,
                  [lang.get("sales", ["Total Tax"])]: row.totalTax,
                  [lang.get("sales", ["Project"])]: row.project,
                  [lang.get("sales", ["Customer"])]: row.customer,
                  [lang.get("sales", ["Tags"])]: row.tags.join(", "),
                  [lang.get("sales", ["Date"])]: row.date,
                  [lang.get("sales", ["Expiry Date"])]: row.expiryDate,
                  [lang.get("sales", ["Reference #"])]: row.reference,
                  [lang.get("sales", ["Status"])]: row.status,
                }));
                expobj.exportToCSV(csvData);
              }}
            >
              {lang.get("sales", ["Export CSV"])}
            </Button>,
            <Button
              key="excel"
              onClick={() => {
                const excelData = Estimates.map((row) => ({
                  [lang.get("sales", ["Estimate #"])]: row.id,
                  [lang.get("sales", ["Amount"])]: row.amount,
                  [lang.get("sales", ["Total Tax"])]: row.totalTax,
                  [lang.get("sales", ["Project"])]: row.project,
                  [lang.get("sales", ["Customer"])]: row.customer,
                  [lang.get("sales", ["Tags"])]: row.tags.join(", "),
                  [lang.get("sales", ["Date"])]: row.date,
                  [lang.get("sales", ["Expiry Date"])]: row.expiryDate,
                  [lang.get("sales", ["Reference #"])]: row.reference,
                  [lang.get("sales", ["Status"])]: row.status,
                }));
                expobj.exportToExcel(excelData);
              }}
            >
              {lang.get("sales", ["Export Excel"])}
            </Button>,
            <Button
              key="pdf"
              onClick={() => {
                expobj.exportToPDF({
                  head: [
                    [
                      lang.get("sales", ["Estimate #"]),
                      lang.get("sales", ["Amount"]),
                      lang.get("sales", ["Total Tax"]),
                      lang.get("sales", ["Project"]),
                      lang.get("sales", ["Customer"]),
                      lang.get("sales", ["Tags"]),
                      lang.get("sales", ["Date"]),
                      lang.get("sales", ["Expiry Date"]),
                      lang.get("sales", ["Reference #"]),
                      lang.get("sales", ["Status"]),
                    ],
                  ],
                  body: Estimates.map((row) => [
                    row.id,
                    row.amount,
                    row.totalTax,
                    row.project,
                    row.customer,
                    row.tags.join(", "),
                    row.date,
                    row.expiryDate,
                    row.reference,
                    row.status,
                  ]),
                });
              }}
            >
              {lang.get("sales", ["Export PDF"])}
            </Button>,
          ]}
        />
      </Col>
      {ExpandedRecord && (
        <Col xs={24} md={13}>
          {/* Expanded Section */}
          <Card
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ display: "flex", gap: "12px", alignItems: "center" }}
                >
                  <Button type="text" icon={<MailOutlined />} />
                  <Button type="text" icon={<EyeOutlined />} />
                  <Button type="text" icon={<FullscreenOutlined />} />
                </div>
                {/* Action Buttons */}
                <div style={{ display: "flex", gap: "8px" }}>
                  <Button type="text" size="small" icon={<EditOutlined />} />
                  <Button type="text" size="small" icon={<DeleteOutlined />} />
                  <Button type="primary" size="small">
                    <span style={{ fontSize: "10px" }}> INV-DRAFT</span>
                  </Button>
                </div>
              </div>
            }
            bordered
            style={{
              border: "1px solid #1677ff",
              overflow: "auto",
              height: "100%",
            }}
            extra={
              <Button
                color="danger"
                variant="outlined"
                onClick={() => setExpandedRecord(null)}
                style={{
                  marginLeft: "10px",
                }}
              >
                Close
              </Button>
            }
          >
            {/* Tabs */}
            <Menu
              mode="horizontal"
              defaultSelectedKeys={["estimate"]}
              selectedKeys={[activeTab]}
              onClick={({ key }) => handleTabChange(key)}
              style={{
                marginBottom: "20px",
                borderBottom: "1px solid #e3e3e3",
              }}
              items={[
                { key: "estimate", label: "Estimate" },
                { key: "tasks", label: "Tasks" },
                { key: "activityLog", label: "Activity Log" },
                { key: "reminders", label: "Reminders" },
                { key: "notes", label: "Notes" },
              ]}
            />

            {/* Tab Contents */}
            {activeTab === "estimate" && (
              <div>
                {/* Estimate Details */}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <div>
                    <p
                      style={{
                        fontWeight: "bold",
                        color: "#1677ff",
                        marginBottom: "8px",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      {ExpandedRecord.id}

                      {ExpandedRecord.status === "Sent" ? (
                        <Tag color="blue">{ExpandedRecord.status}</Tag>
                      ) : ExpandedRecord.status === "Accepted" ? (
                        <Tag color="green">{ExpandedRecord.status}</Tag>
                      ) : (
                        <Tag color="orange">{ExpandedRecord.status}</Tag>
                      )}
                    </p>
                  </div>
                  <div style={{ display: "flex", gap: "8px" }}>
                    <Button icon={<EditOutlined />} />
                    <Button icon={<SendOutlined />} />
                    <Button icon={<MailOutlined />} />
                    <Button>More</Button>
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: "#52c41a",
                        borderColor: "#52c41a",
                      }}
                    >
                      {lang.get("sales", ["convert to invoice"])}
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "16px",
                  }}
                >
                  <div style={{ textAlign: "left", flexBasis: "40%" }}>
                    <p style={{ fontWeight: "bold", marginBottom: "4px" }}>
                      Call Lanka Telecom
                    </p>
                    <p style={{ marginBottom: "4px" }}>10/6</p>
                    <p style={{ marginBottom: "4px" }}>Nawinna Colombo</p>
                    <p style={{ marginBottom: "8px" }}>1202</p>
                  </div>

                  <div style={{ textAlign: "right", flexBasis: "40%" }}>
                    <p>
                      <strong>To:</strong> ABC Pvt Ltd
                    </p>
                    <p>12/A</p>
                    <p>Barker Road, Main Street</p>
                    <Descriptions
                      bordered
                      column={1}
                      size="small"
                      style={{ textAlign: "center", flexBasis: "40%" }}
                    >
                      <Descriptions.Item label="Estimate Date">
                        {ExpandedRecord?.date || "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Expiry Date">
                        {ExpandedRecord?.expiryDate || "N/A"}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                </div>

                <Table
                  dataSource={ExpandedRecord.items}
                  columns={[
                    {
                      title: "#",
                      dataIndex: "name",
                      key: "name",
                      width: "30%",
                    },
                    {
                      title: "Qty",
                      dataIndex: "qty",
                      key: "qty",
                      align: "center",
                    },
                    {
                      title: "Rate",
                      dataIndex: "rate",
                      key: "rate",
                      align: "right",
                    },
                    {
                      title: "Tax",
                      dataIndex: "tax",
                      key: "tax",
                      align: "center",
                    },
                    {
                      title: "Amount",
                      dataIndex: "amount",
                      key: "amount",
                      align: "right",
                    },
                  ]}
                  pagination={false}
                  size="small"
                  style={{ marginTop: 20 }}
                  rowKey={(record, index) => `${record.name}-${index}`}
                />
              </div>
            )}
            {activeTab === "tasks" && <div>Tasks Content</div>}
            {activeTab === "activityLog" && <div>Activity Log Content</div>}
            {activeTab === "reminders" && <div>Reminders Content</div>}
            {activeTab === "notes" && <div>Notes Content</div>}
          </Card>
        </Col>
      )}
    </Row>
  );
};

export default EstimateTable;
