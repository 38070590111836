import React, { useState } from "react";
import { Input, Button, Grid } from "antd";
import { MenuOutlined, SearchOutlined } from "@ant-design/icons";
import NavbarActions from "./NavbarActions"; // Import the updated NavbarActions component
import logo from "../assets/img/icons/ERP Test Logo.png";
import "./css/NavbarTopHorizontal.css";

const { useBreakpoint } = Grid;

interface NavbarTopHorizontalProps {
  toggleDrawer: () => void;
}

const NavbarTopHorizontal: React.FC<NavbarTopHorizontalProps> = ({
  toggleDrawer,
}) => {
  const [theme, setTheme] = useState<"light" | "dark">("light"); // Theme state
  const screens = useBreakpoint();

  const toggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
    document.body.setAttribute("data-theme", newTheme); // Optional: For global theming
  };

  return (
    <div className="navbar-container">
      {/* Menu Button for Small Screens */}
      {!screens.md && (
        <Button
          className="menu-button"
          icon={<MenuOutlined />}
          onClick={toggleDrawer}
        />
      )}

      {/* Logo */}
      <div className="navbar-logo">
        <img src={logo} alt="Logo" />
      </div>

      {screens.md && (
        <div className="navbar-search">
          <Input
            placeholder="Search..."
            prefix={<SearchOutlined />}
            className="search-input"
          />
        </div>
      )}

      {/* Action Buttons */}
      <NavbarActions theme={theme} toggleTheme={toggleTheme} />
    </div>
  );
};

export default NavbarTopHorizontal;
