import { useState, ChangeEvent } from "react";
import {
  Card,
  Steps,
  Row,
  Col,
  Input,
  Select,
  Form,
  Button,
  Divider,
} from "antd";

import lang from "../../../../lang";
import { ProFormUploadDragger } from "@ant-design/pro-components";

const { Option } = Select;
const { Step } = Steps;
const { TextArea } = Input;

const ItemAddBody = (props: {
  modelClose: () => void;
  setUpdateTable: () => void;
}) => {
  const [postData, setPostData] = useState({
    inventoryType: "Inventory",
    itemName: "",
    itemCode: "",
    itemBarcode: "",
    description: "",
    tags: [],
    inventoryAssetAccount: "",
    warehouseName: "",
    units: "",
    supplier: "",
    category: "",
    subCategory: "",
    brand: "",
    minimumStock: "",
    maximumStock: "",
    defaultProfitRate: "",
    purchasePrice: "",
    expensesAccount: "",
    purchaseTax: "",
    sellingPrice: "",
    incomeAccount: "",
    salesTax: "",
    colors: [],
    models: [],
    sizes: [],
    styles: [],
    itemImage: null,
    additionalImages: [],
    productBrochure: null,
  });

  const [currentStep, setCurrentStep] = useState(0);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPostData({
      ...postData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSelectChange = (value: string | string[], field: string) => {
    setPostData({
      ...postData,
      [field]: value,
    });
  };

  const steps = [
    {
      title: lang.get("items", ["General Information"]),
      content: (
        <Row gutter={[16, 4]}>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Inventory Type"])}
                </span>
              }
              name="inventoryType"
            >
              <Select
                onChange={(value) => handleSelectChange(value, "inventoryType")}
                defaultValue="Inventory"
                size="large"
              >
                <Option value="Inventory">Inventory</Option>
                <Option value="Non-inventory">Non-inventory</Option>
                <Option value="Service">Service</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Item Name"])}
                </span>
              }
              name="itemName"
            >
              <Input
                id="itemName"
                placeholder={lang.get("items", ["Item Name"])}
                onChange={handleChange}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Item Code"])}
                </span>
              }
              name="itemCode"
            >
              <Input
                id="itemCode"
                placeholder={lang.get("items", ["Item Code"])}
                onChange={handleChange}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Item Barcode"])}
                </span>
              }
              name="itemBarcode"
            >
              <Input
                id="itemBarcode"
                placeholder={lang.get("items", ["Item Barcode"])}
                onChange={handleChange}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Inventory Asset Account"])}
                </span>
              }
              name="inventoryAssetAccount"
            >
              <Select
                onChange={(value) =>
                  handleSelectChange(value, "inventoryAssetAccount")
                }
                size="large"
              >
                <Option value="account1">Account 1</Option>
                <Option value="account2">Account 2</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Warehouse Name"])}
                </span>
              }
              name="warehouseName"
            >
              <Select
                onChange={(value) => handleSelectChange(value, "warehouseName")}
                size="large"
              >
                <Option value="warehouse1">Warehouse 1</Option>
                <Option value="warehouse2">Warehouse 2</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Units"])}
                </span>
              }
              name="units"
            >
              <Select
                onChange={(value) => handleSelectChange(value, "units")}
                size="large"
              >
                <Option value="unit1">Unit 1</Option>
                <Option value="unit2">Unit 2</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Supplier"])}
                </span>
              }
              name="supplier"
            >
              <Select
                mode="multiple"
                onChange={(value) => handleSelectChange(value, "supplier")}
                size="large"
                allowClear
                placeholder={lang.get("items", ["Select Suppliers"])}
              >
                <Option value="supplier1">Supplier 1</Option>
                <Option value="supplier2">Supplier 2</Option>
                <Option value="supplier3">Supplier 3</Option>
                <Option value="supplier4">Supplier 4</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Category"])}
                </span>
              }
              name="category"
            >
              <Select
                onChange={(value) => handleSelectChange(value, "category")}
                size="large"
              >
                <Option value="category1">Category 1</Option>
                <Option value="category2">Category 2</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Manufacturer"])}
                </span>
              }
              name="manufacturer"
            >
              <Select
                onChange={(value) => handleSelectChange(value, "manufacturer")}
                size="large"
              >
                <Option value="manufacturer1">Manufacturer 1</Option>
                <Option value="manufacturer2">Manufacturer 2</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Sub Category"])}
                </span>
              }
              name="subCategory"
            >
              <Select
                onChange={(value) => handleSelectChange(value, "subCategory")}
                size="large"
              >
                <Option value="subcategory1">Sub Category 1</Option>
                <Option value="subcategory2">Sub Category 2</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Minimum Stock"])}
                </span>
              }
              name="minimumStock"
            >
              <Input
                id="minimumStock"
                placeholder={lang.get("items", ["Minimum Stock"])}
                onChange={handleChange}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Brand"])}
                </span>
              }
              name="brand"
            >
              <Select
                onChange={(value) => handleSelectChange(value, "brand")}
                size="large"
              >
                <Option value="brand1">Brand 1</Option>
                <Option value="brand2">Brand 2</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Description"])}
                </span>
              }
              name="description"
            >
              <TextArea
                id="description"
                placeholder={lang.get("items", ["Description"])}
                onChange={handleChange}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Tags"])}
                </span>
              }
              name="tags"
            >
              <Select
                mode="tags"
                placeholder={lang.get("items", ["Add Tags"])}
                onChange={(value) => handleSelectChange(value, "tags")}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Maximum Stock"])}
                </span>
              }
              name="maximumStock"
            >
              <Input
                id="maximumStock"
                placeholder={lang.get("items", ["Maximum Stock"])}
                onChange={handleChange}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <ProFormUploadDragger
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Item Image"])}
                </span>
              }
              name="itemImage"
              max={5}
              fieldProps={{
                name: "file",
                listType: "picture-card",
              }}
              action="/upload.do"
              title={lang.get("project", [
                "Click or drag files into this area to upload",
              ])}
              description={lang.get("project", [
                "Support single or batch upload",
              ])}
              accept="image/*"
            />
          </Col>
          <Col xs={24}>
            <ProFormUploadDragger
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Additional Images"])}
                </span>
              }
              name="additionalImages"
              max={5}
              fieldProps={{
                name: "file",
                listType: "picture-card",
              }}
              action="/upload.do"
              title={lang.get("project", [
                "Click or drag files into this area to upload",
              ])}
              description={lang.get("project", [
                "Support single or batch upload",
              ])}
              accept="image/*"
            />
          </Col>
          <Col xs={24}>
            <ProFormUploadDragger
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Product Brochure"])}
                </span>
              }
              name="productBrochure"
              max={5}
              fieldProps={{
                name: "file",
                listType: "picture-card",
              }}
              action="/upload.do"
              title={lang.get("project", [
                "Click or drag files into this area to upload",
              ])}
              description={lang.get("project", [
                "Support single or batch upload",
              ])}
              accept="image/*"
            />
          </Col>
        </Row>
      ),
    },
    {
      title: lang.get("items", ["Properties"]),
      content: (
        <Row gutter={[16, 4]}>
          <Col xs={24} lg={8}>
            <Form.Item label={lang.get("items", ["Colors"])} name="colors">
              <Select
                mode="tags"
                placeholder={lang.get("items", ["Add Colors"])}
                onChange={(value) => handleSelectChange(value, "colors")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Models"])}
                </span>
              }
              name="models"
            >
              <Select
                mode="tags"
                placeholder={lang.get("items", ["Add Models"])}
                onChange={(value) => handleSelectChange(value, "models")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Sizes"])}
                </span>
              }
              name="sizes"
            >
              <Select
                mode="tags"
                placeholder={lang.get("items", ["Add Sizes"])}
                onChange={(value) => handleSelectChange(value, "sizes")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Styles"])}
                </span>
              }
              name="styles"
            >
              <Select
                mode="tags"
                placeholder={lang.get("items", ["Add Styles"])}
                onChange={(value) => handleSelectChange(value, "styles")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Rack Name"])}
                </span>
              }
              name="rackName"
            >
              <Input
                id="rackName"
                placeholder={lang.get("items", ["Enter Rack Name"])}
                onChange={(e) => handleChange(e)}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Rack Number"])}
                </span>
              }
              name="rackNumber"
            >
              <Input
                id="rackNumber"
                placeholder={lang.get("items", ["Enter Rack Number"])}
                onChange={(e) => handleChange(e)}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
    {
      title: lang.get("items", ["Pricing Structure"]),
      content: (
        <Row gutter={[16, 4]}>
          <Col xs={24} lg={8}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Default Profit Rate (%)"])}
                </span>
              }
              name="discountRate"
            >
              <Input
                id="discountRate"
                placeholder={lang.get("items", ["Default Profit Rate (%)"])}
                onChange={handleChange}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}></Col>

          {/* Purchase Information */}
          <Col xs={24} lg={12}>
            <Card
              title={
                <span style={{ fontSize: "14px" }}>Purchase Information</span>
              }
              bordered
            >
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("items", ["Purchase Price"])}
                  </span>
                }
                name="purchasePrice"
              >
                <Input
                  id="purchasePrice"
                  addonBefore="USD"
                  placeholder={lang.get("items", ["Purchase Price"])}
                  onChange={handleChange}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("items", ["Expenses Account"])}
                  </span>
                }
                name="purchaseAccount"
              >
                <Select
                  onChange={(value) =>
                    handleSelectChange(value, "purchaseAccount")
                  }
                  size="large"
                  placeholder={lang.get("items", ["Select Account"])}
                >
                  <Option value="5227 Cost of Goods Sold">
                    [5227] Cost of Goods Sold
                  </Option>
                  <Option value="Other">Other Account</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("items", ["Description"])}
                  </span>
                }
                name="purchaseDescription"
              >
                <TextArea
                  id="purchaseDescription"
                  placeholder={lang.get("items", ["Description"])}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("items", ["Tax"])}
                  </span>
                }
                name="purchaseTax"
              >
                <Select
                  onChange={(value) => handleSelectChange(value, "purchaseTax")}
                  size="large"
                  placeholder={lang.get("items", ["Select a Tax"])}
                >
                  <Option value="Tax1">VAT (10%)</Option>
                  <Option value="Tax2">TAX (15%)</Option>
                </Select>
              </Form.Item>
            </Card>
          </Col>
          {/* Sales Information */}
          <Col xs={24} lg={12}>
            <Card
              title={
                <span style={{ fontSize: "14px" }}>Selling Information</span>
              }
              bordered
            >
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("items", ["Selling Price"])}
                  </span>
                }
                name="sellingPrice"
              >
                <Input
                  id="sellingPrice"
                  addonBefore="USD"
                  placeholder={lang.get("items", ["Selling Price"])}
                  onChange={handleChange}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("items", ["Income Account"])}
                  </span>
                }
                name="salesAccount"
              >
                <Select
                  onChange={(value) =>
                    handleSelectChange(value, "salesAccount")
                  }
                  size="large"
                  placeholder={lang.get("items", ["Select Account"])}
                >
                  <Option value="15881 Sales">[15881] Sales</Option>
                  <Option value="Other">Other Account</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("items", ["Description"])}
                  </span>
                }
                name="salesDescription"
              >
                <TextArea
                  id="salesDescription"
                  placeholder={lang.get("items", ["Description"])}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("items", ["Tax"])}
                  </span>
                }
                name="salesTax"
              >
                <Select
                  onChange={(value) => handleSelectChange(value, "salesTax")}
                  size="large"
                  placeholder={lang.get("items", ["Select a Tax"])}
                >
                  <Option value="Tax1">VAT (10%)</Option>
                  <Option value="Tax2">TAX (15%)</Option>
                </Select>
              </Form.Item>
            </Card>
          </Col>

          <Divider />
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Inventory Account"])}
                </span>
              }
              name="inventoryAccount"
              rules={[
                {
                  required: true,
                  message: lang.get("items", ["Account is required"]),
                },
              ]}
            >
              <Select
                size="large"
                placeholder={lang.get("items", ["Select Inventory Account"])}
                onChange={(value) =>
                  handleSelectChange(value, "inventoryAccount")
                }
              >
                <Option value="10485 Inventory Asset">
                  [10485] Inventory Asset
                </Option>
                <Option value="Other">Other Account</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <span style={{ fontSize: "14px" }}>
                  {lang.get("items", ["Opening Stock"])}
                </span>
              }
              name="openingStock"
            >
              <Input
                size="large"
                placeholder={lang.get("items", ["Enter Opening Stock"])}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <Card>
      <Steps
        current={currentStep}
        onChange={(step) => setCurrentStep(step)}
        className="mb-4"
      >
        {steps.map((step, index) => (
          <Step key={index} title={step.title} />
        ))}
      </Steps>
      <Form layout="vertical" style={{ fontSize: "13px" }}>
        {steps[currentStep].content}
      </Form>
      <div className="steps-action mt-4">
        <Row justify="end" gutter={[8, 0]}>
          {currentStep > 0 && (
            <Col>
              <Button onClick={() => setCurrentStep(currentStep - 1)}>
                {lang.get("items", ["Previous"])}
              </Button>
            </Col>
          )}
          {currentStep < steps.length - 1 && (
            <Col>
              <Button
                type="primary"
                onClick={() => setCurrentStep(currentStep + 1)}
              >
                {lang.get("items", ["Next"])}
              </Button>
            </Col>
          )}
          {currentStep === steps.length - 1 && (
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  props.setUpdateTable();
                  props.modelClose();
                }}
              >
                {lang.get("items", ["Save Item"])}
              </Button>
            </Col>
          )}
        </Row>
      </div>
    </Card>
  );
};

export default ItemAddBody;
