import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Checkbox,
  Typography,
  Modal,
  Form,
  Input,
  DatePicker,
} from "antd";
import {
  PlusOutlined,
  UnorderedListOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import MilestonesTable from "./MilestoneTable";

const { Text, Link } = Typography;

const lng = {
  Uncategorized: "Uncategorized",
  LoggedTime: "Logged Time",
  NewMilestone: "New Milestone",
  ExcludeCompletedTasks: "Exclude Completed Tasks",
  Name: "Name",
  StartDate: "Start Date",
  DueDate: "Due Date",
  Description: "Description",
  ShowDescriptionToCustomer: "Show Description To Customer",
  HideFromCustomer: "Hide From Customer",
  Order: "Order",
  Close: "Close",
  Save: "Save",
  LoadMore: "Load More",
};

const Milestones = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTableView, setIsTableView] = useState(false);
  const handleShowModal = () => setIsModalVisible(true);
  const handleCloseModal = () => setIsModalVisible(false);
  const toggleView = () => setIsTableView((prev) => !prev);

  return (
    <div style={{ marginTop: "20px" }}>
      {/* Header Section */}
      <div style={{ marginBottom: "16px", borderRadius: "8px" }}>
        <Row justify="space-between" align="middle">
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              style={{ marginRight: "8px" }}
              onClick={handleShowModal}
            >
              {lng.NewMilestone}
            </Button>
            <Button
              type="default"
              icon={<UnorderedListOutlined />}
              onClick={toggleView}
            />
          </Col>
          <Col>
            <Checkbox defaultChecked>{lng.ExcludeCompletedTasks}</Checkbox>
          </Col>
        </Row>
      </div>

      {/* View Toggle */}
      {isTableView ? (
        <div style={{ background: "#fff", borderRadius: "8px" }}>
          <MilestonesTable />
        </div>
      ) : (
        <div>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={6}>
              <Card
                title={null}
                bordered={false}
                style={{ borderRadius: "8px", overflow: "hidden" }}
                styles={{
                  body: {
                    padding: "0",
                    background: "#f0f2f5",
                    height: "400px",
                  },
                }}
              >
                {/* Custom Card Header */}
                <div
                  style={{
                    backgroundColor: "#1890ff",
                    color: "#fff",
                    padding: "12px 16px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  <Text style={{ color: "#fff" }}>{lng.Uncategorized}</Text>
                  <Text
                    style={{ color: "#fff" }}
                  >{`${lng.LoggedTime}: 00:00`}</Text>
                </div>

                {/* Card Body */}
                <div
                  style={{
                    padding: "16px",
                    background: "#fff",
                    borderRadius: "8px",
                    margin: "16px",
                    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Row align="middle" style={{ marginBottom: "8px" }}>
                    <UnorderedListOutlined
                      style={{ marginRight: "8px", color: "#1890ff" }}
                    />
                    <Text>Test Task</Text>
                  </Row>
                  <Link
                    href="#"
                    style={{
                      color: "#1890ff",
                      display: "block",
                      marginBottom: "8px",
                    }}
                  >
                    In Progress
                  </Link>
                  <Text style={{ display: "block" }}>
                    Total logged time: 00:00
                  </Text>
                  <Text style={{ display: "block" }}>2024-06-20</Text>
                </div>

                {/* Load More Button */}
                <div style={{ padding: "16px" }}>
                  <Button type="default" block style={{ borderRadius: "4px" }}>
                    {lng.LoadMore}
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      )}

      {/* Modal Section */}
      <Modal
        title={lng.NewMilestone}
        open={isModalVisible}
        onCancel={handleCloseModal}
        footer={[
          <Button key="close" onClick={handleCloseModal}>
            {lng.Close}
          </Button>,
          <Button key="save" type="primary" onClick={handleCloseModal}>
            {lng.Save}
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label={lng.Name} name="name" rules={[{ required: true }]}>
            <Input placeholder="Enter milestone name" />
          </Form.Item>
          <Form.Item
            label={lng.StartDate}
            name="startDate"
            rules={[{ required: true }]}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Select start date"
              prefix={<CalendarOutlined />}
            />
          </Form.Item>
          <Form.Item
            label={lng.DueDate}
            name="dueDate"
            rules={[{ required: true }]}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Select due date"
              prefix={<CalendarOutlined />}
            />
          </Form.Item>
          <Form.Item label={lng.Description} name="description">
            <Input.TextArea rows={3} placeholder="Enter description" />
          </Form.Item>
          <Form.Item>
            <Checkbox>{lng.ShowDescriptionToCustomer}</Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox>{lng.HideFromCustomer}</Checkbox>
          </Form.Item>
          <Form.Item label={lng.Order} name="order">
            <Input type="number" defaultValue={1} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Milestones;
