import { useState } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  Input,
  message,
  Breadcrumb,
  Form,
  Typography,
  Checkbox,
} from "antd";
import { MailOutlined, DeleteOutlined, KeyOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import ProfileCard from "./components/ProfileCard";
import CustomerAddressCard from "./components/AddressCard";
import ProfileDetailsTab from "./components/ProfileDetailsTab";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  ReloadOutlined,
} from "@ant-design/icons";
import lang from "../../../lang";

const { Title } = Typography;

export type Customer = {
  id: string;
  name: string;
  vat_number: string;
  website: string;
  zip_code: string;
  city: string;
  state: string;
  country: string;
  phone: string;
  currency: string;
  address: string;
  last_login: string;
  default_language: string;
  last_order?: string;
};

const Profile = () => {
  const navigate = useNavigate();
  const [preload] = useState(true);
  const [client] = useState<Customer>({
    id: "",
    name: "",
    vat_number: "",
    phone: "",
    currency: "",
    address: "",
    last_login: "",
    default_language: "",
    last_order: "",
    website: "",
    zip_code: "",
    city: "",
    state: "",
    country: "",
  });
  const [mailSubject, setMailSubject] = useState("");
  const [mailBody, setMailBody] = useState("");
  const [openMailModal, setOpenMailModal] = useState(false);
  const [openPassModal, setopenPassModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [password, setPassword] = useState("");
  const deleteCustomer = () => {
    Modal.confirm({
      title: lang.get("client", ["Are you sure?"]),
      content: lang.get("client", ["You won't be able to revert this!"]),
      okText: lang.get("client", ["Yes, delete it!"]),
      okType: "danger",
      cancelText: lang.get("client", ["Cancel"]),
      onOk: () => {
        message.loading(lang.get("client", ["Deleting..."]), 1).then(() => {
          message.success(lang.get("client", ["Customer deleted successfully."]), 2);
          navigate("/module/clients/list", { replace: true });
        });
      },
    });
  };

  const generateRandomPassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+";
    let password = "";
    for (let i = 0; i < 12; i++) {
      password += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return password;
  };

  const sendMail = () => {
    if (mailSubject && mailBody) {
      message.loading(lang.get("client", ["Sending mail..."]), 1).then(() => {
        message.success(lang.get("client", ["Email sent successfully."]), 2);
        setMailSubject("");
        setMailBody("");
        setOpenMailModal(false);
      });
    } else {
      message.error(lang.get("client", ["Please fill in the subject and body."]));
    }
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* Breadcrumb Section */}
      <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
        <Breadcrumb.Item>{lang.get("client", ["home"])}</Breadcrumb.Item>
        <Breadcrumb.Item>{lang.get("client", ["Clients"])}</Breadcrumb.Item>
        <Breadcrumb.Item>{lang.get("client", ["Profile"])}</Breadcrumb.Item>
      </Breadcrumb>

      {/* Header Section */}
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "24px" }}
      >
        <Col>
          <h2 style={{ margin: 0 }}>{lang.get("client", ["Profile"])}</h2>
        </Col>
        <Col>
          <Row gutter={[16, 16]} wrap>
            <Col>
              <Button
                type="primary"
                icon={<MailOutlined />}
                onClick={() => setOpenMailModal(true)}
              >
                {lang.get("customer", ["Send Email"])}
              </Button>
            </Col>
            <Col>
              <Button danger icon={<DeleteOutlined />} onClick={deleteCustomer}>
                {lang.get("customer", ["Delete Customer"])}
              </Button>
            </Col>
            <Col>
              <Button
                icon={<KeyOutlined />}
                onClick={() => setopenPassModal(true)}
              >
                {lang.get("client", ["Reset Password"])}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Profile and Address Cards */}
      <Row gutter={[16, 16]} style={{ marginBottom: "24px" }}>
        <Col xs={24} lg={16}>
          <ProfileCard />
        </Col>
        <Col xs={24} lg={8}>
          <CustomerAddressCard />
        </Col>
      </Row>

      {/* Profile Details Tab */}
      <ProfileDetailsTab
        client={client}
        preload={preload}
        setRefresh={setRefresh}
        refresh={refresh}
      />

      {/* Email Modal */}
      <Modal
        title={<Title level={4}>{lang.get("client", ["Send Email"])}</Title>}
        open={openMailModal}
        onCancel={() => setOpenMailModal(false)}
        onOk={sendMail}
        okText={lang.get("client", ["Send Email"])}
        cancelText={lang.get("client", ["Cancel"])}
      >
        <Form layout="vertical">
          {/* Subject Field */}
          <Form.Item label={lang.get("client", ["Subject"])}>
            <Input
              value={mailSubject}
              onChange={(e) => setMailSubject(e.target.value)}
              placeholder={lang.get("client", ["enter email subject"])}
            />
          </Form.Item>

          {/* Message Field */}
          <Form.Item label={lang.get("client", ["Message"])}>
            <Editor
              value={mailBody}
              onEditorChange={(content) => setMailBody(content)}
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  // eslint-disable-next-line no-multi-str
                  "undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help",
              }}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={
          <Title level={4}>{lang.get("client", ["Reset Password"])}</Title>
        }
        open={openPassModal}
        onCancel={() => setopenPassModal(false)}
        okText={lang.get("client", ["Reset"])}
        cancelText={lang.get("client", ["Cancel"])}
      >
        <Form layout="vertical">
          <Form.Item
            label={lang.get("client", ["New Password"])}
            name="password"
            rules={[
              {
                required: true,
                message: lang.get("client", ["Please input the new password!"])
              },
            ]}
          >
            <Input.Password
              placeholder={lang.get("client", ["Enter new password"])}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              addonAfter={
                <ReloadOutlined
                  onClick={() => setPassword(generateRandomPassword())}
                />
              }
            />
          </Form.Item>

          {/* Checkboxes */}
          <Form.Item>
            <Row gutter={[0, 8]}>
              <Col span={24}>
                <Checkbox id="send-mail">
                  {lang.get("client", ["Send password to email"])}
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox id="require-change">
                  {lang.get("client", ["Send password to SMS"])}
                </Checkbox>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Profile;
