import React, { useState, useRef } from "react";
import { Tag, InputRef, Input, Button, Space, Select } from "antd";
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import Export from "../../../../common/components/Export";
import lang from "../../../../lang";
import Item1 from "../../../../assets/img/icons/item/pc.svg";
import { Link } from "react-router-dom";

const Option = Select;
const statusStyles: Record<string, { color: string; icon: React.ReactNode }> = {
  Available: { color: "green", icon: <CheckCircleOutlined /> },
  "Out of Stock": { color: "red", icon: <CloseCircleOutlined /> },
  Pending: { color: "blue", icon: <SyncOutlined spin /> },
};

const ItemListTable = () => {
  const [pageSize, setPageSize] = useState(15);
  const searchInput = useRef<InputRef>(null);
  const actionRef = useRef<ActionType>();
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const [allSearch, setAllSearch] = useState<string>("");

  const expobj = new Export(
    lang.get("projects", ["Projects"]) + ", " + new Date().toDateString()
  );

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("projects", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("projects", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("projects", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  interface Item {
    id: string;
    image: string;
    itemCode: string;
    itemName: string;
    barcode: string;
    type: string;
    warehouseName: string;
    currentStock: number;
    purchasePrice: number;
    sellingPrice: number;
    tax: number;
    status: {
      label: string;
      icon: string;
    };
    category: string;
    brand: string;
    minimumStock: number;
    maximumStock: number;
  }

  const columns: ProColumns<Item>[] = [
    {
      title: lang.get("items", ["Image"]),
      dataIndex: "image",
      key: "image",
      align: "left",
      width: 100,
      render: (text) => (
        <div style={{ width: 50, height: 50, background: "#f0f0f0" }}>
          <img
            src={Item1}
            alt={lang.get("items", ["Item Image"])}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      ),
    },
    {
      title: lang.get("items", ["Item Code"]),
      dataIndex: "itemCode",
      key: "itemCode",
      sorter: true,
      ...searchCols("itemCode", lang.get("items", ["Item Code"])),
      align: "left",
      width: 100,
    },
    {
      title: lang.get("items", ["Item Name"]),
      dataIndex: "itemName",
      key: "itemName",
      sorter: true,
      ...searchCols("itemName", lang.get("items", ["Item Name"])),
      align: "left",
      width: 200,
      render: (_, record: Item) => (
        <Link
          to={`/item-details/${record.id}`}
          style={{
            color: "inherit",
            textDecoration: "none",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>{record.itemName}</p>
        </Link>
      ),
    },
    {
      title: lang.get("items", ["Barcode"]),
      dataIndex: "barcode",
      key: "barcode",
      sorter: true,
      ...searchCols("barcode", lang.get("items", ["Barcode"])),
      align: "left",
      width: 200,
    },
    {
      title: lang.get("items", ["Type"]),
      dataIndex: "type",
      key: "type",
      sorter: true,
      ...searchCols("type", lang.get("items", ["Type"])),
      align: "center",
      width: 100,
    },
    {
      title: lang.get("items", ["Warehouse Name"]),
      dataIndex: "warehouseName",
      key: "warehouseName",
      sorter: true,
      ...searchCols("warehouseName", lang.get("items", ["Warehouse Name"])),
      align: "center",
      width: 200,
    },
    {
      title: lang.get("items", ["Current Stock"]),
      dataIndex: "currentStock",
      key: "currentStock",
      sorter: true,
      ...searchCols("currentStock", lang.get("items", ["Current Stock"])),
      align: "center",
      width: 150,
    },
    {
      title: lang.get("items", ["Purchase Price"]),
      dataIndex: "purchasePrice",
      key: "purchasePrice",
      sorter: true,
      ...searchCols("purchasePrice", lang.get("items", ["Purchase Price"])),
      align: "center",
    },
    {
      title: lang.get("items", ["Selling Price"]),
      dataIndex: "sellingPrice",
      key: "sellingPrice",
      sorter: true,
      ...searchCols("sellingPrice", lang.get("items", ["Selling Price"])),
      align: "center",
    },
    {
      title: lang.get("items", ["Tax"]),
      dataIndex: "tax",
      key: "tax",
      sorter: true,
      ...searchCols("tax", lang.get("items", ["Tax"])),
      align: "center",
      width: 100,
    },
    {
      title: lang.get("items", ["Status"]),
      dataIndex: "status",
      key: "status",
      ...searchCols("status", lang.get("items", ["Status"])),
      render: (_, record: Item) => {
        const { color, icon } = statusStyles[record.status.label] || {
          color: "gray",
          icon: null,
        };
        return (
          <Tag color={color}>
            {icon} {lang.get("items", [record.status.label])}
          </Tag>
        );
      },
      sorter: true,
      align: "left",
    },
    {
      title: lang.get("items", ["Category"]),
      dataIndex: "category",
      key: "category",
      sorter: true,
      ...searchCols("category", lang.get("items", ["Category"])),
      align: "left",
    },
    {
      title: lang.get("items", ["Brand"]),
      dataIndex: "brand",
      key: "brand",
      sorter: true,
      ...searchCols("brand", lang.get("items", ["Brand"])),
      align: "left",
    },
    {
      title: lang.get("items", ["Minimum Stock"]),
      dataIndex: "minimumStock",
      key: "minimumStock",
      sorter: true,
      ...searchCols("minimumStock", lang.get("items", ["Minimum Stock"])),
      align: "center",
    },
    {
      title: lang.get("items", ["Maximum Stock"]),
      dataIndex: "maximumStock",
      key: "maximumStock",
      sorter: true,
      ...searchCols("maximumStock", lang.get("items", ["Maximum Stock"])),
      align: "center",
    },
  ];
  const data = [
    {
      id: "1",
      image: "Item1",
      itemCode: "01",
      itemName: "Truecam Battery",
      barcode: "000123456789",
      type: "Electronics",
      warehouseName: "Main: (10)",
      currentStock: 10,
      purchasePrice: 0.0,
      sellingPrice: 0.0,
      tax: 0,
      status: {
        label: "Available",
        icon: "check",
      },
      category: "Batteries",
      brand: "Truecam",
      minimumStock: 5,
      maximumStock: 20,
    },
    {
      id: "2",
      image: "Item1",
      itemCode: "1234",
      itemName: "Blous 1",
      barcode: "000223456789",
      type: "Clothing",
      warehouseName: "Main: (10)",
      currentStock: 10,
      purchasePrice: 6500.0,
      sellingPrice: 6500.0,
      tax: 8,
      status: {
        label: "Available",
        icon: "check",
      },
      category: "Apparel",
      brand: "Blous",
      minimumStock: 5,
      maximumStock: 50,
    },
    {
      id: "3",
      image: "Item1",
      itemCode: "J7",
      itemName: "Samsung J 7",
      barcode: "000323456789",
      type: "Mobile",
      warehouseName: "Main: (102)",
      currentStock: 102,
      purchasePrice: 8500.0,
      sellingPrice: 8500.0,
      tax: 8,
      status: {
        label: "Pending",
        icon: "On Hold",
      },
      category: "Electronics",
      brand: "Samsung",
      minimumStock: 10,
      maximumStock: 200,
    },
    {
      id: "4",
      image: "Item1",
      itemCode: "test_item",
      itemName: "uykykk",
      barcode: "000423456789",
      type: "Miscellaneous",
      warehouseName: "Main: (94)",
      currentStock: 94,
      purchasePrice: 6500.0,
      sellingPrice: 6500.0,
      tax: 5,
      status: {
        label: "Out of Stock",
        icon: "x",
      },
      category: "Unknown",
      brand: "N/A",
      minimumStock: 10,
      maximumStock: 100,
    },
  ];

  return (
    <ProTable
      columns={columns}
      actionRef={actionRef}
      dataSource={data}
      rowKey="key"
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        onShowSizeChange: (current, size) => setPageSize(size),
      }}
      search={false}
      scroll={{ x: "max-content" }}
      style={{ width: "100%" }}
      toolBarRender={() => [
        <Space size="middle" wrap key="filters">
          <Select
            placeholder={lang.get("items", ["Select Warehouse"])}
            style={{ width: 150 }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              const optionText = option?.children as unknown as string;
              return optionText?.toLowerCase().includes(input.toLowerCase());
            }}
          >
            <Option value="main">{lang.get("warehouses", ["Main"])}</Option>
            <Option value="warehouse-receive-return-order">
              {lang.get("warehouses", ["Warehouse Receive Return Order"])}
            </Option>
          </Select>
          <Select
            placeholder={lang.get("items", ["Commodity"])}
            style={{ width: 200 }}
            showSearch
            mode="multiple"
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) => {
              const optionText = option?.children as unknown as string;
              return optionText?.toLowerCase().includes(input.toLowerCase());
            }}
          >
            <Option value="commodity1">(6,500.00) blous 1 (10)</Option>
            <Option value="commodity2">(8,500.00) Samsung J 7 (102)</Option>
            <Option value="commodity3">(0.00) Truecam Battery (10)</Option>
            <Option value="commodity4">(6,500.00) uykikk (94)</Option>
            <Option value="commodity5">fsdbgdsgf</Option>
          </Select>

          <Select
            placeholder={lang.get("items", ["Tags"])}
            style={{ width: 200 }}
            showSearch
            mode="tags"
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) => {
              const optionText = option?.children as unknown as string;
              return optionText?.toLowerCase().includes(input.toLowerCase());
            }}
          >
            <Option value="tag1">Electronics</Option>
            <Option value="tag2">Clothing</Option>
            <Option value="tag3">Batteries</Option>
            <Option value="tag4">Miscellaneous</Option>
          </Select>

          <Select
            placeholder={lang.get("items", ["Alert"])}
            style={{ width: 150 }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              const optionText = option?.children as unknown as string;
              return optionText?.toLowerCase().includes(input.toLowerCase());
            }}
          >
            <Option value="minimum-stock">
              {lang.get("alerts", ["Minimum stock"])}
            </Option>
            <Option value="maximum-stock">
              {lang.get("alerts", ["Maximum stock"])}
            </Option>
            <Option value="out-of-stock">
              {lang.get("alerts", ["Out of stock"])}
            </Option>
            <Option value="expiration">
              {lang.get("alerts", ["1 Month Before expiration and expired"])}
            </Option>
          </Select>

          <Select
            placeholder={lang.get("items", ["Can be Inventory"])}
            style={{ width: 300 }}
            mode="multiple"
            showSearch
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) => {
              const optionText = option?.children as unknown as string;
              return optionText?.toLowerCase().includes(input.toLowerCase());
            }}
          >
            <Option value="can-be-sold">
              {lang.get("inventory", ["Can be sold"])}
            </Option>
            <Option value="can-be-purchased">
              {lang.get("inventory", ["Can be purchased"])}
            </Option>
            <Option value="can-be-manufacturing">
              {lang.get("inventory", ["Can be manufacturing"])}
            </Option>
            <Option value="can-be-inventory">
              {lang.get("inventory", ["Can be Inventory"])}
            </Option>
          </Select>
        </Space>,
        <Input
          key="global_search"
          placeholder="Global Search"
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef?.current?.reload();
          }}
          style={{ width: 200 }}
        />,

        <Button
          key="csv"
          onClick={() => {
            const csvData = data.map((row) => ({
              [lang.get("items", ["ID"])]: row.id,
              [lang.get("items", ["Image"])]: row.image,
              [lang.get("items", ["Item Code"])]: row.itemCode,
              [lang.get("items", ["Item Name"])]: row.itemName,
              [lang.get("items", ["Barcode"])]: row.barcode,
              [lang.get("items", ["Type"])]: row.type,
              [lang.get("items", ["Warehouse Name"])]: row.warehouseName,
              [lang.get("items", ["Current Stock"])]: row.currentStock,
              [lang.get("items", ["Purchase Price"])]: row.purchasePrice,
              [lang.get("items", ["Selling Price"])]: row.sellingPrice,
              [lang.get("items", ["Tax"])]: row.tax,
              [lang.get("items", ["Status"])]: row.status.label,
              [lang.get("items", ["Category"])]: row.category,
              [lang.get("items", ["Brand"])]: row.brand,
              [lang.get("items", ["Minimum Stock"])]: row.minimumStock,
              [lang.get("items", ["Maximum Stock"])]: row.maximumStock,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("items", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = data.map((row) => ({
              [lang.get("items", ["ID"])]: row.id,
              [lang.get("items", ["Image"])]: row.image,
              [lang.get("items", ["Item Code"])]: row.itemCode,
              [lang.get("items", ["Item Name"])]: row.itemName,
              [lang.get("items", ["Barcode"])]: row.barcode,
              [lang.get("items", ["Type"])]: row.type,
              [lang.get("items", ["Warehouse Name"])]: row.warehouseName,
              [lang.get("items", ["Current Stock"])]: row.currentStock,
              [lang.get("items", ["Purchase Price"])]: row.purchasePrice,
              [lang.get("items", ["Selling Price"])]: row.sellingPrice,
              [lang.get("items", ["Tax"])]: row.tax,
              [lang.get("items", ["Status"])]: row.status.label,
              [lang.get("items", ["Category"])]: row.category,
              [lang.get("items", ["Brand"])]: row.brand,
              [lang.get("items", ["Minimum Stock"])]: row.minimumStock,
              [lang.get("items", ["Maximum Stock"])]: row.maximumStock,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("items", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("items", ["ID"]),
                  lang.get("items", ["Image"]),
                  lang.get("items", ["Item Code"]),
                  lang.get("items", ["Item Name"]),
                  lang.get("items", ["Barcode"]),
                  lang.get("items", ["Type"]),
                  lang.get("items", ["Warehouse Name"]),
                  lang.get("items", ["Current Stock"]),
                  lang.get("items", ["Purchase Price"]),
                  lang.get("items", ["Selling Price"]),
                  lang.get("items", ["Tax"]),
                  lang.get("items", ["Status"]),
                  lang.get("items", ["Category"]),
                  lang.get("items", ["Brand"]),
                  lang.get("items", ["Minimum Stock"]),
                  lang.get("items", ["Maximum Stock"]),
                ],
              ],
              body: data.map((row) => [
                row.id,
                row.image,
                row.itemCode,
                row.itemName,
                row.barcode,
                row.type,
                row.warehouseName,
                row.currentStock,
                row.purchasePrice,
                row.sellingPrice,
                row.tax,
                row.status.label,
                row.category,
                row.brand,
                row.minimumStock,
                row.maximumStock,
              ]),
            });
          }}
        >
          {lang.get("items", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default ItemListTable;
