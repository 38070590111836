import React, { useState, useRef } from "react";
import { Input, Button, Space } from "antd";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import { SearchOutlined } from "@ant-design/icons";
import lang from "../../../../../lang";
import Export from "../../../../../common/components/Export";

const SerialNumberTrackingTable: React.FC = () => {
  const [pageSize, setPageSize] = useState(15);
  const searchInput = useRef(null);
  const actionRef = useRef<ActionType>();
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const [allSearch, setAllSearch] = useState<string>("");
  const expobj = new Export(
    lang.get("serialNumbers", ["Serial Number Tracking"]) +
      ", " +
      new Date().toDateString()
  );

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("serialNumbers", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("serialNumbers", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("serialNumbers", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns[] = [
    {
      title: "Serial Numbers",
      dataIndex: "serialNumbers",
      key: "serialNumbers",
      sorter: true,
      ...searchCols("serialNumbers", "Serial Numbers"),
      render: (text) => text,
    },
    {
      title: "Serial Status",
      dataIndex: "serialStatus",
      key: "serialStatus",
      sorter: true,
      ...searchCols("serialStatus", "Serial Status"),
    },
    {
      title: "Transaction",
      dataIndex: "transaction",
      key: "transaction",
      sorter: true,
      ...searchCols("transaction", "Transaction"),
    },
    {
      title: "Trans Ref No",
      dataIndex: "transRefNo",
      key: "transRefNo",
      sorter: true,
      ...searchCols("transRefNo", " Transaction Reference No"),
    },
    {
      title: "Customer/Vendor",
      dataIndex: "customerVendor",
      key: "customerVendor",
      sorter: true,
      ...searchCols("customerVendor", "Customer/Vendor"),
    },
    {
      title: "Trans Date",
      dataIndex: "transDate",
      key: "transDate",
      sorter: true,
      ...searchCols("transDate", "Transaction Date"),
    },
    {
      title: "Warranty Period",
      dataIndex: "warrantyPeriod",
      key: "warrantyPeriod",
      sorter: true,
      ...searchCols("warrantyPeriod", "Warranty Period"),
    },
    {
      title: "Inventory Name",
      dataIndex: "inventoryName",
      key: "inventoryName",
      sorter: true,
      ...searchCols("inventoryName", "Inventory Name"),
    },
  ];

  const dataSource = [
    {
      key: "1",
      serialNumbers: "SN-12345",
      serialStatus: "Available",
      transaction: "Inventory Receiving Note",
      transRefNo: "TR-001",
      customerVendor: "Vendor A",
      transDate: "2024-08-10",
      warrantyPeriod: "12 Months",
      inventoryName: "Battery",
    },
    {
      key: "2",
      serialNumbers: "SN-67890",
      serialStatus: "Sold",
      transaction: "Sales Invoice",
      transRefNo: "SI-002",
      customerVendor: "Customer B",
      transDate: "2024-08-15",
      warrantyPeriod: "24 Months",
      inventoryName: "Power Supply",
    },
  ];

  return (
    <div style={{ background: "#f5f7fa" }}>
      <ProTable
        headerTitle="Serial Number Tracking"
        columns={columns}
        actionRef={actionRef}
        dataSource={dataSource}
        rowKey="key"
        cardBordered
        pagination={{
          defaultCurrent: 1,
          pageSize: pageSize,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50],
          showTotal: (total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total} items`,
          onShowSizeChange: (current, size) => {
            setPageSize(size);
          },
        }}
        search={false}
        scroll={{ x: 800 }}
        toolBarRender={() => [
          <Input
            style={{ width: "auto" }}
            key="all_search"
            allowClear
            placeholder={lang.get("serialNumbers", ["Global Search"])}
            value={allSearch}
            onChange={(e) => {
              setAllSearch(e.target.value);
              setSearchAttributes([]);
              actionRef?.current?.reload();
            }}
          />,
          <Button
            key="csv"
            onClick={() => {
              const csvData = dataSource.map((row) => ({
                "Serial Numbers": row.serialNumbers,
                "Serial Status": row.serialStatus,
                Transaction: row.transaction,
                "Trans Ref No": row.transRefNo,
                "Customer/Vendor": row.customerVendor,
                "Trans Date": row.transDate,
                "Warranty Period": row.warrantyPeriod,
                "Inventory Name": row.inventoryName,
              }));
              expobj.exportToCSV(csvData);
            }}
          >
            Export CSV
          </Button>,
          <Button
            key="excel"
            onClick={() => {
              const excelData = dataSource.map((row) => ({
                "Serial Numbers": row.serialNumbers,
                "Serial Status": row.serialStatus,
                Transaction: row.transaction,
                "Trans Ref No": row.transRefNo,
                "Customer/Vendor": row.customerVendor,
                "Trans Date": row.transDate,
                "Warranty Period": row.warrantyPeriod,
                "Inventory Name": row.inventoryName,
              }));
              expobj.exportToExcel(excelData);
            }}
          >
            Export Excel
          </Button>,
          <Button
            key="pdf"
            onClick={() => {
              expobj.exportToPDF({
                head: [
                  [
                    "Serial Numbers",
                    "Serial Status",
                    "Transaction",
                    "Trans Ref No",
                    "Customer/Vendor",
                    "Trans Date",
                    "Warranty Period",
                    "Inventory Name",
                  ],
                ],
                body: dataSource.map((row) => [
                  row.serialNumbers,
                  row.serialStatus,
                  row.transaction,
                  row.transRefNo,
                  row.customerVendor,
                  row.transDate,
                  row.warrantyPeriod,
                  row.inventoryName,
                ]),
              });
            }}
          >
            Export PDF
          </Button>,
        ]}
      />
    </div>
  );
};

export default SerialNumberTrackingTable;
