import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Tabs,
  Form,
  Input,
  Button,
  Select,
  Typography,
  Divider,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import lang from "../../../../lang"; // Adjust the import path as needed

const { TabPane } = Tabs;
const { Title } = Typography;

const VendorAddBody: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("vendorDetails");

  const handleSave = () => {
    console.log("Vendor data saved.");
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <Title level={2} className="mb-4">
        {lang.get("purchase", ["Add Vendor"])}
      </Title>
      <Card>
        <Tabs
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key)}
          tabBarStyle={{ marginBottom: 24, backgroundColor: "#f5f7fa" }}
        >
          {/* Vendor Details Tab */}
          <TabPane
            tab={
              <div>
                <InfoCircleOutlined /> {lang.get("purchase", ["Vendor Details"])}
              </div>
            }
            key="vendorDetails"
          >
            <Form layout="vertical">
              <Row >
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Vendor Code"])}
                      </span>
                    }
                    name="vendorCode"
                    rules={[
                      { required: true, message: lang.get("purchase", ["Required"]) },
                    ]}
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Vendor Code"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Company"])}
                      </span>
                    }
                    name="company"
                    rules={[
                      { required: true, message: lang.get("purchase", ["Required"]) },
                    ]}
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Company Name"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["VAT Number"])}
                      </span>
                    }
                    name="vatNumber"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter VAT Number"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Phone"])}
                      </span>
                    }
                    name="phone"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Phone Number"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Website"])}
                      </span>
                    }
                    name="website"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Website URL"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Vendor Category"])}
                      </span>
                    }
                    name="vendorCategory"
                  >
                    <Select
                      placeholder={lang.get("purchase", ["Select Vendor Category"])}
                      size="large"
                    >
                      <Select.Option value="category1">
                        {lang.get("purchase", ["Category 1"])}
                      </Select.Option>
                      <Select.Option value="category2">
                        {lang.get("purchase", ["Category 2"])}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Currency"])}
                      </span>
                    }
                    name="currency"
                  >
                    <Select
                      placeholder={lang.get("purchase", ["System Default"])}
                      size="large"
                    >
                      <Select.Option value="LKR">{lang.get("purchase", ["LKR"])}</Select.Option>
                      <Select.Option value="USD">{lang.get("purchase", ["USD"])}</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Default Language"])}
                      </span>
                    }
                    name="defaultLanguage"
                  >
                    <Select
                      placeholder={lang.get("purchase", ["System Default"])}
                      size="large"
                    >
                      <Select.Option value="en">
                        {lang.get("purchase", ["English"])}
                      </Select.Option>
                      <Select.Option value="si">
                        {lang.get("purchase", ["Sinhala"])}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Address"])}
                      </span>
                    }
                    name="address"
                  >
                    <Input.TextArea
                      rows={3}
                      placeholder={lang.get("purchase", ["Enter Address"])}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["City"])}
                      </span>
                    }
                    name="city"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter City"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["State"])}
                      </span>
                    }
                    name="state"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter State"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Zip Code"])}
                      </span>
                    }
                    name="zipCode"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Zip Code"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Country"])}
                      </span>
                    }
                    name="country"
                  >
                    <Select
                      placeholder={lang.get("purchase", ["Select Country"])}
                      size="large"
                    >
                      <Select.Option value="Sri Lanka">
                        {lang.get("purchase", ["Sri Lanka"])}
                      </Select.Option>
                      <Select.Option value="USA">
                        {lang.get("purchase", ["USA"])}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Bank Details"])}
                      </span>
                    }
                    name="bankDetails"
                  >
                    <Input.TextArea
                      rows={3}
                      placeholder={lang.get("purchase", ["Enter Bank Details"])}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Payment Terms"])}
                      </span>
                    }
                    name="paymentTerms"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Payment Terms"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </TabPane>

          {/* Billing & Shipping Tab */}
          <TabPane tab={lang.get("purchase", ["Billing & Shipping"])} key="billingShipping">
            <Form layout="vertical">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <Divider>{lang.get("purchase", ["Billing Address"])}</Divider>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Street"])}
                      </span>
                    }
                    name="billingStreet"
                  >
                    <Input.TextArea
                      rows={3}
                      placeholder={lang.get("purchase", ["Enter Street"])}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["City"])}
                      </span>
                    }
                    name="billingCity"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter City"])}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["State"])}
                      </span>
                    }
                    name="billingState"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter State"])}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Zip Code"])}
                      </span>
                    }
                    name="billingZipCode"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Zip Code"])}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Country"])}
                      </span>
                    }
                    name="billingCountry"
                  >
                    <Select
                      placeholder={lang.get("purchase", ["Select Country"])}
                      size="large"
                    >
                      <Select.Option value="Sri Lanka">
                        {lang.get("purchase", ["Sri Lanka"])}
                      </Select.Option>
                      <Select.Option value="USA">
                        {lang.get("purchase", ["USA"])}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12}>
                  <Divider>{lang.get("purchase", ["Shipping Address"])}</Divider>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Street"])}
                      </span>
                    }
                    name="shippingStreet"
                  >
                    <Input.TextArea
                      rows={3}
                      placeholder={lang.get("purchase", ["Enter Street"])}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["City"])}
                      </span>
                    }
                    name="shippingCity"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter City"])}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["State"])}
                      </span>
                    }
                    name="shippingState"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter State"])}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Zip Code"])}
                      </span>
                    }
                    name="shippingZipCode"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Zip Code"])}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Country"])}
                      </span>
                    }
                    name="shippingCountry"
                  >
                    <Select
                      placeholder={lang.get("purchase", ["Select Country"])}
                      size="large"
                    >
                      <Select.Option value="Sri Lanka">
                        {lang.get("purchase", ["Sri Lanka"])}
                      </Select.Option>
                      <Select.Option value="USA">
                        {lang.get("purchase", ["USA"])}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </TabPane>

          {/* Return Policies Tab */}
          <TabPane tab={lang.get("purchase", ["Return Policies"])} key="returnPolicies">
            <Form layout="vertical">
              <Row gutter={[16, 5]}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Return Request Days"])}
                      </span>
                    }
                    name="returnRequestDays"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Days"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Fee for Return Order"])}
                      </span>
                    }
                    name="returnFee"
                  >
                    <Input
                      placeholder={lang.get("purchase", ["Enter Fee"])}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label={
                      <span style={{ fontSize: "14px" }}>
                        {lang.get("purchase", ["Return Policies Information"])}
                      </span>
                    }
                    name="returnPoliciesInfo"
                  >
                    <Input.TextArea
                      rows={3}
                      placeholder={lang.get("purchase", ["Enter Return Policies"])}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </TabPane>
        </Tabs>

        <Row justify="end" gutter={16} className="mt-4">
          <Col>
            <Button type="primary" onClick={handleSave}>
              {lang.get("purchase", ["Save"])}
            </Button>
          </Col>
          <Col>
            <Button type="default">{lang.get("purchase", ["Cancel"])}</Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default VendorAddBody;
