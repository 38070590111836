import React, { useState } from 'react';
import type { BadgeProps, CalendarProps } from 'antd';
import { Badge, Calendar, Modal, Form, Input, Button } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import lang from '../../../../../../lang'; 

const getListData = (value: Dayjs) => {
  let listData: { type: string; content: string }[] = [];
  switch (value.date()) {
    case 8:
      listData = [
        { type: 'warning', content: lang.get('calendar', ['Team meeting at 10 AM']) },
        { type: 'success', content: lang.get('calendar', ['Code review session']) },
      ];
      break;
    case 10:
      listData = [
        { type: 'error', content: lang.get('calendar', ['Server downtime expected']) },
        { type: 'success', content: lang.get('calendar', ['Client presentation at 3 PM']) },
      ];
      break;
    case 15:
      listData = [
        { type: 'warning', content: lang.get('calendar', ['Product release deadline']) },
        { type: 'error', content: lang.get('calendar', ['Critical bug fix required']) },
        { type: 'success', content: lang.get('calendar', ['Celebrate team achievements!']) },
      ];
      break;
    default:
  }
  return listData || [];
};

const getMonthData = (value: Dayjs) => {
  if (value.month() === 8) {
    return 1394;
  }
};

const CalendarFull: React.FC = () => {
  const [isEventModalVisible, setIsEventModalVisible] = useState(false);
  const [isAddEventModalVisible, setIsAddEventModalVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [events, setEvents] = useState<{ date: string; type: string; content: string }[]>([]);
  const [form] = Form.useForm();

  const handleDateSelect = (value: Dayjs) => {
    setSelectedDate(value);
    setIsEventModalVisible(true);
  };

  const handleAddEvent = () => {
    form.validateFields().then((values) => {
      setEvents((prevEvents) => [
        ...prevEvents,
        {
          date: selectedDate!.format('YYYY-MM-DD'),
          type: 'success',
          content: values.event,
        },
      ]);
      setIsAddEventModalVisible(false);
      form.resetFields();
    });
  };

  const dateCellRender = (value: Dayjs) => {
    const listData = getListData(value).concat(
      events.filter((event) => event.date === value.format('YYYY-MM-DD'))
    );
    return (
      <ul className="events">
        {listData.map((item, index) => (
          <li key={index}>
            <Badge status={item.type as BadgeProps['status']} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };

  const monthCellRender = (value: Dayjs) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>{lang.get('calendar', ['Backlog number'])}</span>
      </div>
    ) : null;
  };

  const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    if (info.type === 'month') return monthCellRender(current);
    return info.originNode;
  };

  return (
    <div style={{ marginTop:"20px" }}>
      <Button
        type="primary"
        onClick={() => setIsAddEventModalVisible(true)}
        style={{ marginBottom: '20px' }}
      >
        {lang.get('calendar', ['Add New Event'])}
      </Button>
      <Calendar cellRender={cellRender} onSelect={handleDateSelect} />
      <Modal
        title={lang.get('calendar', ['Events for the Selected Date'])}
        open={isEventModalVisible}
        onCancel={() => setIsEventModalVisible(false)}
        footer={null}
      >
        <ul>
          {getListData(selectedDate || dayjs()).map((event, index) => (
            <li key={index}>
              <Badge status={event.type as BadgeProps['status']} text={event.content} />
            </li>
          ))}
          {events
            .filter((event) => event.date === selectedDate?.format('YYYY-MM-DD'))
            .map((event, index) => (
              <li key={index}>
                <Badge status="success" text={event.content} />
              </li>
            ))}
        </ul>
      </Modal>
      <Modal
        title={lang.get('calendar', ['Add New Event'])}
        open={isAddEventModalVisible}
        onCancel={() => setIsAddEventModalVisible(false)}
        onOk={handleAddEvent}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="event"
            label={lang.get('calendar', ['Event Description'])}
            rules={[{ required: true, message: lang.get('calendar', ['Please input the event description!']) }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CalendarFull;