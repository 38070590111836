export interface Item {
    id: string;
    image: string;
    itemCode: string;
    itemName: string;
    barcode: string;
    type: string;
    warehouseName: string;
    currentStock: number;
    purchasePrice: number;
    sellingPrice: number;
    tax: number;
    status: {
      label: string;
      icon: string;
    };
    category: string;
    brand: string;
    minimumStock: number;
    maximumStock: number;
  }
  
  export const items: Item[] = [
    {
      id: "1",
      image: "Item1",
      itemCode: "01",
      itemName: "Truecam Battery",
      barcode: "000123456789",
      type: "Electronics",
      warehouseName: "Main: (10)",
      currentStock: 10,
      purchasePrice: 0.0,
      sellingPrice: 0.0,
      tax: 0,
      status: {
        label: "Available",
        icon: "check",
      },
      category: "Batteries",
      brand: "Truecam",
      minimumStock: 5,
      maximumStock: 20,
    },
    {
      id: "2",
      image: "Item1",
      itemCode: "1234",
      itemName: "Blous 1",
      barcode: "000223456789",
      type: "Clothing",
      warehouseName: "Main: (10)",
      currentStock: 10,
      purchasePrice: 6500.0,
      sellingPrice: 6500.0,
      tax: 8,
      status: {
        label: "Available",
        icon: "check",
      },
      category: "Apparel",
      brand: "Blous",
      minimumStock: 5,
      maximumStock: 50,
    },
    {
      id: "3",
      image: "Item1",
      itemCode: "J7",
      itemName: "Samsung J 7",
      barcode: "000323456789",
      type: "Mobile",
      warehouseName: "Main: (102)",
      currentStock: 102,
      purchasePrice: 8500.0,
      sellingPrice: 8500.0,
      tax: 8,
      status: {
        label: "Pending",
        icon: "On Hold",
      },
      category: "Electronics",
      brand: "Samsung",
      minimumStock: 10,
      maximumStock: 200,
    },
    {
      id: "4",
      image: "Item1",
      itemCode: "test_item",
      itemName: "uykykk",
      barcode: "000423456789",
      type: "Miscellaneous",
      warehouseName: "Main: (94)",
      currentStock: 94,
      purchasePrice: 6500.0,
      sellingPrice: 6500.0,
      tax: 5,
      status: {
        label: "Out of Stock",
        icon: "x",
      },
      category: "Unknown",
      brand: "N/A",
      minimumStock: 10,
      maximumStock: 100,
    },
  ];
  