import React, { useState, useRef } from "react";
import { Button, Input, InputRef, Space, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ProTable, ProColumns, ActionType } from "@ant-design/pro-components";
import lang from "../../../../../../lang";
import Export from "../../../../../../common/components/Export";

interface File {
  id: string;
  filename: string;
  fileType: string;
  lastActivity: string;
  totalComments: number;
  visibleToCustomer: string;
  uploadedBy: string;
  dateUploaded: string;
}

const fileData: File[] = [
  {
    id: "1",
    filename: "ProjectRequirements.pdf",
    fileType: "PDF",
    lastActivity: "2024-12-01",
    totalComments: 5,
    visibleToCustomer: "Yes",
    uploadedBy: "John Doe",
    dateUploaded: "2024-11-30",
  },
];

const FilesTable: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(6);
  const searchInput = useRef<InputRef>(null);
  const actionRef = useRef<ActionType>();
  const [allSearch, setAllSearch] = useState<string>("");
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);

  const expobj = new Export(lang.get("files", ["Files"]) + ", " + new Date().toDateString());

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find((attr) => attr.key === field);

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([...searchAttributes, { key: field, value }]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("files", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={searchAttributes.find((item) => item.key === field)?.value}
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  actionRef.current?.reload();
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => actionRef.current?.reload()}
                style={{ width: 90 }}
              >
                {lang.get("files", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("files", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<File>[] = [
    {
      title: lang.get("files", ["Filename"]),
      dataIndex: "filename",
      key: "filename",
      sorter: true,
      ...searchCols("filename", lang.get("files", ["Filename"])),
    },
    {
      title: lang.get("files", ["File Type"]),
      dataIndex: "fileType",
      key: "fileType",
      sorter: true,
      ...searchCols("fileType", lang.get("files", ["File Type"])),
    },
    {
      title: lang.get("files", ["Last Activity"]),
      dataIndex: "lastActivity",
      key: "lastActivity",
      sorter: true,
      ...searchCols("lastActivity", lang.get("files", ["Last Activity"])),
    },
    {
      title: lang.get("files", ["Total Comments"]),
      dataIndex: "totalComments",
      key: "totalComments",
      sorter: true,
      render: (_, record: File) => record.totalComments,
    },
    {
      title: lang.get("files", ["Visible to Customer"]),
      dataIndex: "visibleToCustomer",
      key: "visibleToCustomer",
      sorter: true,
      ...searchCols("visibleToCustomer", lang.get("files", ["Visible to Customer"])),
      render: (_, record: File) => (
        <Tag color={record.visibleToCustomer === "Yes" ? "green" : "red"}>
          {lang.get("files", [record.visibleToCustomer])}
        </Tag>
      ),
    },
    {
      title: lang.get("files", ["Uploaded By"]),
      dataIndex: "uploadedBy",
      key: "uploadedBy",
      sorter: true,
      ...searchCols("uploadedBy", lang.get("files", ["Uploaded By"])),
    },
    {
      title: lang.get("files", ["Date Uploaded"]),
      dataIndex: "dateUploaded",
      key: "dateUploaded",
      sorter: true,
      ...searchCols("dateUploaded", lang.get("files", ["Date Uploaded"])),
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("files", ["Files"])}
      columns={columns}
      dataSource={fileData}
      actionRef={actionRef}
      rowKey="id"
      pagination={{
        defaultCurrent: 1,
        pageSize,
        showSizeChanger: true,
        pageSizeOptions: [6, 10, 20],
        showTotal: (total, range) =>
          lang.get("files", [`Showing ${range[0]}-${range[1]} of ${total} items`]),
        onShowSizeChange: (_, size) => setPageSize(size),
      }}
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("files", ["Global Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef.current?.reload();
          }}
        />,
        <Button
          key="csv"
          onClick={() => {
            const csvData = fileData.map((row) => ({
              [lang.get("files", ["Filename"])]: row.filename,
              [lang.get("files", ["File Type"])]: row.fileType,
              [lang.get("files", ["Last Activity"])]: row.lastActivity,
              [lang.get("files", ["Total Comments"])]: row.totalComments,
              [lang.get("files", ["Visible to Customer"])]: row.visibleToCustomer,
              [lang.get("files", ["Uploaded By"])]: row.uploadedBy,
              [lang.get("files", ["Date Uploaded"])]: row.dateUploaded,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("files", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = fileData.map((row) => ({
              [lang.get("files", ["Filename"])]: row.filename,
              [lang.get("files", ["File Type"])]: row.fileType,
              [lang.get("files", ["Last Activity"])]: row.lastActivity,
              [lang.get("files", ["Total Comments"])]: row.totalComments,
              [lang.get("files", ["Visible to Customer"])]: row.visibleToCustomer,
              [lang.get("files", ["Uploaded By"])]: row.uploadedBy,
              [lang.get("files", ["Date Uploaded"])]: row.dateUploaded,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("files", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("files", ["Filename"]),
                  lang.get("files", ["File Type"]),
                  lang.get("files", ["Last Activity"]),
                  lang.get("files", ["Total Comments"]),
                  lang.get("files", ["Visible to Customer"]),
                  lang.get("files", ["Uploaded By"]),
                  lang.get("files", ["Date Uploaded"]),
                ],
              ],
              body: fileData.map((row) => [
                row.filename,
                row.fileType,
                row.lastActivity,
                row.totalComments,
                row.visibleToCustomer,
                row.uploadedBy,
                row.dateUploaded,
              ]),
            });
          }}
        >
          {lang.get("files", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default FilesTable;