import { useEffect } from "react";
import SubscriptionTable from "./SubscriptionTable";
// import ProposalsTable from "./ProposalsTable";
const Subscription = () => {
  useEffect(() => {}, []);
  return (
    <div>
     <SubscriptionTable />
    </div>
  );
};

export default Subscription;
