import React, { useState, useRef } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  InputRef,
  Menu,
  Row,
  Select,
  Space,
  Tag,
} from "antd";
import {
  SearchOutlined,
  FullscreenOutlined,
  EyeOutlined,
  MailOutlined,
  CloseCircleOutlined,
  EditOutlined,
  SendOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { ProTable, ProColumns, ActionType } from "@ant-design/pro-components";
import lang from "../../../../lang";
import Export from "../../../../common/components/Export";
import TinyMCEEditor from "../../../../common/base/Editor";

const { Option } = Select;
const { RangePicker } = DatePicker;

interface Proposal {
  id: string;
  subject: string;
  to: string;
  total: string;
  date: string;
  openTill: string;
  project: string;
  tags: string[];
  dateCreated: string;
  status: string;
  items?: {
    description: string;
    qty: number;
    rate: number;
    amount: number;
  }[];
}

const proposalData: Proposal[] = [
  {
    id: "P-001",
    subject: "Proposal for Web Development",
    to: "John Doe",
    total: "$5,000",
    date: "2024-12-10",
    openTill: "2024-12-31",
    project: "Website Revamp",
    tags: ["Urgent", "Priority"],
    dateCreated: "2024-12-01",
    status: "Open",
    items: [
      {
        description: "Web Development",
        qty: 1,
        rate: 5000,
        amount: 5000,
      },
    ],
  },
];

const ProposalsTable: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(15);
  const searchInput = useRef<InputRef>(null);
  const actionRef = useRef<ActionType>();
  const [activeTab, setActiveTab] = useState<string>("proposal");
  const expobj = new Export(
    lang.get("sales", ["Proposals"]) + ", " + new Date().toDateString()
  );

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;
        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("sales", [`Search ${label}`])}
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  actionRef.current?.reload();
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => actionRef.current?.reload()}
                style={{ width: 90 }}
              >
                {lang.get("sales", ["Search"])}
              </Button>
              <Button onClick={close} size="small" style={{ width: 90 }}>
                {lang.get("sales", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const [ExpandedRecord, setExpandedRecord] = useState<Proposal | null>(null);

  const columns: ProColumns<Proposal>[] = [
    {
      title: lang.get("sales", ["Proposal #"]),
      dataIndex: "id",
      key: "id",
      sorter: true,
      ...searchCols("id", lang.get("sales", ["Proposal #"])),
    },
    {
      title: lang.get("sales", ["Subject"]),
      dataIndex: "subject",
      key: "subject",
      sorter: true,
      ...searchCols("subject", lang.get("sales", ["Subject"])),
    },
    {
      title: lang.get("sales", ["To"]),
      dataIndex: "to",
      key: "to",
      sorter: true,
      ...searchCols("to", lang.get("sales", ["To"])),
    },
    {
      title: lang.get("sales", ["Total"]),
      dataIndex: "total",
      key: "total",
      sorter: true,
      ...searchCols("total", lang.get("sales", ["Total"])),
    },
    {
      title: lang.get("sales", ["Date"]),
      dataIndex: "date",
      key: "date",
      sorter: true,
      ...searchCols("date", lang.get("sales", ["Date"])),
    },
    {
      title: lang.get("sales", ["Open Till"]),
      dataIndex: "openTill",
      key: "openTill",
      sorter: true,
      ...searchCols("openTill", lang.get("sales", ["Open Till"])),
    },
    {
      title: lang.get("sales", ["Project"]),
      dataIndex: "project",
      key: "project",
      sorter: true,
      ...searchCols("project", lang.get("sales", ["Project"])),
    },
    {
      title: lang.get("sales", ["Tags"]),
      dataIndex: "tags",
      key: "tags",
      render: (_, record: Proposal) => (
        <>
          {record.tags.map((tag) => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </>
      ),
    },
    {
      title: lang.get("sales", ["Date Created"]),
      dataIndex: "dateCreated",
      key: "dateCreated",
      sorter: true,
      ...searchCols("dateCreated", lang.get("sales", ["Date Created"])),
    },
    {
      title: lang.get("sales", ["Status"]),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (_, record: Proposal) => (
        <Tag color={record.status === "Open" ? "green" : "red"}>
          {lang.get("sales", [record.status])}
        </Tag>
      ),
    },
  ];

  function handleTabChange(key: string): void {
    setActiveTab(key);
  }

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} md={ExpandedRecord ? 11 : 24}>
        <ProTable
          headerTitle={lang.get("sales", ["Proposals"])}
          columns={columns}
          dataSource={proposalData}
          actionRef={actionRef}
          rowKey="id"
          onRow={(record) => {
            return {
              onClick: () => {
                setExpandedRecord(record);
              },
            };
          }}
          style={{ cursor: "pointer" }}
          pagination={{
            defaultCurrent: 1,
            pageSize: pageSize,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 50],
            showTotal: (total, range) =>
              lang.get("sales", [
                `Showing ${range[0]}-${range[1]} of ${total} items`,
              ]),
            onShowSizeChange: (current, size) => {
              setPageSize(size);
            },
          }}
          search={false}
          scroll={{ x: 800 }}
          toolBarRender={() => [
            <RangePicker key="date" />,

            <Select
              placeholder={lang.get("sales", ["Status"])}
              style={{ width: 200 }}
            >
              <Option value="Draft">{lang.get("sales", ["Draft"])}</Option>
              <Option value="Sent">{lang.get("sales", ["Sent"])}</Option>
              <Option value="Open">{lang.get("sales", ["Open"])}</Option>
              <Option value="Revised">{lang.get("sales", ["Revised"])}</Option>
              <Option value="Declined">
                {lang.get("sales", ["Declined"])}
              </Option>
              <Option value="Accepted">
                {lang.get("sales", ["Accepted"])}
              </Option>
              <Option value="Expired">{lang.get("sales", ["Expired"])}</Option>
            </Select>,
            <Button
              key="csv"
              onClick={() => {
                const csvData = proposalData.map((row) => ({
                  [lang.get("sales", ["Proposal #"])]: row.id,
                  [lang.get("sales", ["Subject"])]: row.subject,
                  [lang.get("sales", ["To"])]: row.to,
                  [lang.get("sales", ["Total"])]: row.total,
                  [lang.get("sales", ["Date"])]: row.date,
                  [lang.get("sales", ["Open Till"])]: row.openTill,
                  [lang.get("sales", ["Project"])]: row.project,
                  [lang.get("sales", ["Tags"])]: row.tags.join(", "),
                  [lang.get("sales", ["Date Created"])]: row.dateCreated,
                  [lang.get("sales", ["Status"])]: row.status,
                }));
                expobj.exportToCSV(csvData);
              }}
            >
              {lang.get("sales", ["Export CSV"])}
            </Button>,
            <Button
              key="excel"
              onClick={() => {
                const excelData = proposalData.map((row) => ({
                  [lang.get("sales", ["Proposal #"])]: row.id,
                  [lang.get("sales", ["Subject"])]: row.subject,
                  [lang.get("sales", ["To"])]: row.to,
                  [lang.get("sales", ["Total"])]: row.total,
                  [lang.get("sales", ["Date"])]: row.date,
                  [lang.get("sales", ["Open Till"])]: row.openTill,
                  [lang.get("sales", ["Project"])]: row.project,
                  [lang.get("sales", ["Tags"])]: row.tags.join(", "),
                  [lang.get("sales", ["Date Created"])]: row.dateCreated,
                  [lang.get("sales", ["Status"])]: row.status,
                }));
                expobj.exportToExcel(excelData);
              }}
            >
              {lang.get("sales", ["Export Excel"])}
            </Button>,
            <Button
              key="pdf"
              onClick={() => {
                expobj.exportToPDF({
                  head: [
                    [
                      lang.get("sales", ["Proposal #"]),
                      lang.get("sales", ["Subject"]),
                      lang.get("sales", ["To"]),
                      lang.get("sales", ["Total"]),
                      lang.get("sales", ["Date"]),
                      lang.get("sales", ["Open Till"]),
                      lang.get("sales", ["Project"]),
                      lang.get("sales", ["Tags"]),
                      lang.get("sales", ["Date Created"]),
                      lang.get("sales", ["Status"]),
                    ],
                  ],
                  body: proposalData.map((row) => [
                    row.id,
                    row.subject,
                    row.to,
                    row.total,
                    row.date,
                    row.openTill,
                    row.project,
                    row.tags.join(", "),
                    row.dateCreated,
                    row.status,
                  ]),
                });
              }}
            >
              {lang.get("sales", ["Export PDF"])}
            </Button>,
          ]}
        />
      </Col>
      {ExpandedRecord && (
        <Col xs={24} md={13}>
          <Card
            title={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ display: "flex", gap: "12px", alignItems: "center" }}
                >
                  <Button type="text" icon={<MailOutlined />} />
                  <Button type="text" icon={<EyeOutlined />} />
                  <Button type="text" icon={<FullscreenOutlined />} />
                </div>
                {/* Action Buttons */}
                <div
                  style={{ display: "flex", gap: "8px", marginRight: "12px" }}
                >
                  <Button type="text" size="small" icon={<EditOutlined />} />
                  <Button type="text" size="small" icon={<DeleteOutlined />} />
                  <Button type="primary" size="small">
                    <span style={{ fontSize: "10px" }}> Proposal</span>
                  </Button>
                </div>
              </div>
            }
            bordered
            style={{
              border: "1px solid #1677ff",
              overflow: "auto",
              height: "100%",
            }}
            extra={
              <span
                style={{
                  cursor: "pointer",
                  color: "#e9554a",
                }}
                onClick={() => setExpandedRecord(null)}
              >
                <CloseCircleOutlined style={{ fontSize: "16px" }} />
              </span>
            }
          >
            <Menu
              mode="horizontal"
              defaultSelectedKeys={["proposal"]}
              selectedKeys={[activeTab]}
              onClick={({ key }) => handleTabChange(key)}
              style={{
                marginBottom: "20px",
                borderBottom: "1px solid #e3e3e3",
              }}
              items={[
                { key: "proposal", label: "Proposal" },
                { key: "comments", label: "Comments" },
                { key: "reminders", label: "Reminders" },
                { key: "tasks", label: "Tasks" },
                { key: "notes", label: "Notes" },
                { key: "templates", label: "Templates" },
              ]}
            />

            {activeTab === "proposal" && (
              <div>
                {/* Top Row with Action Buttons */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <div>
                    {ExpandedRecord.status === "Open" ? (
                      <Tag color="green">{ExpandedRecord.status}</Tag>
                    ) : ExpandedRecord.status === "Pending" ? (
                      <Tag color="blue">{ExpandedRecord.status}</Tag>
                    ) : (
                      <Tag color="red">{ExpandedRecord.status}</Tag>
                    )}
                  </div>
                  <div style={{ display: "flex", gap: "8px" }}>
                    <Button icon={<EditOutlined />} />
                    <Button icon={<SendOutlined />} />
                    <Button icon={<MailOutlined />} />
                    <Button>More</Button>
                    <Button
                      type="primary"
                      style={{
                        backgroundColor: "#52c41a", 
                        borderColor: "#52c41a", 
                      }}
                    >
                      Convert 
                    </Button>
                  </div>
                </div>

                {/* Existing Details Row */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    marginBottom: "20px",
                  }}
                >
                  <div style={{ flex: 1, marginRight: "20px" }}>
                    <p
                      style={{
                        fontWeight: "bold",
                        color: "#1677ff",
                        marginBottom: "8px",
                      }}
                    >
                      PRO-000001
                    </p>
                    <p style={{ fontWeight: "bold", marginBottom: "4px" }}>
                      Call Lanka Telecom
                    </p>
                    <p style={{ marginBottom: "4px" }}>10/6</p>
                    <p style={{ marginBottom: "4px" }}>Nawinna Colombo</p>
                    <p style={{ marginBottom: "4px" }}>1202</p>
                    <p style={{ marginBottom: "4px" }}>
                      VAT Number: Clt-120502352
                    </p>
                  </div>

                  <div style={{ flex: 1, textAlign: "right" }}>
                    <p>
                      <strong>To:</strong> {ExpandedRecord.to}
                    </p>
                    <p>tst2FSF@WE.LK</p>
                    <p>+94 71 123 4567</p>
                  </div>
                </div>

                {/* Content Section */}
                <div>
                  <TinyMCEEditor />
                </div>

                {/* Merge Fields */}
                <div style={{ textAlign: "right", color: "#1677ff" }}>
                  <span>{lang.get("sales", ["Available merge fields"])}</span>
                </div>
              </div>
            )}

            {activeTab === "comments" && <div>Comments Content</div>}
            {activeTab === "reminders" && <div>Reminders Content</div>}
            {activeTab === "tasks" && <div>Tasks Content</div>}
            {activeTab === "notes" && <div>Notes Content</div>}
            {activeTab === "templates" && <div>Templates Content</div>}
          </Card>
        </Col>
      )}
    </Row>
  );
};

export default ProposalsTable;
