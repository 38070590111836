import React, { useState, useRef, useEffect } from "react";
import type {
  ProColumns,
  EditableFormInstance,
  ActionType,
} from "@ant-design/pro-components";
import { EditableProTable, ProCard } from "@ant-design/pro-components";
import { Button, Input, Select, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;

export type InvoiceTableDataType = {
  id: React.Key;
  item?: string;
  description?: string;
  quantity?: number;
  rate?: number;
  discount?: string;
  tax?: string;
  amount?: number;
  warranty?: string;
};

export type SectionType = {
  name: string;
  items: InvoiceTableDataType[];
};

export const itemList = [
  {
    id: 1,
    item: "Item 1",
    description: "Description 1",
    rate: 100,
    discount: "10%",
    tax: "5%",
    warranty: "1 Year",
  },
  {
    id: 2,
    item: "Item 2",
    description: "Description 2",
    rate: 200,
    discount: "5%",
    tax: "10%",
    warranty: "6 Months",
  },
  {
    id: 3,
    item: "Item 3",
    description: "Description 3",
    rate: 150,
    discount: "15%",
    tax: "5%",
    warranty: "No Warranty",
  },
];

const defaultValues = {
  description: "N/A",
  quantity: 1,
  rate: 0.0,
  discount: "No Discount",
  tax: "No Tax",
  amount: 0.0,
  warranty: "No Warranty",
};

const AddInvoiceTable: React.FC = () => {
  
  const [tableData, setTableData] = useState<InvoiceTableDataType[]>([
    { id: 1, ...defaultValues },
  ]);
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([1]);
  const actionRef = useRef<ActionType>();
  const editableFormRef = useRef<EditableFormInstance>();

  const handleItemSelect = (record: InvoiceTableDataType, value: string) => {
    const selectedItem = itemList.find((item) => item.item === value);
    if (selectedItem && record) {
      const updatedRow = {
        ...record,
        item: selectedItem.item,
        description: selectedItem.description,
        rate: selectedItem.rate,
        discount: selectedItem.discount,
        tax: selectedItem.tax,
        warranty: selectedItem.warranty,
        amount: selectedItem.rate * (record.quantity || 1),
      };

      setTableData((prev) => {
        const updatedData = prev.map((row) =>
          row.id === record.id ? updatedRow : row
        );
        const isEmptyRowPresent = updatedData.some(
          (row) => !row.item && !row.description
        );
        if (!isEmptyRowPresent) {
          const newRow = { id: Date.now(), ...defaultValues };
          setEditableRowKeys((keys) => [...keys, newRow.id]);
          return [...updatedData, newRow];
        }
        return updatedData;
      });
    }
  };

  const deleteLastRow = () => {
    setTableData((prev) => {
      if (prev.length > 1) {
        const newData = prev.slice(0, -1);
        setEditableRowKeys((keys) =>
          keys.filter((key) => key !== prev[prev.length - 1].id)
        );
        return newData;
      } else {
     console.log("Cannot delete the last remaining row.");
        return prev;
      }
    });
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        deleteLastRow();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const columns: ProColumns<InvoiceTableDataType>[] = [
    {
      title: "Item Details",
      dataIndex: "item",
      align: "center",
      renderFormItem: (_, { record }) => (
        <Select
          showSearch
          placeholder="Search and select an item"
          value={record?.item}
          onChange={(value) =>
            handleItemSelect(record as InvoiceTableDataType, value)
          }
          style={{ width: "100%" }}
        >
          {itemList.map((item) => (
            <Option key={item.id} value={item.item}>
              {item.item}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      align: "center",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          value={record?.description}
          onChange={(e) => {
            const value = e.target.value;
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, description: value } : row
              )
            );
          }}
        />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      align: "center",
      valueType: "digit",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          type="number"
          value={record?.quantity}
          onChange={(e) => {
            const value = Number(e.target.value);
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id
                  ? { ...row, quantity: value, amount: (row.rate || 0) * value }
                  : row
              )
            );
          }}
        />
      ),
    },
    {
      title: "Rate",
      dataIndex: "rate",
      valueType: "money",
      align: "center",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          type="number"
          value={record?.rate}
          onChange={(e) => {
            const value = Number(e.target.value);
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id
                  ? { ...row, rate: value, amount: (row.quantity || 0) * value }
                  : row
              )
            );
          }}
        />
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      editable: false,
      render: (_, record) => <span>{(record.amount || 0).toFixed(2)}</span>,
      align: "center",
    },

    {
      title: "Tax",
      dataIndex: "tax",
      align: "center",
      renderFormItem: (_, { record, onChange }) => (
        <Select
          value={record?.tax}
          onChange={(value) => {
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, tax: value } : row
              )
            );
          }}
          placeholder="Select Tax"
        >
          <Option value="No Tax">No Tax</Option>
          <Option value="5%">5%</Option>
          <Option value="10%">10%</Option>
        </Select>
      ),
    },

    {
      title: "Discount",
      dataIndex: "discount",
      align: "center",
      renderFormItem: (_, { record, onChange }) => (
        <Select
          value={record?.discount}
          onChange={(value) => {
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, discount: value } : row
              )
            );
          }}
          placeholder="Select Discount"
        >
          <Option value="No Discount">No Discount</Option>
          <Option value="5%">5%</Option>
          <Option value="10%">10%</Option>
        </Select>
      ),
    },

    {
      title: "Warranty",
      align: "center",
      dataIndex: "warranty",
      renderFormItem: (_, { record, onChange }) => (
        <Select
          value={record?.warranty}
          onChange={(value) => {
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, warranty: value } : row
              )
            );
          }}
          placeholder="Select Warranty Period"
        >
          <Option value="No Warranty">No Warranty</Option>
          <Option value="6 Months">6 Months</Option>
          <Option value="1 Year">1 Year</Option>
          <Option value="2 Years">2 Years</Option>
        </Select>
      ),
    },
    {
      title: "Actions",
      align: "center",
      valueType: "option",
    },
  ];

  return (
    <ProCard
      style={{
        border: "1px solid #d9d9d9",
        borderRadius: "8px",
      }}
    >
      <EditableProTable<InvoiceTableDataType>
        rowKey="id"
        value={tableData}
        onChange={(value) => setTableData([...value] as InvoiceTableDataType[])}
        columns={columns}
        editable={{
          type: "multiple",
          editableKeys,
          onChange: setEditableRowKeys,
          actionRender: (row, _, defaultDoms) => [
            <button
              key="delete"
              type="button"
              style={{
                color: tableData.length === 1 ? "gray" : "red",
                border: "none",
                background: "none",
                cursor: tableData.length === 1 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (tableData.length > 1) {
                  setTableData((prev) =>
                    prev.filter((item) => item.id !== row.id)
                  );
                  setEditableRowKeys((prev) =>
                    prev.filter((key) => key !== row.id)
                  );
                } else {
                  message.warning("Cannot delete the last remaining row.");
                }
              }}
              disabled={tableData.length === 1}
            >
              <DeleteOutlined />
            </button>,
          ],
        }}
        recordCreatorProps={false}
        actionRef={actionRef}
        editableFormRef={editableFormRef}
        search={false}
        scroll={{ x: 800 }}
        toolBarRender={() => []}
        footer={() => (
          <div style={{ textAlign: "center" }}>
         <Button color="primary" variant="outlined" size="large" style={{width : "100%"}}>
              Add Section
            </Button>
          </div>
        )}
      />
    </ProCard>
  );
};

export default AddInvoiceTable; 