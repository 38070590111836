import React, { useState, useRef } from "react";
import { Input, Button, Space, DatePicker, Tag, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import lang from "../../../lang";
import Export from "../../../common/components/Export";

const { RangePicker } = DatePicker;

interface ReturnOrder {
  orderReturnNumber: string;
  customerName: string;
  totalAmount: number;
  discountTotal: number;
  totalAfterDiscount: number;
  dateCreated: string;
  type: string;
  status: string;
}

const ReturnOrdersTable: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedDates, setSelectedDates] = useState<[string, string] | null>(
    null
  );
  const actionRef = useRef<ActionType>();

  const handleDateChange = (dates: any, dateStrings: [string, string]) => {
    setSelectedDates(dateStrings);
  };

  const columns: ProColumns<ReturnOrder>[] = [
    {
      title: lang.get("returnOrders", ["Order Return Number"]),
      dataIndex: "orderReturnNumber",
      key: "orderReturnNumber",
      sorter: true,
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: lang.get("returnOrders", ["Customer Name"]),
      dataIndex: "customerName",
      key: "customerName",
      sorter: true,
    },
    {
      title: lang.get("returnOrders", ["Total Amount"]),
      dataIndex: "totalAmount",
      key: "totalAmount",
      sorter: true,
      align: "right",
    },
    {
      title: lang.get("returnOrders", ["Discount Total"]),
      dataIndex: "discountTotal",
      key: "discountTotal",
      sorter: true,
      align: "right",
    },
    {
      title: lang.get("returnOrders", ["Total After Discount"]),
      dataIndex: "totalAfterDiscount",
      key: "totalAfterDiscount",
      sorter: true,
      align: "right",
    },
    {
      title: lang.get("returnOrders", ["Date Created"]),
      dataIndex: "dateCreated",
      key: "dateCreated",
      sorter: true,
    },
    {
      title: lang.get("returnOrders", ["Type"]),
      dataIndex: "type",
      key: "type",
      sorter: true,
    },
    {
      title: lang.get("returnOrders", ["Status"]),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (status) => (
        <Tag color={status === "Approved" ? "green" : "blue"}>{status}</Tag>
      ),
    },
    {
      title: lang.get("returnOrders", ["Options"]),
      key: "options",
      render: () => <Button type="link">{lang.get("returnOrders", ["View"])}</Button>,
    },
  ];

  const data: ReturnOrder[] = [
    {
      orderReturnNumber: "OR001",
      customerName: "XYZ Pvt Ltd",
      totalAmount: 5000,
      discountTotal: 500,
      totalAfterDiscount: 4500,
      dateCreated: "2024-12-25",
      type: "Return",
      status: "Approved",
    },
    {
      orderReturnNumber: "OR002",
      customerName: "ABC Pvt Ltd",
      totalAmount: 3000,
      discountTotal: 300,
      totalAfterDiscount: 2700,
      dateCreated: "2024-12-20",
      type: "Exchange",
      status: "Pending",
    },
  ];

  return (
    <ProTable
      columns={columns}
      actionRef={actionRef}
      dataSource={data}
      rowKey="orderReturnNumber"
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        onShowSizeChange: (_, size) => setPageSize(size),
      }}
      search={false}
      scroll={{ x: "max-content" }}
      toolBarRender={() => [
        <Space size="middle" wrap key="toolbar">
          {/* Date Range Picker */}
          <RangePicker
            key="datePicker"
            format="YYYY-MM-DD"
            onChange={handleDateChange}
            placeholder={[
              lang.get("returnOrders", ["From Date"]),
              lang.get("returnOrders", ["To Date"]),
            ]}
          />

          {/* Type Selector */}
          <Select
            key="typeSelector"
            placeholder={lang.get("returnOrders", ["Type"])}
            style={{ width: 200 }}
            options={[
              { value: "Return", label: lang.get("returnOrders", ["Return"]) },
              { value: "Exchange", label: lang.get("returnOrders", ["Exchange"]) },
            ]}
          />

          {/* Staff Selector */}
          <Select
            key="staffSelector"
            placeholder={lang.get("returnOrders", ["Staff"])}
            style={{ width: 200 }}
            options={[
              { value: "staff1", label: "Staff 1" },
              { value: "staff2", label: "Staff 2" },
              { value: "staff3", label: "Staff 3" },
            ]}
          />

          {/* Status Selector */}
          <Select
            key="statusSelector"
            placeholder={lang.get("returnOrders", ["Status"])}
            mode="multiple"
            allowClear
            style={{ width: 300 }}
            options={[
              { value: "approved", label: lang.get("returnOrders", ["Approved"]) },
              {
                value: "notApproved",
                label: lang.get("returnOrders", ["Not yet approved"]),
              },
              { value: "pending", label: lang.get("returnOrders", ["Pending"]) },
            ]}
          />

          {/* Search Input */}
          <Input
            key="searchInput"
            placeholder={lang.get("returnOrders", ["Search"])}
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ width: 250 }}
          />
        </Space>,
      ]}
    />
  );
};

export default ReturnOrdersTable;
