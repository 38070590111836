import { SearchOutlined } from "@ant-design/icons";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import { Button, Input, Space, InputRef, Tag, DatePicker } from "antd";
import { useRef, useState } from "react";
import Export from "../../../../../../../common/components/Export";
import lang from "../../../../../../../lang";

const { RangePicker } = DatePicker;

interface Order {
  purchaseOrder: string;
  total: string;
  totalTax: string;
  vendors: string;
  orderDate: string;
  paymentStatus: string;
  status: string;
}

const Orders: Order[] = [
  {
    purchaseOrder: "PO12345",
    total: "$10,000",
    totalTax: "$1,500",
    vendors: "ABC Corp",
    orderDate: "2023-10-15",
    paymentStatus: "Paid",
    status: "Completed",
  },
  {
    purchaseOrder: "PO67890",
    total: "$7,200",
    totalTax: "$1,080",
    vendors: "XYZ Ltd",
    orderDate: "2023-10-18",
    paymentStatus: "Pending",
    status: "In Progress",
  },
];

const OrderTable = () => {
  const actionRef = useRef<ActionType>();
  const [pageSize, setPageSize] = useState<number>(10);
  const searchInput = useRef<InputRef>(null);
  const [searchAttributes, setSearchAttributes] = useState<{
    key: string;
    value: string;
  }[]>([]);
  const [allSearch, setAllSearch] = useState<string>("");

  const expobj = new Export("orders, " + new Date().toDateString());

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("orders", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("orders", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("orders", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<Order>[] = [
    {
      title: lang.get("orders", ["Purchase Order"]),
      dataIndex: "purchaseOrder",
      sorter: true,
      ...searchCols("purchaseOrder", lang.get("orders", ["Purchase Order"])),
    },
    {
      title: lang.get("orders", ["Total"]),
      dataIndex: "total",
      sorter: true,
      ...searchCols("total", lang.get("orders", ["Total"])),
    },
    {
      title: lang.get("orders", ["Total Tax"]),
      dataIndex: "totalTax",
      sorter: true,
      ...searchCols("totalTax", lang.get("orders", ["Total Tax"])),
    },
    {
      title: lang.get("orders", ["Vendors"]),
      dataIndex: "vendors",
      sorter: true,
      ...searchCols("vendors", lang.get("orders", ["Vendors"])),
    },
    {
      title: lang.get("orders", ["Order Date"]),
      dataIndex: "orderDate",
      sorter: true,
      ...searchCols("orderDate", lang.get("orders", ["Order Date"])),
    },
    {
      title: lang.get("orders", ["Payment Status"]),
      dataIndex: "paymentStatus",
      sorter: true,
      render: (_, record) => (
        <Tag color={record.paymentStatus === "Paid" ? "green" : "orange"}>
          {lang.get("orders", [record.paymentStatus])}
        </Tag>
      ),
    },
    {
      title: lang.get("orders", ["Status"]),
      dataIndex: "status",
      sorter: true,
      render: (_, record) => (
        <Tag color={
          record.status === "Completed"
            ? "green"
            : record.status === "In Progress"
            ? "blue"
            : "gray"
        }>
          {lang.get("orders", [record.status])}
        </Tag>
      ),
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("orders", ["Orders"])}
      columns={columns}
      actionRef={actionRef}
      dataSource={Orders}
      rowKey="purchaseOrder"
      cardBordered
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        showTotal: (total, range) =>
          lang.get("orders", [
            `Showing ${range[0]}-${range[1]} of ${total} items`,
          ]),
        onShowSizeChange: (current, size) => {
          setPageSize(size);
        },
      }}
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <RangePicker key="date" />,
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("orders", ["Global Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef?.current?.reload();
          }}
        />, // Add export buttons here
        <Button
          key="csv"
          onClick={() => {
            const csvData = Orders.map((row) => ({
              [lang.get("orders", ["Purchase Order"])]: row.purchaseOrder,
              [lang.get("orders", ["Total"])]: row.total,
              [lang.get("orders", ["Total Tax"])]: row.totalTax,
              [lang.get("orders", ["Vendors"])]: row.vendors,
              [lang.get("orders", ["Order Date"])]: row.orderDate,
              [lang.get("orders", ["Payment Status"])]: row.paymentStatus,
              [lang.get("orders", ["Status"])]: row.status,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("orders", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = Orders.map((row) => ({
              [lang.get("orders", ["Purchase Order"])]: row.purchaseOrder,
              [lang.get("orders", ["Total"])]: row.total,
              [lang.get("orders", ["Total Tax"])]: row.totalTax,
              [lang.get("orders", ["Vendors"])]: row.vendors,
              [lang.get("orders", ["Order Date"])]: row.orderDate,
              [lang.get("orders", ["Payment Status"])]: row.paymentStatus,
              [lang.get("orders", ["Status"])]: row.status,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("orders", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("orders", ["Purchase Order"]),
                  lang.get("orders", ["Total"]),
                  lang.get("orders", ["Total Tax"]),
                  lang.get("orders", ["Vendors"]),
                  lang.get("orders", ["Order Date"]),
                  lang.get("orders", ["Payment Status"]),
                  lang.get("orders", ["Status"]),
                ],
              ],
              body: Orders.map((row) => [
                row.purchaseOrder,
                row.total,
                row.totalTax,
                row.vendors,
                row.orderDate,
                row.paymentStatus,
                row.status,
              ]),
            });
          }}
        >
          {lang.get("orders", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default OrderTable;
