import React, { useState } from "react";
import {
  Row,
  Col,
  Dropdown,
  Avatar,
  Button,
  Space,
  Tag,
  Typography,
  MenuProps,
  Modal,
} from "antd";
import {
  PlusOutlined,
  SettingOutlined,
  FileTextOutlined,
  DownOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
  PauseCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import lang from "../../../../lang";
import { projects, Project } from "../../common/data/ProjectsData";
import AddTask from "./add-task/AddTask";

const { Title } = Typography;

// Define styles for each status
const statusStyles: Record<string, { color: string; icon: React.ReactNode }> = {
  Finished: { color: "green", icon: <CheckCircleOutlined /> },
  "In Progress": { color: "blue", icon: <LoadingOutlined /> },
  "On Hold": { color: "gold", icon: <PauseCircleOutlined /> },
  Cancelled: { color: "red", icon: <CloseCircleOutlined /> },
};

interface ProjectSummaryProps {
  defaultProjectId: number;
  onProjectChange: (newProjectId: number) => void;
}

const ProjectSummary: React.FC<ProjectSummaryProps> = ({
  defaultProjectId,
  onProjectChange,
}) => {
  const [project, setProject] = useState<Project | undefined>(() =>
    projects.find((proj) => proj.id === defaultProjectId)
  );
  const [showTaskModal, setShowTaskModal] = useState(false);

  const handleProjectChange = (projectId: number) => {
    const foundProject = projects.find((proj) => proj.id === projectId);
    setProject(foundProject);
    onProjectChange(projectId);
  };

  const projectMenuItems: MenuProps["items"] = projects.map((proj) => ({
    key: proj.id,
    label: lang.get("projects", [proj.name]),
    onClick: () => handleProjectChange(proj.id),
  }));

  const renderStatusTag = (status: { label: string }) => {
    const { color, icon } = statusStyles[status.label] || {
      color: "gray",
      icon: null,
    };
    return (
      <Tag color={color}>
        {icon} {lang.get("status", [status.label])}
      </Tag>
    );
  };

  return (
    <>
      <Row justify="space-between" align="middle" gutter={[24, 16]}>
        {/* Project Summary Section */}
        <Col xs={24} md={12}>
          <Space size="large" align="center">
            {/* Project Dropdown */}
            <Dropdown menu={{ items: projectMenuItems }} trigger={["click"]}>
              <Button type="text">
                <Title level={5} style={{ margin: 0 }}>
                  {lang.get("projects", [project?.name || "Select a project"])}
                </Title>
                <DownOutlined />
              </Button>
            </Dropdown>

            {/* Avatar Group */}
            {project && (
              <Avatar.Group>
                {project.members.map((member) => (
                  <Avatar key={member.id} src={member.avatar}>
                    {!member.avatar && member.name[0]}
                  </Avatar>
                ))}
              </Avatar.Group>
            )}

            {/* Status Tag */}
            {project && renderStatusTag(project.status)}
          </Space>
        </Col>

        {/* Action Buttons Section */}
        <Col xs={24} md={12}>
          <Row gutter={[16, 16]} justify="end">
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setShowTaskModal(true)}
              >
                {lang.get("buttons", ["New Task"])}
              </Button>

              <Modal
                title={lang.get("modals", ["Add New Task"])}
                open={showTaskModal}
                onCancel={() => setShowTaskModal(false)}
                footer={null}
                width={800}
              >
                <AddTask />
              </Modal>
            </Col>
            <Col>
              <Button icon={<FileTextOutlined />}>
                {lang.get("buttons", ["Invoice Project"])}
              </Button>
            </Col>
            <Col>
              <Button icon={<SettingOutlined />}>
                {lang.get("buttons", ["Customize"])}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ProjectSummary;
