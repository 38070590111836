import React, { useState } from "react";
import {
  ProForm,
  ProFormText,
  ProFormSelect,
  ProFormDependency,
  ProCard,
  ProFormCheckbox,
} from "@ant-design/pro-components";
import {
  Button,
  Modal,
  Row,
  Col,
  Divider,
  Typography,
  Space,
  Input,
  Select,
  Form,
  DatePicker,
} from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import AddDebitTable from "./AddDebitTable";
import { Editor } from "@tinymce/tinymce-react";

const { Title } = Typography;
const Option = Select;
const AddDebit: React.FC = () => {
  const [isBillToModalVisible, setBillToModalVisible] = useState(false);
  const [isShipToModalVisible, setShipToModalVisible] = useState(false);
  const [discountType, setDiscountType] = useState<string>("%");
  const [discount, setDiscount] = useState<number>(0);
  const [adjustment, setAdjustment] = useState<number>(0);
  const [subTotal] = useState<number>(0);
  const [tax, setTax] = useState<string[]>([]);

  const calculateTotal = () => {
    const discountAmount =
      discountType === "%" ? (subTotal * discount) / 100 : discount;
    return subTotal - discountAmount + adjustment;
  };

  const handleTaxChange = (values: string[]) => {
    console.log("Selected Tax Options: ", values);
    setTax(values);
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <Title level={2}>New Debit Note</Title>
      <ProCard>
        <ProForm layout="vertical" submitter={false}>
          <Row gutter={[16, 5]}>
            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "14px" }}>Customer</span>}
                name="customer"
                rules={[{ required: true, message: "Customer is required!" }]}
              >
                <Select placeholder="Select and begin typing" size="large">
                  <Select.Option value="Customer 1">Customer 1</Select.Option>
                  <Select.Option value="Customer 2">Customer 2</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "14px" }}>Currency</span>}
                name="currency"
                rules={[{ required: true, message: "Currency is required!" }]}
              >
                <Select placeholder="Select Currency" size="large">
                  <Select.Option value="LKR">LKR LKR</Select.Option>
                  <Select.Option value="USD">USD</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "14px" }}>Bill To</span>}
                name="billTo"
              >
                <Input
                  placeholder="--"
                  size="large"
                  suffix={
                    <EditOutlined onClick={() => setBillToModalVisible(true)} />
                  }
                  readOnly
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "14px" }}>Ship To</span>}
                name="shipTo"
              >
                <Input
                  placeholder="--"
                  size="large"
                  suffix={
                    <EditOutlined onClick={() => setShipToModalVisible(true)} />
                  }
                  readOnly
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>Debit Note Date</span>
                }
                name="debitNoteDate"
                rules={[{ required: true, message: "Please select a date!" }]}
              >
                <DatePicker style={{ width: "100%" }} size="large" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "14px" }}>Debit Note #</span>}
                name="debitNoteNumber"
                rules={[
                  {
                    required: true,
                    message: "Debit Note Number is required!",
                  },
                ]}
              >
                <Input addonBefore="CN-" placeholder="000002" size="large" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "14px" }}>Reference #</span>}
                name="reference"
              >
                <Input placeholder="Enter Reference Number" size="large" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span style={{ fontSize: "14px" }}>Discount Type</span>}
                name="discountType"
              >
                <Select placeholder="Select Discount Type" size="large">
                  <Select.Option value="No discount">No discount</Select.Option>
                  <Select.Option value="BeforeTax">Before Tax</Select.Option>
                  <Select.Option value="AfterTax">After Tax</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={<span style={{ fontSize: "14px" }}>Admin Note</span>}
                name="adminNote"
              >
                <Input.TextArea placeholder="Enter admin notes" size="large" />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <AddDebitTable />
          <Divider />

          <Row justify="end" gutter={16}>
            <ProFormDependency name={["total", "adjustment"]}>
              {() => (
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                    backgroundColor: "#f9f9fb",
                    padding: "16px",
                    borderRadius: "8px",
                  }}
                >
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      Sub Total :
                    </Col>
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      ${subTotal.toFixed(2)}
                    </Col>
                  </Row>
                  <Divider style={{ margin: "8px 0" }} />

                  <Row justify="space-between" style={{ marginBottom: "4px" }}>
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      Discount :
                      <Input
                        value={discount}
                        onChange={(e) => setDiscount(Number(e.target.value))}
                        type="number"
                        size="large"
                        style={{ width: "150px", marginLeft: "10px" }}
                        addonAfter={
                          <Select
                            value={discountType}
                            onChange={(value) => setDiscountType(value)}
                            style={{ width: "70px" }}
                            dropdownStyle={{ textAlign: "center" }}
                          >
                            <Option value="%">%</Option>
                            <Option value="Fixed">Fixed</Option>
                          </Select>
                        }
                      />
                    </Col>

                    <Col
                      style={{
                        fontWeight: 600,
                        fontSize: "14px",
                        color: "#ff4d4f",
                      }}
                    >
                      -$
                      {discountType === "%"
                        ? ((subTotal * discount) / 100).toFixed(2)
                        : discount.toFixed(2)}
                    </Col>
                  </Row>
                  <Divider style={{ margin: "8px 0" }} />
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      Tax :
                      <Select
                        mode="multiple"
                        value={tax}
                        onChange={handleTaxChange}
                        size="large"
                        style={{ width: "150px", marginLeft: "10px" }}
                        placeholder="Select Tax"
                      >
                        <Option value="VAT">VAT (5%)</Option>
                        <Option value="CGT">CGT (10%)</Option>
                        <Option value="Service Tax">Service Tax (8%)</Option>
                      </Select>
                    </Col>
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      ${adjustment.toFixed(2)}
                    </Col>
                  </Row>
                  <Divider style={{ margin: "8px 0" }} />
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      Adjustment :
                      <Input
                        value={adjustment}
                        onChange={(e) => setAdjustment(Number(e.target.value))}
                        type="number"
                        size="large"
                        style={{ width: "100px", marginLeft: "10px" }}
                      />
                    </Col>
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      ${adjustment.toFixed(2)}
                    </Col>
                  </Row>
                  <Divider style={{ margin: "8px 0" }} />

                  <Row justify="space-between" align="middle">
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      Total :
                    </Col>
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      ${calculateTotal().toFixed(2)}
                    </Col>
                  </Row>
                </Space>
              )}
            </ProFormDependency>
          </Row>
          <Divider />
          <Row gutter={[16, 5]}>
            <Col span={24}>
              <Form.Item
                label={<span style={{ fontSize: "14px" }}>Client Note</span>}
                name="clientNote"
              >
                <Editor
                  apiKey="your-tinymce-api-key"
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: ["lists link code"],
                    toolbar:
                      "undo redo | formatselect | bold italic underline | bullist numlist | link code",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Terms & Conditions */}
          <Row gutter={[16, 5]}>
            <Col span={24}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>Terms & Conditions</span>
                }
                name="termsConditions"
              >
                <Editor
                  apiKey="your-tinymce-api-key"
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: ["lists link code"],
                    toolbar:
                      "undo redo | formatselect | bold italic underline | bullist numlist | link code",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />

          <Row justify="end" gutter={16}>
            <ProFormCheckbox name="sendForApproval">
              Send the created invoice for approval
            </ProFormCheckbox>
          </Row>
          <Row justify="end" gutter={16}>
            <Col>
              <Button onClick={() => console.log("Saved as Draft")}>
                Save as Draft
              </Button>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => console.log("Saved Final Invoice")}
                style={{ marginLeft: 8 }}
              >
                Save
              </Button>
            </Col>
          </Row>
        </ProForm>
      </ProCard>

      {/* Modals */}
      <Modal
        title="Edit Bill To"
        open={isBillToModalVisible}
        onCancel={() => setBillToModalVisible(false)}
        footer={null}
      >
        <ProForm layout="vertical" submitter={false}>
          <ProFormText
            name="street"
            label="Street"
            placeholder="Enter street"
          />
          <ProFormText name="city" label="City" placeholder="Enter city" />
          <ProFormText name="state" label="State" placeholder="Enter state" />
          <ProFormText
            name="zipCode"
            label="Zip Code"
            placeholder="Enter zip code"
          />
          <ProFormSelect
            name="country"
            label="Country"
            placeholder="Select country"
            options={[
              { value: "USA", label: "USA" },
              { value: "UK", label: "UK" },
            ]}
          />
        </ProForm>
      </Modal>

      <Modal
        title="Edit Ship To"
        open={isShipToModalVisible}
        onCancel={() => setShipToModalVisible(false)}
        footer={null}
      >
        <ProForm layout="vertical" submitter={false}>
          <ProFormText
            name="street"
            label="Street"
            placeholder="Enter street"
          />
          <ProFormText name="city" label="City" placeholder="Enter city" />
          <ProFormText name="state" label="State" placeholder="Enter state" />
          <ProFormText
            name="zipCode"
            label="Zip Code"
            placeholder="Enter zip code"
          />
          <ProFormSelect
            name="country"
            label="Country"
            placeholder="Select country"
            options={[
              { value: "USA", label: "USA" },
              { value: "UK", label: "UK" },
            ]}
          />
        </ProForm>
      </Modal>
    </div>
  );
};

export default AddDebit;
