import React, { useState, useRef, useEffect } from "react";
import type {
  ProColumns,
  EditableFormInstance,
  ActionType,
} from "@ant-design/pro-components";
import { EditableProTable, ProCard } from "@ant-design/pro-components";
import { Button, Input, Select, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import lang from "../../../../../lang";

const { Option } = Select;

export type InvoiceTableDataType = {
  id: React.Key;
  item?: string;
  description?: string;
  quantity?: number;
  rate?: number;
  discount?: string;
  tax?: string;
  amount?: number;
  warranty?: string;
  discountType?: string;
};

export type SectionType = {
  name: string;
  items: InvoiceTableDataType[];
};

export const itemList = [
  {
    id: 1,
    item: "Item 1",
    description: "Description 1",
    rate: 100,
    discount: "10%",
    tax: "5%",
    warranty: "1 Year",
  },
  {
    id: 2,
    item: "Item 2",
    description: "Description 2",
    rate: 200,
    discount: "5%",
    tax: "10%",
    warranty: "6 Months",
  },
  {
    id: 3,
    item: "Item 3",
    description: "Description 3",
    rate: 150,
    discount: "15%",
    tax: "5%",
    warranty: "No Warranty",
  },
];

const defaultValues = {
  description: "N/A",
  quantity: 1,
  rate: 0.0,
  discount: "No Discount",
  tax: "No Tax",
  amount: 0.0,
  warranty: "No Warranty",
};

const AddInvoiceTable: React.FC = () => {
  const [tableData, setTableData] = useState<InvoiceTableDataType[]>([
    { id: 1, ...defaultValues },
  ]);
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([1]);
  const actionRef = useRef<ActionType>();
  const editableFormRef = useRef<EditableFormInstance>();

  const handleItemSelect = (record: InvoiceTableDataType, value: string) => {
    const selectedItem = itemList.find((item) => item.item === value);
    if (selectedItem && record) {
      const updatedRow = {
        ...record,
        item: selectedItem.item,
        description: selectedItem.description,
        rate: selectedItem.rate,
        discount: selectedItem.discount,
        tax: selectedItem.tax,
        warranty: selectedItem.warranty,
        amount: selectedItem.rate * (record.quantity || 1),
      };

      setTableData((prev) => {
        const updatedData = prev.map((row) =>
          row.id === record.id ? updatedRow : row
        );
        const isEmptyRowPresent = updatedData.some(
          (row) => !row.item && !row.description
        );
        if (!isEmptyRowPresent) {
          const newRow = { id: Date.now(), ...defaultValues };
          setEditableRowKeys((keys) => [...keys, newRow.id]);
          return [...updatedData, newRow];
        }
        return updatedData;
      });
    }
  };

  const deleteLastRow = () => {
    setTableData((prev) => {
      if (prev.length > 1) {
        const newData = prev.slice(0, -1);
        setEditableRowKeys((keys) =>
          keys.filter((key) => key !== prev[prev.length - 1].id)
        );
        return newData;
      } else {
        console.log("Cannot delete the last remaining row.");
        return prev;
      }
    });
  };

  const calculateRowTotal = (row: InvoiceTableDataType) => {
    const quantity = row.quantity || 0;
    const taxRate = parseFloat(row.tax || "0") / 100 || 0;
    const discountRate = parseFloat(row.discount || "0") / 100 || 0;

    const baseAmount = quantity * 100;
    const taxAmount = baseAmount * taxRate;
    const discountAmount = baseAmount * discountRate;

    return baseAmount + taxAmount - discountAmount;
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        deleteLastRow();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const columns: ProColumns<InvoiceTableDataType>[] = [
    {
      title: lang.get("sales", ["Item Details"]),
      dataIndex: "item",
      align: "center",
      renderFormItem: (_, { record }) => (
        <Select
          showSearch
          placeholder={lang.get("sales", ["Search and select an item"])}
          value={record?.item}
          onChange={(value) =>
            handleItemSelect(record as InvoiceTableDataType, value)
          }
          style={{ width: "100%" }}
        >
          {itemList.map((item) => (
            <Option key={item.id} value={item.item}>
              {item.item}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: lang.get("sales", ["Description"]),
      dataIndex: "description",
      align: "center",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          value={record?.description}
          onChange={(e) => {
            const value = e.target.value;
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, description: value } : row
              )
            );
          }}
        />
      ),
    },
    {
      title: lang.get("sales", ["Quantity"]),
      dataIndex: "quantity",
      align: "center",
      valueType: "digit",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          type="number"
          value={record?.quantity}
          onChange={(e) => {
            const value = Number(e.target.value);
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id
                  ? { ...row, quantity: value, amount: (row.rate || 0) * value }
                  : row
              )
            );
          }}
        />
      ),
    },
    {
      title: lang.get("sales", ["Rate"]),
      dataIndex: "rate",
      valueType: "money",
      align: "center",
      renderFormItem: (_, { record, onChange }) => (
        <Input
          type="number"
          value={record?.rate}
          onChange={(e) => {
            const value = Number(e.target.value);
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id
                  ? { ...row, rate: value, amount: (row.quantity || 0) * value }
                  : row
              )
            );
          }}
        />
      ),
    },
    {
      title: lang.get("sales", ["Amount"]),
      dataIndex: "amount",
      editable: false,
      render: (_, record) => <span>{(record.amount || 0).toFixed(2)}</span>,
      align: "center",
    },
    {
      title: lang.get("purchase", ["Discount"]),
      dataIndex: "discount",
      align: "center",
      renderFormItem: (_, { record }) => (
        <Input.Group compact>
          <Input
            value={record?.discount}
            onChange={(e) => {
              const value = e.target.value;
              setTableData((prev) =>
                prev.map((row) =>
                  row.id === record?.id
                    ? { ...row, discount: value, total: calculateRowTotal(row) }
                    : row
                )
              );
            }}
            placeholder={lang.get("purchase", ["Enter Discount"])}
            style={{ width: "70%" }}
          />
          <Select
            value={record?.discountType || "%"}
            onChange={(value) => {
              setTableData((prev) =>
                prev.map((row) =>
                  row.id === record?.id
                    ? {
                        ...row,
                        discountType: value,
                        total: calculateRowTotal(row),
                      }
                    : row
                )
              );
            }}
            style={{ width: "30%" }}
            dropdownStyle={{ width: "5%" }}
          >
            <Option value="%">{lang.get("purchase", ["%"])}</Option>
            <Option value="Fixed">{lang.get("purchase", ["Fixed"])}</Option>
          </Select>
        </Input.Group>
      ),
    },
    {
      title: lang.get("sales", ["Tax"]),
      dataIndex: "tax",
      align: "center",
      renderFormItem: (_, { record, onChange }) => (
        <Select
          mode="multiple"
          value={record?.tax}
          onChange={(values) => {
            onChange?.(values);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, tax: values } : row
              )
            );
          }}
          placeholder={lang.get("sales", ["Select Tax"])}
          style={{ width: "100%" }}
        >
          <Option value="No Tax" disabled>
            {lang.get("sales", ["No Tax"])}{" "}
          </Option>
          <Option value="5%">{lang.get("sales", ["5%"])}</Option>
          <Option value="10%">{lang.get("sales", ["10%"])}</Option>
        </Select>
      ),
    },

    {
      title: lang.get("sales", ["Warranty"]),
      align: "center",
      dataIndex: "warranty",
      renderFormItem: (_, { record, onChange }) => (
        <Select
          value={record?.warranty}
          onChange={(value) => {
            onChange?.(value);
            setTableData((prev) =>
              prev.map((row) =>
                row.id === record?.id ? { ...row, warranty: value } : row
              )
            );
          }}
          placeholder={lang.get("sales", ["Select Warranty Period"])}
        >
          <Option value="No Warranty">
            {lang.get("sales", ["No Warranty"])}
          </Option>
          <Option value="6 Months">{lang.get("sales", ["6 Months"])}</Option>
          <Option value="1 Year">{lang.get("sales", ["1 Year"])}</Option>
          <Option value="2 Years">{lang.get("sales", ["2 Years"])}</Option>
        </Select>
      ),
    },
    {
      title: lang.get("sales", ["Actions"]),
      align: "center",
      valueType: "option",
    },
  ];

  return (
    <ProCard
      style={{
        border: "1px solid #d9d9d9",
        borderRadius: "8px",
        backgroundColor: "#f9f9fb",
      }}
    >
      <EditableProTable<InvoiceTableDataType>
        rowKey="id"
        value={tableData}
        onChange={(value) => setTableData([...value] as InvoiceTableDataType[])}
        columns={columns}
        editable={{
          type: "multiple",
          editableKeys,
          onChange: setEditableRowKeys,
          actionRender: (row, _, defaultDoms) => [
            <button
              key="delete"
              type="button"
              style={{
                color: tableData.length === 1 ? "gray" : "red",
                border: "none",
                background: "none",
                cursor: tableData.length === 1 ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (tableData.length > 1) {
                  setTableData((prev) =>
                    prev.filter((item) => item.id !== row.id)
                  );
                  setEditableRowKeys((prev) =>
                    prev.filter((key) => key !== row.id)
                  );
                } else {
                  message.warning(
                    lang.get("sales", ["Cannot delete the last remaining row."])
                  );
                }
              }}
              disabled={tableData.length === 1}
            >
              <DeleteOutlined />
            </button>,
          ],
        }}
        recordCreatorProps={false}
        actionRef={actionRef}
        editableFormRef={editableFormRef}
        search={false}
        scroll={{ x: 800 }}
        toolBarRender={() => []}
        footer={() => (
          <div style={{ textAlign: "center" }}>
            <Button
              color="primary"
              variant="outlined"
              size="large"
              style={{ width: "100%" }}
            >
              {lang.get("sales", ["Add Section"])}
            </Button>
          </div>
        )}
      />
    </ProCard>
  );
};

export default AddInvoiceTable;
