import { useState } from "react";
import { Button } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import InvoiceStatitstic from "./InvoiceStatistics";
import InvoiceTable from "./InvoiceTable";

const Invoice = () => {
  const [showStatistics, setShowStatistics] = useState(true);

  return (
    <div>
      <div style={{ marginBottom: "16px", textAlign: "right" }}>
        <Button
          type="primary"
          onClick={() => setShowStatistics(!showStatistics)}
          icon={showStatistics ? <UpOutlined /> : <DownOutlined />}
        >
          {showStatistics ? "Hide Statistics" : "Show Statistics"}
        </Button>
      </div>

      {showStatistics && (
        <div style={{ marginBottom: "16px" }}>
          <InvoiceStatitstic />
        </div>
      )}

      <InvoiceTable />
    </div>
  );
};

export default Invoice;
