import { useEffect, useState } from "react";
import { Table, Button, Row, Col, Typography, Modal } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Customer, CustomerContact } from "../../../types/types";
import ContactModel from "./contact-model/ContactModel";
import lang from "../../../../../lang";

const { Text } = Typography;

const columns: ColumnsType<CustomerContact> = [
  {
    title: lang.get("contact", ["ID"]),
    dataIndex: "id",
    key: "id",
    render: (_text, _record, index) => <Text>{index + 1}</Text>,
  },
  {
    title: lang.get("contact", ["Profile Image"]),
    dataIndex: "profile_img",
    key: "profile_img",
  },
  {
    title: lang.get("contact", ["First Name"]),
    dataIndex: "first_name",
    key: "first_name",
  },
  {
    title: lang.get("contact", ["Last Name"]),
    dataIndex: "last_name",
    key: "last_name",
  },
  {
    title: lang.get("contact", ["Email"]),
    dataIndex: "email",
    key: "email",
  },
  {
    title: lang.get("contact", ["Phone"]),
    dataIndex: "phone",
    key: "phone",
  },
];

const ContactTab = (props: {
  client: Customer;
  preload: boolean;
  setRefresh: (e: boolean) => void;
  refresh: boolean;
  currentKey: string;
}) => {
  const Contacts: CustomerContact[] = [
    {
      id: "1",
      first_name: "John",
      last_name: "Doe",
      email: "john.doe@example.com",
      phone: "123-456-7890",
    },
    {
      id: "2",
      first_name: "Jane",
      last_name: "Smith",
      email: "jane.smith@example.com",
      phone: "098-765-4321",
    },
    {
      id: "3",
      first_name: "Sam",
      last_name: "Wilson",
      email: "sam.wilson@example.com",
      phone: "567-890-1234",
    },
    {
      id: "4",
      first_name: "Lisa",
      last_name: "Brown",
      email: "lisa.brown@example.com",
      phone: "234-567-8901",
    },
  ];

  const [clientContacts] = useState<CustomerContact[]>(Contacts);
  const [refreshComponent, setRefreshComponent] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);

  useEffect(() => {
    if (props.currentKey === "contacts") {
      setRefreshComponent(true);
    } else {
      setRefreshComponent(false);
    }
  }, [props.currentKey]);

  return (
    <div>
      {/* Header and Add Button */}
      <Row justify="end" align="middle" style={{ marginBottom: "16px" }}>
        <Col>
          <Button type="primary" onClick={() => setContactModalOpen(true)}>
            {lang.get("contact", ["+ Add Contact"])}
          </Button>
        </Col>
      </Row>

      {/* Contacts Table */}
      <Table<CustomerContact>
        columns={columns}
        dataSource={clientContacts}
        rowKey="id"
        pagination={{ pageSize: 6 }}
        bordered
        style={{
          background: "#ffffff",
          borderRadius: "8px",
        }}
        scroll={{ x: "max-content" }}
      />

      <Modal
        title={lang.get("contact", ["Add Contact"])}
        open={contactModalOpen}
        onCancel={() => setContactModalOpen(false)}
        footer={null}
        width={800}
      >
        <ContactModel
          client={props.client.id}
          refreshComponent={refreshComponent}
          setRefreshComponent={setRefreshComponent}
          setContactModelOpen={setContactModalOpen}
        />
      </Modal>
    </div>
  );
};

export default ContactTab;
