import bg51 from "../../../../assets/img/generic/51.png";
import bg52 from "../../../../assets/img/generic/52.png";
import bg53 from "../../../../assets/img/generic/53.png";
import bg54 from "../../../../assets/img/generic/54.png";
import bg55 from "../../../../assets/img/generic/55.png";
import bg56 from "../../../../assets/img/generic/56.png";
import bg57 from "../../../../assets/img/generic/57.png";
import bg40 from "../../../../assets/img/generic/40.png";
import bg59 from "../../../../assets/img/generic/59.png";
import bg60 from "../../../../assets/img/generic/60.png";

import { Member, members } from "./Members";

const findMemberById = (id: number): Member | undefined =>
  members.find((member) => member.id === id);

export interface Project {
  id: number;
  name: string;
  customer: string;
  Tags: string;
  start: string;
  deadline: string;
  members: Member[];
  progress: {
    min: number;
    max: number;
  };
  task: number;
  status: {
    label: string;
    icon: string;
  };
  priority: {
    label: string;
    icon: string;
  };
  bg: string;
  budget: number;
}

export const projects: Project[] = [
  {
    id: 1,
    name: "Making the Butterflies shoot each other dead",
    customer: "Customer A",
    Tags: "Marketing, Design",
    start: "Dec 12, 2018",
    deadline: "Dec 12, 2026",
    members: [17, 16, 11, 5, 18, 19]
      .map(findMemberById)
      .filter(Boolean) as Member[],
    progress: {
      min: 145,
      max: 145,
    },
    task: 287,
    status: {
      label: "Finished",
      icon: "check",
    },
    bg: bg51,
    budget: 3991,
    priority: {
      label: "High",
      icon: "alert-octagon",
    },
  },
  {
    id: 2,
    name: "Project Doughnut Dungeon",
    customer: "Customer B",
    Tags: "Development, Research",
    start: "Jan 9, 2019",
    deadline: "Dec 9, 2022",
    members: [18, 19].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 148,
      max: 223,
    },
    task: 125,
    status: {
      label: "On Hold",
      icon: "pause",
    },
    bg: bg52,
    budget: 5832,
    priority: {
      label: "Medium",
      icon: "info",
    },
  },
  {
    id: 3,
    name: "The Chewing Gum Attack",
    customer: "Customer C",
    Tags: "Strategy, Planning",
    start: "Sep 4, 2019",
    deadline: "Dec 4, 2021",
    members: [12, 10].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 277,
      max: 539,
    },
    task: 72,
    status: {
      label: "In Progress",
      icon: "loader",
    },
    bg: bg53,
    budget: 8305,
    priority: {
      label: "Urgent",
      icon: "alert-triangle",
    },
  },
  {
    id: 4,
    name: "Execution of Micky the foul mouse",
    customer: "Customer D",
    Tags: "Operations, Execution",
    start: "Nov 1, 2019",
    deadline: "Dec 1, 2024",
    members: [11, 18, 17, 5, 19]
      .map(findMemberById)
      .filter(Boolean) as Member[],
    progress: {
      min: 16,
      max: 56,
    },
    task: 91,
    status: {
      label: "Cancelled",
      icon: "x",
    },
    bg: bg54,
    budget: 8888,
    priority: {
      label: "Low",
      icon: "arrow-down",
    },
  },
  {
    id: 5,
    name: "Harnessing stupidity from Jerry",
    customer: "Customer E",
    Tags: "Innovation, R&D",
    start: "Dec 28, 2019",
    deadline: "Nov 28, 2021",
    members: [17, 16, 15].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 169,
      max: 394,
    },
    task: 134,
    status: {
      label: "In Progress",
      icon: "loader",
    },
    bg: bg55,
    budget: 7324,
    priority: {
      label: "Urgent",
      icon: "alert-triangle",
    },
  },
  {
    id: 6,
    name: "Operation Pizza Planet",
    customer: "Customer F",
    Tags: "Planning, Design",
    start: "Mar 1, 2020",
    deadline: "Sep 30, 2025",
    members: [1, 3, 7].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 55,
      max: 100,
    },
    task: 87,
    status: {
      label: "In Progress",
      icon: "loader",
    },
    bg: bg56,
    budget: 4200,
    priority: {
      label: "Medium",
      icon: "info",
    },
  },
  {
    id: 7,
    name: "Project Mars Colony",
    customer: "Customer G",
    Tags: "Research, Development",
    start: "Jun 5, 2021",
    deadline: "Dec 5, 2028",
    members: [8, 9, 6].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 150,
      max: 300,
    },
    task: 145,
    status: {
      label: "On Hold",
      icon: "pause",
    },
    bg: bg57,
    budget: 9800,
    priority: {
      label: "Low",
      icon: "arrow-down",
    },
  },
  {
    id: 8,
    name: "Space Trash Removal",
    customer: "Customer H",
    Tags: "Operations, Innovation",
    start: "Jul 10, 2022",
    deadline: "Nov 10, 2026",
    members: [13, 4].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 20,
      max: 80,
    },
    task: 33,
    status: {
      label: "In Progress",
      icon: "loader",
    },
    bg: bg40,
    budget: 5500,
    priority: {
      label: "Urgent",
      icon: "alert-triangle",
    },
  },
  {
    id: 9,
    name: "Robotic Bees",
    customer: "Customer I",
    Tags: "Development, R&D",
    start: "Aug 20, 2021",
    deadline: "May 20, 2025",
    members: [14, 2].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 180,
      max: 300,
    },
    task: 98,
    status: {
      label: "In Progress",
      icon: "loader",
    },
    bg: bg59,
    budget: 10200,
    priority: {
      label: "High",
      icon: "alert-octagon",
    },
  },
  {
    id: 10,
    name: "Project Cyber Crops",
    customer: "Customer J",
    Tags: "Strategy, Design",
    start: "Nov 11, 2020",
    deadline: "Nov 11, 2027",
    members: [16, 18, 17, 20].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 75,
      max: 200,
    },
    task: 75,
    status: {
      label: "On Hold",
      icon: "pause",
    },
    bg: bg60,
    budget: 7200,
    priority: {
      label: "Low",
      icon: "arrow-down",
    },
  },
  {
    id: 11,
    name: "Project Cyber Crops",
    customer: "Customer J",
    Tags: "Strategy, Design",
    start: "Nov 11, 2020",
    deadline: "Nov 11, 2027",
    members: [16, 18, 17, 20].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 75,
      max: 200,
    },
    task: 75,
    status: {
      label: "On Hold",
      icon: "pause",
    },
    bg: bg60,
    budget: 7200,
    priority: {
      label: "Low",
      icon: "arrow-down",
    },
  },
  {
    id: 12,
    name: "Project Cyber Crops",
    customer: "Customer J",
    Tags: "Strategy, Design",
    start: "Nov 11, 2020",
    deadline: "Nov 11, 2027",
    members: [16, 18, 17, 20].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 75,
      max: 200,
    },
    task: 75,
    status: {
      label: "On Hold",
      icon: "pause",
    },
    bg: bg60,
    budget: 7200,
    priority: {
      label: "Low",
      icon: "arrow-down",
    },
  },
  {
    id: 13,
    name: "Galactic Mining Project",
    customer: "Customer O",
    Tags: "Operations, Research",
    start: "Jun 15, 2023",
    deadline: "Jun 15, 2029",
    members: [1, 2, 3].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 40,
      max: 90,
    },
    task: 123,
    status: {
      label: "In Progress",
      icon: "loader",
    },
    bg: bg53,
    budget: 3200,
    priority: {
      label: "Medium",
      icon: "info",
    },
  },
  {
    id: 14,
    name: "Project Solar Farm",
    customer: "Customer P",
    Tags: "Development, Innovation",
    start: "May 1, 2024",
    deadline: "May 1, 2028",
    members: [14, 7].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 20,
      max: 50,
    },
    task: 45,
    status: {
      label: "On Hold",
      icon: "pause",
    },
    bg: bg54,
    budget: 7000,
    priority: {
      label: "Low",
      icon: "arrow-down",
    },
  },
  {
    id: 15,
    name: "Underwater City Development",
    customer: "Customer Q",
    Tags: "Planning, Strategy",
    start: "Jan 20, 2023",
    deadline: "Jan 20, 2030",
    members: [19, 20, 15].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 100,
      max: 500,
    },
    task: 260,
    status: {
      label: "In Progress",
      icon: "loader",
    },
    bg: bg55,
    budget: 9000,
    priority: {
      label: "Urgent",
      icon: "alert-triangle",
    },
  },
  {
    id: 16,
    name: "Martian Agriculture",
    customer: "Customer R",
    Tags: "Research, Innovation",
    start: "Feb 10, 2022",
    deadline: "Feb 10, 2028",
    members: [2, 3, 7].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 200,
      max: 300,
    },
    task: 180,
    status: {
      label: "Finished",
      icon: "check",
    },
    bg: bg56,
    budget: 12500,
    priority: {
      label: "High",
      icon: "alert-octagon",
    },
  },
  {
    id: 17,
    name: "AI-Powered Ecosystem",
    customer: "Customer S",
    Tags: "Development, Design",
    start: "Jul 4, 2021",
    deadline: "Jul 4, 2027",
    members: [12, 6, 8].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 300,
      max: 600,
    },
    task: 500,
    status: {
      label: "In Progress",
      icon: "loader",
    },
    bg: bg57,
    budget: 15000,
    priority: {
      label: "Urgent",
      icon: "alert-triangle",
    },
  },
  {
    id: 18,
    name: "Robotic Bees Evolution",
    customer: "Customer T",
    Tags: "Innovation, Development",
    start: "Mar 8, 2024",
    deadline: "Mar 8, 2030",
    members: [1, 4, 5].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 50,
      max: 100,
    },
    task: 75,
    status: {
      label: "On Hold",
      icon: "pause",
    },
    bg: bg40,
    budget: 4500,
    priority: {
      label: "Low",
      icon: "arrow-down",
    },
  },
  {
    id: 19,
    name: "Project Hydro Dome",
    customer: "Customer U",
    Tags: "Research, Operations",
    start: "Nov 10, 2023",
    deadline: "Nov 10, 2030",
    members: [6, 13].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 75,
      max: 150,
    },
    task: 120,
    status: {
      label: "Cancelled",
      icon: "x",
    },
    bg: bg59,
    budget: 3000,
    priority: {
      label: "Medium",
      icon: "info",
    },
  },
  {
    id: 20,
    name: "Project Hydro Dome",
    customer: "Customer U",
    Tags: "Research, Operations",
    start: "Nov 10, 2023",
    deadline: "Nov 10, 2030",
    members: [6, 13].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 75,
      max: 150,
    },
    task: 120,
    status: {
      label: "Cancelled",
      icon: "x",
    },
    bg: bg59,
    budget: 3000,
    priority: {
      label: "Medium",
      icon: "info",
    },
  },
  {
    id: 21,
    name: "Project Hydro Dome",
    customer: "Customer U",
    Tags: "Research, Operations",
    start: "Nov 10, 2023",
    deadline: "Nov 10, 2030",
    members: [6, 13].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 75,
      max: 150,
    },
    task: 120,
    status: {
      label: "Cancelled",
      icon: "x",
    },
    bg: bg59,
    budget: 3000,
    priority: {
      label: "Medium",
      icon: "info",
    },
  },
  {
    id: 22,
    name: "Project Hydro Dome",
    customer: "Customer U",
    Tags: "Research, Operations",
    start: "Nov 10, 2023",
    deadline: "Nov 10, 2030",
    members: [6, 13].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 75,
      max: 150,
    },
    task: 120,
    status: {
      label: "Cancelled",
      icon: "x",
    },
    bg: bg59,
    budget: 3000,
    priority: {
      label: "Medium",
      icon: "info",
    },
  },
  {
    id: 23,
    name: "Project Hydro Dome",
    customer: "Customer U",
    Tags: "Research, Operations",
    start: "Nov 10, 2023",
    deadline: "Nov 10, 2030",
    members: [6, 13].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 75,
      max: 150,
    },
    task: 120,
    status: {
      label: "Cancelled",
      icon: "x",
    },
    bg: bg59,
    budget: 3000,
    priority: {
      label: "Medium",
      icon: "info",
    },
  },
  {
    id: 24,
    name: "Project Hydro Dome",
    customer: "Customer U",
    Tags: "Research, Operations",
    start: "Nov 10, 2023",
    deadline: "Nov 10, 2030",
    members: [6, 13].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 75,
      max: 150,
    },
    task: 120,
    status: {
      label: "Cancelled",
      icon: "x",
    },
    bg: bg59,
    budget: 3000,
    priority: {
      label: "Medium",
      icon: "info",
    },
  },
  {
    id: 25,
    name: "Project Hydro Dome",
    customer: "Customer U",
    Tags: "Research, Operations",
    start: "Nov 10, 2023",
    deadline: "Nov 10, 2030",
    members: [6, 13].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 75,
      max: 150,
    },
    task: 120,
    status: {
      label: "Cancelled",
      icon: "x",
    },
    bg: bg59,
    budget: 3000,
    priority: {
      label: "Medium",
      icon: "info",
    },
  },
  {
    id: 26,
    name: "Project Hydro Dome",
    customer: "Customer U",
    Tags: "Research, Operations",
    start: "Nov 10, 2023",
    deadline: "Nov 10, 2030",
    members: [6, 13].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 75,
      max: 150,
    },
    task: 120,
    status: {
      label: "Cancelled",
      icon: "x",
    },
    bg: bg59,
    budget: 3000,
    priority: {
      label: "Medium",
      icon: "info",
    },
  },
  {
    id: 27,
    name: "Project Hydro Dome",
    customer: "Customer U",
    Tags: "Research, Operations",
    start: "Nov 10, 2023",
    deadline: "Nov 10, 2030",
    members: [6, 13].map(findMemberById).filter(Boolean) as Member[],
    progress: {
      min: 75,
      max: 150,
    },
    task: 120,
    status: {
      label: "Cancelled",
      icon: "x",
    },
    bg: bg59,
    budget: 3000,
    priority: {
      label: "Medium",
      icon: "info",
    },
  },
];


const getMemberIdByName = (name: string): number | undefined => {
  const member = members.find(m => m.name === name)
  return member ? member.id : undefined
}


export const timesheetData = [
  {
    member: 'John Doe',
    memberId: getMemberIdByName('John Doe'),
    task: 'Design Landing Page',
    timesheetTags: 'Design',
    startTime: '09:00 AM',
    endTime: '05:00 PM',
    note: 'Worked on the hero section',
    timeH: 8,
    timeDecimal: 8.0,
    options: '',
    projectIds: [1, 2, 4, 10]
  },
  {
    member: 'Jane Smith',
    memberId: getMemberIdByName('Jane Smith'),
    task: 'Develop Authentication System',
    timesheetTags: 'Development',
    startTime: '10:00 AM',
    endTime: '06:00 PM',
    note: 'Implemented OAuth2',
    timeH: 7,
    timeDecimal: 7.0,
    options: '',
    projectIds: [1, 2, 4]
  },
  {
    member: 'Michael Jenkins',
    memberId: getMemberIdByName('Michael Jenkins'),
    task: 'Marketing Strategy Planning',
    timesheetTags: 'Marketing',
    startTime: '10:00 AM',
    endTime: '06:00 PM',
    note: 'Developed a strategic plan',
    timeH: 8,
    timeDecimal: 8.0,
    options: '',
    projectIds: [1, 4, 5, 10]
  },
  {
    member: 'Jennifer Schramm',
    memberId: getMemberIdByName('Jennifer Schramm'),
    task: 'UI Design',
    timesheetTags: 'Design',
    startTime: '09:30 AM',
    endTime: '05:30 PM',
    note: 'Designed new UI elements',
    timeH: 8,
    timeDecimal: 8.0,
    options: '',
    projectIds: [1, 5, 10]
  },
  {
    member: 'Igor Borvibson',
    memberId: getMemberIdByName('Igor Borvibson'),
    task: 'UI Testing',
    timesheetTags: 'Testing',
    startTime: '09:00 AM',
    endTime: '06:00 PM',
    note: 'Conducted end-to-end UI tests',
    timeH: 8,
    timeDecimal: 8.0,
    options: '',
    projectIds: [1, 4, 3]
  },
  {
    member: 'Katerina Karenin',
    memberId: getMemberIdByName('Katerina Karenin'),
    task: 'Campaign Planning',
    timesheetTags: 'Marketing',
    startTime: '09:00 AM',
    endTime: '05:00 PM',
    note: 'Developed campaign strategies',
    timeH: 8,
    timeDecimal: 8.0,
    options: '',
    projectIds: [1, 4]
  },
  {
    member: 'Roy Anderson',
    memberId: getMemberIdByName('Roy Anderson'),
    task: 'Database Optimization',
    timesheetTags: 'Development',
    startTime: '10:00 AM',
    endTime: '06:00 PM',
    note: 'Improved query performance',
    timeH: 8,
    timeDecimal: 8.0,
    options: '',
    projectIds: [3]
  },
  {
    member: 'Tyrion Lannister',
    memberId: getMemberIdByName('Tyrion Lannister'),
    task: 'Planning',
    timesheetTags: 'Strategy',
    startTime: '08:00 AM',
    endTime: '04:00 PM',
    note: 'Led strategic planning meeting',
    timeH: 8,
    timeDecimal: 8.0,
    options: '',
    projectIds: [6]
  },
  {
    member: 'Milind Mikuja',
    memberId: getMemberIdByName('Milind Mikuja'),
    task: 'Research Analysis',
    timesheetTags: 'Research',
    startTime: '10:00 AM',
    endTime: '06:00 PM',
    note: 'Conducted research on trends',
    timeH: 8,
    timeDecimal: 8.0,
    options: '',
    projectIds: [6, 9]
  },
  {
    member: 'Stanly Drinkwater',
    memberId: getMemberIdByName('Stanly Drinkwater'),
    task: 'Product Development',
    timesheetTags: 'Development',
    startTime: '08:30 AM',
    endTime: '04:30 PM',
    note: 'Worked on product prototype',
    timeH: 8,
    timeDecimal: 8.0,
    options: '',
    projectIds: [6, 7]
  },
  {
    member: 'Josef Stravinsky',
    memberId: getMemberIdByName('Josef Stravinsky'),
    task: 'Innovation Planning',
    timesheetTags: 'Planning',
    startTime: '08:30 AM',
    endTime: '04:30 PM',
    note: 'Strategized for space trash project',
    timeH: 7,
    timeDecimal: 7.0,
    options: '',
    projectIds: [8, 7]
  },
  {
    member: 'Carry Anna',
    memberId: getMemberIdByName('Carry Anna'),
    task: 'Research',
    timesheetTags: 'Research',
    startTime: '09:00 AM',
    endTime: '05:00 PM',
    note: 'Analyzed colony feasibility',
    timeH: 8,
    timeDecimal: 8.0,
    options: '',
    projectIds: [7]
  },
  {
    member: 'Jean Renoir',
    memberId: getMemberIdByName('Jean Renoir'),
    task: 'Operations Setup',
    timesheetTags: 'Operations',
    startTime: '09:00 AM',
    endTime: '05:00 PM',
    note: 'Coordinated logistics setup',
    timeH: 8,
    timeDecimal: 8.0,
    options: '',
    projectIds: [8]
  },
  {
    member: 'Ricky Antony',
    memberId: getMemberIdByName('Ricky Antony'),
    task: 'Drone Development',
    timesheetTags: 'Development',
    startTime: '07:00 AM',
    endTime: '03:00 PM',
    note: 'Developed drone technology',
    timeH: 8,
    timeDecimal: 8.0,
    options: '',
    projectIds: [9]
  },
  {
    member: 'Emma Watson',
    memberId: getMemberIdByName('Emma Watson'),
    task: 'Product R&D',
    timesheetTags: 'Research',
    startTime: '08:00 AM',
    endTime: '04:00 PM',
    note: 'Research and development activities',
    timeH: 8,
    timeDecimal: 8.0,
    options: '',
    projectIds: [5]
  },
  {
    member: 'Alex Johnson',
    memberId: getMemberIdByName('Alex Johnson'),
    task: 'Strategic Design',
    timesheetTags: 'Design',
    startTime: '09:30 AM',
    endTime: '05:30 PM',
    note: 'Contributed to strategic design',
    timeH: 8,
    timeDecimal: 8.0,
    options: '',
    projectIds: [10]
  }
]
