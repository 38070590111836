import { useState, ChangeEvent } from "react";
import {
  Tabs,
  Card,
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Space,
} from "antd";
import { Editor } from "@tinymce/tinymce-react";
import {
  FileTextOutlined,
  BellOutlined,
  FileAddOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import Doc from "./Doc";
import Reminder from "./Reminder";
import Whiteboard from "./Whiteboard";

const AddTask = () => {
  const [activeTab, setActiveTab] = useState<string>("task");
  const [taskData, setTaskData] = useState({
    subject: "",
    milestone: "",
    startDate: "",
    deadline: "",
    priority: "Medium",
    tags: "",
    description: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setTaskData({
      ...taskData,
      [id]: value,
    });
  };

  const handleSelectChange = (value: string, key: string) => {
    setTaskData({
      ...taskData,
      [key]: value,
    });
  };

  const handleEditorChange = (content: string) => {
    setTaskData({
      ...taskData,
      description: content,
    });
  };

  const saveTaskHandler = () => {
    console.log("Task Details saved:", taskData);
  };

  return (
    <Card style={{ padding: "16px", borderRadius: "8px" }}>
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <Tabs.TabPane
          key="task"
          tab={
            <Space>
              <FileAddOutlined />
              Task
            </Space>
          }
        >
          <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
            <Col span={12}>
              <Form.Item label="Subject">
                <Input
                  placeholder="Enter subject"
                  id="subject"
                  onChange={handleChange}
                  value={taskData.subject}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Milestone">
                <Select
                  placeholder="Select milestone"
                  onChange={(value) => handleSelectChange(value, "milestone")}
                >
                  <Select.Option value="">None selected</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Start Date">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Deadline">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Priority">
                <Select
                  defaultValue="Medium"
                  onChange={(value) => handleSelectChange(value, "priority")}
                >
                  <Select.Option value="Low">Low</Select.Option>
                  <Select.Option value="Medium">Medium</Select.Option>
                  <Select.Option value="High">High</Select.Option>
                  <Select.Option value="Urgent">Urgent</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Tags">
                <Input
                  placeholder="Enter tags"
                  id="tags"
                  onChange={handleChange}
                  value={taskData.tags}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
            <Col span={24}>
              <Form.Item label="Task Description">
                <Editor
                  value={taskData.description}
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | " +
                      "alignleft aligncenter alignright alignjustify | " +
                      "bullist numlist outdent indent | removeformat | help",
                  }}
                  onEditorChange={handleEditorChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </Tabs.TabPane>

        <Tabs.TabPane
          key="doc"
          tab={
            <Space>
              <FileTextOutlined />
              Doc
            </Space>
          }
        >
          <Doc />
        </Tabs.TabPane>

        <Tabs.TabPane
          key="reminder"
          tab={
            <Space>
              <BellOutlined />
              Reminder
            </Space>
          }
        >
          <Reminder />
        </Tabs.TabPane>

        <Tabs.TabPane
          key="whiteboard"
          tab={
            <Space>
              <CalendarOutlined />
              Whiteboard
            </Space>
          }
        >
          <Whiteboard />
        </Tabs.TabPane>
      </Tabs>

      <Space
        style={{ marginTop: "16px", justifyContent: "flex-end", width: "100%" }}
      >
        <Button type="primary" onClick={saveTaskHandler}>
          Save
        </Button>
        <Button type="default" onClick={saveTaskHandler}>
          Save & Create New
        </Button>
      </Space>
    </Card>
  );
};

export default AddTask;
