import type {
  ActionType,
  EditableFormInstance,
  ProColumns,
} from "@ant-design/pro-components";
import { EditableProTable, ProCard, ProForm } from "@ant-design/pro-components";
import React, { useRef, useState } from "react";
import { Select, Button, Input } from "antd";
import lang from "../../../../../lang";
import { DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;
export type InvoiceTableDataType = {
  id: React.Key;
  item?: string;
  description?: string;
  quantity?: number;
  rate?: number;
  discount?: string;
  tax?: string;
  amount?: number;
  warranty?: string;
};

const itemsData = [
  { id: 1, item: "Item 1", description: "Description 1", rate: 100 },
  { id: 2, item: "Item 2", description: "Description 2", rate: 200 },
  { id: 3, item: "Item 3", description: "Description 3", rate: 300 },
  { id: 4, item: "Item 4", description: "Description 4", rate: 400 },
  { id: 5, item: "Item 5", description: "Description 5", rate: 500 },
  { id: 6, item: "Item 6", description: "Description 6", rate: 600 },
  { id: 7, item: "Item 7", description: "Description 7", rate: 700 },
  { id: 8, item: "Item 8", description: "Description 8", rate: 800 },
  { id: 9, item: "Item 9", description: "Description 9", rate: 900 },
  { id: 10, item: "Item 10", description: "Description 10", rate: 1000 },
];

export const AddPerformaTable: React.FC = () => {
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);
  const formRef = useRef<any>();
  const actionRef = useRef<ActionType>();
  const editableFormRef = useRef<EditableFormInstance>();
  const [pageSize, setPageSize] = useState(15);
  const defaultValues = {
    description: "N/A",
    quantity: 1,
    rate: 0.0,
    tax: "No Tax",
    warranty: "No Warranty",
  };

  const addNewBlankRow = (data?: any) => {
    const tableData = formRef.current?.getFieldValue("table") || [];
    const newRow = { id: Date.now(), ...defaultValues, ...data };
    formRef.current?.setFieldsValue({ table: [...tableData, newRow] });
    setEditableRowKeys([...editableKeys, newRow.id]);
  };

  const autoSave = (data: InvoiceTableDataType[]) => {
    console.log("Auto-saving data:", data);
  };

  const columns: ProColumns<InvoiceTableDataType>[] = [
    {
      title: lang.get("sales", ["Item Details"]),
      dataIndex: "item",
      renderFormItem: (_, { record }) => (
        <Select
          placeholder={lang.get("sales", ["Select an item"])}
          onChange={(value) => {
            const selectedItem = itemsData.find((item) => item.item === value);
            if (selectedItem) {
              const updatedRow = {
                ...record,
                item: selectedItem.item,
                description: selectedItem.description,
                rate: selectedItem.rate,
                amount: selectedItem.rate * (record?.quantity || 1),
              };

              const currentTableData =
                formRef.current?.getFieldValue("table") || [];
              const updatedTable = currentTableData.map((row: any) =>
                row.id === record?.id ? updatedRow : row
              );

              formRef.current?.setFieldsValue({ table: updatedTable });
              addNewBlankRow();
            }
          }}
          value={record?.item || ""}
          style={{ width: "100%" }}
        >
          {itemsData.map((item) => (
            <Option key={item.id} value={item.item}>
              {item.item}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: lang.get("sales", ["Description"]),
      dataIndex: "description",
      formItemProps: {
        rules: [{ required: true, message: lang.get("sales", ["Please enter a description"]) }],
      },
      renderFormItem: () => (
        <Input.TextArea autoSize={{ minRows: 1, maxRows: 3 }} />
      ),
    },
    {
      title: lang.get("sales", ["Quantity"]),
      dataIndex: "quantity",
      valueType: "digit",
      onCell: (record) => ({
        onChange: (value) => {
          formRef.current?.setFieldsValue({
            table: formRef.current?.getFieldValue("table").map((row: any) =>
              row.id === record.id
                ? {
                    ...row,
                    quantity: Number(value),
                    amount: Number(value) * Number(row.rate || 0),
                  }
                : row
            ),
          });
        },
      }),
    },
    {
      title: lang.get("sales", ["Free Quantity"]),
      dataIndex: "freequantity",
      valueType: "digit",
      onCell: (record) => ({
        onChange: (value) => {
          formRef.current?.setFieldsValue({
            table: formRef.current?.getFieldValue("table").map((row: any) =>
              row.id === record.id
                ? {
                    ...row,
                    quantity: Number(value),
                    amount: Number(value) * Number(row.rate || 0),
                  }
                : row
            ),
          });
        },
      }),
    },
    {
      title: lang.get("sales", ["Rate"]),
      dataIndex: "rate",
      valueType: "money",
      onCell: (record) => ({
        onChange: (value) => {
          formRef.current?.setFieldsValue({
            table: formRef.current?.getFieldValue("table").map((row: any) =>
              row.id === record.id
                ? {
                    ...row,
                    rate: Number(value),
                    amount: (row.quantity || 0) * Number(value),
                  }
                : row
            ),
          });
        },
      }),
    },
    {
      title: lang.get("sales", ["Discount"]),
      dataIndex: "discount",
      renderFormItem: () => (
        <Select placeholder={lang.get("sales", ["Select Discount"])}>
          <Option value="No Tax">Fixed</Option>
          <Option value="5%">5%</Option>
          <Option value="10%">10%</Option>
        </Select>
      ),
    },
    {
      title: lang.get("sales", ["Tax"]),
      dataIndex: "tax",
      renderFormItem: () => (
        <Select mode="multiple" placeholder={lang.get("sales", ["Select Tax"])}>
          <Option value="No Tax">No Tax</Option>
          <Option value="5%">VAT (5%)</Option>
          <Option value="10%">CGT (10%)</Option>
        </Select>
      ),
    },
    {
      title: lang.get("sales", ["Amount"]),
      dataIndex: "amount",
      editable: false,
      render: (_, record) => {
        const quantity = record.quantity || 0;
        const rate = record.rate || 0;
        const amount = (quantity * rate).toFixed(2);
        return <span>{amount}</span>;
      },
    },
    {
      title: lang.get("sales", ["Warranty"]),
      dataIndex: "warranty",
      renderFormItem: () => (
        <Select placeholder={lang.get("sales", ["Select Warranty Period"])}>
          <Option value="No Warranty">No Warranty</Option>
          <Option value="6 Months">6 Months</Option>
          <Option value="1 Year">1 Year</Option>
          <Option value="2 Years">2 Years</Option>
        </Select>
      ),
    },
    {
      title: lang.get("sales", ["Actions"]),
      valueType: "option",
      render: (_, row) => [
        <Button
          key="delete"
          type="link"
          danger
          onClick={() => {
            const tableDataSource = formRef.current?.getFieldValue(
              "table"
            ) as InvoiceTableDataType[];
            formRef.current?.setFieldsValue({
              table: tableDataSource.filter((item) => item.id !== row?.id),
            });
          }}
        >
          <DeleteOutlined />
        </Button>,
      ],
    },
  ];

  return (
    <ProCard
      style={{
        border: "1px solid #d9d9d9",
        borderRadius: "8px",
        backgroundColor: "#f9f9fb",
      }}
    >
      <ProForm
        formRef={formRef}
        initialValues={{
          table: [{ id: Date.now(), ...defaultValues }],
        }}
      >
        <EditableProTable<InvoiceTableDataType>
          rowKey="id"
          scroll={{ x: 800 }}
          search={false}
          editableFormRef={editableFormRef}
          actionRef={actionRef}
          value={formRef.current?.getFieldValue("table") || []}
          onChange={(value) => {
            const mutableValue = [...value];
            formRef.current?.setFieldsValue({ table: value });
            autoSave(mutableValue);
          }}
          name="table"
          maxLength={10}
          columns={columns}
          pagination={{
            defaultCurrent: 1,
            pageSize: pageSize,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 50],
            showTotal: (total, range) =>
              lang.get("sales", [
                `Showing ${range[0]}-${range[1]} of ${total} items`,
              ]),
            onShowSizeChange: (current, size) => {
              setPageSize(size);
            },
          }}
          recordCreatorProps={{
            position: "bottom",
            record: () => ({
              id: Date.now(),
              ...defaultValues,
            }),
          }}
          editable={{
            type: "multiple",
            editableKeys,
            onChange: setEditableRowKeys,
            actionRender: (row, config, defaultDoms) => {
              return [defaultDoms.delete];
            },
          }}
        />
      </ProForm>
    </ProCard>
  );
};

export default AddPerformaTable;
