import { SearchOutlined } from "@ant-design/icons";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import { Button, Input, Space, InputRef, Tag, DatePicker } from "antd";
import { ReactNode, useRef, useState } from "react";
import lang from "../../../../../../lang";
import Export from "../../../../../../common/components/Export";

const { RangePicker } = DatePicker;

export const waitTimePromise = async (time: number = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};

export const waitTime = async (time: number = 100) => {
  await waitTimePromise(time);
};

interface Proposal {
  id: string;
  subject: string;
  total: string;
  date: string;
  openTill: string;
  tags: string[];
  dateCreated: string;
  status: string;
}

const Proposals = [
  {
    id: "PRO-000001",
    subject: "Test Complain",
    total: "$6,500.00",
    date: "2023-11-30",
    openTill: "2023-12-07",
    tags: ["Urgent", "Follow-up"],
    dateCreated: "2023-11-30 10:41:27",
    status: "Sent",
  },
  {
    id: "PRO-000002",
    subject: "New Proposal",
    total: "$12,000.00",
    date: "2023-12-01",
    openTill: "2023-12-15",
    tags: ["Important"],
    dateCreated: "2023-12-01 09:30:00",
    status: "Draft",
  },
];

const ProposalTable = () => {
  const actionRef = useRef<ActionType>();
  const [pageSize, setPageSize] = useState<number>(10);
  const searchInput = useRef<InputRef>(null);
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);
  const [allSearch, setAllSearch] = useState<string>("");

  const expobj = new Export("proposals, " + new Date().toDateString());

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find(
      (attr) => attr.key === field
    );

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([
        ...searchAttributes,
        {
          key: field,
          value,
        },
      ]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("proposals", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={
                searchAttributes.filter((item) => item.key === field)[0]?.value
              }
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  if (actionRef.current) {
                    actionRef.current?.reset?.();
                    actionRef.current.reload();
                  }
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => {
                  actionRef.current?.reset?.();
                  actionRef.current?.reload();
                }}
                style={{ width: 90 }}
              >
                {lang.get("proposals", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("proposals", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<Proposal>[] = [
    {
      title: lang.get("proposals", ["Proposal #"]),
      dataIndex: "id",
      sorter: true,
      ellipsis: true,
      ...searchCols("id", lang.get("proposals", ["Proposal #"])),
    },
    {
      title: lang.get("proposals", ["Subject"]),
      dataIndex: "subject",
      sorter: true,
      ...searchCols("subject", lang.get("proposals", ["Subject"])),
    },
    {
      title: lang.get("proposals", ["Total"]),
      dataIndex: "total",
      sorter: true,
      ...searchCols("total", lang.get("proposals", ["Total"])),
    },
    {
      title: lang.get("proposals", ["Date"]),
      dataIndex: "date",
      sorter: true,
      ...searchCols("date", lang.get("proposals", ["Date"])),
    },
    {
      title: lang.get("proposals", ["Open Till"]),
      dataIndex: "openTill",
      sorter: true,
      ...searchCols("openTill", lang.get("proposals", ["Open Till"])),
    },
    {
      title: lang.get("proposals", ["Tags"]),
      dataIndex: "tags",
      key: "tags",
      sorter: true,
      ...searchCols("tags", lang.get("proposals", ["Tags"])),
      render: (tags: ReactNode) => {
        const tagArray = tags as string[] | undefined;
        return tagArray && tagArray.length > 0 ? (
          tagArray.map((tag) => (
            <Tag color="blue" key={tag}>
              {tag}
            </Tag>
          ))
        ) : (
          <span style={{ color: "#ccc" }}>-</span>
        );
      },
    },
    {
      title: lang.get("proposals", ["Date Created"]),
      dataIndex: "dateCreated",
      sorter: true,
      ...searchCols("dateCreated", lang.get("proposals", ["Date Created"])),
    },
    {
      title: lang.get("proposals", ["Status"]),
      dataIndex: "status",
      sorter: true,
      ...searchCols("status", lang.get("proposals", ["Status"])),
      filters: [
        { text: lang.get("proposals", ["Sent"]), value: "Sent" },
        { text: lang.get("proposals", ["Draft"]), value: "Draft" },
      ],
      render: (_, record) =>
        record.status === "Sent" ? (
          <Tag color="green">{lang.get("proposals", ["Sent"])}</Tag>
        ) : (
          <Tag color="orange">{lang.get("proposals", ["Draft"])}</Tag>
        ),
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("proposals", ["Proposals"])}
      columns={columns}
      actionRef={actionRef}
      dataSource={Proposals}
      rowKey="id"
      cardBordered
      pagination={{
        defaultCurrent: 1,
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: [10, 20, 50],
        showTotal: (total, range) =>
          lang.get("proposals", [
            `Showing ${range[0]}-${range[1]} of ${total} items`,
          ]),
        onShowSizeChange: (current, size) => {
          setPageSize(size);
        },
      }}
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <RangePicker key="date" />,
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("proposals", ["Global Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef?.current?.reload();
          }}
        />,
        <Button
          key="csv"
          onClick={() => {
            const csvData = Proposals.map((row) => ({
              [lang.get("Proposals", ["#"])]: row.id,
              [lang.get("Proposals", ["Subject"])]: row.subject,
              [lang.get("Proposals", ["Total"])]: row.total,
              [lang.get("Proposals", ["Date"])]: row.date,
              [lang.get("Proposals", ["OpenTill"])]: row.openTill,
              [lang.get("Proposals", ["Tags"])]: row.tags,
              [lang.get("Proposals", ["Date Created"])]: row.dateCreated,
              [lang.get("Proposals", ["Status"])]: row.status,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("proposals", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = Proposals.map((row) => ({
              [lang.get("Proposals", ["#"])]: row.id,
              [lang.get("Proposals", ["Subject"])]: row.subject,
              [lang.get("Proposals", ["Total"])]: row.total,
              [lang.get("Proposals", ["Date"])]: row.date,
              [lang.get("Proposals", ["OpenTill"])]: row.openTill,
              [lang.get("Proposals", ["Tags"])]: row.tags,
              [lang.get("Proposals", ["Date Created"])]: row.dateCreated,
              [lang.get("Proposals", ["Status"])]: row.status,
            }));
            expobj.exportToCSV(excelData);
          }}
        >
          {lang.get("proposals", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  [lang.get("Proposals", ["#"])],
                  [lang.get("Proposals", ["Subject"])],
                  [lang.get("Proposals", ["Total"])],
                  [lang.get("Proposals", ["Date"])],
                  [lang.get("Proposals", ["OpenTill"])],
                  [lang.get("Proposals", ["Tags"])],
                  [lang.get("Proposals", ["Date Created"])],
                  [lang.get("Proposals", ["Status"])],
                ],
              ],
              body: Proposals.map((row) => [
                row.id,
                row.subject,
                row.total,
                row.date,
                row.openTill,
                row.tags,
                row.dateCreated,
                row.status,
              ]),
            });
          }}
        >
          {lang.get("proposals", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default ProposalTable;
