import React, { useState } from "react";
import { Card, Dropdown, Button, Input, Space, MenuProps } from "antd";
import { FileTextOutlined, FileAddOutlined } from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";

const Doc = () => {
  const [showEditor, setShowEditor] = useState(false);

  const toggleEditor = () => setShowEditor(!showEditor);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Doc 1",
    },
    {
      key: "2",
      label: "Doc 2",
    },
    {
      key: "3",
      label: "Doc 3",
    },
    {
      type: "divider",
    },
    {
      key: "4",
      label: (
        <span>
          <FileAddOutlined /> Add New
        </span>
      ),
    },
  ];

  return (
    <Card style={{ padding: "16px", borderRadius: "8px" }}>
      {/* Dropdown Menu */}
      <Dropdown menu={{ items }} trigger={["click"]}>
        <Button type="text" icon={<FileTextOutlined />}>
          My Docs
        </Button>
      </Dropdown>

      {/* Form for Document */}
      <Space
        direction="vertical"
        size="large"
        style={{ width: "100%", marginTop: "16px" }}
      >
        {/* Document Title */}
        <Input
          placeholder="Name this Doc..."
          size="large"
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            border: "none",
            padding: "0",
            backgroundColor: "transparent",
          }}
        />

        {/* Start Writing Button */}
        <Button
          type="default"
          block
          icon={<FileTextOutlined />}
          onClick={toggleEditor}
        >
          Start Writing
        </Button>

        {/* Editor Section */}
        {showEditor && (
          <Space
            direction="vertical"
            style={{ width: "100%", marginTop: "16px" }}
          >
            <Editor
              init={{
                height: 300,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | " +
                  "alignleft aligncenter alignright alignjustify | " +
                  "bullist numlist outdent indent | removeformat | help",
              }}
              onEditorChange={(content) => console.log("Content:", content)}
            />
            <Space
              direction="horizontal"
              style={{
                width: "100%",
                justifyContent: "flex-end",
                marginTop: "16px",
              }}
            >
              <Button onClick={toggleEditor}>Close</Button>
              <Button
                type="primary"
                onClick={() => console.log("Save editor content")}
              >
                Save
              </Button>
            </Space>
          </Space>
        )}

        {/* AI Writing Button */}
        <Button type="default" block>
          ✨ Write with AI
        </Button>
      </Space>

      {/* Add New Doc Link */}
      <div style={{ marginTop: "16px" }}>
        <Button type="link" style={{ padding: 0 }}>
          Add New
        </Button>
      </div>
    </Card>
  );
};

export default Doc;
