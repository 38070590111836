import React, { useState, useRef } from "react";
import { Button, Input, InputRef, Space, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ProTable, ProColumns, ActionType } from "@ant-design/pro-components";
import lang from "../../../../../../lang";
import Export from "../../../../../../common/components/Export";

interface Contract {
  id: string;
  subject: string;
  customer: string;
  contractType: string;
  contractValue: number;
  startDate: string;
  endDate: string;
  signature: string;
  validityPeriod: string;
}

const contractData: Contract[] = [
  {
    id: "1",
    subject: "Cloud Hosting Agreement",
    customer: "ABC Corp",
    contractType: "Service",
    contractValue: 5000,
    startDate: "2024-01-01",
    endDate: "2024-12-31",
    signature: "Signed",
    validityPeriod: "1 year",
  },
];

const ContractTable: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(6);
  const searchInput = useRef<InputRef>(null);
  const actionRef = useRef<ActionType>();
  const [allSearch, setAllSearch] = useState<string>("");
  const [searchAttributes, setSearchAttributes] = useState<
    { key: string; value: string }[]
  >([]);

  const expobj = new Export(lang.get("contracts", ["Contracts"]) + ", " + new Date().toDateString());

  const handleSearch = (field: string, value: string) => {
    setAllSearch("");
    const existingAttribute = searchAttributes.find((attr) => attr.key === field);

    if (existingAttribute) {
      setSearchAttributes(
        searchAttributes.map((attr) =>
          attr.key === field ? { ...attr, value } : attr
        )
      );
    } else {
      setSearchAttributes([...searchAttributes, { key: field, value }]);
    }
  };

  const searchCols = (field: string, label: string) => {
    return {
      filterDropdown: (props: any) => {
        const { close } = props;

        setTimeout(() => {
          if (searchInput.current) {
            searchInput.current.focus();
          }
        }, 100);

        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={lang.get("contracts", [`Search ${label}`])}
              onChange={(e) => handleSearch(field, e.target.value)}
              value={searchAttributes.find((item) => item.key === field)?.value}
              style={{ marginBottom: 8, display: "block" }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  close();
                  actionRef.current?.reload();
                }
              }}
            />
            <Space>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                size="small"
                onClick={() => actionRef.current?.reload()}
                style={{ width: 90 }}
              >
                {lang.get("contracts", ["Search"])}
              </Button>
              <Button
                onClick={() => close()}
                size="small"
                style={{ width: 90 }}
              >
                {lang.get("contracts", ["Close"])}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
    };
  };

  const columns: ProColumns<Contract>[] = [
    {
      title: lang.get("contracts", ["ID"]),
      dataIndex: "id",
      key: "id",
      sorter: true,
      ...searchCols("id", lang.get("contracts", ["ID"])),
    },
    {
      title: lang.get("contracts", ["Subject"]),
      dataIndex: "subject",
      key: "subject",
      sorter: true,
      ...searchCols("subject", lang.get("contracts", ["Subject"])),
    },
    {
      title: lang.get("contracts", ["Customer"]),
      dataIndex: "customer",
      key: "customer",
      sorter: true,
      ...searchCols("customer", lang.get("contracts", ["Customer"])),
    },
    {
      title: lang.get("contracts", ["Contract Type"]),
      dataIndex: "contractType",
      key: "contractType",
      sorter: true,
      ...searchCols("contractType", lang.get("contracts", ["Contract Type"])),
    },
    {
      title: lang.get("contracts", ["Contract Value"]),
      dataIndex: "contractValue",
      key: "contractValue",
      sorter: true,
      render: (_, record: Contract) => `$${record.contractValue}`,
    },
    {
      title: lang.get("contracts", ["Start Date"]),
      dataIndex: "startDate",
      key: "startDate",
      sorter: true,
      ...searchCols("startDate", lang.get("contracts", ["Start Date"])),
    },
    {
      title: lang.get("contracts", ["End Date"]),
      dataIndex: "endDate",
      key: "endDate",
      sorter: true,
      ...searchCols("endDate", lang.get("contracts", ["End Date"])),
    },
    {
      title: lang.get("contracts", ["Signature"]),
      dataIndex: "signature",
      key: "signature",
      sorter: true,
      ...searchCols("signature", lang.get("contracts", ["Signature"])),
      render: (_, record: Contract)  => (
        <Tag color={record.signature === "Signed" ? "green" : "red"}>
          {lang.get("contracts", [record.signature])}
        </Tag>
      ),
    },
    {
      title: lang.get("contracts", ["Validity Period"]),
      dataIndex: "validityPeriod",
      key: "validityPeriod",
      sorter: true,
      ...searchCols("validityPeriod", lang.get("contracts", ["Validity Period"])),
    },
  ];

  return (
    <ProTable
      headerTitle={lang.get("contracts", ["Contracts"])}
      columns={columns}
      dataSource={contractData}
      actionRef={actionRef}
      rowKey="id"
      pagination={{
        defaultCurrent: 1,
        pageSize,
        showSizeChanger: true,
        pageSizeOptions: [6, 10, 20],
        showTotal: (total, range) =>
          lang.get("contracts", [`Showing ${range[0]}-${range[1]} of ${total} items`]),
        onShowSizeChange: (_, size) => setPageSize(size),
      }}
      search={false}
      scroll={{ x: 800 }}
      toolBarRender={() => [
        <Input
          style={{ width: "auto" }}
          key="all_search"
          allowClear
          placeholder={lang.get("contracts", ["Global Search"])}
          value={allSearch}
          onChange={(e) => {
            setAllSearch(e.target.value);
            setSearchAttributes([]);
            actionRef.current?.reload();
          }}
        />,
        <Button
          key="csv"
          onClick={() => {
            const csvData = contractData.map((row) => ({
              [lang.get("contracts", ["ID"])]: row.id,
              [lang.get("contracts", ["Subject"])]: row.subject,
              [lang.get("contracts", ["Customer"])]: row.customer,
              [lang.get("contracts", ["Contract Type"])]: row.contractType,
              [lang.get("contracts", ["Contract Value"])]: `$${row.contractValue.toLocaleString()}`,
              [lang.get("contracts", ["Start Date"])]: row.startDate,
              [lang.get("contracts", ["End Date"])]: row.endDate,
              [lang.get("contracts", ["Signature"])]: row.signature,
              [lang.get("contracts", ["Validity Period"])]: row.validityPeriod,
            }));
            expobj.exportToCSV(csvData);
          }}
        >
          {lang.get("contracts", ["Export CSV"])}
        </Button>,
        <Button
          key="excel"
          onClick={() => {
            const excelData = contractData.map((row) => ({
              [lang.get("contracts", ["ID"])]: row.id,
              [lang.get("contracts", ["Subject"])]: row.subject,
              [lang.get("contracts", ["Customer"])]: row.customer,
              [lang.get("contracts", ["Contract Type"])]: row.contractType,
              [lang.get("contracts", ["Contract Value"])]: `$${row.contractValue.toLocaleString()}`,
              [lang.get("contracts", ["Start Date"])]: row.startDate,
              [lang.get("contracts", ["End Date"])]: row.endDate,
              [lang.get("contracts", ["Signature"])]: row.signature,
              [lang.get("contracts", ["Validity Period"])]: row.validityPeriod,
            }));
            expobj.exportToExcel(excelData);
          }}
        >
          {lang.get("contracts", ["Export Excel"])}
        </Button>,
        <Button
          key="pdf"
          onClick={() => {
            expobj.exportToPDF({
              head: [
                [
                  lang.get("contracts", ["ID"]),
                  lang.get("contracts", ["Subject"]),
                  lang.get("contracts", ["Customer"]),
                  lang.get("contracts", ["Contract Type"]),
                  lang.get("contracts", ["Contract Value"]),
                  lang.get("contracts", ["Start Date"]),
                  lang.get("contracts", ["End Date"]),
                  lang.get("contracts", ["Signature"]),
                  lang.get("contracts", ["Validity Period"]),
                ],
              ],
              body: contractData.map((row) => [
                row.id,
                row.subject,
                row.customer,
                row.contractType,
                `$${row.contractValue.toLocaleString()}`,
                row.startDate,
                row.endDate,
                row.signature,
                row.validityPeriod,
              ]),
            });
          }}
        >
          {lang.get("contracts", ["Export PDF"])}
        </Button>,
      ]}
    />
  );
};

export default ContractTable;
