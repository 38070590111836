import { useState, useEffect, ChangeEvent } from "react";
import { Button, Modal, Input, Form, Tooltip, Select } from "antd";
import { ClockCircleOutlined, FolderAddOutlined } from "@ant-design/icons";
import lang from "../../../../../../lang";

const { TextArea } = Input;
const { Option } = Select;

const StartTimerComponent = () => {
  const [showStartModal, setShowStartModal] = useState(false);
  const [showStopModal, setShowStopModal] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [taskData, setTaskData] = useState({
    customerName: "",
    project: "",
    task: "",
    notes: "",
    billable: false,
  });

  useEffect(() => {
    let timerInterval: ReturnType<typeof setInterval> | undefined;

    if (isTimerRunning && startTime) {
      timerInterval = setInterval(() => {
        const currentTime = new Date();
        const timeDifference = Math.floor(
          (currentTime.getTime() - startTime.getTime()) / 1000
        );
        setElapsedTime(timeDifference);
      }, 1000);
    } else if (timerInterval) {
      clearInterval(timerInterval);
    }

    return () => {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    };
  }, [isTimerRunning, startTime]);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(remainingSeconds).padStart(2, "0")}`;
  };

  const handleStartShow = () => setShowStartModal(true);
  const handleStartClose = () => setShowStartModal(false);
  const handleStopShow = () => setShowStopModal(true);
  const handleStopClose = () => setShowStopModal(false);

  const startTimer = () => {
    setStartTime(new Date());
    setIsTimerRunning(true);
    setElapsedTime(0);
    setShowStartModal(false);
  };

  const stopTimer = () => {
    setIsTimerRunning(false);
    setStartTime(null);
    setShowStopModal(false);
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setTaskData({
      ...taskData,
      [id]: value,
    });
  };

  return (
    <div>
      {/* Start/Running Timer Button */}
      {!isTimerRunning ? (
        <ClockCircleOutlined
          onClick={handleStartShow}
          style={{
            fontSize: "16px",
            cursor: "pointer",
          }}
        />
      ) : (
        <Tooltip title={lang.get("timer", ["Elapsed Time"])}>
          <span
            style={{
              marginLeft: "8px",
              fontSize: "16px",
              color: "red",
              cursor: "pointer",
            }}
            onClick={handleStopShow}
          >
            {formatTime(elapsedTime)}
          </span>
        </Tooltip>
      )}

      {/* Start Timer Modal */}
      <Modal
        title={lang.get("timer", ["Start Timer"])}
        open={showStartModal}
        onCancel={handleStartClose}
        footer={null}
        centered
      >
        <div style={{ textAlign: "center" }}>
          <FolderAddOutlined style={{ fontSize: "48px", color: "#6c757d" }} />
          <h3>{lang.get("timer", ["No started timers found"])}</h3>
          <Button color="default" variant="outlined" onClick={startTimer}>
            {lang.get("timer", ["Start Timer"])}
          </Button>
          <p>
            <button
              type="button"
              onClick={() =>
                alert(lang.get("timer", ["Navigating to timesheets"]))
              }
              style={{
                background: "none",
                border: "none",
                padding: 0,
                color: "#007bff",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {lang.get("timer", ["View all timesheets"])}
            </button>
          </p>
        </div>
      </Modal>

      {/* Stop Timer Modal */}
      <Modal
        title={lang.get("timer", ["Stop Timer"])}
        open={showStopModal}
        onCancel={handleStopClose}
        footer={null}
        centered
      >
        <Form layout="vertical">
          <Form.Item label={lang.get("timer", ["Time"])} name="time">
            <div
              style={{
                padding: "8px 12px",
                background: "#f5f5f5",
                borderRadius: "4px",
              }}
            >
              {formatTime(elapsedTime) ||
                lang.get("timer", ["No time recorded"])}
            </div>
          </Form.Item>
          <Form.Item
            label={lang.get("timer", ["Customer Name"])}
            name="customerName"
          >
            <Select
              placeholder={lang.get("timer", ["Select customer"])}
              value={taskData.customerName}
              onChange={(value) =>
                setTaskData({ ...taskData, customerName: value })
              }
            >
              <Option value="Customer A">
                {lang.get("customers", ["Customer A"])}
              </Option>
              <Option value="Customer B">
                {lang.get("customers", ["Customer B"])}
              </Option>
              <Option value="Customer C">
                {lang.get("customers", ["Customer C"])}
              </Option>
            </Select>
          </Form.Item>
          <Form.Item label={lang.get("timer", ["Project"])} name="project">
            <Select
              placeholder={lang.get("timer", ["Select project"])}
              value={taskData.project}
              onChange={(value) => setTaskData({ ...taskData, project: value })}
            >
              <Option value="Project X">
                {lang.get("projects", ["Project X"])}
              </Option>
              <Option value="Project Y">
                {lang.get("projects", ["Project Y"])}
              </Option>
              <Option value="Project Z">
                {lang.get("projects", ["Project Z"])}
              </Option>
            </Select>
          </Form.Item>
          <Form.Item label={lang.get("timer", ["Task"])} name="task">
            <Input
              placeholder={lang.get("timer", ["Enter task"])}
              value={taskData.task}
              onChange={handleChange}
              id="task"
            />
          </Form.Item>
          <Form.Item label={lang.get("timer", ["Notes"])} name="notes">
            <TextArea
              rows={3}
              placeholder={lang.get("timer", ["Add notes"])}
              value={taskData.notes}
              onChange={handleChange}
              id="notes"
            />
          </Form.Item>
          <Form.Item
            label={lang.get("timer", ["Billable (Yes / No)"])}
            name="billable"
          >
            <Select
              placeholder={lang.get("timer", ["Select Yes or No"])}
              value={taskData.billable ? "Yes" : "No"}
              onChange={(value) =>
                setTaskData({ ...taskData, billable: value === "Yes" })
              }
            >
              <Option value="Yes">{lang.get("timer", ["Yes"])}</Option>
              <Option value="No">{lang.get("timer", ["No"])}</Option>
            </Select>
          </Form.Item>
          <Button type="primary" block onClick={stopTimer}>
            {lang.get("timer", ["Confirm"])}
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default StartTimerComponent;
