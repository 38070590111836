import { Button, Space, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Customer } from "../../../types/types";
import ContractTable from "./ContractTable";
import lang from "../../../../../lang";

const Contract = (props: {
  client: Customer;
  preload: boolean;
  setRefresh: (e: boolean) => void;
  refresh: boolean;
}) => {
  return (
    <div style={{ padding: "16px", borderRadius: "8px" }}>
      <Row
        gutter={[16, 16]}
        style={{ marginBottom: "16px", marginTop: "16px" }}
        align="middle"
        justify="space-between"
      >
        {/* Left Section: Buttons */}
        <Col xs={24} sm={24} md={12} lg={12}>
          <Space size="middle" wrap>
            <Button type="primary" icon={<PlusOutlined />}>
               {lang.get("contract", ["New Agreement"])}
            </Button>
          </Space>
        </Col>
      </Row>

     <ContractTable />
    </div>
  );
};

export default Contract;
