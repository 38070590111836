import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Breadcrumb, Card, notification } from "antd";
import lang from "../../../lang";
import { projects, Project } from "../common/data/ProjectsData";
import ProjectSummary from "./components/SummarySection";
import ProjectTabs from "./components/ProjectDetailsTab";

const ProjectDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [project, setProject] = useState<Project | undefined>();
  const [currentProjectId, setCurrentProjectId] = useState<number | undefined>();


  const summaryRef = useRef<HTMLDivElement>(null);
  const tabsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (id) {
      const projectId = parseInt(id, 10);
      const foundProject = projects.find((proj) => proj.id === projectId);
      if (foundProject) {
        setCurrentProjectId(projectId);
        setProject(foundProject);
      } else {
        notification.error({
          message: lang.get("notifications", ["Error"]),
          description: lang.get("notifications", ["Project not found!"]),
        });
      }
    }
  }, [id]);

  const handleProjectChange = (newProjectId: number) => {
    const foundProject = projects.find((proj) => proj.id === newProjectId);
    if (foundProject) {
      setCurrentProjectId(newProjectId);
      setProject(foundProject);
    }
  };

  if (!currentProjectId || !project) {
    return (
      <Card style={{ marginTop: 50, textAlign: "center" }}>
        <p>{lang.get("projects", ["Project not found"])}</p>
      </Card>
    );
  }

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <div ref={summaryRef} id="projects-summary">
        <Breadcrumb
          style={{ marginBottom: "20px" }}
          items={[
            { title: lang.get("breadcrumbs", ["Home"]), href: "/" },
            { title: lang.get("breadcrumbs", ["Projects"]), href: "/projects" },
            { title: lang.get("projects", [project.name]) },
          ]}
        />
        <Card
          style={{
            backgroundColor: "#f5f7fa",
          }}
        >
          <ProjectSummary
            defaultProjectId={currentProjectId}
            onProjectChange={handleProjectChange}
          />
        </Card>
      </div>
      <div ref={tabsRef} id="project-tabs" style={{ marginTop: "20px" }}>
        <ProjectTabs projectId={currentProjectId} />
      </div>
    </div>
  );
};

export default ProjectDetails;
