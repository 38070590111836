import React from "react";
import { ProForm, ProCard } from "@ant-design/pro-components";
import {
  Button,
  Row,
  Col,
  Divider,
  Typography,
  Input,
  Select,
  Upload,
  Form,
} from "antd";
import { ProFormUploadDragger } from "@ant-design/pro-components";
import { Editor } from "@tinymce/tinymce-react";
import lang from "../../../lang";
import { SendOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { Option } = Select;

const ShipmentAdd: React.FC = () => {
  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <Title level={2}>{lang.get("inventory", ["Shipment Add"])}</Title>
      <ProCard>
        <ProForm layout="vertical" submitter={false}>
          <Row gutter={[16, 5]}>
            {/* Inventory Delivery Voucher */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("shipment", ["Inventory Delivery Voucher"])}
                  </span>
                }
                name="inventoryDeliveryVoucher"
              >
                <Select
                  placeholder={lang.get("shipment", [
                    "Select Inventory Delivery Voucher",
                  ])}
                  size="large"
                >
                  <Option value="IDV-001">
                    {lang.get("shipment", ["Voucher 1"])}
                  </Option>
                  <Option value="IDV-002">
                    {lang.get("shipment", ["Voucher 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Shipping Carrier */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("shipment", ["Shipping Carrier"])}
                  </span>
                }
                name="shippingCarrier"
              >
                <Select
                  placeholder={lang.get("shipment", [
                    "Select Shipping Carrier",
                  ])}
                  size="large"
                >
                  <Option value="Carrier-001">
                    {lang.get("shipment", ["Carrier 1"])}
                  </Option>
                  <Option value="Carrier-002">
                    {lang.get("shipment", ["Carrier 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Delivery Person */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("shipment", ["Delivery Person"])}
                  </span>
                }
                name="deliveryPerson"
              >
                <Select
                  placeholder={lang.get("shipment", ["Select Delivery Person"])}
                  size="large"
                >
                  <Option value="Person-001">
                    {lang.get("shipment", ["Person 1"])}
                  </Option>
                  <Option value="Person-002">
                    {lang.get("shipment", ["Person 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Tracking Number */}
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("shipment", ["Tracking Number"])}
                  </span>
                }
                name="trackingNumber"
              >
                <Input
                  placeholder={lang.get("shipment", ["Enter Tracking Number"])}
                  size="large"
                />
              </Form.Item>
            </Col>

            {/* Shipping Document */}
            <Col xs={24} sm={24} md={24} lg={24}>
              <ProFormUploadDragger
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("items", ["Item Image"])}
                  </span>
                }
                name="itemImage"
                max={5}
                fieldProps={{
                  name: "file",
                  listType: "picture-card",
                }}
                action="/upload.do"
                title={lang.get("project", [
                  "Click or drag files into this area to upload",
                ])}
                description={lang.get("project", [
                  "Support single or batch upload",
                ])}
                accept="image/*"
              />
            </Col>
          </Row>

          <Row gutter={[16, 5]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Admin Note"])}
                  </span>
                }
                name="adminNote"
              >
                <Editor
                  apiKey="your-tinymce-api-key"
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: ["lists link code"],
                    toolbar:
                      "undo redo | formatselect | bold italic underline | bullist numlist | link code",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 5]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Client Note"])}
                  </span>
                }
                name="termsAndConditions2"
              >
                <Editor
                  apiKey="your-tinymce-api-key"
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: ["lists link code"],
                    toolbar:
                      "undo redo | formatselect | bold italic underline | bullist numlist | link code",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          {/* Submit Section */}
          <Row justify="end" gutter={16}>
            <Button
              color="primary"
              variant="outlined"
              icon={<SendOutlined />}
              style={{ marginBottom: "30px", marginTop: "30px" }}
              onClick={() =>
                console.log(
                  lang.get("shipment", [
                    "Send the created shipment for approval",
                  ])
                )
              }
            >
              {lang.get("shipment", ["Send for Approval"])}
            </Button>
          </Row>
          <Row justify="end" gutter={16}>
            <Col>
              {/* Close Button */}
              <Button
                onClick={() => console.log(lang.get("shipment", ["Close"]))}
                style={{
                  backgroundColor: "white",
                  borderColor: "#d9d9d9",
                  color: "#000",
                  marginRight: 8,
                }}
              >
                {lang.get("shipment", ["Close"])}
              </Button>

              {/* Save Button */}
              <Button
                type="primary"
                onClick={() => console.log(lang.get("shipment", ["Save"]))}
                style={{
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  color: "#fff",
                  marginRight: 8,
                }}
              >
                {lang.get("shipment", ["Save"])}
              </Button>

              {/* Save & Send Request Button */}
              <Button
                type="primary"
                onClick={() =>
                  console.log(lang.get("shipment", ["Save & Send Request"]))
                }
                style={{
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  color: "#fff",
                }}
              >
                {lang.get("shipment", ["Save & Send Request"])}
              </Button>
            </Col>
          </Row>
        </ProForm>
      </ProCard>
    </div>
  );
};

export default ShipmentAdd;
