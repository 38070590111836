import React from "react";
import { Button, Space, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ProposalTable from "./ProposalTable";
import lang from "../../../../../../lang";

const ProposalTab: React.FC = () => {
  return (
    <div style={{  borderRadius: "8px" }}>
      <div>
        <Row
          gutter={[16, 16]}
          style={{ marginBottom: "16px", marginTop: "16px" }}
          align="middle"
          justify="space-between"
        >
          {/* Left Section: Buttons */}
          <Col xs={24} sm={24} md={12} lg={12}>
            <Space size="middle" wrap>
              <Button type="primary" icon={<PlusOutlined />}>
                {lang.get("proposal", ["New Proposal"])}
              </Button>
            </Space>
          </Col>
        </Row>

        <ProposalTable />
      </div>
    </div>
  );
};

export default ProposalTab;
