import React, { useState, useEffect } from "react";
import {
  ConfigProvider,
  Button,
  Space,
  Input,
  Checkbox,
  Typography,
  Card,
  Skeleton,
  theme,
  Divider,
} from "antd";
import {
  LockOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./css/SignIn.css";
import logo from "../assets/img/icons/ERP Test Logo.png";
import { Flex, Spin } from "antd";

import executeRecaptcha from "../common/utils/grecaptcha";
import validation from "../common/utils/validation";
import apiRequest from "../common/utils/apiRequest";

const { Title, Link } = Typography;

const LoginPage: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [reInitializeSession, setReInitializeSession] =
    React.useState<boolean>(true);

  const [loginError, setLoginError] = useState("");
  const [loginBtnText, setLoginBtnText] = useState("Sign In");

  const navigate = useNavigate();

  const toggleTheme = () => {
    setIsDarkMode((prev) => !prev);
  };

  const handleSignIn = () => {
    setEmailError(!validation.validateEmail(email));
    setPasswordError(!validation.validatePassword(password));

    if (!emailError && !passwordError) {
      const API = new apiRequest(
        "https://demo.globalcloudmedia.com/authorize",
        false,
        true
      );
      API.send(
        {
          username: email,
          password: password,
        },
        (data: {
          status: string;
          token: string;
          language: string;
          message: string;
          user: string;
          last_logined: string;
          role: string;
        }) => {
          if (data.status === "success") {
            localStorage.setItem("username", email);
            localStorage.setItem("token", data.token);
            localStorage.setItem("user_lng", data.language);
            localStorage.setItem("role", data.role);
            localStorage.setItem("last_logined", data.last_logined);

            localStorage.setItem(
              "toast",
              JSON.stringify({
                type: "success",
                message: "Welcome Back " + data.user,
                description: "Last Login: " + data.last_logined,
                duration: 3,
                placement: "bottomRight",
              })
            );
            setTimeout(() => {
              navigate("/dashboard", { replace: true });
            }, 100);
          } else {
            setLoginError(data.message);
            setLoginBtnText(data.message);

            setTimeout(() => {
              setLoginError("");
              setLoginBtnText("Sign In");

              handleGCaptcha();
            }, 2000);
          }
        }
      );
    }
  };

  const handleGCaptcha = () => {
    setDisableButton(true);

    const getToken = async () => {
      const token = await executeRecaptcha("submit_form");

      return token;
    };

    getToken()
      .then(() => {
        setLoading(false);
        setDisableButton(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const API = new apiRequest("authorize_token", true, true);
    API.send(
      { token: localStorage.getItem("token") },
      (data: { status: string; redirect: string }) => {
        if (data.status === "success") {
          navigate(data.redirect, { replace: true });
        } else {
          setReInitializeSession(false);
        }
      }
    );

    handleGCaptcha();
  }, []);

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
          colorPrimary: "#007bff",
          borderRadius: 8,
        },
      }}
    >
      <div
        className={`container-fluid d-flex flex-column justify-content-center align-items-center vh-100 ${
          isDarkMode ? "darkMode" : "lightMode"
        }`}
      >
        <div className="position-absolute top-0 end-0 p-3">
          <span
            style={{ cursor: "pointer", fontSize: "1.5rem" }}
            onClick={toggleTheme}
          >
            {isDarkMode ? "☀️" : "🌙"}
          </span>
        </div>

        <div className="fw-bolder fs-5 d-inline-block d-flex align-items-center">
          <img src={logo} alt="Logo" className="logo" />
        </div>

        <Card className={`card ${isDarkMode ? "cardDark" : "cardLight"}`}>
          {reInitializeSession ? (
            <Flex gap="middle" align="center" vertical>
              <Spin spinning={reInitializeSession} size="large"></Spin>
            </Flex>
          ) : (
            <Divider plain>
              <Title level={3} className="header-title">
                Login
              </Title>
            </Divider>
          )}

          {loading && !reInitializeSession ? (
            <>
              <Skeleton.Input
                active
                size="default"
                block={true}
                style={{
                  marginBottom: "10px",
                  marginTop: "25px",
                  width: "100%",
                }}
              />
              <Skeleton.Input
                active
                size="default"
                block={true}
                style={{ marginBottom: "30px", width: "100%" }}
              />
              <Skeleton.Input
                active
                size="default"
                block={true}
                style={{ marginBottom: "20px", width: "100%" }}
              />
            </>
          ) : (
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              {!reInitializeSession && (
                <>
                  <div>
                    <label
                      htmlFor="email"
                      className={`label ${
                        isDarkMode ? "labelDark" : "labelLight"
                      }`}
                    >
                      Email Address
                    </label>
                    <Input
                      prefix={<UserOutlined />}
                      placeholder="name@example.com"
                      size="large"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      status={emailError ? "error" : ""}
                      suffix={
                        emailError && (
                          <ExclamationCircleOutlined style={{ color: "red" }} />
                        )
                      }
                      style={{
                        borderColor: emailError ? "red" : undefined,
                      }}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className={`label ${
                        isDarkMode ? "labelDark" : "labelLight"
                      }`}
                    >
                      Password
                    </label>
                    <Input.Password
                      prefix={<LockOutlined />}
                      placeholder="Password"
                      size="large"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      status={passwordError ? "error" : ""}
                      suffix={
                        passwordError && (
                          <ExclamationCircleOutlined style={{ color: "red" }} />
                        )
                      }
                      style={{
                        borderColor: passwordError ? "red" : undefined,
                      }}
                    />
                  </div>
                  <Space
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                    className="form-options"
                  >
                    <Checkbox>Keep me signed in</Checkbox>
                    <Link href="/forgot-password">Forgot Password?</Link>
                  </Space>

                  <Button
                    type="primary"
                    block
                    size="large"
                    loading={disableButton}
                    onClick={handleSignIn}
                    danger={loginError !== ""}
                  >
                    {loginBtnText}
                  </Button>
                </>
              )}
            </Space>
          )}
        </Card>

        {/* Footer Section */}
        <div className="login-footer">
          <div className="footer-text">
            This site is protected by{" "}
            <Link href="/privacy">Privacy Policy</Link>
          </div>
          <div className="footer-links">
            <Link href="/terms">Terms and Conditions</Link>
            <Link href="/privacy">Privacy Policy</Link>
            <Link href="/ca-privacy">CA Privacy Notice</Link>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default LoginPage;
