import { Card, Row, Col } from "antd";
import { MailOutlined } from "@ant-design/icons";
import lang from "../../../../lang";
export type Customer = {
  id: string;
  name: string;
  vat_number: string;
  phone: string;
  currency: string;
  address: string;
  last_login: string;
  default_language: string;
  primary_email?: string;
  last_order?: string;
};

const CustomerAddressCard = () => {
  const data = [
    { amount: "LKR 4,000", label: "Total Income" },
    { amount: "LKR 5,565", label: "Total Expenses" },
    { amount: "LKR 4,000", label: "Net Profit" },
    { amount: "LKR 5,565", label: "Gross Revenue" },
  ];

  return (
    <Card bordered={false} style={{ borderRadius: "8px", height: "100%" }}>
      <Row justify="center" gutter={[16, 16]}>
        {data.map((item, index) => (
          <Col xs={12} key={index} className="text-center">
            <MailOutlined
              style={{
                fontSize: "31px",
                color: "#52c41a",
                marginBottom: "8px",
              }}
            />
            <h1 style={{ fontSize: "20px", marginTop: "8px" }}>
              {item.amount}
            </h1>
            <p style={{ fontSize: "14px", margin: 0 }}>
              {lang.get("customer", [item.label])}
            </p>
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export default CustomerAddressCard;
