import { useState, useEffect, useRef } from "react";
import { Menu, Button } from "antd";
import {
  DollarOutlined,
  BarChartOutlined,
  UserOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import lang from "../../../../../../lang";
import RequestTab from "./request/Requests";
import ContractTab from "./contract/Contract";
import OrdersTab from "./orders/Order";


const PurchaseTab = () => {
  const [activeKey, setActiveKey] = useState("request");
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1920);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollTabs = (direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === "right" ? 150 : -150;
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const purchaseTabs = [
    {
      id: "request",
      label: lang.get("purchaseSection", ["Request"]),
      icon: <UserOutlined />,
    },
    {
      id: "orders",
      label: lang.get("purchaseSection", ["Orders"]),
      icon: <BarChartOutlined />,
    },
    {
      id: "contracts",
      label: lang.get("purchaseSection", ["Contracts"]),
      icon: <DollarOutlined />,
    },
   
  ];

  const tabContent: Record<string, JSX.Element> = {
    request: <RequestTab />,
    orders: <OrdersTab />,
    contracts: <ContractTab/>,
 
  };

  return (
    <>
      <div
        style={{
          background: "#fff",
          borderRadius: 5,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {isSmallScreen && (
            <Button
              icon={<LeftOutlined />}
              type="text"
              onClick={() => scrollTabs("left")}
            />
          )}
          <div
            ref={scrollContainerRef}
            style={{
              display: "flex",
              overflowX: "auto",
              scrollbarWidth: "none",
              gap: "10px",
              width: "100%",
              whiteSpace: "nowrap",
            }}
          >
            <Menu
              onClick={({ key }) => setActiveKey(key)}
              selectedKeys={[activeKey]}
              mode="horizontal"
              style={{
                display: "flex",
                justifyContent: "start",
                columnGap: "20px",
                fontSize: "14px",
              }}
              items={purchaseTabs.map((tab) => ({
                key: tab.id,
                label: tab.label,
                icon: tab.icon,
              }))}
            />
          </div>
          {isSmallScreen && (
            <Button
              icon={<RightOutlined />}
              type="text"
              onClick={() => scrollTabs("right")}
            />
          )}
        </div>
      </div>

      <div style={{ marginTop: "16px" }}>{tabContent[activeKey]}</div>
    </>
  );
};

export default PurchaseTab;
