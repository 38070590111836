import React, { useState } from "react";
import {
  ProForm,
  ProFormText,
  ProFormSelect,
  ProFormDependency,
  ProCard,
  ProFormCheckbox,
} from "@ant-design/pro-components";
import {
  Button,
  Modal,
  Row,
  Col,
  Divider,
  Typography,
  Space,
  Input,
  Select,
  Form,
  DatePicker,
} from "antd";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import AddPerformaTable from "./AddPerformaTable";
import select from "antd/es/select";
import { Editor } from "@tinymce/tinymce-react";
import lang from "../../../../../lang";

const { Title } = Typography;
const Option = select;
const AddPerformaInvoice: React.FC = () => {
  const [isBillToModalVisible, setBillToModalVisible] = useState(false);
  const [isShipToModalVisible, setShipToModalVisible] = useState(false);
  const [discountType, setDiscountType] = useState<string>("%");
  const [discount, setDiscount] = useState<number>(0);
  const [adjustment, setAdjustment] = useState<number>(0);
  const [tax, setTax] = useState<string[]>([]);
  const [subTotal] = useState<number>(0);

  const calculateTotal = () => {
    const discountAmount =
      discountType === "%" ? (subTotal * discount) / 100 : discount;
    return subTotal - discountAmount + adjustment;
  };

  const handleTaxChange = (values: string[]) => {
    console.log("Selected Tax Options: ", values);
    setTax(values);
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      <Title level={2}>{lang.get("sales", ["Create New Invoice"])}</Title>
      <ProCard>
        <ProForm layout="vertical" submitter={false}>
          <Row gutter={[16, 5]}>
            {/* Customer */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Customer"])}
                  </span>
                }
                name="customer"
                rules={[
                  {
                    required: true,
                    message: lang.get("sales", ["Please select a customer!"]),
                  },
                ]}
              >
                <Select
                  placeholder={lang.get("sales", ["Select Customer"])}
                  size="large"
                >
                  <Option value="Customer 1">
                    {lang.get("sales", ["Customer 1"])}
                  </Option>
                  <Option value="Customer 2">
                    {lang.get("sales", ["Customer 2"])}
                  </Option>
                  <Option value="Customer 3">
                    {lang.get("sales", ["Customer 3"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Tags */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Tags"])}
                  </span>
                }
                name="tags"
              >
                <Select
                  placeholder={lang.get("sales", ["Add Tags"])}
                  mode="multiple"
                  size="large"
                >
                  <Option value="Tag1">{lang.get("sales", ["Tag1"])}</Option>
                  <Option value="Tag2">{lang.get("sales", ["Tag2"])}</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Bill To */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Bill To"])}
                  </span>
                }
                name="billTo"
              >
                <Input
                  placeholder={lang.get("sales", ["Bill To Address"])}
                  suffix={
                    <EditOutlined onClick={() => setBillToModalVisible(true)} />
                  }
                  size="large"
                  readOnly
                />
              </Form.Item>
            </Col>

            {/* Ship To */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Ship To"])}
                  </span>
                }
                name="shipTo"
              >
                <Input
                  placeholder={lang.get("sales", ["Ship To Address"])}
                  suffix={
                    <EditOutlined onClick={() => setShipToModalVisible(true)} />
                  }
                  size="large"
                  readOnly
                />
              </Form.Item>
            </Col>

            {/* Invoice Number */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Invoice Number"])}
                  </span>
                }
                name="invoiceNumber"
                rules={[
                  {
                    required: true,
                    message: lang.get("sales", ["Invoice Number is required!"]),
                  },
                ]}
              >
                <Input addonBefore="INV-" placeholder="000000" size="large" />
              </Form.Item>
            </Col>

            {/* Invoice Date */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Invoice Date"])}
                  </span>
                }
                name="invoiceDate"
                rules={[
                  {
                    required: true,
                    message: lang.get("sales", ["Please select a date!"]),
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} size="large" />
              </Form.Item>
            </Col>

            {/* Due Date */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Due Date"])}
                  </span>
                }
                name="dueDate"
                rules={[
                  {
                    required: true,
                    message: lang.get("sales", ["Please select a date!"]),
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} size="large" />
              </Form.Item>
            </Col>

            {/* Payment Modes */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Allowed Payment Modes"])}
                  </span>
                }
                name="paymentModes"
              >
                <Select
                  placeholder={lang.get("sales", ["Select Payment Mode"])}
                  size="large"
                >
                  <Option value="Card Payment">
                    {lang.get("sales", ["Card Payment"])}
                  </Option>
                  <Option value="Bank Transfer">
                    {lang.get("sales", ["Bank Transfer"])}
                  </Option>
                  <Option value="Cash">{lang.get("sales", ["Cash"])}</Option>
                  <Option value="Cheque">
                    {lang.get("sales", ["Cheque"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Currency */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Currency"])}
                  </span>
                }
                name="currency"
              >
                <Select
                  placeholder={lang.get("sales", ["Select Currency"])}
                  size="large"
                >
                  <Option value="LKR">{lang.get("sales", ["LKR"])}</Option>
                  <Option value="USD">{lang.get("sales", ["USD"])}</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Sales Agent */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Sales Agent"])}
                  </span>
                }
                name="salesAgent"
              >
                <Select
                  placeholder={lang.get("sales", ["Select Sales Agent"])}
                  size="large"
                >
                  <Option value="Agent 1">
                    {lang.get("sales", ["Agent 1"])}
                  </Option>
                  <Option value="Agent 2">
                    {lang.get("sales", ["Agent 2"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Recurring Invoice */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Recurring Invoice"])}
                  </span>
                }
                name="recurringInvoice"
              >
                <Select
                  placeholder={lang.get("sales", ["Select Recurring Invoice"])}
                  size="large"
                >
                  <Option value="No">{lang.get("sales", ["No"])}</Option>
                  <Option value="Yes">{lang.get("sales", ["Yes"])}</Option>
                </Select>
              </Form.Item>
            </Col>

            {/* Discount Type */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Discount Type"])}
                  </span>
                }
                name="discountType"
              >
                <Select
                  placeholder={lang.get("sales", ["Select Discount Type"])}
                  size="large"
                >
                  <Option value="No discount">
                    {lang.get("sales", ["No discount"])}
                  </Option>
                  <Option value="BeforeTax">
                    {lang.get("sales", ["Before Tax"])}
                  </Option>
                  <Option value="AfterTax">
                    {lang.get("sales", ["After Tax"])}
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            {/* VAT Number */}
            <Col span={8}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["VAT No"])}
                  </span>
                }
                name="vatNo"
              >
                <Input
                  placeholder={lang.get("sales", ["Enter VAT Number"])}
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <AddPerformaTable />
          <Divider />

          <Row justify="end" gutter={16}>
            <ProFormDependency name={["total", "adjustment"]}>
              {() => (
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                    backgroundColor: "#f9f9fb",
                    padding: "16px",
                    borderRadius: "8px",
                  }}
                >
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      {lang.get("sales", ["Sub Total"])} :
                    </Col>
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      ${subTotal.toFixed(2)}
                    </Col>
                  </Row>
                  <Divider style={{ margin: "8px 0" }} />

                  <Row justify="space-between" style={{ marginBottom: "4px" }}>
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      {lang.get("sales", ["Discount"])} :
                      <Input
                        value={discount}
                        onChange={(e) => setDiscount(Number(e.target.value))}
                        type="number"
                        size="large"
                        style={{ width: "150px", marginLeft: "10px" }}
                        addonAfter={
                          <Select
                            value={discountType}
                            onChange={(value) => setDiscountType(value)}
                            style={{ width: "70px" }}
                            dropdownStyle={{ textAlign: "center" }}
                          >
                            <Option value="%">%</Option>
                            <Option value="Fixed">
                              {lang.get("sales", ["Fixed"])}
                            </Option>
                          </Select>
                        }
                      />
                    </Col>

                    <Col
                      style={{
                        fontWeight: 600,
                        fontSize: "14px",
                        color: "#ff4d4f",
                      }}
                    >
                      -$
                      {discountType === "%"
                        ? ((subTotal * discount) / 100).toFixed(2)
                        : discount.toFixed(2)}
                    </Col>
                  </Row>
                  <Divider style={{ margin: "8px 0" }} />
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      {lang.get("sales", ["Tax"])} :
                      <Select
                        mode="multiple"
                        value={tax}
                        onChange={handleTaxChange}
                        size="large"
                        style={{ width: "150px", marginLeft: "10px" }}
                        placeholder={lang.get("sales", ["Select Tax"])}
                      >
                        <Option value="VAT">
                          {lang.get("sales", ["VAT (5%)"])}
                        </Option>
                        <Option value="CGT">
                          {lang.get("sales", ["CGT (10%)"])}
                        </Option>
                        <Option value="Service Tax">
                          {lang.get("sales", ["Service Tax (8%)"])}
                        </Option>
                      </Select>
                    </Col>
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      ${adjustment.toFixed(2)}
                    </Col>
                  </Row>
                  <Divider style={{ margin: "8px 0" }} />
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "4px" }}
                  >
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      {lang.get("sales", ["Adjustment"])} :
                      <Input
                        value={adjustment}
                        onChange={(e) => setAdjustment(Number(e.target.value))}
                        type="number"
                        size="large"
                        style={{ width: "100px", marginLeft: "10px" }}
                      />
                    </Col>
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      ${adjustment.toFixed(2)}
                    </Col>
                  </Row>
                  <Divider style={{ margin: "8px 0" }} />

                  <Row justify="space-between" align="middle">
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      {lang.get("sales", ["Total"])} :
                    </Col>
                    <Col style={{ fontWeight: 600, fontSize: "14px" }}>
                      ${calculateTotal().toFixed(2)}
                    </Col>
                  </Row>
                </Space>
              )}
            </ProFormDependency>
          </Row>
          <Divider />
          <Row gutter={[16, 5]}>
            <Col span={24}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Client Note"])}
                  </span>
                }
                name="clientNote"
              >
                <Editor
                  apiKey="your-tinymce-api-key"
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: ["lists link code"],
                    toolbar:
                      "undo redo | formatselect | bold italic underline | bullist numlist | link code",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Terms & Conditions */}
          <Row gutter={[16, 5]}>
            <Col span={24}>
              <Form.Item
                label={
                  <span style={{ fontSize: "14px" }}>
                    {lang.get("sales", ["Terms & Conditions"])}
                  </span>
                }
                name="termsConditions"
              >
                <Editor
                  apiKey="your-tinymce-api-key"
                  init={{
                    height: 200,
                    menubar: false,
                    plugins: ["lists link code"],
                    toolbar:
                      "undo redo | formatselect | bold italic underline | bullist numlist | link code",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />

          <Row justify="end" gutter={16}>
            <ProFormCheckbox name="sendForApproval">
              {lang.get("sales", ["Send the created invoice for approval"])}
            </ProFormCheckbox>
          </Row>
          <Row justify="end" gutter={16}>
            <Col>
              <Button
                onClick={() =>
                  console.log(lang.get("sales", ["Saved as Draft"]))
                }
              >
                {lang.get("sales", ["Save as Draft"])}
              </Button>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() =>
                  console.log(lang.get("sales", ["Saved Final Invoice"]))
                }
                style={{ marginLeft: 8 }}
              >
                {lang.get("sales", ["Save"])}
              </Button>
            </Col>
          </Row>
        </ProForm>
      </ProCard>

      {/* Modals */}
      <Modal
        title={lang.get("sales", ["Edit Bill To"])}
        open={isBillToModalVisible}
        onCancel={() => setBillToModalVisible(false)}
        footer={null}
      >
        <ProForm layout="vertical" submitter={false}>
          <ProFormText
            name="street"
            label={lang.get("sales", ["Street"])}
            placeholder={lang.get("sales", ["Enter street"])}
          />
          <ProFormText
            name="city"
            label={lang.get("sales", ["City"])}
            placeholder={lang.get("sales", ["Enter city"])}
          />
          <ProFormText
            name="state"
            label={lang.get("sales", ["State"])}
            placeholder={lang.get("sales", ["Enter state"])}
          />
          <ProFormText
            name="zipCode"
            label={lang.get("sales", ["Zip Code"])}
            placeholder={lang.get("sales", ["Enter zip code"])}
          />
          <ProFormSelect
            name="country"
            label={lang.get("sales", ["Country"])}
            placeholder={lang.get("sales", ["Select country"])}
            options={[
              { value: "USA", label: lang.get("sales", ["USA"]) },
              { value: "UK", label: lang.get("sales", ["UK"]) },
            ]}
          />
        </ProForm>
      </Modal>

      <Modal
        title={lang.get("sales", ["Edit Ship To"])}
        open={isShipToModalVisible}
        onCancel={() => setShipToModalVisible(false)}
        footer={null}
      >
        <ProForm layout="vertical" submitter={false}>
          <ProFormText
            name="street"
            label={lang.get("sales", ["Street"])}
            placeholder={lang.get("sales", ["Enter street"])}
          />
          <ProFormText
            name="city"
            label={lang.get("sales", ["City"])}
            placeholder={lang.get("sales", ["Enter city"])}
          />
          <ProFormText
            name="state"
            label={lang.get("sales", ["State"])}
            placeholder={lang.get("sales", ["Enter state"])}
          />
          <ProFormText
            name="zipCode"
            label={lang.get("sales", ["Zip Code"])}
            placeholder={lang.get("sales", ["Enter zip code"])}
          />
          <ProFormSelect
            name="country"
            label={lang.get("sales", ["Country"])}
            placeholder={lang.get("sales", ["Select country"])}
            options={[
              { value: "USA", label: lang.get("sales", ["USA"]) },
              { value: "UK", label: lang.get("sales", ["UK"]) },
            ]}
          />
        </ProForm>
      </Modal>
    </div>
  );
};

export default AddPerformaInvoice;
