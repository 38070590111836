import React, { useState } from "react";
import { Card, Descriptions, Button, Modal, Col, Row } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import lang from "../../../../../lang";
import ItemAddBody from "../ItemAddBody";
import SalesChart from "../../common/charts/SalesChart";
import WarehouseLevels from "../../common/charts/WarehouseChart";

const OverviewComponent: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div style={{ background: "#f5f7fa" }}>
      <Card
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Truecam Battery</span>
            <div style={{ display: "flex", gap: "10px" }}>
              <Button
                type="primary"
                icon={<EditOutlined />}
                onClick={() => setModalOpen(true)}
              >
                {lang.get("items", ["Edit"])}
              </Button>
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                  console.log("Delete action triggered");
                }}
              >
                {lang.get("items", ["Delete"])}
              </Button>
            </div>
          </div>
        }
        bordered={false}
        style={{ marginBottom: "20px", border: "1px solid #1677ff" }}
      >
        <Descriptions title="General Information" bordered size="small">
          <Descriptions.Item label="Commodity Code">01</Descriptions.Item>
          <Descriptions.Item label="Commodity Name">
            Truecam Battery
          </Descriptions.Item>
          <Descriptions.Item label="Commodity Group">Battery</Descriptions.Item>
          <Descriptions.Item label="Commodity Barcode">
            3750281548
          </Descriptions.Item>
          <Descriptions.Item label="SKU Code">00007</Descriptions.Item>
          <Descriptions.Item label="SKU Name">
            Truecam Battery
          </Descriptions.Item>
          <Descriptions.Item label="Tax 1">0%</Descriptions.Item>
          <Descriptions.Item label="Tax 2">0%</Descriptions.Item>
          <Descriptions.Item label="Tags">Tag</Descriptions.Item>
        </Descriptions>

        <Descriptions
          title="Properties"
          bordered
          size="small"
          style={{ marginTop: "20px" }}
        >
          <Descriptions.Item label="Origin">-</Descriptions.Item>
          <Descriptions.Item label="Model">-</Descriptions.Item>
          <Descriptions.Item label="Colors">-</Descriptions.Item>
          <Descriptions.Item label="Size">-</Descriptions.Item>
          <Descriptions.Item label="Styles">-</Descriptions.Item>
          <Descriptions.Item label="Unit">Nos</Descriptions.Item>
          <Descriptions.Item label="Purchase Price">0.00</Descriptions.Item>
          <Descriptions.Item label="Warranty">Month</Descriptions.Item>
        </Descriptions>

        <Descriptions
          title="Pricing Structure"
          bordered
          size="small"
          style={{ marginTop: "20px" }}
        >
          <Descriptions.Item label="Default Profit Rate (%)">
            -
          </Descriptions.Item>
          <Descriptions.Item label="Purchase Price">USD 0.00</Descriptions.Item>
          <Descriptions.Item label="Expenses Account">-</Descriptions.Item>
          <Descriptions.Item label="Description">-</Descriptions.Item>
          <Descriptions.Item label="Tax">-</Descriptions.Item>
          <Descriptions.Item label="Selling Price">USD 0.00</Descriptions.Item>
          <Descriptions.Item label="Income Account">-</Descriptions.Item>
          <Descriptions.Item label="Inventory Account">-</Descriptions.Item>
          <Descriptions.Item label="Opening Stock">-</Descriptions.Item>
        </Descriptions>

        <Descriptions
          title="Uploaded Images"
          bordered
          size="small"
          style={{ marginTop: "20px" }}
        />
      </Card>

      <Card
        title="Analytics Overview"
        bordered={false}
        style={{ marginBottom: "20px", border: "1px solid #1677ff" }}
      >
        <Row gutter={[16, 16]}>
          {/* First Chart */}
          <Col xs={24} lg={8}>
            <Card
              bordered={false}
              style={{ textAlign: "center", height: "100%" }}
            >
              <SalesChart />
            </Card>
          </Col>

          {/* Second Chart */}
          <Col xs={24} lg={8}>
            <Card
              bordered={false}
              style={{ textAlign: "center", height: "100%" }}
            >
              {/* Placeholder for Chart 2 */}
              <WarehouseLevels />
            </Card>
          </Col>

          {/* Third Chart */}
          <Col xs={24} lg={8}>
            <Card
              bordered={false}
              style={{ textAlign: "center", height: "100%" }}
            >
              <SalesChart />
            </Card>
          </Col>
        </Row>
      </Card>
      <Modal
        title={lang.get("items", ["Add Item"])}
        open={modalOpen}
        footer={null}
        onCancel={() => setModalOpen(false)}
        width={1250}
      >
        <ItemAddBody
          modelClose={() => setModalOpen(false)}
          setUpdateTable={() => console.log("Update table")}
        />
      </Modal>
    </div>
  );
};

export default OverviewComponent;
