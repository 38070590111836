import React, { useState, useEffect, useRef } from "react";
import { Menu, Button, Row, Typography } from "antd";
import {
  AppstoreOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  FileOutlined,
  FileAddOutlined,
  FileProtectOutlined,
  FileSyncOutlined,
  LineChartOutlined,
  ProjectOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
  LeftOutlined,
  RightOutlined,
  HeatMapOutlined,
} from "@ant-design/icons";
import lang from "../../../../lang";
import { projects, Project } from "../../common/data/ProjectsData";
import Tasks from "./tabs/tasks/Tasks";
import Timesheet from "./tabs/timesheet/Timesheet";
import CalendarFull from "./tabs/calendar/Calendar";
import Milestones from "./tabs/milestone/Milestones";
import Files from "./tabs/files/Files";
import Discussion from "./tabs/discussion/Discussion";
import Tickets from "./tabs/tickets/Tickets";
import Contract from "./tabs/contract/Contract";
import Notes from "./tabs/notes/notes";
import Activity from "./tabs/activity/Activity";
import SalesTab from "./tabs/sales/Sales";
import PurchaseTab from "./tabs/purchase/PurchaseSection";

const { Text } = Typography;

interface ProjectTabsProps {
  projectId: number;
}

const ProjectTabs: React.FC<ProjectTabsProps> = ({ projectId }) => {
  const [activeTab, setActiveTab] = useState<string>("overview");
  const [project, setProject] = useState<Project | undefined>();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const foundProject = projects.find((proj) => proj.id === projectId);
    setProject(foundProject);
  }, [projectId]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1920);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollTabs = (direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === "right" ? 150 : -150;
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const tabs = [
    {
      id: "overview",
      label: lang.get("tabs", ["Overview"]),
      icon: <LineChartOutlined />,
    },
    {
      id: "roadmap",
      label: lang.get("tabs", ["Roadmap"]),
      icon: <HeatMapOutlined />,
    },
    {
      id: "tasks",
      label: lang.get("tabs", ["Tasks"]),
      icon: <AppstoreOutlined />,
    },
    {
      id: "timesheets",
      label: lang.get("tabs", ["Timesheets"]),
      icon: <ClockCircleOutlined />,
    },
    {
      id: "calendar",
      label: lang.get("tabs", ["Calendar"]),
      icon: <CalendarOutlined />,
    },
    {
      id: "milestones",
      label: lang.get("tabs", ["Milestones"]),
      icon: <TeamOutlined />,
    },
    { id: "files", label: lang.get("tabs", ["Files"]), icon: <FileOutlined /> },
    {
      id: "discussions",
      label: lang.get("tabs", ["Discussions"]),
      icon: <FileAddOutlined />,
    },
    {
      id: "gantt",
      label: lang.get("tabs", ["Gantt"]),
      icon: <ProjectOutlined />,
    },
    {
      id: "sales",
      label: lang.get("tabs", ["Sales"]),
      icon: <FileSyncOutlined />,
    },
    {
      id: "purchase",
      label: lang.get("tabs", ["Purchase"]),
      icon: <ShoppingCartOutlined />,
    },
    {
      id: "tickets",
      label: lang.get("tabs", ["Tickets"]),
      icon: <CheckCircleOutlined />,
    },
    {
      id: "contracts",
      label: lang.get("tabs", ["Contracts"]),
      icon: <FileProtectOutlined />,
    },
    {
      id: "notes",
      label: lang.get("tabs", ["Notes"]),
      icon: <FileProtectOutlined />,
    },
    {
      id: "activity",
      label: lang.get("tabs", ["Activity"]),
      icon: <FileProtectOutlined />,
    },
  ];

  const tabContent: Record<string, JSX.Element> = {
    overview: <div>{lang.get("tabs", ["Overview Content"])}</div>,
    roadmap: <div>{lang.get("tabs", ["Road Map Content"])}</div>,
    tasks: <Tasks />,
    timesheets: <Timesheet projectId={projectId} />,
    calendar: <CalendarFull />,
    milestones: <Milestones />,
    files: <Files />,
    discussions: <Discussion />,
    gantt: <div>{lang.get("tabs", ["Gantt Content"])}</div>,
    sales: <SalesTab />,
    purchase: <PurchaseTab />,
    tickets: <Tickets />,
    contracts: <Contract />,
    notes: <Notes />,
    activity: <Activity />,
  };

  if (!project) {
    return <Text>{lang.get("tabs", ["Project not found"])}</Text>;
  }

  return (
    <>
      <Row
        style={{
          background: "#fff",
          borderRadius: "5px",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          {isSmallScreen && (
            <Button
              icon={<LeftOutlined />}
              type="text"
              onClick={() => scrollTabs("left")}
              style={{ marginRight: "10px" }}
            />
          )}
          <div
            ref={scrollContainerRef}
            style={{
              display: "flex",
              overflowX: "auto",
              scrollbarWidth: "none",
              gap: "10px",
              width: "100%",
              whiteSpace: "nowrap",
            }}
          >
            <Menu
              onClick={({ key }) => setActiveTab(key)}
              selectedKeys={[activeTab]}
              mode="horizontal"
              style={{
                display: "flex",
                justifyContent: "start",
                columnGap: "20px",
                fontSize: "14px",
              }}
              items={tabs.map((tab) => ({
                key: tab.id,
                label: (
                  <span>
                    {tab.icon} {tab.label}
                  </span>
                ),
              }))}
            />
          </div>
          {isSmallScreen && (
            <Button
              icon={<RightOutlined />}
              type="text"
              onClick={() => scrollTabs("right")}
              style={{ marginLeft: "10px" }}
            />
          )}
        </div>
      </Row>
      <div style={{ background: "#f5f7fa", borderRadius: "5px" }}>
        {tabContent[activeTab]}
      </div>
    </>
  );
};

export default ProjectTabs;
