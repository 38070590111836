import { Breadcrumb, Typography, Button, Space, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import lang from "../../../lang";
import PurchaseOrderTable from "./PurchaseOrderTable";
import { useNavigate } from "react-router-dom";

// import PurchaseOrderTable from "./PurchaseOrderTable"; // Import PurchaseOrderTable component

const { Title } = Typography;

const PurchaseOrder: React.FC = () => {
  const navigate = useNavigate();

  const handleAddOrderReturnClick = () => {
    navigate("/purchase/purchase-order/order-add");
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
      }}
    >
      {/* Breadcrumb */}
      <Breadcrumb style={{ marginBottom: "16px" }} separator=">">
        <Breadcrumb.Item>{lang.get("breadcrumbs", ["Home"])}</Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Purchases"])}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {lang.get("breadcrumbs", ["Purchase Orders"])}
        </Breadcrumb.Item>
      </Breadcrumb>

      {/* Page Title */}
      <Title level={2} style={{ marginBottom: "16px" }}>
        {lang.get("purchaseOrders", ["Purchase Order List"])} (4)
      </Title>

      {/* Filter and Actions */}
      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Space size="middle" wrap>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => handleAddOrderReturnClick()}
            >
              {lang.get("purchaseOrders", ["Add Purchase Order"])}
            </Button>
          </Space>
        </Col>
      </Row>

      {/* Table */}
      <PurchaseOrderTable />
    </div>
  );
};

export default PurchaseOrder;
